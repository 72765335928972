import { React, Storage, useNavigate } from "../../../common";
import { GetPreApproval } from "../../../services/dashboardApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [list, setList] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    global.Busy(true);
    setList([]);
    let rslt = await GetPreApproval();
    if (rslt.status === 100) {
      let tmp = rslt.data || [];
      setList(tmp);
    }
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const GetStatusIndicator = () => {
    if (list && list.statusIndicator) {
      return parseInt(list.statusIndicator);
    } else {
      let data = Storage.RetrieveJSON("stages");
      let nav = data["stage"];
      if (nav) return 1;
    }
    return 0;
  };

  const OnGettingStarted = (e) => {
    e.preventDefault();
    let data = Storage.RetrieveJSON("stages");
    let nav = data["stage"];
    if (!nav) {
      document.getElementById("ziplook_pop").style.display = "block";
    } else {
      navigate("/preapprove");
    }
  };

  const OnPreApprovalLetter = (e) => {
    e.preventDefault();
    navigate("/letter");
  };

  return (
    <div className="app_box_shadow_noPad">
      <div className="app_headBlk_pad20">
        <div className="app_headBlk_blkName">Pre-Approval</div>
        <div className="blockRefresh_dash"></div>
      </div>

      <ul className="dash_preapprove_IIIcol">
        <li className="dash_preapprove_IIIcol">
          <div className="dash_preapprove_head">Pre-Approval</div>
          <div className="dash_preapprove_head_separator"></div>
          <div className="dash_preapproveText">
            Click here to get a pre-approval letter
          </div>
          <div className="dash_preapprove_btnLabel">
            <input
              type="button"
              value="Get Pre-Approved"
              className="dash_preapprove_btn"
              disabled={GetStatusIndicator() === 0 ? undefined : "disabled"}
              onClick={(e) => OnGettingStarted(e)}
            />
          </div>
        </li>
        <li className="dash_preapprove_IIIcol">
          <div className="dash_preapprove_head">Resume Saved Form</div>
          <div className="dash_preapprove_head_separator"></div>
          <div className="dash_preapproveText">
            Click here to resume and complete the saved form
          </div>
          <div className="dash_preapprove_btnLabel">
            <input
              type="button"
              value="Resume Form"
              className="dash_preapprove_btn"
              onClick={(e) => OnGettingStarted(e)}
              disabled={GetStatusIndicator() === 1 ? undefined : "disabled"}
            />
          </div>
        </li>
        <li className="dash_preapprove_IIIcol">
          <div className="dash_preapprove_head">Pre-Approval Letter</div>
          <div className="dash_preapprove_head_separator"></div>
          <div className="dash_preapproveText">
            Click here to download your pre-approval letter
          </div>
          <div className="dash_formText" style={{ display: "none" }}>
            You do not have any pre-qualification letter. <br />
            Please complete the pre-qualification form and download the letter
            from here.
          </div>
          <div className="dash_preapprove_btnLabel">
            <input
              type="button"
              value="Download Letter"
              className="dash_preapprove_btn"
              onClick={(e) => OnPreApprovalLetter(e)}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Component;
