import {
  React, Link, Helper, TextInput2 as TextInput,
  RadioInput2 as RadioInput, CheckInput2 as CheckInput
} from "../../../../common";

const RendorMilitaryService = (props) => {
  const { item, tag, title, path, onHelpClicked, onInputChanged, onBlurError, onInputClicked, errors } = props;

  const routePath = `${path}.${tag}`;

  const OnHelpClicked = (e) => {
    e.preventDefault();
    if (onHelpClicked) onHelpClicked(2, title);
  };

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">Military Service of {title}</div>
        <div className="formTipHolder">
          <Link onClick={(e) => OnHelpClicked(e)} to="#" title="Info">
            <div className="formTip"></div>
          </Link>
        </div>
      </div>
      <div className="form1003ContentBlk">
        <div className="spacer_20margin_bottom">
          <div className="form1003ContentHead">
            Did you (or your deceased spouse) ever serve, or are you currently
            serving, in the United States Armed Forces?
          </div>
          <ul className="form_A">
            <RadioInput
              path={`${routePath}.selfDeclaredMilitaryServiceIndicator`}
              value={Helper.ToUndefinedCheck(item?.selfDeclaredMilitaryServiceIndicator)}
              options={Helper.Options()}
              uselabel={false} onInputChildChanged={onInputChanged} />
          </ul>
        </div>

        {Helper.ToUndefinedCheck(item?.selfDeclaredMilitaryServiceIndicator) && (
          <>
            <div className="spacer_20margin_bottom">
              <div className="form1003ContentHead">Check all that apply</div>
              <div className="spacer_10margin_bottom">
                <div className="formCheckboxLabel">
                  <CheckInput
                    path={`${routePath}.activeDuty`}
                    value={item?.activeDuty}
                    content={"Currently serving on active duty with projected expiration date of service/tour"}
                    onInputChildChanged={onInputChanged}
                  />
                </div>
              </div>
              <div className="spacer_10margin_bottom">
                <div className="formCheckboxLabel">
                  <CheckInput
                    path={`${routePath}.veteran`}
                    value={item?.veteran}
                    content={"Currently retired, discharged, or separated from service"}
                    onInputChildChanged={onInputChanged}
                  />
                </div>
              </div>
              <div className="spacer_10margin_bottom">
                <div className="formCheckboxLabel">
                  <CheckInput
                    path={`${routePath}.reserveNationalGuardReserveActivated`}
                    value={item?.reserveNationalGuardReserveActivated}
                    content={"Only period of service was as a non-activated member of the Reserve or National Guard"}
                    onInputChildChanged={onInputChanged}
                  />
                </div>
              </div>
              <div className="spacer_10margin_bottom">
                <div className="formCheckboxLabel">
                  <CheckInput
                    path={`${routePath}.spousalVABenefitsEligibilityIndicator`}
                    value={item?.spousalVABenefitsEligibilityIndicator}
                    content={"Surviving spouse"}
                    onInputChildChanged={onInputChanged}
                  />
                </div>
              </div>
            </div>

            <ul className="formContentIIcol">
              <li className="formContentIIcol">
                <div className="form1003ContentHead">
                  Projected expiration date of service/tour
                </div>
                <div className="form_dateicon"></div>
                <TextInput
                  path={`${routePath}.militaryServiceExpectedCompletionDate`}
                  value={Helper.ToDate(item?.militaryServiceExpectedCompletionDate, "MM/DD/YYYY")}
                  maxLength={10}
                  placeholder="MM/DD/YYYY"
                  valuetype={'DATE'}
                  validate={true}
                  validationMessage={'Enter valid Date'}
                  errors={errors}
                  onInputChildChanged={onInputChanged}
                  onBlurError={onBlurError}
                  onInputClicked={onInputClicked}
                />
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  );
};

const Component = (props) => {
  const { item, onInputChanged, onHelpClicked, onBlurError, onInputClicked, errors } = props;

  const rootpath = `militaryService`;
  let clsStyle = item?.coBorrowerIndicator ? "form1003IIcol" : "form1003Icol";

  return (
    <ul className={clsStyle}>
      <li className={clsStyle}>
        <RendorMilitaryService
          item={item?.borrower}
          title={"Borrower"}
          tag={"borrower"}
          path={rootpath}
          onInputChanged={onInputChanged}
          onHelpClicked={onHelpClicked}
          onBlurError={onBlurError}
          onInputClicked={onInputClicked}
          errors={errors}
        />
      </li>
      {item?.coBorrowerIndicator && (
        <li className={clsStyle}>
          <RendorMilitaryService
            item={item?.coborrower}
            title={"Co-Borrower"}
            tag={"coborrower"}
            path={rootpath}
            onInputChanged={onInputChanged}
            onHelpClicked={onHelpClicked}
            onBlurError={onBlurError}
            onInputClicked={onInputClicked}
            errors={errors}
          />
        </li>
      )}
    </ul>
  )


};

export default Component;
