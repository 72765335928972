import { Helper, Link, React, DropDown } from "../../../../common";
import Container from "../../../container";
import { GetAllLoanOffiers } from "../../../../services/loanApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [rows, setRows] = React.useState({});
  const [filterRows, setFilterRows] = React.useState({});
  const [statesList, setStatesList] = React.useState(null);
  const [filterState, setFilterState] = React.useState(0);
  const [stateName, setStateName] = React.useState(null);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    global.Busy(true);
    let rslt = await GetAllLoanOffiers();
    if (rslt.status === 100) {
      let tmp = rslt.data;
      setRows({ childs: tmp });
      ExtractStates(tmp);
      let tmp2 = ExtractRows(tmp);
      setFilterRows({ childs: tmp2 });
    }
    global.Busy(false);
  };

  const ExtractStates = (input) => {
    let _data = input
      .map((x) => x.statename)
      .filter((z) => !Helper.IsNullValue(z))
      .filter(Helper.GetUniqueValues);

    let tmp = [];

    tmp.push({ id: 0, value: "All States" });

    _data.forEach((elm, index) => {
      tmp.push({ id: index + 1, value: elm });
    });

    setStatesList(tmp);
  };

  const ExtractRows = (input) => {
    let _data = input
      .map((x) => x.email)
      .filter((z) => !Helper.IsNullValue(z))
      .filter(Helper.GetUniqueValues);

    let tmp = [];
    _data.forEach((elm, index) => {
      tmp.push(elm);
    });

    let rslts = [];

    tmp.forEach((elm) => {
      let _row = input.filter((x) => x.email === elm);
      if (_row.length > 1) {
        let _states = _row
          .map((x) => x.statename)
          .filter(Helper.GetUniqueValues)
          .join(", ");
        _row[0].statename = _states;
        rslts.push(_row[0]);
      } else {
        rslts.push(_row[0]);
      }
    });
    return rslts;
  };

  if (initlized) {
    setInitlized(false);
    setRows({});
    setStatesList([]);
    setFilterRows({});
    setStateName(null);
    setFilterState(0);
    FetchResults();
  }

  const RenderOfficerLocation = ({ item }) => {
    return (
      <li className="lo_display_blk">
        <div className="loName">{item.fullname}</div>
        <ul className="lo_details">
          <li className="lo_details">Work Phone:</li>
          <li className="lo_details">{item.work_phone}</li>
          <li className="lo_details">Cell Phone:</li>
          <li className="lo_details">{item.mobile_number}</li>
          <li className="lo_details">Email:</li>
          <li className="lo_details">{item.email}</li>
          <li className="lo_details">NMLS ID:</li>
          <li className="lo_details">{item.nmls_id}</li>
          <li className="lo_details">State:</li>
          <li className="lo_details">
            <Link to="#" title={item.statename}>
              {item.statename}
            </Link>
          </li>
        </ul>
      </li>
    );
  };

  const OnInputChanged = async (parent, path, name, value) => {
    setFilterState(value);
  };

  const OnStatesFiltered = (e) => {
    e.preventDefault();
    let _crows = rows.childs;
    setStateName(null);
    if (!Helper.IsNullValue(filterState) && parseInt(filterState) > 0) {
      let _state = statesList.filter((x) => x.id === parseInt(filterState));
      if (_state && _state.length > 0) {
        setStateName(_state[0].value);
        _crows = _crows.filter((x) => x.statename === _state[0].value);
      }
    }

    let tmp2 = ExtractRows(_crows);
    setFilterRows({ childs: tmp2 });
  };

  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_about">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcHeadTxt_generic">Find Loan Officer</div>
                  </li>
                  <li className="bc_col">&nbsp;</li>
                </div>
              </ul>
            </div>

            <div className="widthfix_10px">
              <div className="lo_pgBlk">
                <ul className="filterLo">
                  <li className="filterLo">
                    <div className="selectFilterLO">
                      <DropDown
                        tag={"dpStateName"}
                        path={"dpStateName"}
                        name={"dpStateName"}
                        value={filterState}
                        items={statesList}
                        displayName={"value"}
                        displayValue={"id"}
                        onInputChildChanged={OnInputChanged}
                      />
                    </div>
                  </li>
                  <li className="filterLo">
                    <div className="filterLo_but_blk">
                      <input
                        type="button"
                        value="Search"
                        className="filterLo_but"
                        onClick={(e) => OnStatesFiltered(e)}
                      />
                    </div>
                  </li>
                </ul>

                <div className="loResults">
                  Showing Loan Officers {stateName && `for ${stateName}`}
                </div>

                <ul className="lo_display_blk">
                  {filterRows &&
                    filterRows.childs &&
                    filterRows.childs.map((x, index) => {
                      return <RenderOfficerLocation key={index} item={x} />;
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
