import React from "react";
import { Link } from "react-router-dom";
import helper from "../helper";

const Component = (props) => {
  const {
    name,
    value,
    options,
    uselabel,
    onInputChildChanged,
    errors,
    index,
    parent,
    path,
  } = props;

  let tIndex = index || 0;

  const OnCheckClicked = (e) => {
    helper.RemoveErrorIndex(errors, name);
    if (onInputChildChanged) {
      let _val = uselabel ? e.label : e.value;
      if (name) {
        onInputChildChanged(parent, path, name, _val, tIndex);
      } else {
        onInputChildChanged(parent, path, _val, tIndex);
      }
    }
  };

  let errorStyle = {
    borderColor: "#b22929",
    borderStyle: "solid",
    borderWidth: 1,
  };

  return (
    <>
      {options &&
        options.map((x, i) => {
          return (
            <li key={i}>
              <Link
                to="#"
                className={
                  x.value === value || x.label === value ? "current" : undefined
                }
                style={
                  helper.GetErrorIndex(errors, name) > -1
                    ? errorStyle
                    : { borderWidth: 1 }
                }
                onClick={(e) => OnCheckClicked(x)}
              >
                {x.label}
              </Link>
            </li>
          );
        })}
    </>
  );
};

export default Component;
