import { React } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../components";

const Component = () => {
    const [tabItem, setTabItem] = React.useState("");

    const OnToggleTabe = (tab) => {
        if (tab === tabItem) tab = "";
        setTabItem(tab);
    };

    return (
        <>
            <Container>
                <div id="container">
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">
                                            Frequently Asked Questions
                                        </div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">
                                                What is pre-Approval process in Mortgage?
                                            </li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value={""}
                                                    className={
                                                        tabItem === "Block1"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block1")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block1" && (
                                            <div className="childBlock">
                                                A pre-approval is a process that officially confirms how much you're able to borrow. Your income and asset documents go through a review. After getting pre-approved, you're able to take a more serious look at buying a house.If you're not able to get pre-approved, your adviser will be able to offer some helpful tips on raising your credit score, lowering your debt, or working through any other financial obstacles preventing you from buying a home.
                                            </div>
                                        )}
                                    </div>

                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">What is better, Renting or Buying with Mortgage?</li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value=""
                                                    className={
                                                        tabItem === "Block2"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block2")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block2" && (
                                            <div className="childBlock">
                                                Buying a House is always a smart move than renting. The fact is, with renting, you'll never have a chance to earn your money back. When you buy a house, you're making steady progress toward owning your property. When your loan term is done, you're no longer paying a mortgage. That'll never happen when you rent. Plus, you have the opportunity to sell your home and make some money back.
                                            </div>
                                        )}
                                    </div>

                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">What are the different types of loan?</li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value={""}
                                                    className={
                                                        tabItem === "Block3"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block3")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block3" && (
                                            <div className="childBlock">
                                                <b>Conventional - </b>Lower rates and fees for borrowers making a down payment with good credit<br /><br />
                                                <b>FHA -</b> Popular with first-time homebuyers due to lower down payment requirements<br /><br />
                                                <b>USDA -</b> Zero-down options for rural borrowers in small towns<br /><br />
                                                <b>VA -</b> Competitive rates, zero-down options, and no private mortgage insurance (PMI) requirement for veterans, active service members, and their surviving spouses.

                                            </div>
                                        )}
                                    </div>

                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">What is the Minimum Down payment for a loan?</li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value={""}
                                                    className={
                                                        tabItem === "Block4"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block4")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block4" && (
                                            <div className="childBlock">
                                                Loan options are available for 3.5% or even zero down. A standard 20% down payment will reduce your monthly payments and the total amount of interest you pay over the life of the loan, but it's definitely not required for all borrowers.
                                            </div>
                                        )}
                                    </div>

                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">15 year or 30 year Mortgage what should I prefer?</li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value={""}
                                                    className={
                                                        tabItem === "Block5"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block5")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block5" && (
                                            <div className="childBlock">
                                                Its depends on your monthly repayment capability. While a 15-year mortgage will save a lot on interest compared to a 30-year, the monthly payments will be much higher. A 30-year mortgage would allow a family to move into a nicer home and still afford the monthly payments. Your mortgage adviser can help you compare the pros and cons of both options.
                                            </div>
                                        )}
                                    </div>

                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">What are the additional fees that come with a Mortgage loan?</li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value={""}
                                                    className={
                                                        tabItem === "Block6"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block6")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block6" && (
                                            <div className="childBlock">
                                                Origination fee - Office admin fees - Document preparation fees - Application fee - Appraisal fee - Lender-required home inspections (roof, sewage, pest) - Credit report fee. <br /><br />
                                                At i3lending, we promise the customer of the least possible fees for the above.
                                            </div>
                                        )}
                                    </div>

                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">How long does it take to close a Mortgage?</li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value={""}
                                                    className={
                                                        tabItem === "Block7"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block7")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block7" && (
                                            <div className="childBlock">
                                                From 1003 application to loan funding, the average time it takes to close on the purchase of a home is 30 days. Refinances are much faster. We take our borrowers' closing timelines seriously, and we work hard to close on time. There are some record time closings with super active borrowers with just seven days too.
                                            </div>
                                        )}
                                    </div>

                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">Whom should I approach first? Lender or Realtor?</li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value={""}
                                                    className={
                                                        tabItem === "Block8"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block8")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block8" && (
                                            <div className="childBlock">
                                                We recommend getting pre-approved with a mortgage lender first. Once you're pre-approved for a mortgage, you'll be able to shop with confidence. You'll know exactly how much you're able to purchase.
                                            </div>
                                        )}
                                    </div>

                                    <div className="faqBlk">
                                        <ul className="faqQ">
                                            <li className="faqQ">How do I start the process?</li>
                                            <li className="faqQ">
                                                <input
                                                    type="button"
                                                    value={""}
                                                    className={
                                                        tabItem === "Block9"
                                                            ? "faq_button"
                                                            : "faq_button_select"
                                                    }
                                                    onClick={() => OnToggleTabe("Block9")}
                                                />
                                            </li>
                                        </ul>
                                        {tabItem === "Block9" && (
                                            <div className="childBlock">
                                                Just create an account and fill in our 1003 form.
                                            </div>
                                        )}
                                    </div>

                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
