import { React, Link } from "../../../../common";
import Container from "../../../container";

const Component = () => {

    const smlTexasClick = () => {
        window.open("https://www.sml.texas.gov", "_blank");
    };

    const OnNmlsLookupClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1020884";
        window.open(_link, "_blank");
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Disclosures and Licenses</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="Icol">
                                <div className="pgTxtBold_L">i3 Lending Disclosures and License Information</div>
                                <div className="pgTxtNormal">i3 Lending is an Equal Housing Lender. As prohibited by federal law, we do not engage in business practices that discriminate on the basis of race, color, religion, national origin, sex, marital status, age (provided you have the capacity to enter into a binding contract), because all or part of your income may be derived from any public assistance program, or because you have, in good faith, exercised any right under the Consumer Credit Protection Act. The federal agency that administers our compliance with these federal laws is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC, 20580.</div>
                            </div>
                            <div className="pgTxtBold">i3 Lending State Disclosure Requirements</div>
                            <div className="pgTxtNormal">i3 Lending, Inc. is a licensed mortgage lender. <br />The following states require disclosure of licensing information. (If your state is not listed, it doesn't require a specific license disclosure):</div>
                            <ul className="bulletPoints_un">
                                <li className="bp_circleOutline"><b>Alabama :</b> Consumer Credit License NMLS# - 22208.</li>
                                <li className="bp_circleOutline"><b>Arkansas :</b> Combination Mortgage Banker-Broker-Servicer License, License # 115373.</li>
                                <li className="bp_circleOutline"><b>California :</b> Loans made or arranged pursuant to a California Finance Lenders Law license (603L562).</li>
                                <li className="bp_circleOutline"><b>Colorado :</b> i3 Lending, Inc., (888) 975-1374 NMLS# - 1020884. Regulated by the Colorado Division of Real Estate.</li>
                                <li className="bp_circleOutline"><b>District of Columbia :</b> Mortgage Lender License, License # MLB1020884.</li>
                                <li className="bp_circleOutline"><b>Delaware :</b> Licensed Mortgage Loan Broker, Broker License# - 012797, Lender License# - 019014.</li>
                                <li className="bp_circleOutline"><b>Florida :</b> Mortgage Lender License# - MLD1115.</li>
                                <li className="bp_circleOutline"><b>Illinois :</b> Residential Mortgage License, License # MB.6761370.</li>
                                <li className="bp_circleOutline"><b>Louisiana :</b> Residential Mortgage Lending License NMLS# - 1020884.</li>
                                <li className="bp_circleOutline"><b>Maryland :</b> Mortgage Lender License# 06-20724.</li>
                                <li className="bp_circleOutline"><b>Michigan :</b> Mortgage Broker/Lender License: i3 Lending, Inc., NMLS# - FL0019528.</li>
                                <li className="bp_circleOutline"><b>Mississippi :</b> Mortgage Lender License NMLS# - 1020884.</li>
                                <li className="bp_circleOutline"><b>New Jersey :</b> Loans made or arranged by third parties. Licensed by the N.J. Department of Banking and Insurance. Correspondent Residential Mortgage Lender License #NJ-C-01020, NMLS# - 1020884.</li>
                                <li className="bp_circleOutline"><b>North Carolina :</b> Mortgage Loan Act Certificate of Registration: i3 Lending, Inc., NMLS# - L-162337.</li>
                                <li className="bp_circleOutline"><b>Ohio :</b> Mortgage Loan Act Certificate of Registration: i3 Lending, Inc., NMLS# - SM.501900.000. 1900 Boothe Circle, Suite #100, Longwood, FL 32750</li>
                                <li className="bp_circleOutline"><b>Pennsylvania :</b> Licensed by the Pennsylvania Department of Banking and Securities under Mortgage Loan Correspondent Lender License #45072, NMLS# - 50211.</li>
                                <li className="bp_circleOutline"><b>South Carolina :</b> DCA Mortgage Broker License NMLS# - MB-1602200.</li>
                                <li className="bp_circleOutline"><b>Tennessee :</b> (Mortgage License# - 115450).</li>
                                <li className="bp_circleOutline"><b>Texas :</b> Licensed by the Texas Department of Savings and Mortgage Lending, License # 1020884.</li>
                                <li className="bp_circleOutline"><b>Washington :</b> Licensed by the Washington Consumer Loan Company, License Number : CL-1020884.</li>
                            </ul>
                            <div className="pgTxtNormal">Visit the <Link to="#" onClick={OnNmlsLookupClicked}>NMLS access page</Link> for more information.</div>
                            <div className="pgTxtNormal"><b>In New Jersey -</b> i3 Lending, Inc. arranges HECM loans with third-party providers and will not make any mortgage loan commitments or fund any mortgage loans under the advertised program.</div>
                            <div className="pgTxtNormal"><b>In Texas -</b> Consumers wishing to file a complaint against a company or a residential mortgage loan originator should complete and send a complaint form to the Texas department of savings and mortgage Lending, 2601 North Lamar, Suite 201, Austin, Texas 78705. Complaint Forms and instructions may be obtained from the department's website at <Link to="#" onClick={smlTexasClick}>www.sml.texas.gov</Link>. A toll-free consumer hotline is available at 1-877-276-5550.</div>
                            <div className="pgTxtNormal">The department maintains a recovery fund to make payments of certain actual out of pocket damages sustained by borrowers caused by acts of licensed residential mortgage loan originators. A written application for reimbursement from the recovery fund must be filed with and investigated by the department prior to the payment of a claim. For more information about the recovery fund, please consult the department's website at <Link to="#" onClick={smlTexasClick}>www.sml.texas.gov</Link>.</div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;