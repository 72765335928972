import { React, Link, Helper, TextInput2 as TextInput } from "../../../common";

const Component = ({ title }) => {
  console.log(title);
  const [amount, setAmount] = React.useState("");
  const [rate, setRate] = React.useState("");
  const [term, setTerm] = React.useState("");

  const [monthlyEmi, setMonthlyEmi] = React.useState("0.00");

  const OnApplyNowClicked = (e) => {
    e.preventDefault();
    if (!Helper.IsNullValue(monthlyEmi) && monthlyEmi !== '0.00') {
      let _link = `${window.location.href}?q=/stage1`;
      window.location.href = _link;
    }
  };

  const ValueIsPresent = (val) => {
    return !Helper.IsNullValue(val) && parseInt(val);
  }

  React.useEffect(() => {
    if (ValueIsPresent(amount) && ValueIsPresent(rate) && ValueIsPresent(term)) {
      let months = term * 12;
      let interest = rate / 12 / 100;
      let monthlyPayment = amount * interest * Math.pow(1 + interest, months) / (Math.pow(1 + interest, months) - 1);
      setMonthlyEmi(monthlyPayment.toFixed(2));
    }
  }, [amount, rate, term]);

  return (
    <>
      <div className="inPgRightHeadResponsive">{title}</div>
      <div className="setLoanAmtBlk">
        <ul className="loanAmtCalc">
          <li className="loanAmtCalc">Loan Amount</li>
          <li className="loanAmtCalc">
            <div className="form_dollariconRed"></div>
            <TextInput
              tag={"loanAmount"}
              path={"loanAmount"}
              name={"loanAmount"}
              placeholder={"Loan Amount"}
              value={Helper.ToString(amount)}
              onInputChildChanged={(path, value) => setAmount(value)}
              valuetype={'FINANCE'}
            />
          </li>

          <li className="loanAmtCalc">Interest Rate</li>
          <li className="loanAmtCalc">
            <div className="form_percenticonRed"></div>
            <TextInput
              tag={"loanInterest"}
              path={"loanInterest"}
              name={"loanInterest"}
              placeholder={"Interest Rate"}
              value={Helper.ToString(rate)}
              maxLength={5}
              onInputChildChanged={(path, value) => setRate(value)}
              valuetype={'FINANCE'}
            />
          </li>
          <li className="loanAmtCalc">No. of &nbsp;Years</li>
          <li className="loanAmtCalc">
            <select name="filterType"
              onChange={(e) => setTerm(e.target.value)}
              value={Helper.ToString(term)}>
              <option value="0">Select</option>
              {Helper.range(1, 30).map((x) => (<option key={x} value={x}>{x}</option>))}
            </select>
          </li>
        </ul>
        <ul className="loanAmtEMI">
          <li className="loanAmtEMI">Monthly EMI &nbsp;-</li>
          <li className="loanAmtEMI">$ {monthlyEmi}</li>
        </ul>
        <Link to="#"><input type="button" className="apply_button" value="Apply For Loan" onClick={(e) => OnApplyNowClicked(e)} /></Link>
      </div>

    </>
  );
};

export default Component;
