import { React, Helper, useTimerSession, Container, Session } from "../../common";
import { GetLoan } from "../../services/loanApi";

const Component = () => {
  const [name, setName] = React.useState("");
  const [loanId] = useTimerSession('loanid');
  const [thank] = useTimerSession('thank');

  React.useEffect(() => {
    if (!Helper.IsNullValue(thank)) {
      setName(thank);
      Session.Remove("thank");
    }
  }, [thank]);

  React.useEffect(() => {
    const fn = async () => {
      global.Busy(true);
      if (!Helper.IsNullValue(loanId)) {
        let rslt = await GetLoan(1, loanId);
        if (rslt.status === 100) {
          let _data = rslt.data.stageOne.applicants[0].borrower;
          let _name = Helper.GetStringJoin(_data, ["firstName", "middleName", "lastName"]);
          Session.Remove("loanid");
          setName(_name);
        }
      }
      global.Busy(false);
    };
    fn();
  }, [loanId]);

  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <div className="form1003_pgBlk">
                <div className="formFinishHead">Thank You</div>
                <div className="formFinishText_b">Dear {name}</div>
                <div className="formFinishText">
                  Your application has been submitted, and you will be hearing
                  from someone from our office soon.
                </div>
                <div className="formFinishText">
                  If you have any questions in the meantime, please call us at
                  (888) 975-1374.
                </div>
                <div className="spacer_25margin_bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
