import { React, useNavigate, Helper, useTimerSession } from "../../../common";
import { GetList } from "../../../services/enumsApi";
import { AddLoan, GetShortList, GetLoan } from "../../../services/loanApi";

import Container from "./container1003";

import {
  RenderBorrowerInfo,
  RenderAddress,
  RenderEmployment, RenderEmployments,
  RenderIncomeSources,
} from "./childs";

const AddDefaultObject = (borrowersList) => {

  let _tmp = [];

  borrowersList.forEach((elm) => {
    _tmp.push({
      mapid: elm.mapid,
      applicantType: elm.applicantType,
      owner: elm.applicantType,
      type: elm.type
    })
  });

  // Prepare for residences
  let _res = [
    { index: 0, title: "Current Address", residencyType: 'Current' },
    { index: 1, title: "Previous Address", previousAddressIndicator: true, sameAsCurrentAddressIndicator: false },
    { index: 2, title: "Mailing Address", mailingAddressIndicator: true, sameAsCurrentAddressIndicator: false }
  ];

  // Prepare for Income Sources
  let _incomes = [
    { index: 0, title: "Income from Other Sources", delete: false }
  ];

  // Prepare for employements
  let _emp = [
    {
      index: 0, title: "Complete Current/Self Employment", addEmployment: true,
      currentEmploymentIndicator: true, employmentTypeIndicator: "Current"
    },
    {
      index: 1, title: "Complete Additional/Self Employment and Income", addEmployment: null,
      currentEmploymentIndicator: true, employmentTypeIndicator: "Additional"
    },
    {
      index: 2, title: "Complete Previous/Self Employment and Income", addEmployment: null, delete: false,
      currentEmploymentIndicator: false, employmentTypeIndicator: "Previous"
    }
  ];

  _tmp.forEach((e) => {

    let newItem;

    e.applicant = { applicantType: e.applicantType };
    newItem = JSON.parse(JSON.stringify(_res));
    newItem.forEach((x) => {
      x.applicantType = e.applicantType;
    });
    e.residences = newItem;

    newItem = JSON.parse(JSON.stringify(_incomes));
    newItem.forEach((x) => {
      x.owner = e.owner;
    });
    e.incomesources = newItem;

    newItem = JSON.parse(JSON.stringify(_emp));
    newItem.forEach((x) => {
      x.owner = e.owner;
    });
    e.employments = newItem;
    e.completed = false;
  });

  return _tmp;
}

const copyAddress = ["uRLA2020StreetAddress", "addressUnitIdentifier", "addressUnitDesignatorType", "addressCity", "addressState", "addressPostalCode", "country"];

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [loadData, setLoadData] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loanId] = useTimerSession("loanid");
  const [row, setRow] = React.useState([]);

  const [borrowersList, setBorrowersList] = React.useState([]);
  const [selectedBorrower, setSelectedBorrower] = React.useState("");
  const [selectedBorrowerIndex, setSelectedBorrowerIndex] = React.useState(0);

  const [citizenshiptypes, setCitizenshipTypes] = React.useState([]);
  const [unittypes, setUnitTypes] = React.useState([]);
  const [housingtypes, setHousingtypes] = React.useState([]);
  const [sourcetypes, setSourcetypes] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [maritalStatusTypes, setMaritalStatusTypes] = React.useState([]);
  const [state, setState] = React.useState(false);
  const [lastStage, setLastStage] = React.useState(3);
  let stageIndicator = 3;
  const navigate = useNavigate();

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchLookUps = async () => {
    return new Promise(async (resolve) => {
      global.Busy(true);
      let idList = [5, 7, 8, 18, 20, 21];
      let rslt = await GetList(idList);
      if (rslt.status === 100) {
        let t, _list;
        _list = rslt.data || [];
        t = _list.filter((x) => parseInt(x.type) === 5);
        Helper.AddSelectOption(t, true, 'value');
        setUnitTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 7);
        Helper.AddSelectOption(t, true, 'value');
        setCitizenshipTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 8);
        Helper.AddSelectOption(t, true, 'value');
        setHousingtypes(t);
        t = _list.filter((x) => parseInt(x.type) === 18);
        Helper.AddSelectOption(t, true, 'value');
        setStatesList(t);
        t = _list.filter((x) => parseInt(x.type) === 20);
        Helper.AddSelectOption(t, true, 'value');
        setMaritalStatusTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 21);
        Helper.AddSelectOption(t, true, 'value');
        setSourcetypes(t);
      }

      global.Busy(false);
      return resolve(true);
    });
  };

  const FetchResults = async () => {
    setErrors([]);
    setBorrowersList([]);
    setRow([]);
    await FetchLookUps();
    global.Busy(true);

    if (!Helper.IsNullValue(loanId)) {
      let rslt = await GetShortList(loanId, 1);
      if (rslt.status === 100) {
        let _data = rslt.data;
        Helper.SortBy(_data, 'mapid');
        setBorrowersList(_data);
      }
    }

    global.Busy(false);
  };

  const ContainsKey = (list, mapid) => {
    return list.findIndex((z) => z === mapid) > -1;
  }

  const FetchData = async () => {
    setRow([]);
    let stageThree, rslt;
    global.Busy(true);
    rslt = await GetLoan(stageIndicator, loanId);
    if (rslt.status === 100) {
      let _data = rslt.data;
      setLastStage(_data.stageIndicator);
      stageThree = _data.stageThree.applications;
    }

    let _tmpList, mapList;
    if (Helper.IsNullValue(stageThree) || stageThree.length === 0) {
      stageThree = AddDefaultObject(borrowersList);
    } else {
      mapList = stageThree.map((x) => x.mapid); // 3 of 5 completed = true
      _tmpList = borrowersList.filter((x) => !ContainsKey(mapList, x.mapid));
      let missingList = AddDefaultObject(_tmpList); // rest of 2 completed = false
      let finalObj = stageThree.concat(missingList); // merging both 3 and 2
      stageThree = finalObj;
    }

    _tmpList = stageThree.filter((z) => z.completed).map((x) => x.mapid); // collecting complted=true
    mapList = borrowersList; // entire borrowers list
    mapList.forEach((x) => { x.completed = false; x.disabled = true; }); // setting completed=false for all borrowers list
    mapList.forEach((x) => { /* set complted=true and disabled = false if borrower is completed */
      x.completed = ContainsKey(_tmpList, x.mapid);
      x.disabled = !x.completed;
    });

    _tmpList = mapList.filter((x) => !x.completed); // collecting completed=false for all borrowers list it will return 2 objects
    if (_tmpList.length > 0) {
      let idx = mapList.findIndex((x) => x.mapid === _tmpList[0].mapid); // setting complted=true first record
      mapList[idx].completed = true;
      mapList[idx].disabled = false;
    }

    setBorrowersList(mapList);
    setRow(stageThree);
    global.Busy(false);
  }

  if (initlized) {
    setInitlized(false);
    setErrors([]);
    setError(null);
    setSuccess(null);
    FetchResults();
  }

  if (loadData) {
    setLoadData(false);
    console.log("Loading Data...");
    FetchData();
  }

  const OnRecordValidate = async () => {
    return new Promise(async (resolve) => {

      setError("");
      setErrors([]);
      setState(!state);
      const { errors, valid } = Helper.IsFormValid('stageThree');
      if (!valid) {
        setErrors(errors);
        setError("Fill all required details");
        return resolve(false);
      }

      row.forEach(r => {
        r.employments.forEach((j, index) => {
          if (!Helper.IsNullValue(j['employmentStartDate']) && !Helper.IsNullValue(j['endDate'])) {
            let rslt = Helper.CompareDate(j['employmentStartDate'], j['endDate'], 'MM/DD/YYYY');
            if (rslt < 1) {
              errors.push({ field: `employments_${index}_employmentStartDate`, value: 'Start date more than end date' });
              errors.push({ field: `employments_${index}_endDate`, value: 'Start date more than end date' });
              setErrors(errors);
            }
          }
        });
      });

      setState(!state);

      return resolve(errors.length === 0);
    });
  };

  const OnUpdateRecord = async (e, saved) => {
    e.preventDefault();
    setState(!state);
    let _status = await OnRecordValidate();
    if (!_status) return;
    setSuccess(null);
    setErrors([]);
    setError(null);
    setSuccess(null);

    row._id = loanId;
    row.stageIndicator = stageIndicator;

    let applicants = JSON.parse(JSON.stringify(row));
    const removeItems = ['stageIndicator', 'statusIndicator', '_id'];
    removeItems.forEach(e => {
      delete applicants[e];
    });

    let newObjs = [];
    for (var cur = 0; cur <= selectedBorrowerIndex; cur++) {
      applicants[cur].completed = true;
      newObjs.push(applicants[cur]);
    }

    stageIndicator = lastStage > 3 ? lastStage : 3

    let newObj = {
      _id: loanId,
      statusIndicator: 0,
      stageIndicator,
      stageThree: { applications: newObjs },
    };

    global.Busy(true);
    let rslt = await AddLoan(newObj);
    global.Busy(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    }

    if (saved) {
      setSuccess(Helper.SavedMessage);
    } else {
      if (selectedBorrowerIndex === borrowersList.length - 1) {
        navigate("/stage5");
      } else {
        MoveNext();
      }
    }
  };

  const OnAddArrayList = (name, json) => {
    let _row = row;
    let obj = row && row[selectedBorrowerIndex];
    if (!Helper.IsJSONEmpty(obj)) {
      json.index = obj[name].length;
      obj[name].push(json);
      _row[selectedBorrowerIndex] = obj;
      setRow(_row);
      setState(!state);
    }
  };

  const OnInputChanged = async (path, value, index) => {
    let _row = row;
    let newRow = Helper.ModifyJsonArrayObject4(_row[selectedBorrowerIndex], path, value);

    if (path.endsWith('sameAsCurrentAddressIndicator')) {
      let _index = path.split('_')[1];
      if (value) {
        copyAddress.forEach(elm => {
          newRow.residences[_index][elm] = newRow.residences[0][elm];
        });
      } else {
        copyAddress.forEach(elm => {
          newRow.residences[_index][elm] = "";
        });
      }
    }

    _row[selectedBorrowerIndex] = newRow;
    setRow(_row);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    setSuccess(null);
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    setSuccess(null);
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const GetArrayObject = (name, requiredChilds, excludeBy, filterBy) => {
    if (!Helper.IsJSONEmpty(row)) {
      let obj = row && row[selectedBorrowerIndex];
      if (name === 'applicant') return obj[name];
      if (!Helper.IsJSONEmpty(obj)) {
        let _childs = obj[name];
        if (!Helper.IsNullValue(filterBy)) {
          _childs = _childs.filter((x) => x.employmentTypeIndicator === filterBy);
        } else if (!Helper.IsNullValue(excludeBy)) {
          _childs = _childs.filter((x) => x.employmentTypeIndicator !== excludeBy);
        }

        if (name === 'residences') {
          let _years = 0;
          _childs.forEach((e, index) => {
            e.usePrevAddr = false;
            if (index === 0) {
              _years = Helper.ToInteger(e.durationTermYears, 0);
            }
            e.currentyears = _years;
            if (e.mailingAddressIndicator) {
              e.usePrevAddr = true;
            }
          });
        }
        let _obj = requiredChilds ? { childs: _childs } : _childs[0];
        return _obj;
      }
    }
    return requiredChilds ? { childs: [] } : [];
  }

  const MoveNext = () => {
    let index = selectedBorrowerIndex;
    let listItems = borrowersList;
    let _row = row;
    if (listItems.length - 1 > index) {
      index++;
      let _item = listItems[index].mapid;
      row[index].completed = true;
      listItems[index].disabled = false;
      setBorrowersList(listItems);
      setSelectedBorrowerIndex(index);
      setSelectedBorrower(_item);
    }
    setRow(_row);
    setState(!state);
  }

  React.useEffect(() => {
    if (borrowersList && borrowersList.length > 0) {
      setLoadData(true);
    }
  }, [borrowersList]);

  const OnBorrowerSelected = (e) => {
    setSelectedBorrowerIndex(e.target.selectedIndex);
    setSelectedBorrower(e.target.value);
    setErrors([]);
    setError(null);
    setSuccess(null);
    setState(!state);
  }

  return (
    <>
      <Container selected={3} lastStage={lastStage}>

        <form id="stageThree">
          <ul className="selectBorrowerBlk">
            <li className="selectBorrowerBlk">
              <div className="selectPadLeft10">
                <select
                  id={'dpBorrower'}
                  name={'dpBorrower'}
                  value={Helper.ToString(selectedBorrower)}
                  onChange={(e) => OnBorrowerSelected(e)}
                >
                  {!Helper.IsArrayNull(borrowersList) &&
                    borrowersList.map((x, index) => {
                      return (
                        <option key={index} disabled={x.disabled} value={x['mapid']}>{x['text']}</option>
                      );
                    })}
                </select>
              </div>
            </li>
            <li className="selectBorrowerBlk">&nbsp;</li>
          </ul>

          <ul className="form1003IIcol">
            <li className="form1003IIcol">
              <RenderBorrowerInfo
                item={GetArrayObject('applicant')}
                maritalStatusTypes={maritalStatusTypes}
                citizenshiptypes={citizenshiptypes}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                onInputChanged={OnInputChanged}
                errors={errors}
              />

              {GetArrayObject('residences', true).childs.map((x, index) => {
                return (
                  <RenderAddress
                    key={x.index}
                    item={x}
                    statesList={statesList}
                    unittypes={unittypes}
                    housingtypes={housingtypes}
                    onBlurError={OnBlurError}
                    onInputClicked={OnInputClicked}
                    onInputChanged={OnInputChanged}
                    errors={errors}
                  />
                )
              })}

              <RenderEmployment
                item={GetArrayObject('employments', false, null, 'Current')}
                unittypes={unittypes}
                onInputChanged={OnInputChanged}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                statesList={statesList}
                onAddArrayList={OnAddArrayList}
                errors={errors}
              />

            </li>
            <li className="form1003IIcol">
              <RenderEmployment
                item={GetArrayObject('employments', false, null, 'Additional')}
                unittypes={unittypes}
                onInputChanged={OnInputChanged}
                statesList={statesList}
                onAddArrayList={OnAddArrayList}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                errors={errors}
              />
              <RenderEmployments
                items={GetArrayObject('employments', true, null, 'Previous')}
                unittypes={unittypes}
                onInputChanged={OnInputChanged}
                statesList={statesList}
                onAddArrayList={OnAddArrayList}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                errors={errors}
              />
              <RenderIncomeSources
                sourcetypes={sourcetypes}
                onInputChanged={OnInputChanged}
                items={GetArrayObject("incomesources", true)}
                onAddArrayList={OnAddArrayList}
              />
            </li>
          </ul>
        </form>

        <ul className="form_btn_successError_blk">
          <li className="form_btn_steps_label">
            <input
              type="button"
              defaultValue="Save & Finish Later"
              className="form_btn_steps"
              onClick={(e) => OnUpdateRecord(e, true)}
            />
            <input
              type="button"
              defaultValue="Continue"
              className="form_btn_steps"
              onClick={(e) => OnUpdateRecord(e, false)}
            />
          </li>
          {error && (
            <li className="form_successError_steps">
              <div className="errormessage">{error}</div>
            </li>
          )}
          {success && (
            <li className="form_successError_steps">
              <div className="successmessage">{success}</div>
            </li>
          )}
        </ul>
      </Container>
    </>
  );
};

export default Component;
