import { React, Link, useTimerSession } from "../../../common";

const Component = (props) => {
  const { selected, lastStage } = props;
  const [initialized, setInitialized] = React.useState(false);
  const [steps, setSteps] = React.useState(null);
  const [stepClass, setStepClass] = React.useState(null);
  const [navClass, setNavClass] = React.useState(null);
  const [count, setCount] = React.useState(6);
  const [isCoBorrower] = useTimerSession('coBorrowerIndicator', true);

  //const completedStyle = selected

  const Steps = [
    { name: "Personal Details", link: 1, isCoBorrower: false },
    { name: "Loan Details", link: 2, isCoBorrower: false },
    { name: "Borrower & Co-Borrower Information", link: 3, isCoBorrower: false },
    { name: "Co-Borrower Information", link: 4, isCoBorrower: true },
    { name: "Assets & Liabilities", link: 5, isCoBorrower: false },
    { name: "Declarations", link: 6, isCoBorrower: false },
  ];

  React.useEffect(() => {
    setInitialized(true);
  }, [isCoBorrower]);

  const SyncStorage = async () => {
    let _stpes = Steps.filter((x) => !x.isCoBorrower);
    if (isCoBorrower) _stpes = Steps;
    _stpes.forEach((elm, index) => {
      elm['key'] = index + 1;
    });
    setCount(_stpes.length);
    setSteps(_stpes);
    setStepClass(`step_${_stpes.length}`);
    setNavClass(`appFormNavi_${_stpes.length}step`);
  };

  if (initialized) {
    setInitialized(false);
    SyncStorage();
  }

  const DrawTabs = ({ x }) => {
    if (parseInt(x.link) === parseInt(selected)) {
      return (
        <li className={navClass}>
          <Link to="#" className={"current"}>
            <div className="navinumber active">{x.key}</div>
            <div className="naviTxt">{x.name}</div>
          </Link>
        </li>
      );
    } else if (parseInt(x.link) > parseInt(selected) && parseInt(x.link) > parseInt(lastStage)) {
      return (
        <li className={navClass}>
          <Link to="#">
            <div className="navinumber">{x.key}</div>
            <div className="naviTxt">{x.name}</div>
          </Link>
        </li>
      );
    } else {
      return (
        <li className={navClass}>
          <Link to={`/stage${x.link}`} className={'passed'}>
            <div className="navinumber passed">{x.key}</div>
            <div className="naviTxt">{x.name}</div>
          </Link>
        </li>
      );
    }
  };

  const GetPosHeader = () => {
    let current = 1;
    if (steps) {
      current = steps.filter((x) => parseInt(x.link) === parseInt(selected))[0].key;
    }
    return `Step ${current} of ${count}`;
  };

  return (
    <>
      <ul className="formHeadStageBlk">
        <li className="formHeadStageBlk">1003 Form - Complete Application</li>
        <li className="formHeadStageBlk">
          <ul className="formStage">
            <div className="stageHead"><GetPosHeader /></div>
            {steps &&
              steps.map((x, i) => {
                if (parseInt(x.link) <= parseInt(selected)) {
                  return <li key={i} className={`${stepClass} current`}></li>;
                }
                return <li key={i} className={stepClass}></li>;
              })}
          </ul>
        </li>
      </ul>
      <ul className="appFormNavi">
        {steps &&
          steps.map((x, i) => {
            return <DrawTabs x={x} key={i} />;
          })}
      </ul>
    </>
  );
};

export default Component;
