import { React, Helper, Link } from "../../common";
import Container from "../container";
import { GetPreapprovalLetters } from "../../services/preapprovalApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const FetchResults = async () => {
    global.Busy(true);
    let rslt = await GetPreapprovalLetters();
    if (rslt.status === 100) {
      setRows(rslt.data);
    }
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const OnDownloadClicked = (e, letter) => {
    e.preventDefault();
    window.open(JSON.parse(letter), "_blank");
  };

  return (
    <>
      <Container>

        <div id="container" >
          <div id="content">
            <div className="widthfix_10px">
              <div className="preapprove_pgBlk">
                <div className="preApprove_breadcrumb">Pre-Approval: Approval Letters</div>
                <div className="savedApprovalLetterBlk">
                  {rows && rows.length > 0 ? (
                    <>
                      <div className="approvalList_head">
                        <table>
                          <tbody>
                            <tr>
                              <td>Property Address</td>
                              <td>Date &amp; Time</td>
                              <td>Approved Amount</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="approvalList">
                        <table>
                          <tbody>
                            {rows.map((x) => (
                              <tr key={x._id}>
                                <td><Link to="#" onClick={(e) => OnDownloadClicked(e, x.Letter)}>{x.Address}</Link></td>
                                <td>{Helper.ToDateFormat(x.DateOn, "YYYY-MMM-DD HH:mm")}</td>
                                <td>{Helper.USCurrencyFormat(x.LoanAmount)}</td>
                              </tr>
                            )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <div className="noContent">No pre-approval letter available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
