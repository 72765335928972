const Component = () => {
    return (
        <div id="info_occupancy" className="pop_disable_bg">
            <div className="occupancy_pop">
                <div className="pop_head">Occupancy</div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Primary Residence - </div>
                    <div className="pop_content">Select if you will in live in this property most of the time.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">Second Home - </div>
                    <div className="pop_content">Select if this will be your second home.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">Investment Property - </div>
                    <div className="pop_content">Select if you will use this as an investment property.</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_occupancy')} />
                </div>
            </div>
        </div>
    );
};

export default Component;