import { React } from "../../../../common";
import Container from "../../../container";
import TabNavigations from "../../components/tablist";
import ApplyNow from "../applynow";

const Component = () => {
  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_lo_30">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcBlkDesc">
                      <div className="blkDescTxt">Loan Options</div>
                      <div className="rightCurve"></div>
                    </div>
                    <div className="bcHeadTxt_topTxt_noRight">Jumbo Loans</div>
                  </li>
                  <li className="bc_col">&nbsp;</li>
                </div>
              </ul>
            </div>

            <div className="widthfix_10px">
              <ul className="loPgStructure">
                <li className="loPgStructure">
                  <TabNavigations type="jumbo" />
                </li>
                <li className="loPgStructure">
                  <ul className="loPointsBlk">
                    <li className="loPointsBlk">
                      <ul className="loDescPoints">
                        <li className="loDescPoints">
                          No maximum purchase limit
                        </li>
                        <li className="loDescPoints">
                          Flexible credit guidelines
                        </li>
                        <li className="loDescPoints">
                          Steady, affordable monthly payments
                        </li>
                        <li className="loDescPoints">
                          Qualify for higher loan amounts
                        </li>

                        <ApplyNow />
                      </ul>
                    </li>
                    <li className="loPointsBlk">
                      <div className="pgTxtNormal">
                        For a loan to be considered a “jumbo” mortgage, the loan
                        amount must exceed conventional conforming loan limits,
                        which are set by two government-sponsored enterprises -
                        Fannie Mae and Freddie Mac. These two corporations set
                        the limit on the maximum value of any individual
                        mortgage they will purchase from a lender. Currently,
                        the conforming loan limit is $417,000 for a one-unit
                        home - meaning, if you need to borrower half a million
                        dollars to buy a million-dollar home, you would need a
                        jumbo mortgage.
                      </div>
                      <div className="pgTxtBold">
                        It is also worth nothing that some conforming loan
                        limits may exceed $417,000.
                      </div>
                      <div className="pgTxtNormal">
                        In some cases, there are loan products that have
                        elevated conforming loan limits, which give borrowers
                        the ability to obtain mortgages exceeding the normal
                        $417,000. The availability of these loans varies by
                        county. The U.S. Housing Department has a useful tool on
                        their website where you can find limits in each
                        state/county.
                      </div>
                      <div className="pgTxtNormal">
                        For further information on jumbo loans, please contact
                        us. Our mortgage professionals will review your loan
                        options and help you figure out whether a jumbo loan is
                        right for you.
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
