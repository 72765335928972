import API from "./api";
import session from "../session";

const GetListById = async (id) => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`lookups/${id}`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetList = async (input) => {
    let token = await session.GetHeader();
    try {
        const res = await API.post(`lookups/list`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetListById, GetList };
