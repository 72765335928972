const Component = () => {
    return (
        <div id="info_interestRate" className="pop_disable_bg">
            <div className="interestRate_pop">
                <div className="pop_head">Interest Rate</div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Interest Rate - </div>
                    <div className="pop_content">The simple interest rate, stated as a percentage, charged by a lender on the principal (Loan Amount) amount of borrowed money.</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_interestRate')} />
                </div>
            </div>
        </div>
    );
};

export default Component;