const Component = () => {
    return (
        <div id="info_loanType" className="pop_disable_bg">
            <div className="loanType_pop">
                <div className="pop_head">Loan Type</div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Conventional Loan - </div>
                    <div className="pop_content">A mortgage loan that is not insured, guaranteed or funded by the Veterans Administration (VA), the Federal Housing Administration (FHA) or Rural Economic Community Development (RECD) (formerly Farmers Home Administration).</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">VA (Veteran Administration) - </div>
                    <div className="pop_content">The federal agency responsible for the VA loan guarantee program as well as other services for eligible veterans. In general, qualified veterans can apply for home loans with no down payment and a funding fee of 1 percent of the loan amount.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">FHA (Federal Housing Authority) - </div>
                    <div className="pop_content">FHA loans may be high loan-to-value, and they are limited by loan amount. FHA mortgage insurance requires a fee of 1.5 percent of the loan amount to be paid at closing, as well as an annual fee of 0.5 percent of the loan amount added to each monthly payment.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">USDA / Rural Housing Service - </div>
                    <div className="pop_content">Provides loans to farmers and other qualified applicants who cannot obtain financing elsewhere.</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_loanType')} />
                </div>
            </div>
        </div>
    );
};

export default Component;