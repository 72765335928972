import {
    React, Link, Helper, TextInput2 as TextInput, RadioInput2 as RadioInput,
    DropDown2 as DropDown, CheckInput2 as CheckInput
} from "../../../../common";

const RenderChildComponent = (props) => {
    const { path, item, count, onInputChanged, unittypes, errors, onBlurError, onInputClicked,
        statesList, occupancies, featureoccupancies, propertystatustypes, loantypes } = props;

    const OnDeleteClicked = (e) => {
        e.preventDefault();
        if (onInputChanged) onInputChanged(`${path}.delete`.replace(/\./g, '_'), true);
    }

    return (
        <>
            {count > 1 && (
                <div className="formPartition">
                    <div className="closePartition">
                        <Link to="#" onClick={(e) => OnDeleteClicked(e)}>&#x2715; &nbsp;Delete</Link>
                    </div>
                </div>
            )}
            <ul className="formContentIIcol">
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Property Street</div>
                    <div className="locationicon"></div>
                    <TextInput
                        path={`${path}.uRLA2020StreetAddress`}
                        value={item?.uRLA2020StreetAddress}
                        onInputChildChanged={onInputChanged} />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Unit No</div>
                    <div className="hastagicon"></div>
                    <TextInput
                        path={`${path}.unitNumber`}
                        value={item?.unitNumber}
                        valuetype={'NFINANCE'}
                        onInputChildChanged={onInputChanged} />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Unit Type</div>
                    <div className="hastagicon"></div>
                    <DropDown
                        path={`${path}.unitType`}
                        value={item?.unitType}
                        onInputChildChanged={onInputChanged}
                        items={unittypes}
                        displayName={"text"}
                        displayValue={"value"}
                    />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Property City</div>
                    <div className="locationicon"></div>
                    <TextInput
                        path={`${path}.city`}
                        value={item?.city}
                        onInputChildChanged={onInputChanged} />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">State</div>
                    <div className="locationicon"></div>
                    <DropDown
                        path={`${path}.state`}
                        value={item?.state}
                        items={statesList}
                        displayName={"text"}
                        displayValue={"value"}
                        onInputChildChanged={onInputChanged}
                    />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Zip Code</div>
                    <div className="form_homeicon"></div>
                    <TextInput
                        path={`${path}.postalCode`}
                        value={item?.postalCode}
                        onInputChildChanged={onInputChanged} />
                </li>

                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Present Market Value</div>
                    <div className="form_dollaricon"></div>
                    <TextInput
                        path={`${path}.marketValueAmount`}
                        value={item?.marketValueAmount}
                        valuetype={'FINANCE'}
                        onInputChildChanged={onInputChanged} />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Property Status</div>
                    <div className="typeicon"></div>
                    <DropDown
                        path={`${path}.dispositionStatusType`}
                        value={item?.dispositionStatusType}
                        items={propertystatustypes}
                        displayName={"text"}
                        displayValue={"value"}
                        onInputChildChanged={onInputChanged}
                    />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Intended Occupancy</div>
                    <div className="form_homeicon"></div>
                    <DropDown
                        path={`${path}.propertyUsageType`}
                        value={item?.propertyUsageType}
                        items={occupancies}
                        displayName={"text"}
                        displayValue={"value"}
                        onInputChildChanged={onInputChanged}
                    />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Intended Occupancy as</div>
                    <div className="form_homeicon"></div>
                    <DropDown
                        path={`${path}.futurePropertyUsageType`}
                        value={item?.futurePropertyUsageType}
                        items={featureoccupancies}
                        displayName={"text"}
                        displayValue={"value"}
                        onInputChildChanged={onInputChanged}
                    />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Monthly Insurance, Maintenance, Misc.</div>
                    <div className="form_dollaricon"></div>
                    <TextInput
                        path={`${path}.maintenanceExpenseAmount`}
                        value={item?.maintenanceExpenseAmount}
                        valuetype={"FINANCE"}
                        onInputChildChanged={onInputChanged} />
                </li>
                <li className="formContentIIcol">
                    <div className="form1003ContentHead">Monthly Gross Rental Income</div>
                    <div className="form_dollaricon"></div>
                    <TextInput
                        path={`${path}.rentalIncomeGrossAmount`}
                        value={item?.rentalIncomeGrossAmount}
                        valuetype={"FINANCE"}
                        onInputChildChanged={onInputChanged} />
                </li>
            </ul>
            <div className="spacer_20margin_bottom">
                <div className="form1003ContentHead">
                    Mortgage Loan on this Property?
                </div>
                <ul className="form_A" data-opts={true}
                    data-req-msg={"Mortgage Loan on this Property?"}
                    data-id={`${path}.mortgateLoanIndicator`}>
                    <RadioInput
                        path={`${path}.mortgateLoanIndicator`}
                        value={item?.mortgateLoanIndicator}
                        options={Helper.Options()}
                        uselabel={false}
                        errors={errors}
                        onInputChildChanged={onInputChanged} />
                </ul>
            </div>
            {item?.mortgateLoanIndicator && (<>
                <ul className="formContentIIcol">
                    <li className="formContentIIcol">
                        <div className="form1003ContentHead">Creditor Name</div>
                        <div className="usericon"></div>
                        <div className="formMandatory">*</div>
                        <TextInput
                            path={`${path}.loanCreditorName`}
                            value={item?.loanCreditorName}
                            onInputChildChanged={onInputChanged}
                            requiredMessage={'Creditor Name is required'}
                            dataRequired={true}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                        />
                    </li>
                    <li className="formContentIIcol">
                        <div className="form1003ContentHead">Account Number</div>
                        <div className="hastagicon"></div>
                        <div className="formMandatory">*</div>
                        <TextInput
                            path={`${path}.loanAccountIdentifier`}
                            value={item?.loanAccountIdentifier}
                            onInputChildChanged={onInputChanged}
                            requiredMessage={'Account Number is required'}
                            dataRequired={true}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                        />
                    </li>
                    <li className="formContentIIcol">
                        <div className="form1003ContentHead">Monthly Mortgage Payment</div>
                        <div className="form_dollaricon"></div>
                        <div className="formMandatory">*</div>
                        <TextInput
                            path={`${path}.lienInstallmentAmount`}
                            value={item?.lienInstallmentAmount}
                            onInputChildChanged={onInputChanged}
                            requiredMessage={'Monthly Mortgage Payment is required'}
                            dataRequired={true}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            valuetype={'FINANCE'}
                        />
                    </li>
                    <li className="formContentIIcol">
                        <div className="form1003ContentHead">Unpaid Balance</div>
                        <div className="form_dollaricon"></div>
                        <div className="formMandatory">*</div>
                        <TextInput
                            path={`${path}.lienUpbAmount`}
                            value={item?.lienUpbAmount}
                            valuetype={"FINANCE"}
                            onInputChildChanged={onInputChanged}
                            requiredMessage={'Unpaid Balance is required'}
                            dataRequired={true}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                        />
                    </li>
                    <li className="formContentIIcol">
                        <div className="form1003ContentHead">Loan Type</div>
                        <div className="formMandatory">*</div>
                        <Link
                            to="#"
                            onClick={(e) => window.pop("info_loanType")}
                            title="Info"
                        >
                            <div className="formTip"></div>
                        </Link>

                        <div className="typeicon"></div>
                        <DropDown
                            path={`${path}.lienLoanType`}
                            value={item?.lienLoanType}
                            items={loantypes}
                            displayName={"text"}
                            displayValue={"value"}
                            onInputChildChanged={onInputChanged}
                            requiredMessage={'Loan Type is required'}
                            dataRequired={true}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            indexValue={1}
                        />
                    </li>
                </ul>
                <div className="spacer_20margin_bottom">
                    <div className="form1003ContentHead">To Be Paid Off</div>
                    <div className="formCheckboxLabel">
                        <CheckInput
                            path={`${path}.lienPaidOffIndicator`}
                            value={item?.lienPaidOffIndicator}
                            content={`To be Paid off before closing`}
                            onInputChildChanged={onInputChanged} />
                    </div>
                </div>
            </>)}
        </>
    );
};

const Component = (props) => {
    const { items, unittypes, onInputChanged, statesList, propertystatustypes, loantypes, occupancies,
        featureoccupancies, onBlurError, onInputClicked, onAddArrayList, errors } = props;

    const item = items && items.childs && items.childs.length > 0 ? items.childs[0] : null;

    const AddNewRealEstate = (e) => {
        e.preventDefault();
        let obj = {
            applicantType: item?.applicantType,
            realEstateIndicator: item?.realEstateIndicator,
            delete: false
        };
        console.log(obj);
        if (onAddArrayList) onAddArrayList('reoProperties', obj);
    }

    return (
        <>
            <div className="form1003BlkHead">
                <div className="formHeadContent">Real Estate</div>
                <div className="form1003Blk_right">&nbsp;</div>
            </div>
            <div className="form1003ContentBlk">
                <div className="spacer_20margin_bottom">
                    <div className="form1003ContentHead">
                        Do you currently own real estate?
                    </div>
                    <ul className="form_A" data-opts={true}
                        data-req-msg={"Do you currently own real estate?"}
                        data-id={`reoProperties.${item?.index}.realEstateIndicator`}>
                        <RadioInput
                            path={`reoProperties.${item?.index}.realEstateIndicator`}
                            value={Helper.ToUndefinedCheck(item?.realEstateIndicator, true)}
                            options={Helper.Options()}
                            uselabel={false}
                            errors={errors}
                            onInputChildChanged={onInputChanged}
                        />
                    </ul>
                </div>
                {item?.realEstateIndicator && (
                    <div className="form1003BlkInsideHead">
                        <div className="formHeadContent">Real Estate Owned</div>
                        <div className="form1003Blk_right">
                            <input type="button" value="Add Another" className="headRight_button"
                                onClick={(e) => AddNewRealEstate(e)} /></div>
                    </div>
                )}

                {item?.realEstateIndicator && items && items.childs && items.childs.map((x) => {
                    if (!x.delete) {
                        return (
                            <RenderChildComponent
                                count={items.childs.filter((x) => !x.delete).length}
                                key={x.index}
                                path={`reoProperties.${x.index}`}
                                item={x}
                                onInputChanged={onInputChanged}
                                onBlurError={onBlurError}
                                onInputClicked={onInputClicked}
                                unittypes={unittypes}
                                statesList={statesList}
                                occupancies={occupancies}
                                propertystatustypes={propertystatustypes}
                                loantypes={loantypes}
                                featureoccupancies={featureoccupancies}
                                errors={errors}
                            />
                        )
                    } else { return null }
                })}
            </div>
        </>
    );
};

export default Component;
