import {
    InfoMortgageApplied, InfoLoanAmount, InfoInterestRate, InfoLoanTerm, InfoAmortizationType,
    InfoLoanPurpose, InfoOccupancy
} from "./index";


const Component = () => {
    return (
        <>
            <InfoMortgageApplied />
            <InfoLoanAmount />
            <InfoInterestRate />
            <InfoLoanTerm />
            <InfoAmortizationType />
            <InfoLoanPurpose />
            <InfoOccupancy />
        </>
    );
};

export default Component;