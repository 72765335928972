import { React, TabNavigations } from "../../../common";
import Container from "../../container";

const Component = ({ children, selected, lastStage }) => {
    return (
        <Container>
            <div id="container">
                <div id="content">
                    <div className="widthfix_10px">
                        <div className="form1003_pgBlk">
                            <TabNavigations selected={selected} lastStage={lastStage} />
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Component;