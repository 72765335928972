import { React, Helper, Storage } from "../../../common";
import { GetPreapprovalLetter } from "../../../services/preapprovalApi";

const Component = () => {
  const [initialized, setInitialized] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [noShow, setNoShow] = React.useState(true);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  const FetchResults = async () => {
    setRow({});
    setNoShow(true);
    let data = Storage.RetrieveJSON("stages");
    if (!Helper.IsNullValue(data?.id)) {
      global.Busy(true);
      let rslt = await GetPreapprovalLetter(data.id);
      global.Busy(false);
      if (rslt.status !== 100) {
        alert(rslt.statusText);
      } else {
        setRow(rslt.data);
        setNoShow(false);
      }
    }
  };

  if (initialized) {
    setNoShow(true);
    setInitialized(false);
    FetchResults();
    Storage.Remove("preapprove");
    Storage.Remove("stages");
  }

  const OnFormI003Clicked = (e) => {
    e.preventDefault();
    document.getElementById("popAuthorize").style.display = "block";
  };

  const OnDownloadClicked = (e) => {
    window.location.href = e;
  };

  return (
    <>
      {noShow ? (
        <div className="errormessage">Cound not found the last record.</div>
      ) : (
        <>
          {Helper.ToInteger(row?.LoanAmount) === 0 ? (
            <div className="nonApproveLetter_blk">
              <div className="nonapprovalBoldText">Dear. {row?.Borrower},</div>
              <div className="nonapprovalText">We would need some more information before we can provide you with the pre-approval letter.</div>
              <div className="nonapprovalText">Please call us at (888) 975-1374 or email us at info@i3lending.com</div>
            </div>
          ) : (
            <ul className="approveLetter_blk">
              <li className="approveLetter_blk">
                <div className="approveMainText">
                  Congrats, {row?.Borrower}! <br />
                  You've been pre-approved for
                </div>
                <div className="approveMainTextAmount">
                  {Helper.USCurrencyFormat(row?.LoanAmount)}
                </div>
                <div className="approveSecondText">
                  Based on your information, we have created your custom
                  pre-approval letter. It shows what you are qualified for.
                </div>
                <div className="approveButtonBlk">
                  <input
                    type="button"
                    value="Download pre-approval letter"
                    className="button_approve"
                    onClick={(e) => OnDownloadClicked(JSON.parse(row?.Letter))}
                  />
                  <input
                    type="button"
                    value="Fill 1003 Form"
                    className="button_approve"
                    onClick={(e) => OnFormI003Clicked(e)}
                  />
                </div>
              </li>
              <li className="approveLetter_blk">
                <div className="approvalLetter">
                  <div className="approvalcoLogo">
                    <img src={'https://misjpegs.s3.amazonaws.com/i3LendingLogo.jpg'} alt="i3 Lending" style={{ height: "35px" }} />
                  </div>
                  <div className="approvalcoAdd">
                    1900 Boothe Circle, Suite 100,<br /> Longwood, FL 32750S
                  </div>
                  <ul className="letterHeadNMLS_blk">
                    <li className="letterHeadNMLS_blk">
                      <div className="approvalLetterHead">Mortgage Loan</div>
                      <div className="approvalLetterHeadPre">Pre-Approval</div>
                    </li>
                    <li className="letterHeadNMLS_blk">
                      <div className="NMLSTxt">Company NMLS: 1606866</div>
                    </li>
                  </ul>
                  <div className="letterDate">
                    {Helper.ToDate(row?.DateOn, "MMMM DD, YYYY")}
                  </div>
                  <div className="letterTextB">Dear. {row?.Borrower},</div>
                  <div className="letterText">
                    We are pleased to notify you that your application for a
                    mortgage pre-approval has been approved for a new home purchase.
                    The pre-approved purchase price has been set at{" "}
                    {Helper.USCurrencyFormat(row?.LoanAmount)} with {row?.MortgageType} 30
                    year fixed financing.
                  </div>
                  <div className="spacer_15margin_bottom"></div>
                  <div className="letterText">
                    This pre-approval is valid until{" "}
                    {Helper.ToDate(row?.Validitydate, "DD-MMM-YYYY")} assuming that
                    there are no changes in your financial situation. This
                    pre-approval should not be considered a commitment to lend until
                    the following conditions are met:
                  </div>
                  <ul className="letterBullet">
                    <li className="letterBullet">
                      <div className="letterText">
                        A valid sales contract is ratified;
                      </div>
                    </li>
                    <li>
                      <div className="letterText">
                        A satisfactory appraisal is accomplished on said property;
                      </div>
                    </li>
                    <li>
                      <div className="letterText">
                        A rate commitment is issued by our company under the above
                        referenced mortgage program.{" "}
                      </div>
                    </li>
                  </ul>
                  <div className="letterText">
                    In addition to the above standard conditions, your pre-approval
                    is subject to the following conditions:
                  </div>
                  <ul className="letterBullet">
                    <li>
                      <div className="letterText">Satisfactory Title Report</div>
                    </li>
                    <li>
                      <div className="letterText">
                        Satisfactory documentation to fully support continued
                        financial qualifications as seen during initial loan
                        application.
                      </div>
                    </li>
                  </ul>
                  <div className="letterText">
                    This loan approval is being issued with verbal income provided
                    and credit verification. If any figures or information change
                    from the initial application then please let us know at your
                    first convenience and we can update this approval for you with
                    updated figures and requirements.
                  </div>
                  <div className="letterText">
                    We wish you luck in the home buying process and we stand ready
                    to continue our service.
                  </div>
                  <div className="spacer_20margin_bottom"></div>
                  <div className="letterTextB">i3 Lending, Inc</div>
                </div>
              </li>
            </ul>
          )
          }
        </>
      )}
    </>
  );
};

export default Component;
