// http://i3fintech.s3-website.ap-south-1.amazonaws.com
import React from "react";
import RendorRoutes from "./routes";

/* {
  "email": "ajayadavikatla14@gmail.com",
    "scope": "email",
      "apptype": "lend",
        "password": "123"
} */

const user = {
  name: "Stuart Little",
  role: 0,
  status: 1,
  typeindex: 3,
  entity: "Borrower",
  rolename: "Borrower",
  issuer: "i3lend lend",
  parentid: 393,
  approved: 0,
};

const userToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNDA0IiwidHlwZWlkIjowLCJmaXJzdF9uYW1lIjoiU3R1YXJ0IiwibGFzdF9uYW1lIjoiTGl0dGxlIiwiY3JlYXRlZHQiOiIyMDIyLTExLTIyVDAwOjAwOjAwLjAwMFoiLCJzdGF0dXNpZCI6MSwiaXNwaWQiOiI2MzdjYThmN2FhMzE2YzAwMjBjMTVmYWEiLCJwYXJlbnRpZCI6IjM5MyIsInR5cGVpbmRleCI6MywiZGVmYXJvdXRlIjoiYmQiLCJhcHByb3ZlZCI6MH0sImlhdCI6MTY3NDY0NzQ5MSwiZXhwIjoxNjc4MjQ3NDkxLCJzdWIiOiI0MDQifQ.S7CIRY3U6iKV4qkeri9RT9cnk5NKewAmNH1OyllBXIE";

global.Busy = (bBusy) => {
  var x = document.getElementById("busyloader");
  if (x) {
    if (bBusy) {
      x.className = "loader display-block";
    } else {
      x.className = "loader display-none";
    }
  }
};

/* eslint-disable */
const PreToken = () => {
  sessionStorage.setItem("userToken", userToken);
  sessionStorage.setItem("user", JSON.stringify(user));
  sessionStorage.setItem("status", 1);
  sessionStorage.setItem("loanid", '63cb479b000efc424b547109');
};

const RemovePreToken = () => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("status");
};
/* eslint-enable */

function App() {
  //PreToken();
  //RemovePreToken();
  return <RendorRoutes />;
}

export default App;
