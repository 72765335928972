import API from "./api";
import session from "../session";

const GetStakeHolders = async (loanid) => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`stakes?l=${loanid}`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const AddStakeHolders = async (input) => {
    let token = await session.GetHeader();
    try {
        const res = await API.post(`stakes`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetStakeHolders, AddStakeHolders };
