import { React, Link, useParams, Helper } from "../../../common";
import { SearchLoans } from "../../../services/loanApi";
import { GetStakeHolders } from "../../../services/stakesApi";

const RenderInputType = (props) => {
  const { type, loans, OnSearchClicked, loanNumber, loanId } = props;
  const [initlized, setInitlized] = React.useState(false);
  const [selectedLoan, setSelectedLoan] = React.useState({});

  const GetLoanById = (num) => {
    let loanItem = { id: num };
    let item = loans.filter((x) => parseInt(x.id) === parseInt(num));
    if (item.length > 0) {
      loanItem.loanNumber = item[0].loannumber;
    }
    return loanItem;
  };

  const GetLoanByNumber = (num) => {
    let loanItem = { id: -1, loanNumber: num };
    let item = loans.filter((x) => x.loannumber === num);
    if (item.length > 0) {
      loanItem.id = item[0].id;
    }
    return loanItem;
  };

  const OnKeyPressEvent = (e) => {
    let charCode = e.charCode || e.which;
    if (charCode === 13) {
      OnSearchClicked(e);
    }
  };

  const OnSearchButtonClicked = (e) => {
    e.preventDefault();
    if (OnSearchClicked) OnSearchClicked(selectedLoan);
  };

  const OnInputChanged = (val) => {
    let _tmp = GetLoanByNumber(val);
    setSelectedLoan(_tmp);
  };

  const OnDropDownChanged = (val) => {
    let _tmp = GetLoanById(val);
    setSelectedLoan(_tmp);
    if (OnSearchClicked) OnSearchClicked(_tmp);
  };

  if (initlized) {
    setInitlized(false);
    setSelectedLoan({ id: loanId, loanNumber: loanNumber });
  }

  React.useEffect(() => {
    setInitlized(true);
  }, [loanNumber, loanId]);

  if (type === 1) {
    return (
      <>
        <input
          type="text"
          value={Helper.ToString(selectedLoan.loanNumber)}
          onChange={(e) => OnInputChanged(e.target.value)}
          onKeyPress={(e) => OnKeyPressEvent(e)}
        />
        <Link
          to="#"
          title="Search Loan"
          onClick={(e) => OnSearchButtonClicked(e)}
        >
          <div className="iconsearch"></div>
        </Link>
      </>
    );
  } else if (type === 2) {
    return (
      <>
        <div className="loanSearchSelect">
          <select
            name="filterType"
            value={Helper.ToString(selectedLoan.id)}
            onChange={(e) => OnDropDownChanged(e.target.value)}
          >
            <option value="0">Select</option>
            {loans &&
              loans.map((x, index) => {
                return (
                  <option value={x.id} key={index}>
                    {x.loannumber}
                  </option>
                );
              })}
          </select>
        </div>
      </>
    );
  }
};

const Component = (props) => {
  const { upload, stakes, view, onLoanSelected, onStakeSelected } = props;
  const [initlized, setInitlized] = React.useState(false);
  const [loanslist, setLoansList] = React.useState([]);
  const [loanSelected, setLoanSelected] = React.useState(false);
  const [noStakeHolders, setNoStakeHolders] = React.useState(false);
  const [row, setRow] = React.useState({});
  const { id } = useParams();

  const loanSelectionType = 2;

  const GetLoanById = (items, num) => {
    let _obj = {};
    let item = items.filter((x) => parseInt(x.id) === parseInt(num));
    if (item.length > 0) {
      _obj = item[0];
    }
    return _obj;
  };

  const GetLoanByNumber = (items, findItem) => {
    let _obj = findItem;
    let item = items.filter(
      (x) => parseInt(x.loannumber) === parseInt(findItem.loanNumber)
    );
    if (item.length > 0) {
      _obj = item[0];
    }
    return _obj;
  };

  const FetchLoans = async () => {
    let _list = [];
    let req = { stakes: stakes, upload: upload, view: view };
    global.Busy(true);
    let rslt = await SearchLoans(req);
    global.Busy(false);
    if (rslt.status === 100) {
      setLoansList(rslt.data);
      _list = rslt.data;
    }
    CheckForParams(_list);
  };

  const CheckForParams = (list) => {
    let _loanId = id || 0;
    if (parseInt(_loanId) === 0 && list.length > 0) {
      _loanId = list[0].id;
    }
    let _row = GetLoanById(list, _loanId);
    setRow(_row);
    if (_loanId && parseInt(_loanId) > 0) {
      OnSearchClicked(_row);
    }
  };

  if (initlized) {
    setInitlized(false);
    FetchLoans();
  }

  React.useEffect(() => {
    setInitlized(true);
  }, [id]);

  const OnSearchClicked = async (e) => {
    let _tmp = true;
    setLoanSelected(false);
    setNoStakeHolders(false);
    if (parseInt(e.id) > 0) {
      global.Busy(true);
      let rslt = await GetStakeHolders(e.id);
      global.Busy(false);
      if (rslt.status === 100) {
        if (!Helper.IsArrayEmpty(rslt.data)) {
          setTimeout(() => {
            if (onStakeSelected) onStakeSelected(rslt.data);
          }, 500);
          let item = GetLoanByNumber(loanslist, e);
          setRow(item);
          if (loanSelectionType === 2) {
            setTimeout(() => {
              if (onLoanSelected) onLoanSelected(item);
            }, 500);
          }
          _tmp = false;
          setLoanSelected(true);
        }
      }
      setNoStakeHolders(_tmp);
    }
  };

  const RendorStatusMessage = () => {
    if (noStakeHolders) {
      return <li className="noLoan">No Stake Holders are configured</li>;
    } else {
      return (
        <li className="noLoan">
          Please search for a loan to view the loan details
        </li>
      );
    }
  };

  return (
    <div>
      <div className="app_box_shadow">
        <ul className="summaryVblk">
          <li className="summaryVblk">
            <div className="summaryHead">Loan Number:</div>
            <RenderInputType
              type={loanSelectionType}
              loans={loanslist}
              OnSearchClicked={OnSearchClicked}
              loanNumber={row?.loannumber}
              loanId={row?.id}
            />
          </li>
          {!loanSelected && <RendorStatusMessage />}
          {loanSelected && (
            <>
              <li className="summaryVblk">
                <div className="summaryHead">Borrower Name:</div>
                <div className="summaryText">{row?.borrower}</div>
              </li>
              <li className="summaryVblk">
                <div className="summaryHead">Loan Amount:</div>
                <div className="summaryText">
                  {row?.loanamount ? "$ " + row?.loanamount : ""}
                </div>
              </li>
              <li className="summaryVblk">
                <div className="summaryHead">Issuing Authority:</div>
                <div className="summaryText">{row?.issuingauth}</div>
              </li>
              <li className="summaryVblk">
                <div className="summaryHead">Est Close Date:</div>
                <div className="summaryText">{row?.closingdate}</div>
              </li>
              <li className="summaryVblk">
                <div className="summaryHead">Property Address:</div>
                <div className="summaryText">{row?.propaddress}</div>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Component;
