import React from "react";

const ViewDocComponent = ({ stream, setViewPdf, viewPdf, viewType }) => {
  const [pdfStream, setPdfStream] = React.useState(null);

  React.useEffect(() => {
    if (viewPdf) {
      setPdfStream(JSON.parse(stream));
    }
  }, [viewPdf, stream, viewType]);

  const OnCloseViwer = () => {
    setPdfStream(null);
    setViewPdf(false);
  };

  return (
    <>
      <div
        id="popViewFile"
        className="pop_disable_bg"
        style={{ display: viewPdf ? "block" : "none" }}
      >
        <div className="viewFile">
          <div className="pop_viewContentArea">
            <object
              data={pdfStream}
              style={{ height: "100%", width: "100%" }}
              type="application/pdf"
            >
              <div>No PDF viewer available</div>
            </object>
          </div>
          <div className="pop_buttonLabel_noTop">
            <input
              type="button"
              value="Close"
              className="pop_button"
              onClick={(e) => OnCloseViwer()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDocComponent;
