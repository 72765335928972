import { React, Link, Helper, TextInput2 as TextInput, RadioInput2 as RadioInput, DropDown2 as DropDown } from "../../../../common";

const RenderChildGiftsOrGrants = (props) => {
  const { tag, item, count, index, giftsOrGrantTypes, sourceTypes,
    onInputChildChanged, allowDelete, } = props;

  const tIndex = index === -1 ? "" : `.${index}`;
  const rootpath = `${tag}${tIndex}`;

  const OnDeleteClicked = (e) => {
    e.preventDefault();
    if (onInputChildChanged) onInputChildChanged(`${rootpath}.delete`.replace(/\./g, '_'), true);
  }

  return (
    <>
      {count > 1 && allowDelete && (
        <div className="formPartition">
          <div className="closePartition">
            <Link to="#" onClick={(e) => OnDeleteClicked(e)}>&#x2715; &nbsp;Delete</Link>
          </div>
        </div>
      )}
      <ul className="formContentIIcol">
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Asset Type:</div>
          <div className="gifticon"></div>
          <DropDown
            path={`${rootpath}.assetType`}
            items={giftsOrGrantTypes}
            value={item?.assetType}
            displayName={"text"}
            displayValue={"value"}
            onInputChildChanged={onInputChildChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Deposited/Not Deposited</div>
          <ul className="form_A">
            <RadioInput
              path={`${rootpath}.depositedIndicator`}
              value={item?.depositedIndicator}
              options={Helper.Deposits()}
              uselabel={false}
              onInputChildChanged={onInputChildChanged}
            />
          </ul>
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Source</div>
          <div className="typeicon"></div>
          <DropDown
            path={`${rootpath}.source`}
            items={sourceTypes}
            value={item?.source}
            displayName={"text"}
            displayValue={"value"}
            onInputChildChanged={onInputChildChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Cash or Market Value</div>
          <div className="form_dollaricon"></div>
          <TextInput
            path={`${rootpath}.amount`}
            name={"amount"}
            value={item?.amount}
            valuetype={"FINANCE"}
            onInputChildChanged={onInputChildChanged}
          />
        </li>
      </ul>
      {count > 1 && !allowDelete && (
        <div className="formPartition">
          <div className="closePartition"></div>
        </div>
      )}
    </>
  );
};

const Component = (props) => {
  const { tag, owner, items, giftsOrGrantTypes, sourceTypes,
    onInputChanged, onAddArrayList, allowAdd, allowDelete } = props;

  let options = {
    filters: [{ name: "index", value: 0 }],
    IsArray: true,
    path: tag,
  };

  const OnAddNewGiftOrGrants = () => {
    let obj = { delete: false, owner: owner };
    let tmp = Helper.AddNewRow(items.childs, obj);
    tmp.forEach((elm) => {
      let pos = options.filters.findIndex((x) => x.name === "index");
      if (pos > -1) {
        options.filters[pos].value = elm.index;
      }
      if (onAddArrayList) onAddArrayList(tag, elm, options);
    });
  };

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">
          Gifts/Grants You Have Been Receive for this Loan
        </div>
        {allowAdd && (
          <div className="form1003Blk_right">
            <input
              type="button"
              defaultValue="&#65291; New"
              className="headRight_button"
              onClick={(e) => OnAddNewGiftOrGrants()}
            />
          </div>
        )}
      </div>
      <div className="form1003ContentBlk">
        {items &&
          items.childs &&
          items.childs
            .filter((x) => !x.delete)
            .map((item, index) => {
              return (
                <RenderChildGiftsOrGrants
                  tag={tag}
                  count={items.childs.filter((x) => !x.delete).length}
                  item={item}
                  key={index}
                  index={item.index}
                  allowDelete={allowDelete}
                  giftsOrGrantTypes={giftsOrGrantTypes}
                  sourceTypes={sourceTypes}
                  onInputChildChanged={onInputChanged}
                />
              );
            })}
      </div>
    </>
  );
};

export default Component;
