import API from "./api";
import session from "../session";

const SetPreapproval = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`pre/setapproval`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreapproval = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/getapproval`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreapprovalLetter = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/getletter/${input}`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreapprovalLetters = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/getletters`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreDocRequests = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/getdocrequests`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreDocTypes = async (id) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/assigndoctypes/${id}`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const AddPreDocument = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`pre/adddocument`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreDocuments = async (id, all) => {
  let token = await session.GetHeader();
  let type = all || 0;
  console.log(`pre/getdocuments/${id}/${type}`);
  try {
    const res = await API.get(`pre/getdocuments/${id}/${type}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const DeletePreDocument = async (docid) => {
  let token = await session.GetHeader();
  try {
    const res = await API.delete(`pre/document/${docid}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

export {
  GetPreapproval, SetPreapproval, GetPreDocTypes, AddPreDocument, DeletePreDocument,
  GetPreapprovalLetters, GetPreapprovalLetter, GetPreDocRequests, GetPreDocuments
};
