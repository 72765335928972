import { React, useNavigate, Helper, Session, Loader, TextInput, RadioInput, DropDown } from "../../common";

import Container from "../container";
import { GetList } from "../../services/enumsApi";

import { AddShortLoan, GetLoanOffiers } from "../../services/loanApi";
import { GetShortUserProfile } from "../../services/profileApi";


let readOnlyFields = [];

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [loanOfficers, setLoanOfficers] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [occupancyTypes, setOccupancyTypes] = React.useState([]);
  const [state, setState] = React.useState(false);
  const [loanPurposeTypes, setLoanPurposeTypes] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchProfile = async () => {
    global.Busy(true);
    let rslt = await GetShortUserProfile();
    global.Busy(false);
    return rslt;
  };

  const FetchResults = async () => {
    setProgress(true);

    let idList = [3, 4, 18];
    let rslt = await GetList(idList);
    if (rslt.status === 100) {
      let t, _list;
      _list = rslt.data || [];

      t = _list.filter((x) => parseInt(x.type) === 3);
      Helper.AddSelectOption(t);
      setLoanPurposeTypes(t);

      t = _list.filter((x) => parseInt(x.type) === 4);
      Helper.AddSelectOption(t);
      setOccupancyTypes(t);

      t = _list.filter((x) => parseInt(x.type) === 18);
      Helper.AddSelectOption(t, true, 'value');
      setStatesList(t);
    }

    rslt = await GetLoanOffiers();
    if (rslt.status === 100) {
      let tmp = rslt.data;
      tmp.forEach((elm) => {
        elm.text = Helper.GetStringJoin(elm, ["fullname", " - ", "state"]);
        elm.value = elm.id;
      });
      Helper.AddSelectOption(tmp);
      setLoanOfficers(tmp);
    }

    rslt = FetchProfile().then((obj) => {
      if (parseInt(obj.status) === 100) {
        let _data = obj.data;
        let _row = {
          borrower: {
            firstName: _data?.first_name,
            lastName: _data?.last_name,
            mobilePhone: _data?.mobile_number,
            emailAddressText: _data?.email,
            phoneType: "Mobile"
          }
        };
        setRow(_row);
        readOnlyFields = ["firstName", "lastName"];
      }
    });

    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    setError(null);
    FetchResults();
  }

  const OnInputChanged = async (parent, path, name, value) => {
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, {
      IsArray: false,
    });
    if (name === "loanOfficerId") {
      ExtractOfficerDetails(newRow, value);
    }
    setRow(newRow);
    setState(!state);
  };

  const OnSubmitClicked = async (e) => {
    e.preventDefault();
    setError(null);

    let flag = 0;
    if (errors && errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        let current = errors[i];
        if (current.isBlur === 1) {
          flag = 1;
          break;
        }
      }
    }

    if (flag === 1) {
      setError("You have some errors. Please fix and proceed.");
      return;
    }

    setError(null);
    setErrors([]);

    setProgress(true);
    let rslt = await AddShortLoan(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setError("You have some errors. Please fix and proceed.");
      setErrors(rslt.errors);
      return;
    }

    await Session.StoreJSONAsync(
      "thank",
      Helper.GetStringJoin(row?.borrower, ["firstName", "lastName"])
    );

    navigate("/thank");
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val, isBlur: 1 });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const ExtractOfficerDetails = (nRow, id) => {
    let tmp = loanOfficers.filter((x) => parseInt(x.id) === parseInt(id));
    if (tmp && tmp.length > 0) {
      nRow.loanOfficerEmail = tmp[0].email;
      nRow.loanOfficerPhone = tmp[0].work_phone;
      nRow.loanOfficerNMLSId = tmp[0].nmls_id;
      nRow.loanOfficerLicenseState = tmp[0].state;
      nRow.loanOfficerLicenseID = tmp[0].license;
      nRow.loanOfficer = tmp[0].fullname;
    }
  };

  return (
    <>
      <Loader show={progress} />
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <div className="form1003_pgBlk">
                <ul className="formHeadStageBlk">
                  <li className="formHeadStageBlk">1003 Form - Short Application</li>
                  <li className="formHeadStageBlk">&nbsp;</li>
                </ul>
                <div className="spacer_25margin_bottom"></div>
                <ul className="form1003IIcol">
                  <li className="form1003IIcol">
                    <div className="form1003BlkHead">
                      <div className="formHeadContent">Personal Details</div>
                      <div className="form1003Blk_right">&nbsp;</div>
                    </div>
                    <div className="form1003ContentBlk">
                      <ul className="formContentIIcol">
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">First Name</div>
                          <div className="formMandatory">*</div>
                          <div className="usericon"></div>
                          <TextInput
                            errors={errors}
                            tag={"borrower.firstName"}
                            path={"borrower.firstName"}
                            name={"firstName"}
                            value={Helper.ToString(row?.borrower?.firstName)}
                            onInputChildChanged={OnInputChanged}
                            readOnly={readOnlyFields.indexOf('firstName') > -1}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">Last Name</div>
                          <div className="formMandatory">*</div>
                          <div className="usericon"></div>
                          <TextInput
                            errors={errors}
                            tag={"borrower.lastName"}
                            path={"borrower.lastName"}
                            name={"lastName"}
                            value={Helper.ToString(row?.borrower?.lastName)}
                            onInputChildChanged={OnInputChanged}
                            readOnly={readOnlyFields.indexOf('lastName') > -1}
                          />
                        </li>
                      </ul>
                      <div className="spacer_20margin_bottom">
                        <div className="form1003ContentHead">Email Address</div>
                        <div className="formMandatory">*</div>
                        <div className="mailicon"></div>
                        <TextInput
                          errors={errors}
                          tag={"borrower.emailAddressText"}
                          path={"borrower.emailAddressText"}
                          name={"emailAddressText"}
                          value={Helper.ToString(
                            row?.borrower?.emailAddressText
                          )}
                          onInputChildChanged={OnInputChanged}
                          type={"EMAIL"}
                          onBlurError={OnBlurError}
                          onInputClicked={OnInputClicked}
                          required={true}
                        />
                      </div>
                      <ul className="formContentIIcol">
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            Are you working with a Loan Officer?
                          </div>
                          <ul className="form_A">
                            <RadioInput
                              errors={errors}
                              tag={`borrower_loanOfficerIndicator`}
                              name={"loanOfficerIndicator"}
                              path={"loanOfficerIndicator"}
                              value={Helper.ToUndefinedCheck(
                                row?.loanOfficerIndicator
                              )}
                              options={Helper.Options()}
                              uselabel={false}
                              onInputChildChanged={OnInputChanged}
                            />
                          </ul>
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            Loan Officer Name
                          </div>
                          <div className="usericon"></div>
                          <DropDown
                            errors={errors}
                            tag={"borrower_loanOfficer"}
                            name={"loanOfficerId"}
                            path={"loanOfficerId"}
                            value={Helper.ToString(row?.loanOfficerId)}
                            items={loanOfficers}
                            displayName={"text"}
                            displayValue={"value"}
                            onInputChildChanged={OnInputChanged}
                            disabled={
                              !Helper.ToUndefinedCheck(row?.loanOfficerIndicator)
                            }
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            Is there a Co-Borrower?
                          </div>
                          <div className="formMandatory">*</div>
                          <ul className="form_A">
                            <RadioInput
                              path={`coBorrowerIndicator`}
                              errors={errors}
                              tag={`coBorrowerIndicator`}
                              name={"coBorrowerIndicator"}
                              value={Helper.ToUndefinedCheck(
                                row?.coBorrowerIndicator
                              )}
                              options={Helper.Options()}
                              uselabel={false}
                              onInputChildChanged={OnInputChanged}
                            />
                          </ul>
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            Evening Phone
                          </div>
                          <div className="formMandatory">*</div>
                          <div className="phicon"></div>
                          <TextInput
                            errors={errors}
                            maxLength={14}
                            tag={"borrower.mobilePhone"}
                            path={"borrower.mobilePhone"}
                            name={"mobilePhone"}
                            value={Helper.ToString(row?.borrower?.mobilePhone)}
                            type={"PHONE"}
                            onInputChildChanged={OnInputChanged}
                            onBlurError={OnBlurError}
                            onInputClicked={OnInputClicked}
                            required={true}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">Work Phone</div>
                          <div className="phicon"></div>
                          <TextInput
                            errors={errors}
                            maxLength={14}
                            tag={"borrower.workPhone"}
                            path={"borrower.workPhone"}
                            name={"workPhone"}
                            value={Helper.ToString(row?.borrower?.workPhone)}
                            type={"PHONE"}
                            onInputChildChanged={OnInputChanged}
                            onBlurError={OnBlurError}
                            onInputClicked={OnInputClicked}
                            required={true}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            How would you like to be contacted
                          </div>
                          <div className="typeicon"></div>
                          <DropDown
                            path={"borrower.preferendContact"}
                            tag={"borrower.preferendContact"}
                            errors={errors}
                            name={"preferendContact"}
                            value={row?.borrower?.preferendContact}
                            items={Helper.PreferendContact}
                            displayName={"text"}
                            displayValue={"value"}
                            onInputChildChanged={OnInputChanged}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            My / Our Credit is
                          </div>
                          <div className="typeicon"></div>
                          <DropDown
                            path={"borrower.creditType"}
                            tag={"borrower.creditType"}
                            errors={errors}
                            name={"creditType"}
                            value={row?.borrower?.creditType}
                            items={Helper.CreditTypes}
                            displayName={"text"}
                            displayValue={"value"}
                            onInputChildChanged={OnInputChanged}
                          />
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="form1003IIcol">
                    <div className="form1003BlkHead">
                      <div className="formHeadContent">
                        Loan and Property Info
                      </div>
                      <div className="form1003Blk_right">&nbsp;</div>
                    </div>
                    <div className="form1003ContentBlk">
                      <div className="spacer_20margin_bottom">
                        <div className="form1003ContentHead">
                          Property Address
                        </div>
                        <div className="form_homeicon"></div>
                        <TextInput
                          path={"property.addressLineText"}
                          tag={"property.addressLineText"}
                          errors={errors}
                          name={"addressLineText"}
                          value={row?.property?.addressLineText}
                          onInputChildChanged={OnInputChanged}
                        />
                      </div>
                      <ul className="formContentIIcol">
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">City</div>
                          <div className="locationicon"></div>
                          <TextInput
                            path={"property.city"}
                            tag={"property.city"}
                            errors={errors}
                            name={"city"}
                            value={row?.property?.city}
                            onInputChildChanged={OnInputChanged}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">State</div>
                          <div className="locationicon"></div>
                          <DropDown
                            path={"property.state"}
                            tag={"property.state"}
                            errors={errors}
                            name={"state"}
                            value={row?.property?.state}
                            items={statesList}
                            displayName={"text"}
                            displayValue={"value"}
                            onInputChildChanged={OnInputChanged}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">Zip Code</div>
                          <div className="form_homeicon"></div>
                          <TextInput
                            path={"property.postalCode"}
                            tag={"property.postalCode"}
                            errors={errors}
                            name={"postalCode"}
                            value={row?.property?.postalCode}
                            onInputChildChanged={OnInputChanged}
                            onBlurError={OnBlurError}
                            type={"ZIP"}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            Purpose of Loan
                          </div>
                          <div className="formMandatory">*</div>
                          <div className="locationicon"></div>
                          <DropDown
                            path={"loanDetails.loanPurposeType"}
                            tag={"loanDetails.loanPurposeType"}
                            errors={errors}
                            name={"loanPurposeType"}
                            value={row?.loanDetails?.loanPurposeType}
                            items={loanPurposeTypes}
                            displayName={"text"}
                            displayValue={"value"}
                            onInputChildChanged={OnInputChanged}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            Property Will Be
                          </div>
                          <div className="formMandatory">*</div>
                          <div className="locationicon"></div>
                          <DropDown
                            path={"loanDetails.propertyUsageType"}
                            tag={"loanDetails.propertyUsageType"}
                            errors={errors}
                            name={"propertyUsageType"}
                            value={row?.loanDetails?.propertyUsageType}
                            items={occupancyTypes}
                            displayName={"text"}
                            displayValue={"value"}
                            onInputChildChanged={OnInputChanged}
                          />
                        </li>
                        <li className="formContentIIcol">
                          <div className="form1003ContentHead">
                            Purchase Price (or Estimated Value)
                          </div>
                          <div className="form_dollaricon"></div>
                          <TextInput
                            path={"property.propertyAppraisedValueAmount"}
                            tag={"property.propertyAppraisedValueAmount"}
                            errors={errors}
                            name={"propertyAppraisedValueAmount"}
                            type={"FINANCE"}
                            value={row?.property?.propertyAppraisedValueAmount}
                            onInputChildChanged={OnInputChanged}
                          />
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <ul className="form_btn_successError_blk">
                  <li className="form_btn_steps_srt_label">
                    <input
                      type="button"
                      defaultValue="Submit"
                      className="form_btn_steps"
                      onClick={(e) => OnSubmitClicked(e)}
                    />
                  </li>

                  {error && (
                    <li className="form_successError_srt_steps">
                      <div className="errormessage">
                        {error}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
