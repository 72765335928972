import { React, Link, TextInput2 as TextInput, DropDown2 as DropDown } from "../../../../common";

const RenderChildAsset = (props) => {
  const { path, item, count, assetsTypes, onInputChanged } = props;

  return (
    <>
      {count > 1 && (
        <div
          className="formPartition"
          onClick={(e) => onInputChanged(`${path}.delete`.replace(/\./g, '_'), true)}
        >
          <div className="closePartition">
            <Link to="#">&#x2715; &nbsp;Delete</Link>
          </div>
        </div>
      )}
      <ul className="formContentIIcol">
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Account Type</div>
          <div className="usericon"></div>
          <DropDown
            path={`${path}.assetType`}
            value={item?.assetType}
            items={assetsTypes}
            displayName={"text"}
            displayValue={"value"}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Financial Institution</div>
          <div className="officeicon"></div>
          <TextInput
            path={`${path}.holderName`}
            value={item?.holderName}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Account Number</div>
          <div className="hastagicon"></div>
          <TextInput
            path={`${path}.accountIdentifier`}
            value={item?.accountIdentifier}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Cash or Market Value</div>
          <div className="form_dollaricon"></div>
          <TextInput
            path={`${path}.urla2020CashOrMarketValueAmount`}
            value={item?.urla2020CashOrMarketValueAmount}
            valuetype={"FINANCE"}
            onInputChildChanged={onInputChanged}
          />
        </li>
      </ul>
    </>
  );
};

const Component = (props) => {
  const { items, assetsTypes, onAddArrayList, onInputChanged } = props;

  const item = items && items.childs && items.childs.length > 0 ? items.childs[0] : null;

  const OnAddNewAsset = (e) => {
    e.preventDefault();
    let obj = {
      title: item?.title,
      owner: item?.owner,
      delete: false
    };
    if (onAddArrayList) onAddArrayList('assets', obj);
  };

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">ASSETS -</div>
      </div>
      <div className="form1003ContentBlk">
        <div className="form1003BlkInsideHead_topMinus40">
          <div className="formHeadContent_70pc">
            Bank Accounts, Retirement and Other Accounts You Have
          </div>
          <div className="form1003Blk_right">
            <input
              type="button"
              defaultValue="&#65291; Another"
              className="headRight_button"
              onClick={(e) => OnAddNewAsset(e)}
            />
          </div>
        </div>
        {items && items.childs && items.childs.map((x) => {
          if (!x.delete) {
            return (
              <RenderChildAsset
                count={items.childs.filter((x) => !x.delete).length}
                key={x.index}
                path={`assets.${x.index}`}
                item={x}
                assetsTypes={assetsTypes}
                onInputChanged={onInputChanged}
              />
            )
          } else { return null }
        })}
      </div>
    </>
  );
};

export default Component;
