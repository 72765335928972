import { React, Helper, TextInput } from "../../common";
import { ChangePassword } from "../../services/profileApi";

const Component = () => {
    const [row, setRow] = React.useState({});
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);
    const [errors, setErrors] = React.useState([]);

    React.useEffect(() => {
        setRow({});
        setError(null);
        setSuccess(null);
        setErrors([]);
    }, []);

    const OnInputChanged = async (parent, path, name, value) => {
        setRow({
            ...row,
            [name]: value
        });
    };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        setErrors([]);

        let _fields = ["oldpassword", "newpassword", "conpassword"];
        let _errs = [];
        _fields.forEach((elm) => {
            _errs.push({ field: elm });
        });

        if (Helper.IsJSONEmpty(row)) {
            setErrors(_errs);
            setError("All fields are mandatory");
            return;
        }

        _errs = [];
        let isInvalid = false;

        _fields.forEach((elm) => {
            if (Helper.IsNullValue(row[elm])) {
                isInvalid = true;
                _errs.push({ field: elm });
            }
        });

        if (isInvalid) {
            setErrors(_errs);
            setError("All fields are mandatory");
            return;
        }

        if (row.newpassword !== row.conpassword) {
            setErrors([{ field: 'newpassword' }]);
            setError("Password does not match");
            return;
        }

        _errs = [];
        global.Busy(true);
        const rslt = await ChangePassword(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            setErrors(rslt.errors);
            return;
        }

        setSuccess("Password changed successful");
    }

    const OnCancelClicked = async (e) => {
        e.preventDefault();
        setRow({});
        setError(null);
        setSuccess(null);
        setErrors([]);
        window.hide('changepswd_pop');
    }

    return (
        <div id="changepswd_pop" className="pop_disable_bg">
            <div className="changePassword_pop">
                <div className="pop_head">Change Password</div>
                <div className="pop_contentArea">
                    <div className="pop_formFieldHead_center">Old Password</div>
                    <div className="textBox_whiteBg">
                        <TextInput
                            inputtype={"password"}
                            errors={errors}
                            tag={"oldpassword"}
                            path={"oldpassword"}
                            name={"oldpassword"}
                            value={Helper.ToString(row?.oldpassword)}
                            onInputChildChanged={OnInputChanged}
                        />
                    </div>
                    <div className="pop_formFieldHead_center">New Password</div>
                    <div className="textBox_whiteBg">
                        <TextInput
                            inputtype={"password"}
                            errors={errors}
                            tag={"newpassword"}
                            path={"newpassword"}
                            name={"newpassword"}
                            value={Helper.ToString(row?.newpassword)}
                            onInputChildChanged={OnInputChanged}
                        />
                    </div>
                    <div className="pop_formFieldHead_center">Confirm Password</div>
                    <div className="textBox_whiteBg">
                        <TextInput
                            inputtype={"password"}
                            errors={errors}
                            tag={"conpassword"}
                            path={"conpassword"}
                            name={"conpassword"}
                            value={Helper.ToString(row?.conpassword)}
                            onInputChildChanged={OnInputChanged}
                        />
                    </div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Update" className="pop_button" onClick={(e) => OnSubmitClicked(e)} />
                    <input type="button" value="Close" className="pop_button" onClick={(e) => OnCancelClicked(e)} />
                </div>
                {error && (
                    <div className="errormessage_center">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="successmessage_center">
                        {success}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Component;