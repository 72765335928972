import { React, Link, useNavigate, useTimerSession, useSearchParams, Helper, Session, Storage } from "../../common";
import { SignIn, SignUp } from "../../services/authApi";

const SignUpScreen = ({ onClicked }) => {
  const NavigateTo = useNavigate();
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [success, setSuccess] = React.useState(null);
  const [received, setReceived] = React.useState(false);
  const [state, setState] = React.useState(false);
  const RedirectUrl = window.location.href.replace(/\/$/, "");

  React.useEffect(() => {
    const Reset = () => {
      setError(null);
      setSuccess(null);
      setReceived(true);
      setRow({ scope: "OTP", apptype: "LEND", redirecturl: RedirectUrl });
    };
    Reset();
  }, [state, RedirectUrl]);

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnVerifyClicked = async (e) => {
    setError(null);
    setErrors(null);
    setSuccess(null);
    global.Busy(true);
    const rslt = await SignUp(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      setErrors(rslt.errors);
      return;
    }
    setReceived(true);
  };

  const OnSubmitClicked = async (e) => {
    setError(null);
    setSuccess(null);
    row.signupVal = 1;
    global.Busy(true);
    const rslt = await SignIn(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    let data = rslt.data;
    await Session.StoreJSON("status", data.data.status);
    await Session.StoreJSON("user", data.data);
    await Session.Store("userToken", data.token);
    onClicked("hide");
    if (parseInt(data.data.status) === 3) {
      NavigateTo("/profile");
    } else {
      NavigateTo("/dashboard");
    }

  };

  const OnBlurPhoneChanged = (name, val) => {
    val = Helper.ToUSAPhone(val);
    if (val === null) return;
    OnInputChanged(name, val);
  };

  const OnDummyChange = () => { };

  const OnStateClicked = () => {
    setState(!state);
  };

  const OnNavigatePage = (e, link) => {
    e.preventDefault();
    onClicked('hide');
    NavigateTo(link);
  }

  return (
    <>
      <div id="signup" style={{ display: "block" }}>
        <input id="txtState" type="hidden" onClick={() => OnStateClicked()} />
        <div className="si_su_Head">Create your i3 Mortgage Account</div>
        <div className="si_su_sub">
          Already have an account?{" "}
          <Link to="#" onClick={(e) => onClicked("signin")}>
            Sign In
          </Link>
        </div>
        <div className="si_su_method" style={{ display: "none" }}>
          <div className="method_head">
            Choose on the following sign up methods.
          </div>
          <div className="spacer_10margin_bottom">
            <Link to="#" title="Twitter">
              <div className="twitterLogo"></div>
            </Link>
            <Link to="#" title="Facebook">
              <div className="facebookLogo"></div>
            </Link>
            <Link to="#" title="Google">
              <div className="googleLogo"></div>
            </Link>
          </div>
        </div>
        <div className="si_su_emailmethod">
          Sign up using your email address
        </div>
        <div className="spacer_20margin_bottom">
          <ul className="si_su_II_col">
            <li className="si_su_II_col">
              <div className="si_su_Q">First Name</div>
              <input
                type="text"
                placeholder="First Name"
                value={Helper.ToString(row.first_name)}
                style={{
                  borderColor:
                    errors && errors.indexOf("first_name") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("first_name", e.target.value)}
              />
            </li>
            <li className="si_su_II_col">
              <div className="si_su_Q">Last Name</div>
              <input
                type="text"
                placeholder="Last Name"
                value={Helper.ToString(row.last_name)}
                style={{
                  borderColor:
                    errors && errors.indexOf("last_name") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("last_name", e.target.value)}
              />
            </li>
            <li className="si_su_II_col">
              <div className="si_su_Q">Mobile</div>
              <input
                type="text"
                placeholder="Mobile"
                value={Helper.ToString(row.mobile_number)}
                style={{
                  borderColor:
                    errors && errors.indexOf("mobile_number") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) =>
                  OnInputChanged("mobile_number", e.target.value)
                }
                onKeyPress={(e) => {
                  return Helper.AcceptedChars(e, "PHONE");
                }}
                onBlur={(e) =>
                  OnBlurPhoneChanged("mobile_number", e.target.value)
                }
              />
            </li>
            <li className="si_su_II_col">
              <div className="si_su_Q">Work Phone</div>
              <input
                type="text"
                placeholder="Work Phone"
                value={Helper.ToString(row.work_phone)}
                style={{
                  borderColor:
                    errors && errors.indexOf("work_phone") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("work_phone", e.target.value)}
                onKeyPress={(e) => {
                  return Helper.AcceptedChars(e, "PHONE");
                }}
                onBlur={(e) => OnBlurPhoneChanged("work_phone", e.target.value)}
              />
            </li>
          </ul>
        </div>
        <div className="spacer_20margin_bottom">
          <ul className="si_su_II_col_by150">
            <li className="si_su_II_col_by150">
              <div className="si_su_Q">Email Address</div>
              <input
                type="text"
                placeholder="Email Address"
                value={Helper.ToString(row.email)}
                style={{
                  borderColor:
                    errors && errors.indexOf("email") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("email", e.target.value)}
              />
            </li>
            <li className="si_su_II_col_by150">
              <div className="si_su_Q">&nbsp;</div>
              <input
                type="button"
                value="Verify Email"
                className="button_verify"
                onClick={(e) => OnVerifyClicked(e)}
              />
            </li>
          </ul>
        </div>
        {received && (
          <div className="spacer_20margin_bottom">
            <div className="spacer_20margin_bottom">
              <div className="si_su_note">
                <input
                  type="checkbox"
                  id="cb_scope"
                  checked={row?.scope === 'EMAIL'}
                  onChange={(e) => OnDummyChange(e)}
                  onClick={(e) => OnInputChanged("scope", e.target.checked ? 'EMAIL' : 'OTP')}
                />
                <label htmlFor="cb_scope">Use Password</label>
              </div>
            </div>
            {row?.scope === 'OTP' && (
              <ul className="si_su_II_col">
                <li className="si_su_II_col">
                  <div className="si_su_Q">OTP</div>
                  <input
                    type="text"
                    value={Helper.ToString(row.otp)}
                    onChange={(e) => OnInputChanged("otp", e.target.value)}
                  />
                </li>
                <li className="si_su_II_col">
                  <div className="si_su_OTP_note">
                    Please authenticate using the One Time Password that is sent
                    to the email address
                  </div>
                </li>
              </ul>
            )}

            {row?.scope === 'EMAIL' && (
              <ul className="si_su_II_col">
                <li className="si_su_II_col">
                  <div className="si_su_Q">Password</div>
                  <input
                    type="text"
                    value={Helper.ToString(row.password)}
                    onChange={(e) => OnInputChanged("password", e.target.value)}
                  />
                </li>
                <li className="si_su_II_col">
                  <div className="si_su_OTP_note">
                    Please authenticate using the Password that is sent to the
                    email address
                  </div>
                </li>
              </ul>
            )}
          </div>
        )}
        {error && (
          <div
            className="errormessage"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            {error}
          </div>
        )}
        {success && (
          <div
            className="successmessage"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            {success}
          </div>
        )}
        {received && (
          <div className="button_si_su_label">
            <input
              type="button"
              value="Create My Account"
              className="button_si_su"
              onClick={(e) => OnSubmitClicked(e)}
            />
          </div>
        )}
        <div className="si_su_terms">
          By creating an account, you're agreeing to our
          <Link to="#" onClick={(e) => OnNavigatePage(e, '/terms')}>Terms Of Use</Link> and our
          <Link to="#" onClick={(e) => OnNavigatePage(e, '/privacy')}>Security And Privacy Policy</Link>, which includes using
          arbitration to resolve claims related to the Telephone Consumer
          Protection Act.
        </div>
      </div>
    </>
  );
};

const SignInScreen = ({ onClicked }) => {
  const [email, setEmail] = React.useState("");
  const [loginKey, setLoginKey] = React.useState("");
  const [error, setError] = React.useState(null);
  const [received, setReceived] = React.useState(false);
  const [remember, setRemember] = React.useState(false);
  const [scope, setScope] = React.useState("");
  const [state, setState] = React.useState(false);
  const [searchParams] = useSearchParams();
  let params = searchParams.get("q") || undefined;

  const NavigateTo = useNavigate();

  const ResetValues = () => {
    setEmail("");
    setLoginKey("");
    setError(null);
    setReceived(false);
    setRemember(false);
  };

  React.useEffect(() => {
    ResetValues();
    const fn = async () => {
      let data = await Storage.RetrieveJSONAsync("login");
      if (data["value"]) {
        setRemember(Helper.ToBool(data["remember"], false));
        setEmail(data["value"]);
      }
    };
    fn();
  }, [state]);

  const OnSignInClicked = async (e) => {
    e.preventDefault();
    setError(null);
    if (Helper.IsNullValue(email) || !Helper.IsEmailValid(email)) {
      setError("Enter valid email");
      return;
    }

    if (Helper.IsNullValue(loginKey)) {
      if (scope.toUpperCase() === "OTP") {
        setError("Enter Valid OTP");
      } else if (scope.toUpperCase() === "EMAIL") {
        setError("Enter Valid Password");
      }
      return;
    }

    let row = { email: email, scope: scope, apptype: "lend" };

    if (scope.toUpperCase() === "OTP") {
      row.otp = loginKey;
    } else if (scope.toUpperCase() === "EMAIL") {
      row.password = loginKey;
    }

    global.Busy(true);
    let rslt = await SignIn(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    rslt = rslt.data;

    await Session.StoreJSONAsync("status", rslt.data.status);
    await Session.StoreJSONAsync("user", rslt.data);
    await Session.StoreAsync("userToken", rslt.token);

    if (remember) {
      let data = {
        remember: true,
        value: email,
      };
      await Storage.StoreJSON("login", data);
    }
    onClicked("hide");
    if (parseInt(rslt.data.status) === 3) {
      NavigateTo("/profile");
    } else {
      if (params) {
        NavigateTo(params);
      } else {
        NavigateTo("/dashboard");
      }
    }
  };

  const OnDummyChange = (e) => { };

  const OnEmailLoginAccount = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      scope: "email",
      apptype: "lend",
    };
    OnVerifyAccount(data);
  };

  const OnOTPLoginAccount = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      scope: "otp",
      apptype: "lend",
    };
    OnVerifyAccount(data);
  };

  const OnVerifyAccount = async (e) => {
    setError(null);
    setReceived(false);
    if (Helper.IsNullValue(e.email) || !Helper.IsEmailValid(e.email)) {
      setError("Enter valid email");
      return;
    }
    if (e.scope.toUpperCase() === "OTP") {
      global.Busy(true);
      const rslt = await SignIn(e);
      global.Busy(false);
      if (rslt.status !== 100) {
        setError(rslt.statusText);
        return;
      }
    }
    setScope(e.scope);
    setReceived(true);
  };

  const OnStateClicked = () => {
    setState(!state);
  };

  return (
    <>
      <div id="signin" style={{ display: "block" }}>
        <input id="txtState" type="hidden" onClick={() => OnStateClicked()} />
        <div className="si_su_Head">Login to your i3 Mortgage Account</div>
        <div className="si_su_sub">
          Don't have an account?{" "}
          <Link to="#" onClick={(e) => onClicked("signup")}>
            Register Now!
          </Link>
        </div>
        <div className="si_su_method" style={{ display: "none" }}>
          <div className="method_head">
            Connect with your social media account
          </div>
          <div className="spacer_10margin_bottom">
            <Link to="#" title="Twitter">
              <div className="twitterLogo"></div>
            </Link>
            <Link to="#" title="Facebook">
              <div className="facebookLogo"></div>
            </Link>
            <Link to="#" title="Google">
              <div className="googleLogo"></div>
            </Link>
          </div>
        </div>
        <div className="spacer_20margin_bottom">
          <div className="si_su_emailmethod">
            Sign In using your email address
          </div>
        </div>
        <div className="spacer_15margin_bottom">
          <div className="si_su_I_col">
            <div className="si_su_Q">
              Email Address
              <div className="si_su_note">
                <input
                  type="checkbox"
                  id="cb_remember"
                  checked={remember}
                  onChange={(e) => OnDummyChange(e)}
                  onClick={(e) => setRemember(e.target.checked)}
                />
                <label htmlFor="cb_remember">Remember Me</label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={() => setError(null)}
            />
          </div>
        </div>
        <div className="spacer_5margin_bottom">
          <ul className="si_su_II_col">
            <li className="si_su_II_col">
              <input
                type="button"
                value="Login with OTP"
                className="button_verify"
                onClick={(e) => OnOTPLoginAccount(e)}
              />
            </li>
            <li className="si_su_II_col">
              <input
                type="button"
                value="Login with Password"
                className="button_verify"
                onClick={(e) => OnEmailLoginAccount(e)}
              />
            </li>
          </ul>
        </div>
        {received && scope.toUpperCase() === "OTP" && (
          <div className="spacer_20margin_bottom">
            <ul className="si_su_II_col_40by60">
              <li className="si_su_II_col_40by60">
                <div className="si_su_Q">OTP</div>
                <input
                  value={loginKey}
                  placeholder="OTP"
                  type="text"
                  onChange={(e) => setLoginKey(e.target.value)}
                  onKeyPress={(e) => {
                    setError(null);
                    return Helper.AcceptedChars(e);
                  }}
                />
              </li>
              <li className="si_su_II_col_40by60">
                <div className="si_su_OTP_note">
                  Please authenticate using the One Time Password that is sent
                  to the email address
                </div>
              </li>
            </ul>
          </div>
        )}

        {received && scope.toUpperCase() === "EMAIL" && (
          <div className="spacer_20margin_bottom">
            <ul className="si_su_II_col_40by60">
              <li className="si_su_II_col_40by60">
                <div className="si_su_Q">Password</div>
                <input
                  value={loginKey}
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setLoginKey(e.target.value)}
                />
              </li>
              <li className="si_su_II_col_40by60">
                <div className="si_su_OTP_note">
                  Please authenticate using the Password that is sent to the
                  email address
                </div>
              </li>
            </ul>
          </div>
        )}

        {received && (
          <div className="button_si_su_label">
            <input
              type="button"
              value="LOGIN"
              onClick={(e) => OnSignInClicked(e)}
              className="button_verify"
            />
          </div>
        )}

        {error && (
          <div
            className="errormessage"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            {error}
          </div>
        )}
      </div>
    </>
  );
};

const Component = () => {
  const [signtype] = useTimerSession('signtype');
  const [logintype, setLoginType] = React.useState("signin");
  let closeElm = React.useRef(null);

  React.useEffect(() => {
    let _signtype = signtype || "signin";
    setLoginType(_signtype);
  }, [signtype]);

  const OnClicked = (e) => {
    if (e === "hide") {
      closeElm.click();
    } else {
      setLoginType(e);
    }
  };

  return (
    <div className="si_su_Layout">
      <Link
        to="#"
        ref={(input) => (closeElm = input)}
        className="si_su_Close"
        onClick={(e) => window.fnSign(0)}
      ></Link>
      <div className="si_su_ContentArea">
        {logintype === "signup" ? (
          <SignUpScreen onClicked={(e) => OnClicked(e)} />
        ) : (
          <SignInScreen onClicked={(e) => OnClicked(e)} />
        )}
      </div>
    </div>
  );
};

export default Component;
