const Component = () => {
    return (
        <div id="info_loanAmount" className="pop_disable_bg">
            <div className="loanAmount_pop">
                <div className="pop_head">Loan Amount</div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Loan Amount - </div>
                    <div className="pop_content">The amount of money you are applying for.</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_loanAmount')} />
                </div>
            </div>
        </div>
    );
};

export default Component;