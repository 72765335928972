import { React, useNavigate, Helper, useTimerSession } from "../../../common";
import { GetList } from "../../../services/enumsApi";
import { AddLoan, GetShortList, GetLoan } from "../../../services/loanApi";

import Container from "./container1003";

import {
  RenderAssets,
  RenderOtherAssets,
  RenderOtherCredits,
  RenderLiabilities,
  RenderOtherLiabilities,
  RenderRealEstate,
} from "./childs";

import { InfoLoanType } from "../help";

const AddDefaultObject = (borrowersList) => {
  let _tmp = [];

  borrowersList.forEach((elm) => {
    _tmp.push({
      mapid: elm.mapid,
      applicantType: elm.applicantType,
      owner: elm.applicantType,
      type: elm.type
    })
  });

  // Prepare for assets
  let _assets = [{ index: 0, delete: false }];

  // Prepare for liabilities
  let _liabilities = [{ index: 0, delete: false }];

  // Prepare for otherAssets
  let _otherAssets = [{ index: 0, delete: false }];

  // Prepare for otherCredits
  let _otherCredits = [{ index: 0, delete: false }];

  // Prepare for otherLiabilities
  let _otherLiabilities = [{ index: 0, delete: false }];

  // Prepare for reoProperties
  let _reoProperties = [{ index: 0, delete: false }];

  _tmp.forEach((e, index) => {

    let newItem;

    newItem = JSON.parse(JSON.stringify(_assets));
    newItem.forEach((x) => {
      x.owner = e.owner;
    });
    e.assets = newItem;

    newItem = JSON.parse(JSON.stringify(_liabilities));
    newItem.forEach((x) => {
      x.owner = e.owner;
    });
    e.liabilities = newItem;

    newItem = JSON.parse(JSON.stringify(_otherAssets));
    newItem.forEach((x) => {
      x.borrowerType = e.applicantType;
    });
    e.otherAssets = newItem;

    newItem = JSON.parse(JSON.stringify(_otherCredits));
    newItem.forEach((x) => {
      x.borrowerType = e.applicantType;
    });
    e.otherCredits = newItem;

    newItem = JSON.parse(JSON.stringify(_otherLiabilities));
    newItem.forEach((x) => {
      x.borrowerType = e.applicantType;
    });
    e.otherLiabilities = newItem;

    newItem = JSON.parse(JSON.stringify(_reoProperties));
    newItem.forEach((x) => {
      x.owner = e.applicantType;
    });
    e.reoProperties = newItem;
    e.completed = false;
  });

  return _tmp;
}

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [loadData, setLoadData] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loanId] = useTimerSession("loanid");
  const [row, setRow] = React.useState([]);

  const [borrowersList, setBorrowersList] = React.useState([]);
  const [selectedBorrower, setSelectedBorrower] = React.useState("");
  const [selectedBorrowerIndex, setSelectedBorrowerIndex] = React.useState(0);

  /* Get Enum list */
  const [assetstypes, setAssetsTypes] = React.useState([]);
  const [otherassetstypes, setOtherAssetsTypes] = React.useState([]);
  const [othercreditsstypes, setOtherCreditsTypes] = React.useState([]);
  const [libalitiytypes, setLibalitiyTypes] = React.useState([]);
  const [otherlibalitiytypes, setOtherLibalitiyTypes] = React.useState([]);
  const [propertystatustypes, setPropertyStatusTypes] = React.useState([]);
  const [occupancies, setOccupancies] = React.useState([]);
  const [loantypes, setLoanTypes] = React.useState([]);
  const [featureOccupancies, setFeatureOccupancies] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [unittypes, setUnitTypes] = React.useState([]);
  const [state, setState] = React.useState(false);
  const [lastStage, setLastStage] = React.useState(5);
  let stageIndicator = 5;
  const navigate = useNavigate();

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchLookUps = async () => {
    return new Promise(async (resolve) => {
      global.Busy(true);
      let idList = [1, 4, 5, 10, 11, 12, 13, 14, 18, 19, 23];
      let rslt = await GetList(idList);
      if (rslt.status === 100) {
        let t, _list;
        _list = rslt.data || [];
        t = _list.filter((x) => parseInt(x.type) === 1);
        Helper.AddSelectOption(t, true, 'value');
        setLoanTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 4);
        Helper.AddSelectOption(t, true, 'value');
        setOccupancies(t);
        t = _list.filter((x) => parseInt(x.type) === 5);
        Helper.AddSelectOption(t, true, 'value');
        setUnitTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 10);
        Helper.AddSelectOption(t, true, 'value');
        setAssetsTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 11);
        Helper.AddSelectOption(t, true, 'value');
        setOtherAssetsTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 12);
        Helper.AddSelectOption(t, true, 'value');
        setPropertyStatusTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 13);
        Helper.AddSelectOption(t, true, 'value');
        setLibalitiyTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 14);
        Helper.AddSelectOption(t, true, 'value');
        setOtherLibalitiyTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 18);
        Helper.AddSelectOption(t, true, 'value');
        setStatesList(t);
        t = _list.filter((x) => parseInt(x.type) === 19);
        Helper.AddSelectOption(t, true, 'value');
        setOtherCreditsTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 23);
        Helper.AddSelectOption(t, true, 'value');
        setFeatureOccupancies(t);
      }

      global.Busy(false);
      return resolve(true);
    });
  };

  const FetchResults = async () => {
    setErrors([]);
    setBorrowersList([]);
    setRow([]);
    await FetchLookUps();

    global.Busy(true);

    if (!Helper.IsNullValue(loanId)) {
      let rslt = await GetShortList(loanId, 2);
      if (rslt.status === 100) {
        let _data = rslt.data;
        Helper.SortBy(_data, 'mapid');
        setBorrowersList(_data);
      }
    }

    global.Busy(false);
  };

  const ContainsKey = (list, mapid) => {
    return list.findIndex((z) => z === mapid) > -1;
  }

  const FetchData = async () => {
    setRow([]);
    let stageFive, rslt;
    global.Busy(true);
    rslt = await GetLoan(stageIndicator, loanId);
    if (rslt.status === 100) {
      let _data = rslt.data;
      setLastStage(_data.stageIndicator);
      stageFive = _data.stageFive.applications;
    }

    let _tmpList, mapList;
    if (Helper.IsNullValue(stageFive) || stageFive.length === 0) {
      stageFive = AddDefaultObject(borrowersList);
    } else {
      mapList = stageFive.map((x) => x.mapid);
      _tmpList = borrowersList.filter((x) => !ContainsKey(mapList, x.mapid));
      let missingList = AddDefaultObject(_tmpList);
      let finalObj = stageFive.concat(missingList);
      stageFive = finalObj;
    }

    _tmpList = stageFive.filter((z) => z.completed).map((x) => x.mapid);
    mapList = borrowersList;
    mapList.forEach((x) => { x.completed = false; x.disabled = true; });
    mapList.forEach((x) => {
      x.completed = ContainsKey(_tmpList, x.mapid);
      x.disabled = !x.completed;
    });

    _tmpList = mapList.filter((x) => !x.completed);
    if (_tmpList.length > 0) {
      let idx = mapList.findIndex((x) => x.mapid === _tmpList[0].mapid);
      mapList[idx].completed = true;
      mapList[idx].disabled = false;
    }

    setBorrowersList(mapList);
    setRow(stageFive);

    global.Busy(false);
  }

  if (initlized) {
    setInitlized(false);
    setErrors([]);
    setError(null);
    setSuccess(null);
    FetchResults();
  }

  if (loadData) {
    setLoadData(false);
    console.log("Loading Data...");
    FetchData();
  }

  const OnRecordValidate = async () => {
    return new Promise(async (resolve) => {
      setError("");
      setErrors([]);
      setState(!state);
      const { errors, valid } = Helper.IsFormValid('stageFive');
      if (!valid) {
        setErrors(errors);
        setError("Fill all required details");
        return resolve(false);
      }

      setState(!state);

      return resolve(errors.length === 0);
    });
  };

  const OnUpdateRecord = async (e, saved) => {
    e.preventDefault();
    setState(!state);
    setSuccess(null);
    let _status = await OnRecordValidate();
    if (!_status) return;
    setErrors([]);
    setError(null);
    setSuccess(null);

    row._id = loanId;
    row.stageIndicator = stageIndicator;

    let applicants = JSON.parse(JSON.stringify(row));
    const removeItems = ['stageIndicator', 'statusIndicator', '_id'];
    removeItems.forEach(e => {
      delete applicants[e];
    });

    let newObjs = [];
    for (var cur = 0; cur <= selectedBorrowerIndex; cur++) {
      applicants[cur].completed = true;
      newObjs.push(applicants[cur]);
    }

    stageIndicator = lastStage > 5 ? lastStage : 5;

    let newObj = {
      _id: loanId,
      statusIndicator: 0,
      stageIndicator,
      stageFive: { applications: newObjs },
    };

    global.Busy(true);
    let rslt = await AddLoan(newObj);
    global.Busy(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    }

    if (saved) {
      setSuccess(Helper.SavedMessage);
    } else {
      if (selectedBorrowerIndex === borrowersList.length - 1) {
        navigate("/stage6");
      } else {
        MoveNext();
      }
    }

  };

  const OnAddArrayList = (name, json) => {
    let _row = row;
    let obj = row && row[selectedBorrowerIndex];
    if (!Helper.IsJSONEmpty(obj)) {
      json.index = obj[name].length;
      obj[name].push(json);
      _row[selectedBorrowerIndex] = obj;
      setRow(_row);
      setState(!state);
    }
  };

  const OnInputChanged = async (path, value, index) => {
    let _row = row;
    let newRow = Helper.ModifyJsonArrayObject4(_row[selectedBorrowerIndex], path, value);
    _row[selectedBorrowerIndex] = newRow;
    setRow(_row);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    setSuccess(null);
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    setSuccess(null);
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const GetArrayObject = (name) => {
    if (!Helper.IsJSONEmpty(row)) {
      let obj = row && row[selectedBorrowerIndex];
      if (!Helper.IsJSONEmpty(obj)) {
        return { childs: obj[name] };
      }
    }
    return { childs: [] };
  }

  const MoveNext = () => {
    let index = selectedBorrowerIndex;
    let listItems = borrowersList;
    let _row = row;
    if (listItems.length - 1 > index) {
      index++;
      let _item = listItems[index].mapid;
      row[index].completed = true;
      listItems[index].disabled = false;
      setBorrowersList(listItems);
      setSelectedBorrowerIndex(index);
      setSelectedBorrower(_item);
    }
    setRow(_row);
    setState(!state);
  }

  React.useEffect(() => {
    if (borrowersList && borrowersList.length > 0) {
      setLoadData(true);
    }
  }, [borrowersList]);

  const OnBorrowerSelected = (e) => {
    setSelectedBorrowerIndex(e.target.selectedIndex);
    setSelectedBorrower(e.target.value);
    setErrors([]);
    setError(null);
    setSuccess(null);
    setState(!state);
  }

  return (
    <>
      <Container selected={5} lastStage={lastStage}>
        <ul className="selectBorrowerBlk">
          <li className="selectBorrowerBlk">
            <div className="selectPadLeft10">
              <select
                id={'dpBorrower'}
                name={'dpBorrower'}
                value={Helper.ToString(selectedBorrower)}
                onChange={(e) => OnBorrowerSelected(e)}
              >
                {!Helper.IsArrayNull(borrowersList) &&
                  borrowersList.map((x, index) => {
                    return (
                      <option key={index} disabled={x.disabled} value={x['mapid']}>{x['text']}</option>
                    );
                  })}
              </select>
            </div>
          </li>
          <li className="selectBorrowerBlk">&nbsp;</li>
        </ul>

        <form id="stageFive">
          <ul className="form1003IIcol">
            <li className="form1003IIcol">
              <RenderAssets
                items={GetArrayObject('assets')}
                assetsTypes={assetstypes}
                onAddArrayList={OnAddArrayList}
                onInputChanged={OnInputChanged}
              />

              <RenderOtherAssets
                items={GetArrayObject('otherAssets')}
                otherAssetsTypes={otherassetstypes}
                onAddArrayList={OnAddArrayList}
                onInputChanged={OnInputChanged}
              />

              <RenderOtherCredits
                items={GetArrayObject('otherCredits')}
                otherCreditTypes={othercreditsstypes}
                onAddArrayList={OnAddArrayList}
                onInputChanged={OnInputChanged}
              />

            </li>
            <li className="form1003IIcol">

              <RenderLiabilities
                items={GetArrayObject('liabilities')}
                libalitiyTypes={libalitiytypes}
                onAddArrayList={OnAddArrayList}
                onInputChanged={OnInputChanged}
              />

              <RenderOtherLiabilities
                items={GetArrayObject('otherLiabilities')}
                otherLibalitiyTypes={otherlibalitiytypes}
                onAddArrayList={OnAddArrayList}
                onInputChanged={OnInputChanged}
              />

              <RenderRealEstate
                items={GetArrayObject('reoProperties')}
                unittypes={unittypes}
                occupancies={occupancies}
                onInputChanged={OnInputChanged}
                onBlurError={OnBlurError}
                statesList={statesList}
                onAddArrayList={OnAddArrayList}
                onInputClicked={OnInputClicked}
                propertystatustypes={propertystatustypes}
                featureoccupancies={featureOccupancies}
                loantypes={loantypes}
                errors={errors}
              />

            </li>
          </ul>
        </form>
        <ul className="form_btn_successError_blk">
          <li className="form_btn_steps_label">
            <input
              type="button"
              defaultValue="Save & Finish Later"
              className="form_btn_steps"
              onClick={(e) => OnUpdateRecord(e, true)}
            />
            <input
              type="button"
              defaultValue="Continue"
              className="form_btn_steps"
              onClick={(e) => OnUpdateRecord(e, false)}
            />
          </li>
          {error && (
            <li className="form_successError_steps">
              <div className="errormessage">{error}</div>
            </li>
          )}
          {success && (
            <li className="form_successError_steps">
              <div className="successmessage">{success}</div>
            </li>
          )}
        </ul>
      </Container>
      <InfoLoanType />
    </>
  );
};

export default Component;
