import { React } from "../../../../common";
import Container from "../../../container";
import TabNavigations from "../../components/tablist";
import ApplyNow from "../applynow";

const Component = () => {
  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_lo_30">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcBlkDesc">
                      <div className="blkDescTxt">Loan Options</div>
                      <div className="rightCurve"></div>
                    </div>
                    <div className="bcHeadTxt_topTxt_noRight">
                      Reverse Mortgages
                    </div>
                  </li>
                  <li className="bc_col">&nbsp;</li>
                </div>
              </ul>
            </div>

            <div className="widthfix_10px">
              <ul className="loPgStructure">
                <li className="loPgStructure">
                  <TabNavigations type="reverse" />
                </li>
                <li className="loPgStructure">
                  <ul className="loPointsBlk">
                    <li className="loPointsBlk">
                      <ul className="loDescPoints">
                        <li className="loDescPoints">
                          Receive money from the loan
                        </li>
                        <li className="loDescPoints">
                          Include all costs in one loan
                        </li>
                        <li className="loDescPoints">
                          Steady, affordable monthly payments
                        </li>
                        <li className="loDescPoints">
                          Qualify for higher loan amounts
                        </li>

                        <ApplyNow />
                      </ul>
                    </li>
                    <li className="loPointsBlk">
                      <div className="pgTxtBoldNoTopSpace">
                        Improve your life by cashing in on your home's equity
                      </div>
                      <div className="pgTxtNormal">
                        Whether seeking money to finance a home improvement, pay
                        off a current mortgage, supplement their retirement
                        income, or pay for healthcare expenses, many older
                        Americans are turning to “reverse” mortgages. They allow
                        homeowners 62 years and older to convert part of the
                        equity in their homes into cash without having to sell
                        their homes.
                      </div>
                      <div className="pgTxtNormal">
                        In a “regular” mortgage, you make monthly principal and
                        interest mortgage payments to the lender. But in a
                        “reverse” mortgage, you receive money from the loan and
                        generally don't have to pay it back for as long as you
                        live in your home and continue to follow the loan
                        guidelines. It is important, however, to keep in mind
                        that payments for taxes and insurance still must be
                        paid. The loan must be repaid when you die, sell your
                        home, or no longer live there as your principal
                        residence. Reverse mortgages can help homeowners who
                        have a considerable amount of home equity and would like
                        to stay in their homes and still meet their financial
                        obligations.
                      </div>
                      <div className="pgTxtBold">
                        Three Types of Reverse Mortgages
                      </div>
                      <div className="pgTxtNormal">
                        The three basic types of reverse mortgage are: (1).
                        single-purpose reverse mortgages, which are loans
                        offered by some state and local government agencies and
                        nonprofit organizations; (2). federally-insured reverse
                        mortgages, which are known as Home Equity Conversion
                        Mortgages (HECMs), and are backed by the U. S.
                        Department of Housing and Urban Development (HUD); (3).
                        proprietary reverse mortgages, which are private loans
                        that are backed by the companies that develop them.
                      </div>
                      <div className="pgTxtNormal">
                        Single-purpose reverse mortgages generally have very low
                        costs. But they are not available everywhere, and they
                        only can be used for one purpose specified by the
                        government or nonprofit lender, for example, to pay for
                        home repairs, improvements, or property taxes. In most
                        cases, you can qualify for these loans only if your
                        income is low or moderate.
                      </div>
                      <div className="pgTxtBold">Loan Features</div>
                      <div className="pgTxtNormal">
                        Reverse mortgage loan advances are not taxable and may
                        not affect Social Security or Medicare benefits. We
                        recommend you speak with your benefits plan
                        administrator and tax advisor for more information on
                        how a reverse mortgage will impact your situation.You
                        retain the title to your home and do not have to make
                        monthly principal and interest mortgage repayments. The
                        loan must be repaid when the last surviving borrower
                        dies, sells the home, or no longer lives in the home as
                        a principal residence. The borrower continues to pay all
                        property related fees, taxes and insurance.
                      </div>
                      <div className="pgTxtNormal">
                        ∗ These materials are not from HUD or FHA and were not
                        approved by HUD or a government agency.
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
