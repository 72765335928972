import { React, Storage, Helper, RadioInput, DropDown, TextInput } from "../../../common";
import { PropertyTypes, PropertyUses, PropertyPrices, MortgageTypes } from "../../../helper/support";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";

const Component = ({ onClick }) => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [stage, setStage] = React.useState(0);
  const [state, setState] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  if (initialized) {
    setInitialized(false);
    setErrors([]);
    let data = Storage.RetrieveJSON("stages");
    setRow(data.stage2 || {});
    setStage(2);
  }

  const OnInputLabelChanged = async (parent, path, name, value) => {
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnInputChanged = async (parent, path, name, value) => {
    OnInputLabelChanged(parent, path, name, value);
    GetIndicatorText(parent, path, name, value);
  };

  const GetIndicatorText = async (parent, path, name, value) => {
    let titlename = undefined;
    let title = undefined;
    switch (name) {
      case "propertyMortgageApplied":
        titlename = "propertyMortgageAppliedText";
        title = MortgageTypes().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].text;
        break;
      case "propertyPriceIndicator":
        titlename = "propertyPriceIndicatorText";
        title = PropertyPrices().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      case "propertyUseIndicator":
        titlename = "propertyUseIndicatorText";
        title = PropertyUses().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      case "propertyIndicator":
        titlename = "propertyIndicatorText";
        title = PropertyTypes().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      default:
        titlename = undefined;
        title = undefined;
        break;
    }
    if (titlename) OnInputLabelChanged(parent, titlename, titlename, title);
  };

  const OnNavClicked = async (type, e) => {
    setError(null);
    setErrors([]);

    if (type === "prev") {
      if (onClick) onClick(e);
      return;
    }

    let _fields = [
      "propertyPriceIndicator",
      "propertyUseIndicator",
      "propertyMortgageApplied",
      "propertyIndicator",
      "propertyAddressText",
      "propertyDownPayment",
    ];

    let _errs = [];
    _fields.forEach((elm) => {
      _errs.push({ field: elm });
    });

    if (Helper.IsJSONEmpty(row)) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let isInvalid = false;

    _errs = [];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        isInvalid = true;
        _errs.push({ field: elm });
      }
    });

    if (isInvalid) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let data = Storage.RetrieveJSON("stages");
    data["stage2"] = row;
    data["stage"] = e;

    Storage.StoreJSON("stages", data);
    if (onClick) onClick(e);
  };

  return (
    <>
      <RenderBreadCrumb stage={2} row={row} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead">Tell us a bit more about your property</li>
          <li className="countBlk">
            <div className="countNo">2/5</div>
          </li>
        </ul>
        <div className="purchase">
          <div className="spacer_25margin_bottom">
            <div className="pur_Q">
              What is the property address you are purchasing? (Please add the state and zip)
            </div>
            <TextInput
              tag={"propertyAddressText"}
              path={"propertyAddressText"}
              name={"propertyAddressText"}
              value={Helper.ToString(row?.propertyAddressText)}
              onInputChildChanged={OnInputChanged}
              errors={errors}
              placeholder={"if you have not decided on the property just type - TBD"}
            />
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">What property type would this be?</div>
            <ul className="pur_A">
              <RadioInput
                options={PropertyTypes()}
                path={`propertyIndicator`}
                tag={`propertyIndicator`}
                name={"propertyIndicator"}
                uselabel={false}
                value={Helper.ToInteger(row?.propertyIndicator)}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">What is the property use?</div>
            <ul className="pur_A">
              <RadioInput
                options={PropertyUses()}
                path={`propertyUseIndicator`}
                tag={`propertyUseIndicator`}
                name={"propertyUseIndicator"}
                uselabel={false}
                value={Helper.ToInteger(row?.propertyUseIndicator)}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">Estimated purchase price</div>
            <ul className="pur_A">
              <RadioInput
                options={PropertyPrices()}
                path={`propertyPriceIndicator`}
                tag={`propertyPriceIndicator`}
                name={"propertyPriceIndicator"}
                uselabel={false}
                value={Helper.ToInteger(row?.propertyPriceIndicator)}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <ul className="III_col">
              <li className="III_col">
                <div className="pur_Q">Down payment</div>
                <TextInput
                  currency="$"
                  maxLength={14}
                  tag={"propertyDownPayment"}
                  path={"propertyDownPayment"}
                  name={"propertyDownPayment"}
                  placeholder={"DownPayment"}
                  value={Helper.ToString(row?.propertyDownPayment)}
                  type={"NFINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Mortgage Applied For</div>
                <DropDown
                  tag={"propertyMortgageApplied"}
                  path={"propertyMortgageApplied"}
                  name={"propertyMortgageApplied"}
                  value={row?.propertyMortgageApplied}
                  items={MortgageTypes()}
                  displayName={"text"}
                  displayValue={"value"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
            </ul>
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              onClick={(e) => OnNavClicked("prev", stage - 1)}
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked("next", stage + 1)}
            />
          </div>
          {error && (
            <div className="errormessage">All fields are mandatory</div>
          )}
        </div>
      </li>
    </>
  );
};

export default Component;
