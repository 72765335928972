import { React } from "../../../../common";
import Container from "../../../container";
import { CallBack } from "../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_refin_lower">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Mortgage Refinance</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Lower your Monthly Payment</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Leave Your Phone Number</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">A lower monthly mortgage payment can help free-up money you can save, invest or use for other expenses. When rates are favorable, refinancing to a lower rate or longer-term mortgage can keep more money in your pocket every month.</div>
                                    <div className="pgTxtNormal"><b>Example:</b><br /> The Smiths have 25 years remaining on their 30-year mortgage and their home has appraised for $200,000.</div>

                                    <div className="refin_5col_lower">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Mortgage Amount</td>
                                                    <td>Rate</td>
                                                    <td>Term</td>
                                                    <td>Monthly Payment</td>
                                                </tr>
                                                <tr>
                                                    <td>Original Mortgage</td>
                                                    <td>$150,000</td>
                                                    <td>7%</td>
                                                    <td>30-year</td>
                                                    <td>$998</td>
                                                </tr>
                                                <tr>
                                                    <td>Refinance</td>
                                                    <td>$141,198* <br />remaining balance</td>
                                                    <td>5%</td>
                                                    <td>30-year</td>
                                                    <td>$758</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pgTxtNormal"><b>Example:</b><br /> The Smiths have 13 years remaining on their 15-year mortgage and their home has appraised for $200,000.</div>
                                    <div className="refin_5col_lower">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Mortgage Amount</td>
                                                    <td>Rate</td>
                                                    <td>Term</td>
                                                    <td>Monthly Payment</td>
                                                </tr>
                                                <tr>
                                                    <td>Original Mortgage</td>
                                                    <td>$150,000</td>
                                                    <td>7%</td>
                                                    <td>15-year</td>
                                                    <td>$1,348</td>
                                                </tr>
                                                <tr>
                                                    <td>Refinance</td>
                                                    <td>$137,847* <br />remaining balance</td>
                                                    <td>5%</td>
                                                    <td>30-year</td>
                                                    <td>$740</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pgTxtNormal">*Remaining balance assumes that the borrower will pay all closing costs out of pocket.</div>
                                </li>
                                <li className="IIcol_100by400">
                                    <CallBack />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;