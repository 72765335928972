import { React } from "../../../../common";
import Container from "../../../container";

const Component = () => {
  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_statistics">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcHeadTxt_generic">Lending Statistics</div>
                  </li>
                  <li className="bc_col">&nbsp;</li>
                </div>
              </ul>
            </div>
            <div className="widthfix_10px">
              <ul className="statisticsIIcol">
                <li className="statisticsIIcol"></li>
                <li className="statisticsIIcol"></li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
