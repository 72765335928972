import React from "react";

function getLocalStorageOrDefault(key, doParse, defaultValue) {
    let stored = localStorage.getItem(key);
    if (stored === 'undefined') stored = undefined;
    if (!stored) {
        return defaultValue;
    }
    return doParse ? JSON.parse(stored) : stored;
}

export default function useTimerStore(key, doParse, defaultValue) {
    const [value, setValue] = React.useState(getLocalStorageOrDefault(key, doParse, defaultValue));

    React.useEffect(() => {
        const interval = setInterval(() => {
            let _val = getLocalStorageOrDefault(key, doParse, defaultValue);
            if (value !== _val) setValue(_val);
        }, 500);
        return () => clearInterval(interval);
    }, [value, setValue, doParse, key, defaultValue]);


    return [value, setValue];
}