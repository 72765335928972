import { React, Link } from "../../../../common";
import Container from "../../../container";

const Component = () => {
  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_blog">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcHeadTxt_generic">Blogs</div>
                  </li>
                  <li className="bc_col">&nbsp;</li>
                </div>
              </ul>
            </div>

            <div className="widthfix_10px">
              <div className="Icol">
                <ul className="blog_IIIcol">
                  <li className="blog_IIIcol">
                    <div className="blogImg1">
                      <div className="blogdateMonthBlk">
                        <div className="blogDate">25</div>
                        <div className="blogMonth">Feb</div>
                      </div>
                    </div>
                    <div className="blogColHead">Mortgage Loans, it's types and details</div>
                    <div className="blogColDesc">Mortgage loans are a type of secured loan that is commonly used to finance the purchase of real estate property in the ...</div>
                    <div className="blogReadMore"><Link to="/blog1" className="readMoreLink">Read More</Link></div>
                  </li>
                  <li className="blog_IIIcol">
                    <div className="blogImg2">
                      <div className="blogdateMonthBlk">
                        <div className="blogDate">19</div>
                        <div className="blogMonth">Sep</div>
                      </div>
                    </div>
                    <div className="blogColHead">Blockchain in Mortgage Industry</div>
                    <div className="blogColDesc">The mortgage industry in the USA has been one of the most traditional and bureaucratic industries. It has been slow to adapt ...</div>
                    <div className="blogReadMore"><Link to="/blog2" className="readMoreLink">Read More</Link></div>
                  </li>
                  <li className="blog_IIIcol">
                    <div className="blogImg3">
                      <div className="blogdateMonthBlk">
                        <div className="blogDate">31</div>
                        <div className="blogMonth">Dec</div>
                      </div>
                    </div>
                    <div className="blogColHead">Mortgage loans trends in USA for 2023 </div>
                    <div className="blogColDesc">Mortgage loans have been the backbone of the American housing market for years, and 2023 will be no different ...</div>
                    <div className="blogReadMore"><Link to="/blog3" className="readMoreLink">Read More</Link></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
