import { React, Link, Helper, Session, UploadHelper, BreadCrumb } from "../../../common";
import {
  GetDocTypes, GetDocuments, DeleteDocuments,
  SubmitDocuments, GetAccessList, SetAccessList
} from "../../../services/documentApi";
import LoanSummary from "../loansummary";
import Container from "../../container";

const maxsize = 106000000;
const formats = ["PNG", "JPG", "PDF"];

const Component = () => {
  const [error, setError] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [fileurl, setFileUrl] = React.useState(null);
  const [formatError, setFormatError] = React.useState(null);
  const [doctypes, setDocTypes] = React.useState([]);
  const [typeId, setTypeId] = React.useState(0);
  const [loadDocuments, setLoadDocuments] = React.useState(false);
  const [documents, setDocuments] = React.useState([]);
  const [accessDocuments, setAccessDocuments] = React.useState([]);
  const [docfields, setDocFields] = React.useState({});
  const [row, setRow] = React.useState({});
  const [typeIndex, setTypeIndex] = React.useState(0);
  const [requestApproval, setRequestApproval] = React.useState(false);

  const OnLoanSelected = (e) => {
    Helper.NativeInputClick("loandocs");
    setDocFields({});
    setRequestApproval(false);
    setTypeId(0);
    setSearch(e);
    FetchLoans(e);
  };

  const FetchLoans = async (e) => {
    setError(null);
    setRow({});
    setDocFields({});
    if (Helper.ToInteger(e?.id) < 1) {
      setError("Select loan to view the documents");
    } else {
      global.Busy(true);
      let rslt = await GetDocTypes();
      global.Busy(false);
      if (rslt.status === 100) {
        let tmp = rslt.data;
        tmp.splice(0, 0, { id: "0", name: "Select" });
        setDocTypes(tmp);
      } else {
        setError(rslt.statusText);
      }
    }
  };

  const FetchDocuments = async () => {
    global.Busy(true);
    let rslt = await GetDocuments(search.id, typeId);
    if (rslt.status === 100) {
      setDocuments({ childs: rslt.data });
      rslt = await GetAccessList(search.id, typeId);
      global.Busy(false);
      if (rslt.status === 100) {
        setAccessDocuments({ childs: rslt.data });
      }
    } else {
      global.Busy(false);
    }
  };

  if (loadDocuments) {
    setLoadDocuments(false);
    setDocFields({});
    setDocuments([]);
    setAccessDocuments([]);
    setRow({});
    let tmp = doctypes.filter((x) => parseInt(x.id) === parseInt(typeId));
    if (tmp && tmp.length > 0 && tmp[0].fields) {
      let _tmp = JSON.parse(tmp[0].fields);
      _tmp.forEach((elm, index) => {
        elm["id"] = index + 1;
      });
      setDocFields({ name: tmp[0].name, childs: _tmp });
      FetchDocuments();
    }
  }

  const OnInputChanged = (name, val, id, arr) => {
    if (arr) {
      let arrChild = arr.childs;
      let _index = arrChild.findIndex((x) => parseInt(x.id) === parseInt(id));
      if (_index > -1) {
        arrChild[_index].value = val;
        setDocFields({ name: arr.name, childs: arrChild });
      }
    } else {
      setRow((prev) => ({
        ...prev,
        [name]: val,
      }));
    }
  };

  const OnFileInputChanged = (e) => {
    let _file = e.target.files[0];
    setFormatError(null);
    setFileUrl(null);
    if (!Helper.IsNullValue(_file)) {
      let _ext = _file.name.split(".").pop();
      let _index = formats.findIndex((x) => x === _ext.toUpperCase());
      if (_index > -1) {
        if (_file.size > maxsize) {
          Helper.NativeInputClick("loandocs");
          setFormatError("File size allowed: 100mb");
        } else {
          setFileUrl(e.target.files[0]);
        }
      } else {
        Helper.NativeInputClick("loandocs");
        setFormatError("Supported Format: " + formats.join(","));
      }
    }
  };

  const OnFileUploadClicked = async (e) => {
    e.preventDefault();
    setError(null);

    let obj = {
      ...row,
      loanid: search.id,
      typeid: typeId,
      type: "DOC",
      list: docfields.childs,
      ofileName: fileurl.name,
      loannumber: search.loannumber,
      req: {
        lender_id: search.lender_id,
        borrower_id: search.borrower_ids,
        loan_id: search.loannumber,
        stage: docfields.name,
        fileName: fileurl.name,
      },
    };

    let rslt = await UploadHelper.Upload(fileurl, obj);
    if (rslt.status !== 100) {
      Helper.NativeInputClick("loandocs");
      setError(rslt.statusText);
      return;
    }

    let _docs = documents.childs || [];
    _docs.push(rslt.data);
    setDocuments({ childs: _docs });
    Helper.NativeInputClick("loandocs");
  };

  const OnDeleteClicked = async (e) => {
    e.preventDefault();
    let docId = e.target.attributes["keyid"].value;
    global.Busy(true);
    await DeleteDocuments(docId);
    global.Busy(false);
    FetchDocuments();
  };

  const OnSubmitClicked = async (e) => {
    e.preventDefault();

    let rslt;
    setError(null);

    global.Busy(true);
    rslt = await SetAccessList(accessDocuments.childs);
    if (rslt.status !== 100) {
      global.Busy(false);
      setError(rslt.statusText);
      return;
    }

    let data = {
      loanid: search.id,
      typeid: typeId,
      approval: requestApproval ? 1 : 0,
    };

    if (typeIndex === 3) data.approval = 1;

    let tmp = doctypes.filter((x) => parseInt(x.id) === parseInt(typeId));
    if (tmp && tmp.length > 0) {
      data["doctypename"] = tmp[0].name;
    }

    rslt = await SubmitDocuments(data);
    if (rslt.status !== 100) {
      global.Busy(false);
      setError(rslt.statusText);
      return;
    }
    global.Busy(false);
    setError(rslt.statusText);
    setRequestApproval(false);
    OnLoanSelected({});
  };

  React.useEffect(() => {
    setLoadDocuments(true);
  }, [typeId]);

  React.useEffect(() => {
    const fn = async () => {
      let user = await Session.RetrieveJSONAsync("user");
      setTypeIndex(user.typeindex);
    };
    fn();
  }, []);

  const OnDummyChange = () => { };

  const OnResetClicked = (e) => {
    e.preventDefault();
    OnLoanSelected({});
  };

  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <BreadCrumb home="Documents" title="Upload Documents" />
              <div className="document_pgBlk">
                <div className="appPgStructure_Icol">
                  <LoanSummary upload={true} onLoanSelected={OnLoanSelected} />
                </div>
                {Helper.ToInteger(search?.id) > 0 && (
                  <div className="appPgStructure_Icol">
                    <div className="app_box_shadow_noPad">
                      <div className="app_headBlk_pad20">
                        <div className="app_headBlk_blkName">
                          Upload Documents for Loan No: {search?.loannumber}
                        </div>
                      </div>
                      <div className="appContentarea_pad20">
                        <ul className="appformColumn">
                          <div className="uploadDoc">
                            <div className="uploadDocHeadBlk">
                              <div className="uploadHeading">Document Type</div>
                              <div className="selectDocType">
                                <select
                                  name="filterStatus"
                                  required
                                  value={Helper.ToString(typeId)}
                                  onChange={(e) => setTypeId(e.target.value)}
                                >
                                  {doctypes &&
                                    doctypes.map((x, index) => {
                                      return (
                                        <option value={x.id} key={index}>
                                          {x.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="appContentarea_pad20_bgTexure">
                              <ul className="uploadIIIcol">
                                <li className="uploadIIIcol">
                                  <div className="appformContentHead">
                                    Document Name
                                  </div>
                                  <div className="documentFormicon"></div>
                                  <input
                                    type="text"
                                    value={Helper.ToString(row.docname)}
                                    onChange={(e) =>
                                      OnInputChanged("docname", e.target.value)
                                    }
                                  />
                                </li>
                                <li className="uploadIIIcol">
                                  <div className="appformContentHead">
                                    Document Information
                                  </div>
                                  <div className="documentFormicon"></div>
                                  <input
                                    type="text"
                                    value={Helper.ToString(row.docinfo)}
                                    onChange={(e) =>
                                      OnInputChanged("docinfo", e.target.value)
                                    }
                                  />
                                </li>
                                {docfields &&
                                  docfields.childs &&
                                  docfields.childs.map((x) => {
                                    return (
                                      <li
                                        className="uploadIIIcol"
                                        key={x.id}
                                        style={{ marginBottom: 10 }}
                                      >
                                        <div className="appformContentHead">
                                          {x.label}
                                        </div>
                                        <div className="documentFormicon"></div>
                                        <input
                                          type="text"
                                          value={Helper.ToString(x?.value)}
                                          onChange={(e) =>
                                            OnInputChanged(
                                              x.name,
                                              e.target.value,
                                              x.id,
                                              docfields
                                            )
                                          }
                                        />
                                      </li>
                                    );
                                  })}
                                <li className="uploadIIIcol">
                                  <div className="appformContentHead">
                                    Document Upload
                                  </div>
                                  <div className="fileUpload">
                                    <div className="uploadBox">
                                      <input
                                        id="loandocs"
                                        type="file"
                                        name="loandocs"
                                        onClick={(e) => {
                                          e.target.value = null;
                                        }}
                                        onChange={(e) => OnFileInputChanged(e)}
                                      />
                                    </div>
                                    <div className="buttonUploadLayout">
                                      <input
                                        type="submit"
                                        className="buttonUpload"
                                        value="Upload"
                                        onClick={(e) => OnFileUploadClicked(e)}
                                      />
                                    </div>
                                    {formatError && (
                                      <div className="submitError">
                                        {formatError}
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </ul>

                              <div className="uploadSubHead">
                                Uploaded Documents
                              </div>
                              <div className="appcontentarea_table_border">
                                <div
                                  className="noContent"
                                  style={{ display: "none" }}
                                >
                                  No documents available
                                </div>
                                <div className="viewDocTable_head">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <Link to="#">Document Name</Link>
                                        </td>
                                        <td>Document Information</td>
                                        <td>Status</td>
                                        <td>&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="viewDocTable">
                                  <table>
                                    <tbody>
                                      {documents &&
                                        documents.childs &&
                                        documents.childs.map((x) => {
                                          return (
                                            <tr key={x.id}>
                                              <td>{x.docname}</td>
                                              <td>{x.docinfo}</td>
                                              <td>Upload Successful</td>
                                              <td>
                                                <input
                                                  type="button"
                                                  value="Delete"
                                                  keyid={x.id}
                                                  className="viewDoc_btn"
                                                  onClick={(e) =>
                                                    OnDeleteClicked(e)
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="appform_btn_label_notop">
                            <input
                              type="button"
                              value="Submit"
                              className="appform_btn"
                              onClick={(e) => OnSubmitClicked(e)}
                            />
                            <input
                              type="button"
                              value="Reset"
                              className="appform_btn"
                              onClick={(e) => OnResetClicked(e)}
                            />
                            {typeIndex !== 3 && (
                              <div className="buttonLabel_chkbox">
                                <input
                                  type="checkbox"
                                  id="reqapproval"
                                  name="reqapproval"
                                  checked={requestApproval}
                                  onChange={() => OnDummyChange()}
                                  onClick={(e) =>
                                    setRequestApproval(e.target.checked)
                                  }
                                />
                                <label htmlFor="reqapproval">
                                  Request for Approval
                                </label>
                              </div>
                            )}
                          </div>
                          {error && <div className="errormessage">{error}</div>}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
