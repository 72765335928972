import { React, Link } from "../../../../../common";
import Container from "../../../../container";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_blog">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col_100pc">
                                        <Link to="/blogs"><div className="bcHeadTxt_generic_inline">Blogs</div></Link>
                                        <div className="bcHeadTxt_generic_inline_support">- Mortgage loans trends in USA for 2023</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="Icol">
                                <div className="pgTxtBold_XL">Mortgage loans trends in USA for 2023</div>
                                <div className="pgTxtNormal">Mortgage loans have been the backbone of the American housing market for years, and 2023 will be no different. However, several changes are expected to take place in the mortgage industry that will have a significant impact on borrowers, lenders, and the housing market as a whole. Here are some of the trends that we can expect to see in the mortgage industry in 2023.</div>

                                <ul className="bulletPoints_un">
                                    <li className="bp_circleOutline"><b>Rising Interest Rates:</b><br />Interest rates have been at historic lows for several years, but this trend is expected to change in 2023. The Federal Reserve has indicated that it may raise interest rates to combat inflation, which will impact mortgage rates. Higher interest rates mean higher mortgage payments, which may deter some borrowers from taking out a mortgage or buying a home.</li>
                                    <li className="bp_circleOutline"><b>Digital Transformation:</b><br />The mortgage industry has been slow to adopt digital technology, but that is expected to change in 2023. Lenders will increasingly use digital tools to streamline the mortgage application process, improve customer experience, and reduce costs. Borrowers will be able to complete the entire mortgage application process online, from pre-approval to closing.</li>
                                    <li className="bp_circleOutline"><b>Increased Competition:</b><br />As interest rates rise, the number of mortgage applications is likely to decline. This may lead to increased competition among lenders, which could result in more favorable terms for borrowers. However, borrowers should be wary of predatory lenders who may offer low-interest rates but charge high fees or provide unfavorable terms.</li>
                                    <li className="bp_circleOutline"><b>Continued Rise of Non-Traditional Lenders:</b><br />Non-traditional lenders, such as online mortgage lenders and peer-to-peer lenders, have been gaining popularity in recent years. These lenders often offer lower interest rates and more flexible terms than traditional lenders. In 2023, we can expect to see more borrowers turn to non-traditional lenders for their mortgage needs.</li>
                                    <li className="bp_circleOutline"><b>Increased Focus on Diversity and Inclusion:</b><br />The mortgage industry has historically been criticized for its lack of diversity and inclusion. In 2023, we can expect to see more lenders prioritize diversity and inclusion in their hiring practices and lending decisions. This could result in more equitable access to mortgage loans for borrowers from diverse backgrounds.</li>
                                </ul>
                                <div className="pgTxtNormal">In conclusion, 2023 will be a year of change for the mortgage industry. Borrowers can expect to see rising interest rates, increased competition among lenders, and a continued shift toward digital technology. Non-traditional lenders will continue to gain popularity, and diversity and inclusion will be a growing priority for lenders. As always, borrowers should do their research and shop around to find the best mortgage terms for their individual needs.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
