import { Link } from "react-router-dom";

const tabItems = [
    { name: "mortgagecalculator", link: "/mortgagecalculator", icon: "calc_icon1", label: "Mortgage Calculator" },
    { name: "refinancecalculator", link: "/refinancecalculator", icon: "calc_icon2", label: "Refinance Calculator" },
    { name: "principalcalculator", link: "/principalcalculator", icon: "calc_icon3", label: "Principal Calculator" },
    { name: "extrapayment", link: "/extrapayment", icon: "calc_icon4", label: "Extra Payment Calculator" },
    { name: "incometoqualify", link: "/incometoqualify", icon: "calc_icon5", label: "Income to qualify?" },
    { name: "affordability", link: "/affordability", icon: "calc_icon6", label: "How much can I afford?" },
    { name: "taxbenefit", link: "/taxbenefit", icon: "calc_icon7", label: "Tax benefits of buying" },
    { name: "apr", link: "/apr", icon: "calc_icon8", label: "What's my Annual Rate?" },
]

const Component = ({ type }) => {
    return (
        <div>
            <ul className="calcIconNavi">
                {
                    tabItems.map((x) => {
                        if (x.name === type) {
                            return (
                                <li className="calcIconNavi" key={x.name}>
                                    <Link to={x.link} className="current"><div className={x.icon}></div>{x.label}</Link>
                                </li>
                            )
                        } else {
                            return (
                                <li className="calcIconNavi" key={x.name}>
                                    <Link to={x.link}><div className={x.icon}></div>{x.label}</Link>
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </div >
    )
}

export default Component;