import React from "react";

const Loader = ({ show }) => {
  const showHideClassName = show
    ? "loader display-block"
    : "loader display-none";

  return (
    <div className={showHideClassName}>
      <section className="loader-modal">
        <i
          className="fa fa-refresh fa-spin"
          style={{ fontSize: 60, color: "#2bb0e3" }}
        ></i>
        <div style={{ marginTop: 10, color: "white", fontWeight: "bold" }}>
          Loading...
        </div>
      </section>
    </div>
  );
};

export default Loader;
