import { React, Storage, useTimerSession } from "../../common";

import Container from "../container";
import Stage1 from "./childs/stage1";
import Stage2 from "./childs/stage2";
import Stage3 from "./childs/stage3";
import Stage4 from "./childs/stage4";
import Stage5 from "./childs/stage5";
import Stage6 from "./childs/stage6";
import Stage7 from "./childs/stage7";
import Stage8 from "./childs/stage8";
import Stage9 from "./childs/stage9";
import Stage10 from "./childs/stage10";

const Component = () => {
  const [stage, setStage] = React.useState(0);
  const [initialized, setInitialized] = React.useState(false);
  const [userToken] = useTimerSession("userToken");

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  if (initialized) {
    setInitialized(false);
    let data = Storage.RetrieveJSON("stages");
    let nav = data["stage"] || 10;
    setStage(nav);
  }

  const OnNavClicked = async (e) => {
    setStage(e);
  };

  return (
    <>
      <Container>
        <div id="container">
          <div id={userToken ? "content" : "content_fullHeader"}>
            <div className="widthfix_10px">
              <div className="preapprove_pgBlk">
                <div className="preApprove_breadcrumb">Get Pre-Approved</div>
                <ul className="preApprove_IIcol_285by100pc">
                  {stage === 1 && <Stage1 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 2 && <Stage2 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 3 && <Stage3 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 4 && <Stage4 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 5 && <Stage5 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 6 && <Stage6 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 7 && <Stage7 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 8 && <Stage8 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 9 && <Stage9 onClick={(e) => OnNavClicked(e)} />}
                  {stage === 10 && <Stage10 onClick={(e) => OnNavClicked(e)} />}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
