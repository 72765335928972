import { React, Storage, Helper, RadioInput, TextInput, DropDown, useTimerSession } from "../../../common";
import { OptionTypes, PhoneTypes } from "../../../helper/support";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";

import { GetShortUserProfile } from "../../../services/profileApi";

let readOnlyFields = ["first_name", "last_name", "email", "cell_phone"];

const Component = ({ onClick }) => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [stage, setStage] = React.useState(0);
  const [state, setState] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [userStatus] = useTimerSession('status');

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  const FetchResults = async () => {
    global.Busy(true);
    let rslt = await GetShortUserProfile();
    global.Busy(false);
    return rslt;
  };

  if (initialized) {
    setInitialized(false);
    setErrors([]);
    readOnlyFields = [];
    let _status = Helper.ToInteger(userStatus);
    let data = Storage.RetrieveJSON("stages");
    let _stage3 = data.stage3;
    setStage(3);
    if (parseInt(_status) === 1 && Helper.IsJSONEmpty(_stage3)) {
      FetchResults().then((data) => {
        if (data.status === 100) {
          let _data = data.data;
          _stage3 = {
            borrower: {
              firstName: _data?.first_name,
              lastName: _data?.last_name,
              mobilePhone: _data?.mobile_number,
              phoneType: "Mobile"
            }
          };
          setRow(_stage3);
          readOnlyFields = ["firstName", "lastName"];
        }
      });
    } else {
      if (Helper.IsJSONEmpty(_stage3)) _stage3 = { borrower: { phoneType: "Mobile" } };
      setRow(_stage3);
    }
  }

  const OnInputChanged = async (parent, path, name, value) => {
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnNavClicked = async (type, e) => {
    setError(null);
    setErrors([]);

    let _fields = [
      "birthDate",
      "firstName",
      "lastName",
      "militaryServiceIndicator",
      "mobilePhone",
      "phoneType",
    ];

    if (type === "prev") {
      if (onClick) onClick(e);
      return;
    }

    let _errs = [];
    _fields.forEach((elm) => {
      _errs.push({ field: elm });
    });

    if (Helper.IsJSONEmpty(row)) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let isInvalid = false;

    _errs = [];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row?.borrower[elm])) {
        isInvalid = true;
        _errs.push({ field: elm });
      }
    });

    if (!isInvalid) {
      if (!Helper.IsValidateDate(row?.borrower["birthDate"], "DOB")) {
        isInvalid = true;
        _errs.push({ field: "birthDate" });
      }
      if (!Helper.IsValidPhone(row?.borrower["mobilePhone"])) {
        isInvalid = true;
        _errs.push({ field: "mobilePhone" });
      }
    }

    if (isInvalid) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let data = Storage.RetrieveJSON("stages");
    data["stage3"] = row;
    data["stage"] = e;

    Storage.StoreJSON("stages", data);
    if (onClick) onClick(e);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };


  return (
    <>
      <RenderBreadCrumb stage={3} row={row} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead">
            Now, tell us a few details about yourself
          </li>
          <li className="countBlk">
            <div className="countNo">3/5</div>
          </li>
        </ul>
        <div className="purchase">
          <div className="spacer_25margin_bottom">
            <div className="pur_Q">Full Legal Name (as shown on your ID)</div>
            <ul className="III_col">
              <li className="III_col">
                <TextInput
                  tag={"borrower.firstName"}
                  path={"borrower.firstName"}
                  name={"firstName"}
                  placeholder={"First Name"}
                  value={Helper.ToString(row?.borrower?.firstName)}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  readOnly={readOnlyFields.indexOf("firstName") > -1}
                />
              </li>
              <li className="III_col">
                <TextInput
                  placeholder={"Last Name"}
                  tag={"borrower.lastName"}
                  path={"borrower.lastName"}
                  name={"lastName"}
                  value={Helper.ToString(row?.borrower?.lastName)}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  readOnly={readOnlyFields.indexOf("lastName") > -1}
                />
              </li>
            </ul>
          </div>
          <div className="spacer_25margin_bottom">
            <ul className="III_col">
              <li className="III_col">
                <div className="pur_Q">Date of Birth</div>
                <TextInput
                  tag={"borrower.birthDate"}
                  path={"borrower.birthDate"}
                  name={"birthDate"}
                  placeholder={"MM/DD/YYYY"}
                  value={Helper.ToString(row?.borrower?.birthDate)}
                  type={"DATE"}
                  maxLength={10}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Phone Number</div>
                <TextInput
                  maxLength={14}
                  tag={"borrower.mobilePhone"}
                  path={"borrower.mobilePhone"}
                  name={"mobilePhone"}
                  placeholder={"Phone Number"}
                  value={Helper.ToString(row?.borrower?.mobilePhone)}
                  type={"PHONE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Phone Type</div>
                <DropDown
                  tag={"borrower.phoneType"}
                  path={"borrower.phoneType"}
                  name={"phoneType"}
                  value={row?.borrower?.phoneType}
                  items={PhoneTypes()}
                  displayName={"text"}
                  displayValue={"value"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">Military Service</div>
            <ul className="pur_A">
              <RadioInput
                options={OptionTypes()}
                path={`borrower.militaryServiceIndicator`}
                tag={`borrower.militaryServiceIndicator`}
                name={"militaryServiceIndicator"}
                uselabel={false}
                value={row?.borrower?.militaryServiceIndicator}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              onClick={(e) => OnNavClicked("prev", stage - 1)}
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked("next", stage + 1)}
            />
          </div>
          {error && (
            <div className="errormessage">{error}</div>
          )}
        </div>
      </li>
    </>
  );
};

export default Component;
