import { React, Helper, Storage } from "../../../../common";
import { DrawBreadCrumbs, BreadCrumbMaps, GetFullName, GetPhoneDisplay, GetMilitaryService } from "../../../../helper/support";

const StageOne = ({ row }) => {
    return (
        <>
            <li className="breadcrumb">1</li>
            <li className="breadcrumb">Get Pre-approved
                <ul className="sub_breadcrumb">
                    {!Helper.IsNullValue(row?.processIndicatorText) && (
                        <li className="sub_breadcrumb">
                            {Helper.ToString(row.processIndicatorText)}
                        </li>
                    )}
                    {!Helper.IsNullValue(row?.propertyPlanIndicatorText) && (
                        <li className="sub_breadcrumb">
                            {Helper.ToString(row.propertyPlanIndicatorText)}
                        </li>
                    )}
                    {!Helper.IsNullValue(row?.helpItemIndicatorText) && (
                        <li className="sub_breadcrumb">
                            {Helper.ToString(row.helpItemIndicatorText)}
                        </li>
                    )}
                </ul>
            </li>
        </>
    );
}

const StageTwo = ({ row }) => {
    return (
        <>
            <li className="breadcrumb">2</li>
            <li className="breadcrumb">
                Property Details
                <ul className="sub_breadcrumb">
                    {!Helper.IsNullValue(row?.propertyAddressText) && (
                        <li className="sub_breadcrumb">
                            {Helper.ToString(row.propertyAddressText)}
                        </li>
                    )}

                    {!Helper.IsNullValue(row?.propertyIndicatorText) && (
                        <li className="sub_breadcrumb">
                            {Helper.ToString(row.propertyIndicatorText)}
                        </li>
                    )}

                    {!Helper.IsNullValue(row?.propertyUseIndicatorText) && (
                        <li className="sub_breadcrumb">
                            {Helper.ToString(row.propertyUseIndicatorText)}
                        </li>
                    )}

                    {!Helper.IsNullValue(row?.propertyPriceIndicatorText) && (
                        <li className="sub_breadcrumb">
                            {Helper.ToString(row.propertyPriceIndicatorText)}
                        </li>
                    )}

                    {!Helper.IsNullValue(row?.propertyDownPayment) && (
                        <li className="sub_breadcrumb">
                            ${Helper.ToString(row.propertyDownPayment)}
                        </li>
                    )}

                    {!Helper.IsNullValue(row?.propertyMortgageAppliedText) && (
                        <li className="sub_breadcrumb">
                            {Helper.ToString(row.propertyMortgageAppliedText)}
                        </li>
                    )}
                </ul>
            </li>
        </>
    );
}

const StageThree = ({ row }) => {
    return (
        <>
            <li className="breadcrumb">3</li>
            <li className="breadcrumb">
                About Self
                <ul className="sub_breadcrumb">
                    <GetFullName row={row?.borrower} names={["firstName", "lastName"]} tag={true} />
                    {row?.borrower?.birthDate && <li className="sub_breadcrumb">{row?.borrower?.birthDate}</li>}
                    <GetPhoneDisplay
                        row={row?.borrower}
                        type={"phoneType"}
                        val={"mobilePhone"}
                        tag={true}
                    />
                    <GetMilitaryService row={row?.borrower} name={"militaryServiceIndicator"} tag={true} />
                </ul>
            </li>
        </>
    );
}

const StageFour = () => {
    return (
        <>
            <li className="breadcrumb">4</li>
            <li className="breadcrumb">Income Details</li>
        </>
    );
}

const StageFive = () => {
    return (
        <>
            <li className="breadcrumb">5</li>
            <li className="breadcrumb">Co-Borrower</li>
        </>
    );
}

const StageSix = ({ stages }) => {
    const IsCoBorrowerExist = () => {
        return Helper.ToBool(stages?.stage4?.coBorrowerIndicator);
    };
    return (
        <>
            <li className="breadcrumb">{IsCoBorrowerExist() ? 6 : 5}</li>
            <li className="breadcrumb">Confirm Account</li>
        </>
    );
}

const RenderStages = ({ stage, row, breadcrumbs, onClick, stages }) => {
    if (stage === 1) return <StageOne row={row} />;
    return (
        <>
            {breadcrumbs &&
                breadcrumbs.map((item, index) => {
                    return (
                        <DrawBreadCrumbs
                            key={index}
                            count={1}
                            item={item}
                            stages={stages}
                            onNodeClicked={(e) => onClick(e)}
                        />
                    );
                })}
            {stage === 2 && <StageTwo row={row} />}
            {stage === 3 && <StageThree row={row} />}
            {stage === 4 && <StageFour />}
            {stage === 5 && <StageFive />}
            {stage === 6 && <StageSix stages={stages} />}
        </>
    );
}

const Component = (props) => {
    const { stage, row, onClick } = props;

    const [initialized, setInitialized] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState(null);
    const [stages, setStages] = React.useState({});

    React.useEffect(() => {
        setInitialized(true);
    }, []);

    if (initialized) {
        setInitialized(false);
        let items = BreadCrumbMaps.filter((x) => x.id < stage);
        setBreadcrumbs(items);
        let data = Storage.RetrieveJSON("stages");
        setStages(data);
    }

    return (
        <li className="preApprove_IIcol_285by100pc">
            <div className="breadcrumbHead">Home Purchase</div>
            <ul className="breadcrumb">
                <RenderStages stage={stage} row={row} stages={stages} breadcrumbs={breadcrumbs} onClick={onClick} />
            </ul>
        </li>
    )
}

export default Component;