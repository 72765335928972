import { React, Helper, Storage, useNavigate, TextInput, DropDown } from "../../../common";
import { LoanTypes } from "../../../helper/support";
import Mp4Video from "../video/083041670.mp4";
import WebmVideo from "../video/083041670.webm";

import { RequestForQuote } from "../../../services/commonApi";
import { ConfirmDialog } from "../../popups";

const Component = () => {
  const [confirmShow, setConfirmShow] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState();
  const [success, setSuccess] = React.useState(null);
  const [state, setState] = React.useState(false);
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    setError(null);
    setSuccess(null);
  }, []);

  const OnGettingStarted = async (e) => {
    //let data = await Storage.RetrieveJSONAsync("stages");
    let nav = undefined; //data["stage"];
    if (!nav) {
      await Storage.RemoveAsync("preapprove");
      await Storage.RemoveAsync("stages");
      document.getElementById("ziplook_pop").style.display = "block";
    } else {
      setConfirmShow(true);
    }
  };

  const OnCancelClicked = (e) => {
    setConfirmShow(false);
  };

  const OnConfirmClicked = async (e) => {
    if (!e) {
      await Storage.RemoveAsync("preapprove");
      await Storage.RemoveAsync("stages");
    }
    setConfirmShow(false);
    if (!e) {
      OnGettingStarted();
    } else {
      NavigateTo("/preapprove");
    }
  };

  const OnInputChanged = async (parent, path, name, value) => {
    setError(null);
    setSuccess(null);
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const OnApplyNowClicked = async (e) => {
    e.preventDefault();

    if (Helper.IsJSONEmpty(row)) {
      setError("You have some errors. Please fix.");
      return;
    }

    let isInvalid = false;
    let _fields = ["quoteFName", "quoteEmail", "quotePhone", "quoteLoanType"];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        if (!isInvalid) isInvalid = true;
      }
    });

    if (!isInvalid) {
      _fields = ["quoteEmail"];
      _fields.forEach((elm) => {
        if (!Helper.IsEmailValid(row[elm])) {
          if (!isInvalid) isInvalid = true;
        }
      });
    }

    if (!isInvalid) {
      _fields = ["quotePhone"];
      _fields.forEach((elm) => {
        if (!Helper.IsValidPhone(row[elm])) {
          if (!isInvalid) isInvalid = true;
        }
      });
    }

    if (isInvalid) {
      setError("You have some errors. Please fix.");
      return;
    }

    row.quoteLName = "";
    row.quoteAmount = "N/A";
    row.quoteValue = "N/A";
    row.quoteCredit = "N/A";

    global.Busy(true);
    let rslt = await RequestForQuote(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(Helper.ErrorMessage);
      return;
    }
    setSuccess(rslt.statusText);
  };

  return (
    <>
      <div id="homeVideo">
        <div className="videoContainer">
          <video className="video1-bg" autoPlay={true} loop muted style={{ width: '100%' }}>
            <source src={Mp4Video} />
            <source src={WebmVideo} />
            Video not supported.
          </video>
        </div>
        <div className="fallback_smallscreen"></div>
        <div className="videoContent_blk">
          <div className="widthfix_10px">
            <ul className="homeText_Quote_blk">
              <li className="homeText">
                <div className="homeMainTxtBlock">
                  <h1>
                    <div className="mainTxt_line">Realize your Dream of</div>
                    <div className="mainTxt_line">
                      Owning a Home with i3 Lending
                    </div>
                    <div className="mainTxt_line">powered by Blockchain</div>
                  </h1>
                </div>
                <div className="secondaryTxt">
                  We will help you understand your mortgage options so that you
                  can feel certain about what you can afford
                </div>
                <div className="homeButLabel">
                  <input
                    type="submit"
                    className="but_preQuaify"
                    value="Get Pre-Qualified"
                    onClick={(e) => OnGettingStarted(e)}
                  />
                </div>
              </li>
              <li className="requestQuote">
                <div className="requestHead">Request A Quote</div>
                <div className="requestSub">decide your mortgage</div>
                <TextInput
                  tag={"quoteFName"}
                  path={"quoteFName"}
                  name={"quoteFName"}
                  placeholder={"Your Name"}
                  value={Helper.ToString(row?.quoteFName)}
                  onInputChildChanged={OnInputChanged}
                />

                <TextInput
                  tag={"quoteEmail"}
                  path={"quoteEmail"}
                  name={"quoteEmail"}
                  placeholder={"Email"}
                  value={Helper.ToString(row?.quoteEmail)}
                  type={"EMAIL"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
                <TextInput
                  maxLength={14}
                  tag={"quotePhone"}
                  path={"quotePhone"}
                  name={"quotePhone"}
                  placeholder={"Phone Number"}
                  value={Helper.ToString(row?.quotePhone)}
                  type={"PHONE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
                <DropDown
                  tag={"quoteLoanType"}
                  path={"quoteLoanType"}
                  name={"quoteLoanType"}
                  value={row?.quoteLoanType}
                  items={LoanTypes("Loan Purpose")}
                  displayName={"text"}
                  displayValue={"text"}
                  onInputChildChanged={OnInputChanged}
                />
                <input
                  type="button"
                  value="Apply Now"
                  className="requestButton"
                  onClick={(e) => OnApplyNowClicked(e)}
                />
                {error && (
                  <div className="errormessage" style={{ marginTop: 8 }}>
                    {error}
                  </div>
                )}
                {success && (
                  <div className="successmessage" style={{ marginTop: 8 }}>
                    {success}
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ConfirmDialog
        title="Do you want to continue with your Pre-approval?"
        message="You'll be able to pick from where you left"
        display={confirmShow}
        onConfirmClicked={OnConfirmClicked}
        onCancelClicked={OnCancelClicked}
      />
    </>
  );
};

export default Component;
