import { React, useNavigate, Storage } from "../../../common";
import { ConfirmDialog } from "../../popups";

const Component = () => {
  const [confirmShow, setConfirmShow] = React.useState(false);

  const NavigateTo = useNavigate();

  const OnCancelClicked = (e) => {
    setConfirmShow(false);
  };

  const OnConfirmClicked = async (e) => {
    if (!e) {
      await Storage.RemoveAsync("stages");
      await Storage.RemoveAsync("preapprove");
    }
    setConfirmShow(false);
    if (!e) {
      OnGettingStarted();
    } else {
      NavigateTo("/preapprove");
    }
  };

  const OnGettingStarted = async (e) => {
    //let data = await Storage.RetrieveJSONAsync("stages");
    let nav = undefined; //data["stage"];
    if (!nav) {
      await Storage.RemoveAsync("preapprove");
      await Storage.RemoveAsync("stages");
      document.getElementById("ziplook_pop").style.display = "block";
    } else {
      setConfirmShow(true);
    }
  };

  const OnQuoteClicked = (e) => {
    e.preventDefault();
    document.getElementById("rateQuote_pop").style.display = "block";
  };

  const OnAgentServiceClicked = (e) => {
    e.preventDefault();
    NavigateTo("/findlo");
  };
  return (
    <>
      <div className="ourServiceBlk">
        <div className="widthfix_10px">
          <div className="ourService_blockDesc">
            Our Services<span className="curveShape"></span>
          </div>
          <div className="ourService_blkHeadTxt">Loan Products We Offer</div>
          <div className="ourService_IIIcol hover_ourService buy">
            <div className="ourService_icon1"></div>
            <div className="ourService_colHeadTxt">01. Buying a Home</div>
            <div className="ourService_colTxt">
              We have a mortgage solution for you. Get your custom rate quote
              today.
            </div>
            <input
              type="button"
              value="Get Pre-Approved"
              className="ourServiceButton"
              onClick={(e) => OnGettingStarted(e)}
            />
          </div>
          <div className="ourService_IIIcol hover_ourService refinance">
            <div className="ourService_icon2"></div>
            <div className="ourService_colHeadTxt">02. Refinancing</div>
            <div className="ourService_colTxt">
              We are committed to helping you refinance with lowest rates and
              fees.
            </div>
            <input
              type="button"
              value="Get a Quote"
              className="ourServiceButton"
              onClick={(e) => OnQuoteClicked(e)}
            />
          </div>
          <div className="ourService_IIIcol hover_ourService credit lastCol">
            <div className="ourService_icon3"></div>
            <div className="ourService_colHeadTxt">03. Credit Service</div>
            <div className="ourService_colTxt">
              Our management consulting services focus on our clients most
              critical issues.
            </div>
            <input
              type="button"
              value="Connect with an Agent"
              className="ourServiceButton"
              onClick={(e) => OnAgentServiceClicked(e)}
            />
          </div>
        </div>
      </div>
      <ConfirmDialog
        title="Do you want to continue with your Pre-approval?"
        message="You'll be able to pick from where you left"
        display={confirmShow}
        onConfirmClicked={OnConfirmClicked}
        onCancelClicked={OnCancelClicked}
      />
    </>
  );
};

export default Component;
