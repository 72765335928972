import React from "react";
import { Link } from "react-router-dom";
import helper from "../helper";

const Component = (props) => {
    const { name, value, options, uselabel,
        onInputChildChanged, errors, path } = props;

    const OnCheckClicked = (e) => {
        helper.RemoveErrorIndex(errors, name);
        if (onInputChildChanged) {
            let _val = uselabel ? e.label : e.value;
            let _name = GetKeyName();
            onInputChildChanged(_name, _val);
        }
    };

    const GetKeyName = () => {
        if (name) {
            return `${name.replace(/\./g, '_')}`;
        } if (path) {
            return `${path.replace(/\./g, '_')}`;
        } else {
            return `chkinput`;
        }
    };

    let errorStyle = {
        borderColor: "#b22929",
        borderStyle: "solid",
        borderWidth: 1,
    };

    return (
        <>
            {options &&
                options.map((x, i) => {
                    return (
                        <li key={i}>
                            <Link
                                to="#"
                                className={
                                    x.value === value || x.label === value ? "current" : undefined
                                }
                                style={
                                    helper.GetErrorIndex(errors, GetKeyName()) > -1
                                        ? errorStyle
                                        : { borderWidth: 1 }
                                }
                                onClick={(e) => OnCheckClicked(x)}
                            >
                                {x.label}
                            </Link>
                        </li>
                    );
                })}
        </>
    );
};

export default Component;
