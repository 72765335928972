import { React } from "../../../../common";
import Container from "../../../container";
import TabNavigations from "../../components/tablist";
import ApplyNow from "../applynow";

const Component = () => {
  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_lo_30">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcBlkDesc">
                      <div className="blkDescTxt">Loan Options</div>
                      <div className="rightCurve"></div>
                    </div>
                    <div className="bcHeadTxt_topTxt_noRight">
                      Conventional Financing
                    </div>
                  </li>
                  <li className="bc_col">&nbsp;</li>
                </div>
              </ul>
            </div>

            <div className="widthfix_10px">
              <ul className="loPgStructure">
                <li className="loPgStructure">
                  <TabNavigations type="conventional" />
                </li>
                <li className="loPgStructure">
                  <ul className="loPointsBlk">
                    <li className="loPointsBlk">
                      <ul className="loDescPoints">
                        <li className="loDescPoints">
                          Receive money from the loan
                        </li>
                        <li className="loDescPoints">
                          Include all costs in one loan
                        </li>
                        <li className="loDescPoints">
                          Steady, affordable monthly payments
                        </li>
                        <li className="loDescPoints">
                          Qualify for higher loan amounts
                        </li>

                        <ApplyNow />
                      </ul>
                    </li>
                    <li className="loPointsBlk">
                      <div className="pgTxtNormal">
                        Getting a mortgage can be confusing. There are lots of
                        terms you may not be familiar with and of course major
                        decisions to be made. Basically, loans fall into two
                        main categories: government and conventional. Government
                        loans include FHA loans (Federal Housing
                        Administration), VA loans (Department of Veterans
                        Affairs) and USDA rural development (United States
                        Department of Agriculture). All other mortgages are
                        considered conventional.
                      </div>
                      <div className="pgTxtNormal">
                        When you go with conventional financing, the terms and
                        conditions of your mortgage must meet the funding
                        criteria of Fannie Mae and Freddie Mac, two
                        government-sponsored enterprises established to secure
                        mortgages in the form of mortgage-backed securities.
                        These mortgages may be fixed-rate or adjustable-rate
                        mortgages.
                      </div>
                      <div className="pgTxtNormal">
                        There are several benefits to having a conventional
                        mortgage. Because individual lenders – not the federal
                        government – set the fees and rates, conventional
                        financing can often have both lower fees and rates.
                        Additionally, the lender may allow borrowers to offer
                        collateral other than the property mortgaged. This is a
                        particular benefit to borrowers with limited access to
                        credit.
                      </div>
                      <div className="pgTxtNormal">
                        For some, though, conventional financing may not be
                        feasible. Conventional loans generally require larger
                        down payments than government-backed loans, making it
                        difficult for many borrowers who do not have enough
                        money saved to spend out-of-pocket. Interest rates are
                        also set by individual lenders and can exceed those of
                        FHA and VA loans, both of which are government-backed.
                        However, for the most part, rates for conventional loans
                        and for FHA loans do not vary significantly, as they are
                        both competing in the same market.
                      </div>
                      <div className="pgTxtNormal">
                        Additionally, if the loan has an LTV (loan-to-value)
                        greater than 80 percent, a borrower is required to
                        purchase Private Mortgage Insurance, which can be paid
                        monthly. This is not insurance for you – the homebuyer.
                        This is insurance for the lender in case of defaulting
                        on your loan. Please note that once your LTV drops below
                        80, you will also be able to drop the PMI, making
                        conventional financing attainable for more people.
                      </div>
                      <div className="pgTxtNormal">
                        With all of these factors to consider, it is not easy to
                        decide which type of financing is right for you. One of
                        our mortgage professionals can assist you in making this
                        decision. A brief application will help determine what
                        you are eligible for , and a full application will
                        provide further details. Your loan officer will be able
                        to review your options with you and show you how the
                        costs break down compared with one another, allowing you
                        to make an informed decision on your home loan.
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
