import { React, Storage, Helper, Link, TextInput, DropDown } from "../../../common";
import { CreditTypes } from "../../../helper/support";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";
import { GetList } from "../../../services/enumsApi";

let errorStyle = {
  borderColor: "#b22929",
  borderStyle: "solid",
  borderWidth: 1,
};

const Component = ({ onClick }) => {
  const [initialized, setInitialized] = React.useState(false);
  const [fullname, setFullName] = React.useState("");
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [stage, setStage] = React.useState(0);
  const [state, setState] = React.useState(false);
  const [statesList, setStatesList] = React.useState([]);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  const FetchLookUps = async () => {
    return new Promise(async (resolve) => {
      global.Busy(true);
      let idList = [1, 2, 3, 4, 5, 6, 16, 22, 18];
      let rslt = await GetList(idList);
      if (rslt.status === 100) {
        let t, _list;
        _list = rslt.data || [];
        t = _list.filter((x) => parseInt(x.type) === 18);
        Helper.AddSelectOption(t, true, 'value');
        setStatesList(t);
      }
      global.Busy(false);
    });
  };

  if (initialized) {
    setInitialized(false);
    setErrors([]);
    FetchLookUps();
    let data = Storage.RetrieveJSON("stages");
    setStage(7);
    let tmp = Helper.GetStringJoin(data.stage3.borrower, [
      "firstName",
      "lastName",
    ]);
    setFullName(tmp);
    let _row = data.stage7 || {};
    setRow(_row);
  }

  const OnDummyChanged = () => { };

  const OnInputChanged = async (parent, path, name, value) => {
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const OnNavClicked = async (type, e) => {
    setError(null);
    setErrors([]);

    let data = Storage.RetrieveJSON("stages");
    let coBorrowerIndicator = Helper.ToBool(data.stage4.coBorrowerIndicator);
    let stageIndicator = parseInt(e);

    if (type === "prev") {
      if (!coBorrowerIndicator) stageIndicator--;
      if (onClick) onClick(stageIndicator);
      return;
    }

    let _fields = [
      "authorizeIndicator",
      "addressCity",
      "addressState",
      "addressText",
      "mobilePhone",
      "postalCode",
      "taxIdentificationIdentifier",
      "creditScore"
    ];

    let _errs = [];
    let isInvalid = false;
    _fields.forEach((elm) => {
      _errs.push({ field: elm });
    });

    if (Helper.IsJSONEmpty(row)) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    if (Helper.IsJSONEmpty(row?.borrower)) {
      setErrors(_errs);
      isInvalid = true;
    }

    _errs = [];

    if (!isInvalid) {
      _fields = [
        "addressCity",
        "addressState",
        "addressText",
        "mobilePhone",
        "postalCode",
        "taxIdentificationIdentifier",
        "creditScore"
      ];
      _fields.forEach((elm) => {
        if (Helper.IsNullValue(row.borrower[elm])) {
          isInvalid = true;
          _errs.push({ field: elm });
        }
      });
    }

    if (!isInvalid) {
      if (!Helper.IsValidSSN(row?.borrower["taxIdentificationIdentifier"])) {
        isInvalid = true;
        _errs.push({ field: "taxIdentificationIdentifier" });
      }
      if (!Helper.IsValidPhone(row?.borrower["mobilePhone"])) {
        isInvalid = true;
        _errs.push({ field: "mobilePhone" });
      }
    }

    _fields = ["authorizeIndicator"];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        isInvalid = true;
        _errs.push({ field: "authorizeIndicator" });
      }
    });

    if (isInvalid) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    data["stage7"] = row;
    data["stage"] = stageIndicator;
    Storage.StoreJSON("stages", data);
    if (onClick) onClick(stageIndicator);
  };

  return (
    <>
      <RenderBreadCrumb stage={7} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead">
            To see how much you can afford, we will need to do a soft credit
            check
          </li>
        </ul>
        <div className="purchase">
          <div className="spacer_25margin_bottom">
            <div className="pur_Q">Current Address</div>
            <TextInput
              placeholder="Current Address"
              tag={"borrower.addressText"}
              path={"borrower.addressText"}
              name={"addressText"}
              value={Helper.ToString(row?.borrower?.addressText)}
              onInputChildChanged={OnInputChanged}
              errors={errors}
            />
          </div>
          <div className="spacer_25margin_bottom">
            <ul className="III_col">
              <li className="III_col">
                <div className="pur_Q">City</div>
                <TextInput
                  placeholder="City"
                  tag={"borrower.addressCity"}
                  path={"borrower.addressCity"}
                  name={"addressCity"}
                  value={Helper.ToString(row?.borrower?.addressCity)}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">State</div>
                <DropDown
                  tag={"borrower.addressState"}
                  path={"borrower.addressState"}
                  name={"addressState"}
                  value={row?.borrower?.addressState}
                  items={statesList}
                  displayName={"text"}
                  displayValue={"value"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Zip</div>
                <TextInput
                  placeholder="Zip Code"
                  tag={"borrower.postalCode"}
                  path={"borrower.postalCode"}
                  name={"postalCode"}
                  value={Helper.ToString(row?.borrower?.postalCode)}
                  onInputChildChanged={OnInputChanged}
                  onBlurError={OnBlurError}
                  errors={errors}
                  type={"ZIP"}
                />
              </li>
            </ul>
          </div>
          <div className="spacer_25margin_bottom">
            <ul className="II_col3by2">
              <span className="spacer_5margin_bottom">
                <div className="pur_Q">Mobile Number</div>
              </span>
              <li className="II_col3by2">
                <TextInput
                  maxLength={14}
                  placeholder="XXX-XXXX-XXX"
                  tag={"borrower.mobilePhone"}
                  path={"borrower.mobilePhone"}
                  name={"mobilePhone"}
                  value={Helper.ToString(row?.borrower?.mobilePhone)}
                  type={"PHONE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
              </li>
              <li className="II_col3by2">
                <div className="noteText">
                  By entering your phone number you are authorizing i3 Mortgage
                  to call and text. We won't charge you for any contact.
                </div>
                {/* <div className="pur_Q">Last 4 digits of Social Security Number</div> */}
              </li>
            </ul>
          </div>
          <div className="spacer_25margin_bottom">
            <ul className="II_col3by2">
              <span className="spacer_5margin_bottom">
                <div className="pur_Q">Credit Score</div>
              </span>
              <li className="II_col3by2">
                <DropDown
                  tag={"borrower.creditScore"}
                  path={"borrower.creditScore"}
                  name={"creditScore"}
                  value={row?.borrower?.creditScore}
                  items={CreditTypes()}
                  displayName={"text"}
                  displayValue={"value"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="II_col3by2">
                <div className="noteText">Please select the range you fall under. <br />Highest credit score qualifies for the best interest rate.</div>
              </li>
            </ul>
          </div>
          <div className="spacer_25margin_bottom">
            <ul className="III_col">
              <div className="spacer_5margin_bottom">
                <div className="pur_Q">Social Security Number</div>
              </div>
              <li className="III_col">
                <TextInput
                  placeholder="Social Security Number"
                  tag={"borrower.taxIdentificationIdentifier"}
                  path={"borrower.taxIdentificationIdentifier"}
                  name={"taxIdentificationIdentifier"}
                  maxLength={11}
                  value={Helper.ToString(
                    row?.borrower?.taxIdentificationIdentifier
                  )}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  type={"SSN"}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
              </li>
            </ul>
          </div>
          <div
            className="spacer_25margin_bottom"
            style={
              Helper.GetErrorIndex(errors, "authorizeIndicator") > -1
                ? errorStyle
                : { borderWidth: 1 }
            }
          >
            <div className="checkboxLabel">
              <input
                type="checkbox"
                id="chkAcceptStage7"
                checked={Helper.ToBool(row?.authorizeIndicator)}
                onChange={() => OnDummyChanged()}
                onClick={(e) =>
                  OnInputChanged(
                    null,
                    "authorizeIndicator",
                    "authorizeIndicator",
                    e.target.checked
                  )
                }
              />
              <label htmlFor="chkAcceptStage7">
                I, {fullname}, authorize i3 Mortgage to verify my credit. I have
                also read and agreed to i3 Mortgage{" "}
                <Link to="/terms">Terms of Use</Link>,{" "}
                <Link to="/privacy">Privacy Policy</Link> and{" "}
                <Link to="/consent">Consent to Receive Electronic Loan Documents</Link>
                , and consent to a credit enquiry and income verification.
              </label>
            </div>
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              onClick={(e) => OnNavClicked("prev", stage - 2)}
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked("next", stage + 2)}
            />
          </div>
          {error && (
            <div className="errormessage">{error}</div>
          )}
        </div>
      </li>
    </>
  );
};

export default Component;
