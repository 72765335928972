import { React, Storage, Helper, TextInput } from "../../../common";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";
import { SetPreapproval } from "../../../services/preapprovalApi";

const Component = ({ onClick }) => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [stage, setStage] = React.useState(0);
  const [initialized, setInitialized] = React.useState(false);
  const [totalAssets, setTotalAssets] = React.useState(0);
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  const SetDefaultValues = (_input) => {

    if (Helper.IsJSONEmpty(_input)) _input['borrower'] = {};

    let _fields = [
      "savingsAccount",
      "retirementAccount",
      "stockAndBondAccounts",
      "gitfAccounts",
      "realEstateAccounts",
      "otherAccounts",
    ];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(_input.borrower[elm])) {
        _input.borrower[elm] = 0;
      }
    });
  }

  const OnInputChanged = async (parent, path, name, value) => {
    setError(null);
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnNavClicked = async (e, type, num) => {
    e.preventDefault();
    setError(null);
    setErrors([]);
    if (type === "next") {
      let _fields = [
        "savingsAccount",
        "retirementAccount",
        "stockAndBondAccounts",
        "gitfAccounts",
        "realEstateAccounts",
        "otherAccounts",
      ];

      let _errs = [];
      _fields.forEach((elm) => {
        _errs.push({ field: elm });
      });

      if (Helper.IsJSONEmpty(row)) {
        setErrors(_errs);
        setError("All fields are mandatory");
        return;
      }

      let isInvalid = false;
      if (!isInvalid && Helper.IsJSONEmpty(row?.borrower)) {
        isInvalid = true;
      }

      if (!isInvalid) {
        _errs = [];
        _fields = [
          "savingsAccount",
          "retirementAccount",
          "stockAndBondAccounts",
          "gitfAccounts",
          "realEstateAccounts",
          "otherAccounts",
        ];
        _fields.forEach((elm) => {
          if (Helper.IsNullValue(row.borrower[elm])) {
            isInvalid = true;
            _errs.push({ field: elm });
          }
        });
      }

      if (isInvalid) {
        setErrors(_errs);
        setError("All fields are mandatory");
        return;
      }

      let data = Storage.RetrieveJSON("stages");
      data["stage9"] = row;
      data["stage"] = num;

      if (num === 10) {
        global.Busy(true);
        let rslt = await SetPreapproval(data);
        global.Busy(false);
        if (rslt.status !== 100) {
          setError(rslt.statusText);
          return;
        }
        data["id"] = rslt.id;
      }
      Storage.StoreJSON("stages", data);
    }
    if (onClick) onClick(num);
  };

  React.useEffect(() => {
    let total = 0;
    console.log("Data Changed");
    if (!Helper.IsJSONEmpty(row?.borrower)) {
      let _fields = [
        "savingsAccount",
        "retirementAccount",
        "stockAndBondAccounts",
        "gitfAccounts",
        "realEstateAccounts",
        "otherAccounts",
      ];
      _fields.forEach((elm) => {
        if (!Helper.IsNullValue(row?.borrower[elm])) {
          total += Helper.ToFloat(row?.borrower[elm]);
        }
      });
    }
    setTotalAssets(total);
  }, [row, state]);

  if (initialized) {
    setError(null);
    setInitialized(false);
    setErrors([]);
    let data = Storage.RetrieveJSON("stages");
    setStage(9);
    let _row = data.stage9 || {};
    SetDefaultValues(_row);
    setRow(_row);
  }

  return (
    <>
      <RenderBreadCrumb stage={9} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead_100pc">
            One last step. Tell us about your assets, even if you don't plan on
            spending them
          </li>
        </ul>
        <div className="purchase">
          <ul className="III_col">
            <div className="spacer_25margin_bottom">
              <li className="III_col">
                <div className="pur_Q">Savings Account</div>
                <TextInput
                  currency="$"
                  tag={"borrower.savingsAccount"}
                  path={"borrower.savingsAccount"}
                  name={"savingsAccount"}
                  value={Helper.ToString(row?.borrower?.savingsAccount)}
                  type={"FINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Retirement Accounts</div>
                <TextInput
                  currency="$"
                  tag={"borrower.retirementAccount"}
                  path={"borrower.retirementAccount"}
                  name={"retirementAccount"}
                  value={Helper.ToString(row?.borrower?.retirementAccount)}
                  type={"FINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Stock and Bonds</div>
                <TextInput
                  currency="$"
                  tag={"borrower.stockAndBondAccounts"}
                  path={"borrower.stockAndBondAccounts"}
                  name={"stockAndBondAccounts"}
                  value={Helper.ToString(row?.borrower?.stockAndBondAccounts)}
                  type={"FINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
            </div>
            <div className="spacer_25margin_bottom">
              <li className="III_col">
                <div className="pur_Q">Gifts from Relatives</div>
                <TextInput
                  currency="$"
                  tag={"borrower.gitfAccounts"}
                  path={"borrower.gitfAccounts"}
                  name={"gitfAccounts"}
                  value={Helper.ToString(row?.borrower?.gitfAccounts)}
                  type={"FINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Sales of Other Real Estate</div>
                <TextInput
                  currency="$"
                  tag={"borrower.realEstateAccounts"}
                  path={"borrower.realEstateAccounts"}
                  name={"realEstateAccounts"}
                  value={Helper.ToString(row?.borrower?.realEstateAccounts)}
                  type={"FINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Other (Business Account)</div>
                <TextInput
                  currency="$"
                  tag={"borrower.otherAccounts"}
                  path={"borrower.otherAccounts"}
                  name={"otherAccounts"}
                  value={Helper.ToString(row?.borrower?.otherAccounts)}
                  type={"FINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
            </div>
          </ul>
          <div className="spacer_20margin_bottom">
            <div className="pur_Q">Total: $ {Helper.ToString(totalAssets)}</div>
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              onClick={(e) => OnNavClicked(e, "prev", stage - 2)}
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked(e, "next", stage + 1)}
            />
          </div>
          {error && <div className="errormessage">{error}</div>}
        </div>
      </li>
    </>
  );
};

export default Component;
