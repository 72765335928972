const Component = (props) => {
  const { home, title } = props;
  return (
    <ul className="topBreadcrumb">
      <li className="topBreadcrumb_home"></li>
      <li className="topBreadcrumb">{home}</li>
      <li className="topBreadcrumb">{title}</li>
    </ul>
  );
};

export default Component;
