import React from "react";
import helper from "../helper";

const Component = (props) => {
    const { path, content, name, value, onInputChildChanged, index } = props;

    let tIndex = index || 0;

    const OnCheckClicked = (e) => {
        let _name = GetKeyName();
        onInputChildChanged(_name, e, tIndex);
    }

    const GetKeyName = () => {
        let _name = `checkinput`;
        if (name) {
            _name = `${name.replace(/\./g, '_')}`;
        } if (path) {
            _name = `${path.replace(/\./g, '_')}`;
        }
        return _name;
    };

    const OnDummyChanged = () => { };

    return (
        <>
            <input type="checkbox"
                id={GetKeyName()}
                name={GetKeyName()}
                checked={helper.ToCheck(value)}
                onChange={() => OnDummyChanged()}
                onClick={(e) => OnCheckClicked(e.target.checked)} />
            <label htmlFor={GetKeyName()}>{content}</label>
        </>
    );
};

export default Component;
