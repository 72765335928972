const Component = ({ helpType }) => {
    return (
        <>
            <div id="info_DemographicInfo" className="pop_disable_bg">
                <div className="coApplicant_pop">
                    <div className="pop_head">Demographic Info</div>
                    <div className="pop_contentArea">
                        <div className="pop_contentHead">
                            Please fill all required demographic info for this {helpType}.
                        </div>
                    </div>
                    <div className="pop_buttonLabel">
                        <input
                            type="button"
                            value="Close"
                            className="pop_button"
                            onClick={() => window.hide("info_DemographicInfo")}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Component;