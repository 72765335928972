import { React, Link } from "../../../../common";
import Container from "../../../container";

const Component = () => {

    const hoFLClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.co.in/maps/dir/12.9125217,77.5922232/1900+Boothe+Cir+%23100,+Longwood,+FL+32750,+USA/@1.4938424,-87.9608577,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x88e773cd7ed0718d:0x69da88dba0c2efac!2m2!1d-81.3864665!2d28.6902932";
        window.open(_link, "_blank");
    };

    const boFLClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.co.in/maps/dir/12.9125217,77.5922232/852+SW+18th+Terrace,+Cape+Coral,+FL+33991,+USA/@1.422477,-88.2382699,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x88db47002cb2a529:0xceafeb4f41eb0757!2m2!1d-81.9939162!2d26.6180576";
        window.open(_link, "_blank");
    };

    const boFL1Clicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.co.in/maps/dir/12.9125217,77.5922232/8950+SW+74th+Ct,+Miami,+FL+33156,+USA/@1.3902621,-87.3878241,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x88d9c7a69d369f5d:0xefbeee0bc3c228a9!2m2!1d-80.3158267!2d25.687051";
        window.open(_link, "_blank");
    };

    const boTXClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.com/maps/place/1300+W+Sam+Houston+Pkwy+S,+Houston,+TX+77042,+USA/@29.7481345,-95.5586999,17z/data=!3m1!4b1!4m6!3m5!1s0x8640c34c7b68ee45:0x1a999710d41e5044!8m2!3d29.7481345!4d-95.5586999!16s%2Fg%2F11bw3z692s";
        window.open(_link, "_blank");
    };

    const boSCClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.com/maps/dir/12.9614549,77.7108679/201+Columbia+Mall+Blvd,+Columbia,+SC,+USA/@18.8673794,-39.8672422,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x88f8af401d60dbc1:0xcd26e4dc5077ab26!2m2!1d-80.9657476!2d34.0664131";
        window.open(_link, "_blank");
    };

    const boMSClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.com/maps/dir/12.9614549,77.7108679/808+Robert+E+Lee+Dr,+Tupelo,+MS+38801,+USA/@1.4736353,-92.1288091,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x88874c1ea143844f:0x6ff62f853ca222a2!2m2!1d-88.7174527!2d34.2538643";
        window.open(_link, "_blank");
    };

    const boTNClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.co.in/maps/dir/12.9125217,77.5922232/150+Timber+Creek+Dr+%238,+Cordova,+TN+38018,+USA/@1.7122906,-92.2562766,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x887f9b8a740cf34f:0xf0d1aced508906f0!2m2!1d-89.7975505!2d35.1264059";
        window.open(_link, "_blank");
    };

    const boTX1Clicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.com/maps/place/4040+Hwy+6+Ste+200,+College+Station,+TX+77845,+USA/@30.5792828,-96.2843092,17z/data=!3m1!4b1!4m5!3m4!1s0x864684df22a55555:0x7721cf8386969034!8m2!3d30.5792782!4d-96.2821205";
        window.open(_link, "_blank");
    };

    const boTX2Clicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.com/maps/place/2770+Main+St+%23157,+Frisco,+TX+75034,+USA/@33.1546911,-96.8684912,17z/data=!3m1!4b1!4m5!3m4!1s0x864c3bd9ee0d59ab:0x1a58fd81eea3e073!8m2!3d33.1546866!4d-96.8663025";
        window.open(_link, "_blank");
    };

    const boCAClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.co.in/maps/dir/12.9125217,77.5922232/4332+Los+Vecinos,+Fallbrook,+CA+92028,+USA/@1.6512344,74.0475388,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x80dc79a28e9275bb:0x8650eb7118f4ccc4!2m2!1d-117.2430622!2d33.3123455";
        window.open(_link, "_blank");
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Branch Locations</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="branchLocationBlk">
                                <ul className="IIIcol">
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Corporate Office</div>
                                            <div className="bl_city">Longwood</div>
                                            <div className="bl_content"> 1900 Boothe Circle, Suite #100,
                                                Longwood,
                                                <div className="state">FL 32750</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={hoFLClicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">Cape Coral</div>
                                            <div className="bl_content">852 SW 18th Ter, Cape Coral,
                                                <div className="state">FL, United States</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boFLClicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">Miami</div>
                                            <div className="bl_content">8950 SW 74th Ct, Miami,
                                                <div className="state">FL, United States</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boFL1Clicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">HOUSTON PARKWAY</div>
                                            <div className="bl_content">1300 W Sam Houston Pkwy S, Houston,
                                                <div className="state">TX 77042, USA</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boTXClicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">COLUMBIA</div>
                                            <div className="bl_content">201 Columbia Mall Blvd., Ste. 185, Columbia,
                                                <div className="state">SC 29223</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boSCClicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">Tupelo</div>
                                            <div className="bl_content">808 Robert E Lee Drive, Tupelo,
                                                <div className="state">MS 38801</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boMSClicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">Cordova</div>
                                            <div className="bl_content">150 Timber Creek Drive, Suite 8, Cordova,
                                                <div className="state">TN, United States</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boTNClicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">HOUSTON</div>
                                            <div className="bl_content">4040 Highway 6 Suite 200 College Station,
                                                <div className="state">Texas 77845</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boTX1Clicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">HOUSTON</div>
                                            <div className="bl_content">2770 Main Street #157 Frisco,
                                                <div className="state">Texas 75033</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boTX2Clicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Branch Office</div>
                                            <div className="bl_city">FallBrook</div>
                                            <div className="bl_content">4332 Los Vecinos, Fallbrook,
                                                <div className="state">CA 92028</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={boCAClicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;