import { React, BreadCrumb, Link, Helper, useTimerSession, ViewPdf } from "../../../common";
import LoanSummary from "../loansummary";
import Container from "../../container";
import { GetDocuments, SetConsent, GetDocumentUrl } from "../../../services/documentApi";
import { GetApproval, SetApproval } from "../../../services/loanApi";

const Component = () => {
  const [rows, setRows] = React.useState([]);
  const [noResults, setNoResults] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [selectAll, setSelectAll] = React.useState(false);
  const [noSelectAll, setNoSelectAll] = React.useState(true);
  const [user] = useTimerSession("user", true);
  const [error, setError] = React.useState(null);
  const [approvalSelected, setApprovalSelected] = React.useState(false);
  const [loanApproval, setLoanApproval] = React.useState(false);

  const [pdfStream, setPdfStream] = React.useState(null);
  const [viewPdf, setViewPdf] = React.useState(false);
  const [viewType, setViewType] = React.useState(null);

  const OnLoanSelected = (e) => {
    setViewPdf(false);
    setSearch(e);
    setApprovalSelected(false);
    setLoanApproval(false);
    FetchLoans(e);
  };

  const FetchLoans = async (e) => {
    setError(null);
    setRows([]);
    setNoResults(null);
    setSelectAll(false);
    if (Helper.ToInteger(e?.id) < 1) {
      setNoResults("Select loan to view the documents");
    } else {
      global.Busy(true);
      let rslt = await GetDocuments(e.id, -100);
      global.Busy(false);
      if (rslt.status === 100) {
        setRows({ childs: rslt.data });
        if (rslt.data.length === 0) {
          setRows([]);
          setNoResults(`No matcing results found`);
        }
        if (rslt.data.length > 0) {
          let _rows = rslt.data.filter(
            (x) => GetApprovalStatus(x) === "Awaiting"
          );
          setNoSelectAll(true);
          if (_rows.length > 0) setNoSelectAll(false);

          let tmp = {
            lender_id: rslt.data[0].lender_id,
            loan_id: rslt.data[0].loan_id,
            borrower_id: rslt.data[0].borrower_id,
          };
          setLoanApproval(null);
          global.Busy(true);
          rslt = await GetApproval(tmp);
          global.Busy(false);
          if (rslt.status === 100) {
            SetConsentApprovedStatus(rslt.data);
          }
        }
      } else {
        setNoResults(rslt.statusText);
      }
    }
  };

  const OnDummyChange = () => { };

  const OnSelectedAll = (e) => {
    setSelectAll(e);
    if (rows && rows.childs) {
      let _rows = rows.childs;
      _rows.forEach((row) => {
        row.selectedItem = e;
      });
      setRows({ childs: _rows });
    }
  };

  const RenderCheckBox = ({ item }) => {
    let _status = GetApprovalStatus(item);

    if (_status !== "Awaiting") {
      return <td>&nbsp;</td>;
    } else {
      if (user.typeindex === 3) {
        return (
          <td>
            <input
              id={`rowcheck${item.keyIndex}`}
              type="checkbox"
              checked={item.selectedItem}
              onChange={() => OnDummyChange()}
              onClick={(e) => OnValueSelected(e.target.checked, item.keyIndex)}
            />
            <label htmlFor={`rowcheck${item.keyIndex}`}></label>
          </td>
        );
      } else {
        return <td style={{ width: "1px" }}>&nbsp;</td>;
      }
    }
  };

  const GetApprovalStatus = (x) => {
    let _status = x.document_consent.toString().toLowerCase();
    let _statusTag = "Awaiting";
    if (_status === "accepted") {
      _statusTag = "Approved";
    } else if (_status === "denied") {
      _statusTag = "Denied";
    }
    return _statusTag;
  };

  const OnValueSelected = (e, indx) => {
    let _rows = rows.childs;
    let index = _rows.findIndex((x) => parseInt(x.keyIndex) === parseInt(indx));
    _rows[index]["selectedItem"] = e;
    setRows({ childs: _rows });
  };

  const OnLinkOpen = async (e, type) => {
    let data = {
      doc: e,
      type: type,
    };
    setViewType(type);
    global.Busy(true);
    let rslt = await GetDocumentUrl(data);
    global.Busy(false);
    if (rslt.status !== 100) {
      alert(rslt.statusText);
      return;
    }
    setPdfStream(rslt.data);
    setViewPdf(true);
  };

  const IsSelectedCount = () => {
    return rows.childs.filter((x) => Helper.ToBool(x.selectedItem)).length > 0;
  };

  const SetConsentApprovedStatus = (input) => {
    setApprovalSelected(false);
    setLoanApproval(false);
    if (!Helper.IsNullValue(input)) {
      if (input.toString().toLowerCase() === "accepted") {
        setLoanApproval(true);
        setApprovalSelected(true);
      }
    }
  };

  const OnLoanApprovalClicked = async (e) => {
    e.preventDefault();
    setError(null);
    let tmp = {
      lender_id: rows.childs[0].lender_id,
      loan_id: rows.childs[0].loan_id,
      borrower_id: rows.childs[0].borrower_id,
      consent: approvalSelected ? "accepted" : " denied",
    };

    global.Busy(true);
    let rslt = await SetApproval(tmp);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }

    SetConsentApprovedStatus(tmp.consent);

  };

  const OnSubmitClicked = async (e, val) => {
    e.preventDefault();
    setError(null);
    let _rows = rows.childs.filter(
      (x) =>
        Helper.ToBool(x.selectedItem) && GetApprovalStatus(x) === "Awaiting"
    );
    let data = {};
    if (!Helper.IsArrayNull(_rows)) {
      let firstRow = _rows[0];
      data = {
        lender_id: firstRow["lender_id"],
        borrower_id: firstRow["borrower_id"],
        loan_id: firstRow["loan_id"],
        stage: firstRow["doctype"],
        consent_array: [],
      };
      _rows.forEach((row) => {
        data.consent_array.push({
          document_name: row.docname,
          version_identifier: row.version_identifier,
          consent: val,
        });
      });
      global.Busy(true);
      let rslt = await SetConsent(data);
      global.Busy(false);
      if (rslt.status !== 100) {
        setError(rslt.statusText);
        return;
      }
      _rows = rows.childs;
      rslt.data.forEach((elm) => {
        let _index = _rows.findIndex((x) => x.version_identifier === elm);
        if (_index > -1) {
          _rows[_index]["document_consent"] = val;
        }
      });
      setRows({ childs: _rows });
      setSelectAll(false);
    }
  };

  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <BreadCrumb home="Documents" title="Approvals" />
              <div className="document_pgBlk">
                <div className="appPgStructure_Icol">
                  <LoanSummary
                    view={true}
                    onLoanSelected={OnLoanSelected}
                    setSearch={setSearch}
                  />
                </div>
                <div className="appPgStructure_Icol">
                  <div className="app_box_shadow_noPad">
                    <div className="app_headBlk_pad20">
                      <div className="app_headBlk_blkName">
                        Approve Documents for Loan No:{search?.loannumber}
                      </div>
                    </div>
                    <div className="appContentarea">
                      <ul className="appformColumn">
                        {noResults && (
                          <div className="noContent">{noResults}</div>
                        )}
                        {rows && rows.childs && rows.childs.length > 0 && (
                          <>
                            <div className="approvalTable_head">
                              <table>
                                <tbody>
                                  <tr>
                                    {!noSelectAll ? (
                                      <td>
                                        <input
                                          id="rowcheck"
                                          type="checkbox"
                                          checked={selectAll}
                                          onChange={() => OnDummyChange()}
                                          onClick={(e) =>
                                            OnSelectedAll(e.target.checked)
                                          }
                                        />
                                        <label htmlFor="rowcheck"></label>
                                      </td>
                                    ) : (
                                      <td style={{ width: "1px" }}>&nbsp;</td>
                                    )}
                                    <td>Sl.</td>
                                    <td>Document Type</td>
                                    <td>Document Name</td>
                                    <td>Document Info</td>
                                    <td>Ver</td>
                                    <td>Uploaded By</td>
                                    <td>Date &amp; Time</td>
                                    <td>ISP</td>
                                    <td>Aura</td>
                                    <td>Approval</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="approvalTable">
                              <table>
                                <tbody>
                                  {rows &&
                                    rows.childs &&
                                    rows.childs.map((x) => {
                                      return (
                                        <tr key={x.keyIndex}>
                                          {!noSelectAll ? (
                                            <RenderCheckBox item={x} />
                                          ) : (
                                            <td style={{ width: "1px" }}>
                                              &nbsp;
                                            </td>
                                          )}
                                          <td>{x.keyIndex}</td>
                                          <td title={x.doctype}>
                                            {x.child ? null : x.doctype}
                                          </td>
                                          <td title={x.docname}>
                                            {x.child ? null : x.docname}
                                          </td>
                                          <td title={x.docinfo}>
                                            {x.child ? null : x.docinfo}
                                          </td>
                                          <td>{x.version}</td>
                                          <td>
                                            {Helper.GetStringJoin(x, [
                                              "first_name",
                                              "last_name",
                                            ])}
                                          </td>
                                          <td>
                                            {Helper.ToDate(
                                              x.createdt,
                                              "YYYY-MM-DD HH:mm"
                                            )}
                                          </td>
                                          <td>
                                            <Link
                                              to="#"
                                              onClick={(e) =>
                                                OnLinkOpen(x.blobname, "ISP")
                                              }
                                              title="ISP PDF"
                                            >
                                              <div className="pdficon"></div>
                                            </Link>
                                          </td>
                                          <td>
                                            <Link
                                              to="#"
                                              onClick={(e) =>
                                                OnLinkOpen(x.blobname, "AWS")
                                              }
                                              title="AURA PDF"
                                            >
                                              <div className="pdficon"></div>
                                            </Link>
                                          </td>
                                          <td>
                                            <input
                                              type="button"
                                              value={GetApprovalStatus(x)}
                                              disabled
                                              className="approveDenyButton"
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>

                            {user.typeindex === 3 && IsSelectedCount() && (
                              <div className="approvalButLabel">
                                <input
                                  type="button"
                                  value="Approve"
                                  className="appDenyButton"
                                  onClick={(e) =>
                                    OnSubmitClicked(e, "accepted")
                                  }
                                />
                                <input
                                  type="button"
                                  value="Deny"
                                  className="appDenyButton"
                                  onClick={(e) => OnSubmitClicked(e, "denied")}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {rows && rows.childs && rows.childs.length > 0 && (
                  <div className="appPgStructure_Icol">
                    <div className="app_box_shadow_alert">
                      <ul className="appformColumn">
                        <input
                          id="chkConsent"
                          type="checkbox"
                          checked={approvalSelected}
                          onChange={() => OnDummyChange()}
                          onClick={(e) => setApprovalSelected(e.target.checked)}
                          disabled={loanApproval ? "disabled" : undefined}
                        />
                        <label htmlFor="chkConsent">
                          I also consent to any version of the above document
                          and/or any new document provided by me related to this
                          loan I also consent to any version of the above
                          document and/or any new document provided by me
                          related to this loan
                        </label>
                        <div className="ack_but_label">
                          <input
                            type="button"
                            value="Give Consent"
                            className="ackButton"
                            onClick={(e) => OnLoanApprovalClicked(e)}
                            disabled={loanApproval ? "disabled" : undefined}
                          />
                        </div>
                      </ul>
                    </div>
                  </div>
                )}
                {error && <div className="errormessage">{error}</div>}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ViewPdf
        stream={pdfStream}
        setViewPdf={setViewPdf}
        viewPdf={viewPdf}
        viewType={viewType}
      />
    </>
  );
};

export default Component;
