import { React, Link, Helper, TextInput2 as TextInput, RadioInput2 as RadioInput } from "../../../../common";

const RenderChildOtherProperties = (props) => {
  const { tag, count, item, index, onInputChildChanged, allowDelete } = props;

  const tIndex = index === -1 ? "" : `.${index}`;
  const rootpath = `${tag}${tIndex}`;

  const OnDeleteClicked = (e) => {
    e.preventDefault();
    if (onInputChildChanged) onInputChildChanged(`${rootpath}.delete`.replace(/\./g, '_'), true);
  }

  return (
    <>
      {count > 1 && allowDelete && (
        <div className="formPartition">
          <div className="closePartition">
            <Link to="#" onClick={(e) => OnDeleteClicked(e)}>&#x2715; &nbsp;Delete</Link>
          </div>
        </div>
      )}

      <ul className="formContentIIcol">
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Creditor Name</div>
          <div className="usericon"></div>
          <TextInput
            path={`${rootpath}.creditorName`}
            value={item?.creditorName}
            onInputChildChanged={onInputChildChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Lien Type</div>
          <ul className="form_A">
            <RadioInput
              path={`${rootpath}.lienType`}
              value={item?.lienType}
              options={Helper.LienTypes()}
              uselabel={true}
              onInputChildChanged={onInputChildChanged}
            />
          </ul>
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Monthly Payment</div>
          <div className="form_dollaricon"></div>
          <TextInput
            path={`${rootpath}.monthlyPayment`}
            value={item?.monthlyPayment}
            type={"FINANCE"}
            onInputChildChanged={onInputChildChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">
            Loan Amount / Amount to be Drawn
          </div>
          <div className="form_dollaricon"></div>
          <TextInput
            path={`${rootpath}.loanAmount`}
            value={item?.loanAmount}
            type={"FINANCE"}
            onInputChildChanged={onInputChildChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">
            Credit Limit (if applicable)
          </div>
          <div className="form_dollaricon"></div>
          <TextInput
            path={`${rootpath}.creditLimit`}
            value={item?.creditLimit}
            type={"FINANCE"}
            onInputChildChanged={onInputChildChanged}
          />
        </li>
      </ul>

      {count > 1 && !allowDelete && (
        <div className="formPartition">
          <div className="closePartition"></div>
        </div>
      )}
    </>
  );
};

const Component = (props) => {
  const { tag, owner, items, onAddArrayList, onInputChanged,
    allowAdd, allowDelete, } = props;

  let options = {
    filters: [{ name: "index", value: 0 }],
    IsArray: true,
    path: tag,
  };

  const OnAddNewMortgate = () => {
    let obj = { delete: false, owner: owner };
    let tmp = Helper.AddNewRow(items.childs, obj);
    tmp.forEach((elm) => {
      let pos = options.filters.findIndex((x) => x.name === "index");
      if (pos > -1) {
        options.filters[pos].value = elm.index;
      }
      if (onAddArrayList) onAddArrayList(tag, elm, options);
    });
  };

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">
          Other New Mortgage Loans on the Property
        </div>
        {allowAdd && (
          <div className="form1003Blk_right">
            <input
              type="button"
              defaultValue="&#65291; New"
              className="headRight_button"
              onClick={(e) => OnAddNewMortgate()}
            />
          </div>
        )}
      </div>
      <div className="form1003ContentBlk">
        {items &&
          items.childs &&
          items.childs
            .filter((x) => !x.delete)
            .map((item, index) => {
              return (
                <RenderChildOtherProperties
                  tag={tag}
                  count={items.childs.filter((x) => !x.delete).length}
                  item={item}
                  key={index}
                  index={item.index}
                  allowDelete={allowDelete}
                  onInputChildChanged={onInputChanged}
                />
              );
            })}
      </div>
    </>
  );
};

export default Component;
