import { React, useNavigate } from "../../../common";


const Component = () => {

  const imgLinkUrl = 'https://misjpegs.s3.amazonaws.com/';

  const [classList, setClassList] = React.useState([
    "gallery-item gallery-item-1",
    "gallery-item gallery-item-2",
    "gallery-item gallery-item-3",
    "gallery-item gallery-item-4",
    "gallery-item gallery-item-5",
  ]);
  const [state, setState] = React.useState(null);
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    if (state) {
      let _classes = classList;
      if (state === "NEXT") {
        _classes.unshift(_classes.pop());
      } else {
        _classes.push(_classes.shift());
      }
      setClassList(_classes);
      setState(null);
    }
  }, [state, classList]);

  const OnLinkClicked = (e, link) => {
    e.preventDefault();
    NavigateTo(link);
  };

  return (
    <div className="testiBlk">
      <div className="widthfix">
        <div className="testiIcon"></div>
        <div className="testi_blkHead">Our Clients do the Talking</div>
        <div className="testi_blkTxtDesc">
          We work day and night for our customers and create a clean space.{" "}
          <br />
          Here's what they have to say
        </div>
        <div className="gallery">
          <div className="gallery-container">
            <img className={classList[0]} src={`${imgLinkUrl}testi.jpg`} alt="Testimonial" />
            <img className={classList[1]} src={`${imgLinkUrl}testi_1.jpg`} alt="Testimonial" />
            <img className={classList[2]} src={`${imgLinkUrl}testi_2.jpg`} alt="Testimonial" />
            <img className={classList[3]} src={`${imgLinkUrl}testi_3.jpg`} alt="Testimonial" />
            <img className={classList[4]} src={`${imgLinkUrl}testi_4.jpg`} alt="Testimonial" />
          </div>
          <div className="gallery-controls"></div>

          <div className="gallery-controls">
            <button
              className="gallery-controls-previous"
              onClick={() => setState("PREV")}
            >
              previous
            </button>
            <button
              className="gallery-controls-More"
              onClick={(e) => OnLinkClicked(e, "reviews")}
            >
              More
            </button>
            <button
              className="gallery-controls-next"
              onClick={() => setState("NEXT")}
            >
              next
            </button>
          </div>
        </div>
        {/* <script src="carousel.js"></script> */}
        {/* <ScriptTag type="text/javascript" src="../../carousel.js" /> */}
      </div>
    </div>
  );
};

export default Component;
