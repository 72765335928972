import { React, useNavigate, Helper, Session, useTimerSession } from "../../../common";
import Container from "./container1003";

import { GetListById } from "../../../services/enumsApi";
import { AddLoan, GetLoan, GetLoanOffiers } from "../../../services/loanApi";

import { InfoCoApplicant, InfoCoBorrower } from "../help";
import { GetShortUserProfile } from "../../../services/profileApi";
import { RenderApplicant, RenderOtherDetails } from "./childs";

const sample = '{"applicants":[{"applicantIndicatorType":"Applicant","borrower":{"firstName":"user","lastName":"09","mobilePhone":"(216) 357-3323","emailAddressText":"user09@gmail.com","phoneType":"Mobile","workPhone":"(216) 357-3323"},"coborrower":{"emailAddressText":"userb01@gmail.com","firstName":"Borrower","lastName":"User","mobilePhone":"(984) 509-8451","workPhone":""},"coBorrowerIndicator":true},{"applicantIndicatorType":"Co-Applicant","borrower":{"firstName":"Applicant","lastName":"Co","emailAddressText":"userc01@gmail.com","mobilePhone":"(984) 509-8452","workPhone":""},"coBorrowerIndicator":true,"coborrower":{"emailAddressText":"usercb01@gmail.com","firstName":"Applicant","lastName":"BCo","mobilePhone":"(984) 509-8453","workPhone":""}},{"applicantIndicatorType":"Co-Applicant"}],"borrowerCreditType":"Joint","coApplicantIndicator":true,"coApplicantCount":"1","loanOfficerIndicator":true,"loanOfficerId":"4","loanOfficerEmail":"alombardo@i3lending.com","loanOfficerPhone":"(734) 782-5626","loanOfficerNMLSId":"133024","loanOfficerLicenseState":"AL","loanOfficerLicenseID":"58629","loanOfficer":"ANTHONY LOMBARDO","howDoYouHear":"NewsChannel"}';

//const readOnlyFields = ["firstName", "lastName"];
const readOnlyFields = [];

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [state, setState] = React.useState(false);

  const [errors, setErrors] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loanId] = useTimerSession("loanid");

  const [howdoyouhear, setHowdoyouhear] = React.useState([]);
  const [loanOfficers, setLoanOfficers] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [lastStage, setLastStage] = React.useState(1);

  let stageIndicator = 1;
  const navigate = useNavigate();

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchProfile = async () => {
    global.Busy(true);
    let rslt = await GetShortUserProfile();
    global.Busy(false);
    return rslt;
  };

  const FetchResults = async () => {
    global.Busy(true);
    let rslt = await GetListById(15);
    if (rslt.status === 100) {
      let tmp = rslt.data;
      Helper.AddSelectOption(tmp);
      setHowdoyouhear(tmp);
    }

    rslt = await GetLoanOffiers();
    if (rslt.status === 100) {
      let tmp = rslt.data;
      tmp.forEach((elm) => {
        elm.text = Helper.GetStringJoin(elm, ["fullname", " - ", "state"]);
        elm.value = elm.id;
      });
      Helper.AddSelectOption(tmp);
      setLoanOfficers(tmp);
    }

    setRow([]);
    let stageOne;
    if (!Helper.IsNullValue(loanId)) {
      rslt = await GetLoan(stageIndicator, loanId);
      if (rslt.status === 100) {
        let _data = rslt.data;
        setLastStage(_data.stageIndicator);
        stageOne = _data.stageOne;
      }
    }

    rslt = await GetListById(18);
    if (rslt.status === 100) {
      let t = rslt.data || [];
      Helper.AddSelectOption(t, true, 'value');
      setStatesList(t);
    }

    //stageOne = JSON.parse(sample);

    if (Helper.IsNullValue(stageOne)) {
      rslt = await FetchProfile().then((obj) => {
        if (parseInt(obj.status) === 100) {
          let _data = obj.data;
          stageOne = {
            applicants: [{
              applicantIndicatorType: "Applicant",
              borrower: {
                firstName: _data?.first_name,
                lastName: _data?.last_name,
                mobilePhone: _data?.mobile_number,
                emailAddressText: _data?.email,
                phoneType: "Mobile",
                middleName: _data?.middle_name,
                workPhone: _data?.work_phone
              },
              coborrower: {}
            }]
          };
        }
      });
    }
    setRow(stageOne);
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    setErrors([]);
    setError(null);
    setSuccess(null);
    FetchResults();
  }

  const GetDuplicateRecord = (name) => {
    let rec = [];
    if (!Helper.IsJSONEmpty(row.applicants)) {
      let applicants = row.applicants;
      for (let i = 0; i < applicants.length; i++) {
        let Outer = applicants[i];
        let _OouterBValue = Helper.GetJsonValue(Outer?.borrower, name);
        let _OouterCValue = Helper.GetJsonValue(Outer?.coborrower, name);
        if (!Helper.IsNullValue(_OouterBValue) && _OouterBValue === _OouterCValue) {
          rec.push({ text: _OouterBValue, borrower: true, index: i });
          rec.push({ text: _OouterCValue, borrower: false, index: i });
        }
        for (let j = i + 1; j < applicants.length; j++) {
          let current = applicants[j];
          let _CurrentBValue = Helper.GetJsonValue(current?.borrower, name);
          let _CurrentCValue = Helper.GetJsonValue(current?.coborrower, name);
          if (!Helper.IsNullValue(_OouterBValue)) {
            if (_OouterBValue === _CurrentBValue) {
              rec.push({ text: _OouterBValue, borrower: true, index: i });
              rec.push({ text: _CurrentBValue, borrower: true, index: j });
            } else if (_OouterBValue === _CurrentCValue) {
              rec.push({ text: _OouterBValue, borrower: true, index: i });
              rec.push({ text: _CurrentCValue, borrower: false, index: j });
            }
          }
          if (!Helper.IsNullValue(_OouterCValue)) {
            if (_OouterCValue === _CurrentBValue) {
              rec.push({ text: _OouterCValue, borrower: false, index: i });
              rec.push({ text: _CurrentBValue, borrower: true, index: j });
            } else if (_OouterCValue === _CurrentCValue) {
              rec.push({ text: _OouterCValue, borrower: false, index: i });
              rec.push({ text: _CurrentCValue, borrower: false, index: j });
            }
          }
        }
      }
    }
    return rec;
  }

  const IsDuplicateRecord = (name) => {

    let rec = GetDuplicateRecord(name);
    if (rec && rec.length > 0) {
      rec.forEach((e) => {
        if (e.borrower === true) {
          e["field"] = `applicants_${e.index}_borrower_${name}`;
        } else {
          e["field"] = `applicants_${e.index}_coborrower_${name}`;
        }
      })
      return rec;
    }

    return false;
  }

  const OnRecordValidate = () => {
    setError("");
    setErrors([]);
    const { errors, valid } = Helper.IsFormValid('stageOne');

    if (!valid) {
      setErrors(errors);
      setError("Fill all required details");
      return false;
    }

    let EmailDupliates = IsDuplicateRecord('emailAddressText');
    if (EmailDupliates) {
      setErrors(EmailDupliates);
      setError("Email Id should not be same");
      return false;
    }

    let MobileDupliates = IsDuplicateRecord('mobilePhone');
    if (MobileDupliates) {
      setErrors(MobileDupliates);
      setError("Phone Number should not be same");
      return false;
    }

    return true;
  };

  const RemoveUnWantedItems = () => {
    let _rows = row;
    let _applicants = _rows.applicants;
    let _apps = [];
    const removeItems = ['applicantIndicatorType'];
    removeItems.forEach(e => {
      _applicants.forEach(x => {
        let tmp = JSON.parse(JSON.stringify(x));
        delete tmp[e];
        if (!Helper.IsJSONEmpty(tmp)) {
          _apps.push(x);
        }
      });
    });
    _rows.applicants = _apps;
    setRow(_rows);
    setState(!state);
  }

  const OnUpdateRecord = async (e, saved) => {
    e.preventDefault();
    setState(!state);
    RemoveUnWantedItems();
    if (!OnRecordValidate()) return;
    setErrors([]);
    setError(null);
    setSuccess(null);
    row._id = loanId;
    row.stageIndicator = stageIndicator;
    let applicants = JSON.parse(JSON.stringify(row));
    const removeItems = ['stageIndicator', 'statusIndicator', '_id'];
    removeItems.forEach(e => { delete applicants[e]; });
    stageIndicator = lastStage > 1 ? lastStage : 1

    let newObj = {
      _id: loanId,
      statusIndicator: 0,
      emailIndicator: !saved ? 1 : 0,
      stageIndicator,
      stageOne: applicants,
    };

    global.Busy(true);
    let rslt = await AddLoan(newObj);
    global.Busy(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    } else {
      let _data = rslt.data;
      Session.Store("loanid", _data._id);
      setRow(_data.stageOne);
    }
    if (saved) {
      setSuccess(Helper.SavedMessage);
    } else {
      navigate("/stage2");
    }
  };

  const OnInputChanged = async (path, value, index) => {
    let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
    if (path === "loanOfficerId") {
      ExtractOfficerDetails(newRow, value);
    }
    if (path === 'coApplicantCount') {
      for (let i = 0; i < value; i++) {
        let _path = `applicants_${i + 1}_applicantIndicatorType`;
        let _newRow = newRow;
        newRow = Helper.ModifyJsonArrayObject4(_newRow, _path, 'Co-Applicant');
      }
    }
    setRow(newRow);
    setState(!state);
  }

  const ExtractOfficerDetails = (nRow, id) => {
    let tmp = loanOfficers.filter((x) => parseInt(x.id) === parseInt(id));
    if (tmp && tmp.length > 0) {
      nRow.loanOfficerEmail = tmp[0].email;
      nRow.loanOfficerPhone = tmp[0].work_phone;
      nRow.loanOfficerNMLSId = tmp[0].nmls_id;
      nRow.loanOfficerLicenseState = tmp[0].state;
      nRow.loanOfficerLicenseID = tmp[0].license;
      nRow.loanOfficer = tmp[0].fullname;
    }
  };

  const OnBlurError = (name, val, clear) => {
    setSuccess(null);
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    setSuccess(null);
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  return (
    <>
      <Container selected={1} lastStage={lastStage}>
        <form id="stageOne">
          <div className="pageLabelbelow1023">Personal Details</div>
          <div className="form1003Icol">
            <RenderApplicant
              coborrower={true} index={0} type={'Applicant'}
              row={row} errors={errors} onInputChanged={OnInputChanged}
              readOnlyFields={readOnlyFields} onBlurError={OnBlurError} onInputClicked={OnInputClicked} />
          </div>
          <div className="form1003Icol">
            <RenderOtherDetails
              row={row} errors={errors} onInputChanged={OnInputChanged}
              loanOfficers={loanOfficers} howdoyouhear={howdoyouhear} statesList={statesList}
              onBlurError={OnBlurError} onInputClicked={OnInputClicked}
            />
          </div>
          {row?.borrowerCreditType === "Joint" &&
            <ul className="form1003IIcol">
              {[...Array(Helper.GetApplicantCount(row)).keys()].map((x, index, obj) => {
                const formStyle = (obj.length % 2) === 1 && (x + 1) === obj.length ? "form1003Icol" : "form1003IIcol";
                return (
                  <li className={formStyle} key={x}>
                    <RenderApplicant
                      coborrower={true} index={x + 1}
                      type={'Co-Applicant'}
                      row={row} errors={errors} onInputChanged={OnInputChanged}
                      onBlurError={OnBlurError} onInputClicked={OnInputClicked} />
                  </li>
                )
              })}
            </ul>
          }
          <ul className="form_btn_successError_blk">
            <li className="form_btn_steps_label">
              <input
                type="button"
                defaultValue="Save & Finish Later"
                className="form_btn_steps"
                onClick={(e) => OnUpdateRecord(e, true)}
              />
              <input
                type="button"
                defaultValue="Continue"
                className="form_btn_steps"
                onClick={(e) => OnUpdateRecord(e, false)}
              />
            </li>
            {error && (
              <li className="form_successError_steps">
                <div className="errormessage">{error}</div>
              </li>
            )}
            {success && (
              <li className="form_successError_steps">
                <div className="successmessage">{success}</div>
              </li>
            )}
          </ul>
        </form>
      </Container>
      <InfoCoApplicant />
      <InfoCoBorrower />
    </>
  );
};

export default Component;
