import { React, Link, TextInput, Loader, Helper, RadioInput, DropDown } from "../../../common";
import { GetLoanRawData } from "../../../services/loanApi";
import { GetList, GetListById } from "../../../services/enumsApi";
import "./styles.scss";

import {
    RenderGiftsOrGrants,
    RenderLoanDetails,
    RenderPropertyDetails,
    RenderOtherProperties,
    RenderBorrowerInfo,
    RenderAddress,
    RenderEmployment,
    RenderIncomeSources,
    RenderAssets,
    RenderOtherAssets,
    RenderOtherCredits,
    RenderLiabilities,
    RenderOtherLiabilities,
    RenderRealEstate,
    RenderDeclarations,
    RenderDemographicInfo,
    RenderMilitaryService
} from "../stages/childs";

const RendorStageOne = (props) => {
    const { row, howdoyouhear, loanOfficers } = props;

    return (
        <ul className="form1003IIcol">
            <li className="form1003Icol">
                <div className="form1003BlkHead">
                    <div className="formHeadContent">Personal Details</div>
                    <div className="form1003Blk_right">&nbsp;</div>
                </div>
                <div className="form1003ContentBlk">
                    <ul className="formContentIIcol">
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">First Name</div>
                            <div className="formMandatory">*</div>
                            <div className="usericon"></div>
                            <TextInput
                                tag={'borrower.firstName'}
                                path={'borrower.firstName'}
                                name={'firstName'}
                                value={Helper.ToString(row?.borrower?.firstName)} />
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">Middle Name</div>
                            <div className="usericon"></div>
                            <TextInput
                                tag={'borrower.middleName'}
                                path={'borrower.middleName'}
                                name={'middleName'}
                                value={Helper.ToString(row?.borrower?.middleName)} />
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">Last Name</div>
                            <div className="formMandatory">*</div>
                            <div className="usericon"></div>
                            <TextInput
                                tag={'borrower.lastName'}
                                path={'borrower.lastName'}
                                name={'lastName'}
                                value={Helper.ToString(row?.borrower?.lastName)} />
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">Cell Phone</div>
                            <div className="formMandatory">*</div>
                            <div className="phicon"></div>
                            <TextInput
                                maxLength={14}
                                tag={'borrower.mobilePhone'}
                                path={'borrower.mobilePhone'}
                                name={'mobilePhone'}
                                value={Helper.ToString(row?.borrower?.mobilePhone)} />
                        </li>
                    </ul>
                    <div className="spacer_20margin_bottom">
                        <div className="form1003ContentHead">Email Address</div>
                        <div className="formMandatory">*</div>
                        <div className="mailicon"></div>
                        <TextInput
                            tag={'borrower.emailAddressText'}
                            path={'borrower.emailAddressText'}
                            name={'emailAddressText'}
                            value={Helper.ToString(row?.borrower?.emailAddressText)} />
                    </div>
                </div>
            </li>
            <li className="form1003Icol">
                <div className="form1003BlkHead">
                    <div className="formHeadContent">Other Details</div>
                    <div className="form1003Blk_right">&nbsp;</div>
                </div>
                <div className="form1003ContentBlk">
                    <ul className="formContentIIcol">
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">
                                Is there a Co-Borrower?
                            </div>
                            <div className="formMandatory">*</div>
                            <ul className="form_A">
                                <RadioInput
                                    path={`coBorrowerIndicator`}
                                    tag={`coBorrowerIndicator`}
                                    name={"coBorrowerIndicator"}
                                    value={Helper.ToUndefinedCheck(row?.coBorrowerIndicator)}
                                    options={Helper.Options()}
                                    uselabel={false} />
                            </ul>
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">
                                Is there a Co-Applicant?
                            </div>
                            <Link
                                to="#"
                                title="Info"
                            >
                                <div className="formTip"></div>
                            </Link>
                            <ul className="form_A">
                                <RadioInput
                                    tag={`borrower_coApplicantIndicator`}
                                    name={"coApplicantIndicator"}
                                    path={"coApplicantIndicator"}
                                    value={Helper.ToUndefinedCheck(row?.coApplicantIndicator)}
                                    options={Helper.Options()}
                                    uselabel={false} />
                            </ul>
                            {/* <div style="color:red">If No is selected, there will be 5 steps. Step 4 from top has to be removed</div> */}
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">
                                Are you working with a Loan Officer?
                            </div>
                            <ul className="form_A">
                                <RadioInput
                                    tag={`borrower_loanOfficerIndicator`}
                                    name={"loanOfficerIndicator"}
                                    path={"loanOfficerIndicator"}
                                    value={Helper.ToUndefinedCheck(row?.loanOfficerIndicator)}
                                    options={Helper.Options()}
                                    uselabel={false} />
                            </ul>
                        </li>

                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">
                                Loan Officer Name
                            </div>
                            <div className="usericon"></div>
                            <DropDown
                                tag={'borrower_loanOfficer'}
                                name={'loanOfficer'}
                                path={'loanOfficer'}
                                items={loanOfficers}
                                value={Helper.ToString(row?.loanOfficer)}
                                displayName={"text"}
                                displayValue={"value"}
                                disabled={!Helper.ToUndefinedCheck(row?.loanOfficerIndicator)}
                            />
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">
                                How did you hear about us?
                            </div>
                            <div className="noteicon"></div>
                            <DropDown
                                tag={'borrower_howDoYouHear'}
                                name={'howDoYouHear'}
                                path={'howDoYouHear'}
                                items={howdoyouhear}
                                value={Helper.ToString(row?.howDoYouHear)}
                                displayName={"text"}
                                displayValue={"value"}
                            />
                        </li>
                        {row?.howDoYouHear === "Other" && (
                            <li className="formContentIIcol">
                                <div className="form1003ContentHead">Others</div>
                                <div className="textPad10">
                                    <TextInput
                                        tag={'borrower_others'}
                                        name={'howDoYouHearOthers'}
                                        path={'howDoYouHearOthers'}
                                        value={Helper.ToString(row?.howDoYouHearOthers)} />
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </li>
        </ul>
    );
}

const RendorStageTwo = (props) => {
    const { row, mortgageTypes, amortizationTypes, loanPurposeTypes,
        occupancyTypes, unitTypes, statesList, giftsOrGrantTypes, sourceTypes } = props;

    const GetItemsList = (name) => {
        if (row && row[name] &&
            (!Helper.IsArrayNull(row[name]) || !Helper.IsJSONEmpty(row[name])))
            return row[name];
        let tmp = [{ index: 0, delete: false }];
        if (name === 'giftsGrants') {
            tmp = [{ index: 0, delete: false, owner: 'Borrower' }];
            //row[name] = tmp;
            //OnDirectInputChanged(name, tmp);
            return tmp;
        } else if (name === 'otherMortgageLoans') {
            tmp = [{ index: 0, delete: false, owner: 'Borrower' }];
            //row[name] = tmp;
            //OnDirectInputChanged(name, tmp);
            return tmp;
        }
        //row[name] = tmp;
        //OnDirectInputChanged(name, tmp);
        return tmp;
    }

    return (
        <ul className="form1003IIcol">
            <li className="form1003Icol">
                <RenderLoanDetails
                    tag={'loanDetails'}
                    item={row}
                    mortgageTypes={mortgageTypes}
                    amortizationTypes={amortizationTypes}
                    loanPurposeTypes={loanPurposeTypes}
                    occupancyTypes={occupancyTypes}
                />
                <RenderPropertyDetails
                    tag={'property'}
                    item={row}
                    unitTypes={unitTypes}
                    statesList={statesList}
                />
            </li>
            <li className="form1003Icol">
                <RenderGiftsOrGrants
                    allowAdd={false}
                    allowDelete={false}
                    tag={"giftsGrants"}
                    items={{ childs: GetItemsList('giftsGrants') }}
                    owner={'Borrower'}
                    giftsOrGrantTypes={giftsOrGrantTypes}
                    sourceTypes={sourceTypes}
                />
                <RenderOtherProperties
                    allowAdd={false}
                    allowDelete={false}
                    tag={'otherMortgageLoans'}
                    owner={'Borrower'}
                    items={{ childs: GetItemsList('otherMortgageLoans') }}
                />
            </li>
        </ul>
    );
};

const RendorStageThree = (props) => {
    const { row, maritalStatusTypes, citizenshipTypes, statesList,
        unitTypes, housingTypes, sourceTypes } = props;

    const GetBorrower = () => {
        return row?.borrower || {};
    }

    const GetResidences = (appType, resType, mailIndicator) => {
        let ntmp = { applicantType: appType };
        if (resType) ntmp['residencyType'] = resType;
        if (mailIndicator) ntmp['mailingAddressIndicator'] = mailIndicator;
        let tmp = row?.residences?.filter((x) => x.applicantType === appType);
        if (tmp) {
            if (resType) {
                tmp = tmp.filter((x) => x.residencyType === resType);
                if (!Helper.IsArrayNull(tmp)) {
                    return tmp[0];
                }
            } else if (mailIndicator) {
                tmp = tmp.filter((x) => x.mailingAddressIndicator === mailIndicator);
                if (!Helper.IsArrayNull(tmp)) {
                    return tmp[0];
                }
            }
        }
        return ntmp;
    }

    const GetOtherIncomeSources = (owner) => {
        let ntmp = { delete: false, index: 0, owner: owner };
        let tmp = row?.otherIncomeSources?.filter((x) => x.owner === owner);
        if (!Helper.IsArrayNull(tmp)) {
            return tmp;
        }
        return [ntmp];
    }

    const GetEmployments = (owner, employmentTypeIndicator, addEmployment) => {
        let ntmp = { delete: false, index: 0, owner: owner };
        if (employmentTypeIndicator !== undefined) ntmp['employmentTypeIndicator'] = employmentTypeIndicator;
        if (employmentTypeIndicator === 'Previous') {
            ntmp['currentEmploymentIndicator'] = false;
        } else {
            ntmp['currentEmploymentIndicator'] = true;
        }
        if (addEmployment !== undefined) ntmp['addEmployment'] = addEmployment;
        let tmp = row?.employment?.filter((x) => x.owner === owner);
        if (tmp) {
            if (employmentTypeIndicator !== undefined) {
                tmp = tmp.filter((x) => x.employmentTypeIndicator === employmentTypeIndicator);
                if (!Helper.IsArrayNull(tmp)) {
                    return tmp;
                }
            }
        }
        return [ntmp];
    }

    return (
        <ul className="form1003IIcol">
            <li className="form1003Icol">
                <RenderBorrowerInfo
                    tag={"borrower"}
                    item={GetBorrower()}
                    maritalStatusTypes={maritalStatusTypes}
                    citizenshiptypes={citizenshipTypes}
                    allowAdd={false}
                />
                <RenderAddress
                    tag={"residences"}
                    item={GetResidences("Borrower", "Current")}
                    statesList={statesList}
                    unittypes={unitTypes}
                    housingtypes={housingTypes}
                />
                <RenderAddress
                    tag={"residences"}
                    item={GetResidences("Borrower", null, true)}
                    citem={GetResidences("Borrower", "Current")}
                    statesList={statesList}
                    unittypes={unitTypes}
                    housingtypes={housingTypes}
                />
                <RenderEmployment
                    items={{ childs: GetEmployments("Borrower", "Current", true) }}
                    owner={"Borrower"}
                    unittypes={unitTypes}
                    statesList={statesList}
                    allowAdd={false}
                    allowDelete={false}
                />
            </li>
            <li className="form1003Icol">
                <RenderEmployment
                    items={{ childs: GetEmployments("Borrower", "Additional") }}
                    owner={"Borrower"}
                    unittypes={unitTypes}
                    statesList={statesList}
                    allowAdd={false}
                    allowDelete={false}
                />
                <RenderEmployment
                    allowAdd={false}
                    allowDelete={false}
                    items={{ childs: GetEmployments("Borrower", "Previous") }}
                    owner={"Borrower"}
                    unittypes={unitTypes}
                    statesList={statesList}
                />
                <RenderIncomeSources
                    allowAdd={false}
                    allowDelete={false}
                    owner={"Borrower"}
                    tag={"otherIncomeSources"}
                    sourcetypes={sourceTypes}
                    items={{ childs: GetOtherIncomeSources('Borrower') }}
                />
            </li>
        </ul>
    );
};

const RendorStageFour = (props) => {
    const { row, maritalStatusTypes, citizenshipTypes, statesList,
        unitTypes, housingTypes, sourceTypes } = props;

    const GetCoBorrower = () => {
        return row?.coborrower || {};
    }

    const GetResidences = (appType, resType, mailIndicator) => {
        let ntmp = { applicantType: appType };
        if (resType) ntmp['residencyType'] = resType;
        if (mailIndicator) ntmp['mailingAddressIndicator'] = mailIndicator;
        let tmp = row?.residences?.filter((x) => x.applicantType === appType);
        if (tmp) {
            if (resType) {
                tmp = tmp.filter((x) => x.residencyType === resType);
                if (!Helper.IsArrayNull(tmp)) {
                    return tmp[0];
                }
            } else if (mailIndicator) {
                tmp = tmp.filter((x) => x.mailingAddressIndicator === mailIndicator);
                if (!Helper.IsArrayNull(tmp)) {
                    return tmp[0];
                }
            }
        }
        return ntmp;
    }

    const GetOtherIncomeSources = (owner) => {
        let ntmp = { delete: false, index: 0, owner: owner };
        let tmp = row?.otherIncomeSources?.filter((x) => x.owner === owner);
        if (!Helper.IsArrayNull(tmp)) {
            return tmp;
        }
        return [ntmp];
    }

    const GetEmployments = (owner, employmentTypeIndicator, addEmployment) => {
        let ntmp = { delete: false, index: 0, owner: owner };
        if (employmentTypeIndicator !== undefined) ntmp['employmentTypeIndicator'] = employmentTypeIndicator;
        if (employmentTypeIndicator === 'Previous') {
            ntmp['currentEmploymentIndicator'] = false;
        } else {
            ntmp['currentEmploymentIndicator'] = true;
        }
        if (addEmployment !== undefined) ntmp['addEmployment'] = addEmployment;
        let tmp = row?.employment?.filter((x) => x.owner === owner);
        if (tmp) {
            if (employmentTypeIndicator !== undefined) {
                tmp = tmp.filter((x) => x.employmentTypeIndicator === employmentTypeIndicator);
                if (!Helper.IsArrayNull(tmp)) {
                    return tmp;
                }
            }
        }
        return [ntmp];
    }

    return (
        <>

            <li className="form1003IIcol">
                <div className="form1003BlkHead">
                    <div className="formHeadContent">Co-Borrower Information</div>
                    <div className="form1003Blk_right">&nbsp;</div>
                </div>
                <div className="form1003ContentBlk">
                    <ul className="formContentIIcol">
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">First Name</div>
                            <div className="formMandatory">*</div>
                            <div className="usericon"></div>
                            <TextInput
                                path={'coborrower.firstName'}
                                tag={'coborrower.firstName'}
                                name={'firstName'}
                                value={Helper.ToString(row?.coborrower?.firstName)} />
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">Middle Name</div>
                            <div className="usericon"></div>
                            <TextInput
                                tag={'coborrower.middleName'}
                                path={'coborrower.middleName'}
                                name={'middleName'}
                                value={Helper.ToString(row?.coborrower?.middleName)} />
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">Last Name</div>
                            <div className="formMandatory">*</div>
                            <div className="usericon"></div>
                            <TextInput
                                path={'coborrower.lastName'}
                                tag={'coborrower.lastName'}
                                name={'lastName'}
                                value={Helper.ToString(row?.coborrower?.lastName)} />
                        </li>
                        <li className="formContentIIcol">
                            <div className="form1003ContentHead">Cell Phone</div>
                            <div className="formMandatory">*</div>
                            <div className="phicon"></div>
                            <TextInput
                                maxLength={14}
                                tag={'coborrower.mobilePhone'}
                                path={'coborrower.mobilePhone'}
                                name={'mobilePhone'}
                                value={Helper.ToString(row?.coborrower?.mobilePhone)}
                                type={'PHONE'} />
                        </li>
                    </ul>
                    <div className="spacer_20margin_bottom">
                        <div className="form1003ContentHead">Email Address</div>
                        <div className="formMandatory">*</div>
                        <div className="mailicon"></div>
                        <TextInput
                            tag={'coborrower.emailAddressText'}
                            path={'coborrower.emailAddressText'}
                            name={'emailAddressText'}
                            value={Helper.ToString(row?.coborrower?.emailAddressText)} />
                    </div>
                    <RenderBorrowerInfo
                        noheaders={true}
                        tag={"coborrower"}
                        item={GetCoBorrower()}
                        maritalStatusTypes={maritalStatusTypes}
                        citizenshiptypes={citizenshipTypes}
                        allowAdd={false}
                        allowDelete={false}
                    />
                </div>

                <RenderAddress
                    tag={"residences"}
                    item={GetResidences("CoBorrower", "Current")}
                    statesList={statesList}
                    unittypes={unitTypes}
                    housingtypes={housingTypes}
                />
                <RenderAddress
                    tag={"residences"}
                    item={GetResidences("CoBorrower", null, true)}
                    citem={GetResidences("CoBorrower", "Current")}
                    statesList={statesList}
                    unittypes={unitTypes}
                    housingtypes={housingTypes}
                />
                <RenderEmployment
                    allowAdd={false}
                    allowDelete={false}
                    items={{ childs: GetEmployments("CoBorrower", "Current", true) }}
                    owner={"CoBorrower"}
                    unittypes={unitTypes}
                    statesList={statesList}
                />
            </li>
            <li className="form1003IIcol">
                <RenderEmployment
                    items={{ childs: GetEmployments("CoBorrower", "Additional") }}
                    owner={"CoBorrower"}
                    unittypes={unitTypes}
                    statesList={statesList}
                    allowAdd={false}
                    allowDelete={false}
                />
                <RenderEmployment
                    items={{ childs: GetEmployments("CoBorrower", "Previous") }}
                    owner={"CoBorrower"}
                    unittypes={unitTypes}
                    statesList={statesList}
                    allowAdd={false}
                    allowDelete={false}
                />
                <RenderIncomeSources
                    owner={"CoBorrower"}
                    tag={"otherIncomeSources"}
                    sourcetypes={sourceTypes}
                    items={{ childs: GetOtherIncomeSources('CoBorrower') }}
                    allowAdd={false}
                    allowDelete={false}
                />
            </li>

        </>
    );

}

const RendorStageFive = (props) => {
    const { row, assetsTypes, otherAssetsTypes, otherCreditsTypes, libalitiyTypes, otherLibalitiyTypes, unitTypes,
        occupancies, statesList, propertyStatusTypes, loanTypes } = props;

    const GetItemsList = (name, ownerfield, owner) => {
        let ntmp = {};
        ntmp[name] = [{ index: 0, delete: false }];
        ntmp[name][0][ownerfield] = owner;
        let tmp = ntmp[name].filter((x) => x[ownerfield] === owner);
        if (row && row[name] &&
            (!Helper.IsArrayNull(row[name]) || !Helper.IsJSONEmpty(row[name]))) {
            tmp = row[name].filter((x) => x[ownerfield] === owner);
        }
        return tmp;
    }

    return (
        <>
            <ul className="form1003IIcol">
                <li className="form1003Icol">
                    <RenderAssets
                        owner={'Borrower'}
                        items={{ childs: GetItemsList('assets', 'owner', 'Borrower') }}
                        tag={"assets"}
                        assetsTypes={assetsTypes}
                        allowAdd={false}
                        allowDelete={false}
                    />
                    <RenderOtherAssets
                        borrowerType={'Borrower'}
                        items={{ childs: GetItemsList('otherAssets', 'borrowerType', 'Borrower') }}
                        tag={"otherAssets"}
                        otherAssetsTypes={otherAssetsTypes}
                        allowAdd={false}
                        allowDelete={false}
                    />

                    <RenderOtherCredits
                        borrowerType={'Borrower'}
                        items={{ childs: GetItemsList('otherCredits', 'borrowerType', 'Borrower') }}
                        tag={"otherCredits"}
                        otherCreditTypes={otherCreditsTypes}
                        allowAdd={false}
                        allowDelete={false}
                    />

                </li>
                <li className="form1003Icol">

                    <RenderLiabilities
                        owner={'Borrower'}
                        items={{ childs: GetItemsList('liabilities', 'owner', 'Borrower') }}
                        tag={"liabilities"}
                        libalitiyTypes={libalitiyTypes}
                        allowAdd={false}
                        allowDelete={false}
                    />

                    <RenderOtherLiabilities
                        borrowerType={'Borrower'}
                        items={{ childs: GetItemsList('otherLiabilities', 'borrowerType', 'Borrower') }}
                        tag={"otherLiabilities"}
                        otherLibalitiyTypes={otherLibalitiyTypes}
                        allowAdd={false}
                        allowDelete={false}
                    />

                    <RenderRealEstate
                        items={{ childs: GetItemsList("reoProperties", "owner", "Borrower") }}
                        owner={"Borrower"}
                        unittypes={unitTypes}
                        occupancies={occupancies}
                        statesList={statesList}
                        propertystatustypes={propertyStatusTypes}
                        loantypes={loanTypes}
                        allowAdd={false}
                        allowDelete={false}
                    />
                </li>
            </ul>
        </>
    );
};

const RendorStageSix = (props) => {
    const { row, isCoBorrower } = props;
    return (
        <>
            <RenderDeclarations item={row} coBorrowerIndicator={isCoBorrower} />
            <RenderDemographicInfo split={true} item={row} coBorrowerIndicator={isCoBorrower} />
            <RenderMilitaryService split={true} item={row} coBorrowerIndicator={isCoBorrower} />
        </>
    );
}

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [isCoBorrower, setIsCoBorrower] = React.useState(false);
    const [raw, setRaw] = React.useState({});

    const [howdoyouhear, setHowdoyouhear] = React.useState([]);
    const [loanOfficers, setLoanOfficers] = React.useState([]);
    const [mortgageTypes, setMortgageTypes] = React.useState([]);
    const [amortizationTypes, setAmortizationTypes] = React.useState([]);
    const [loanPurposeTypes, setLoanPurposeTypes] = React.useState([]);
    const [occupancyTypes, setOccupancyTypes] = React.useState([]);
    const [sourceTypes, setSourceTypes] = React.useState([]);
    const [unitTypes, setUnitTypes] = React.useState([]);
    const [giftsOrGrantTypes, setGiftsOrGrantTypes] = React.useState([]);
    const [statesList, setStatesList] = React.useState([]);
    const [citizenshipTypes, setCitizenshipTypes] = React.useState([]);
    const [housingTypes, setHousingtypes] = React.useState([]);
    const [maritalStatusTypes, setMaritalStatusTypes] = React.useState([]);

    const [assetsTypes, setAssetsTypes] = React.useState([]);
    const [otherAssetsTypes, setOtherAssetsTypes] = React.useState([]);
    const [otherCreditsTypes, setOtherCreditsTypes] = React.useState([]);
    const [libalitiyTypes, setLibalitiyTypes] = React.useState([]);
    const [otherLibalitiyTypes, setOtherLibalitiyTypes] = React.useState([]);
    const [propertyStatusTypes, setPropertyStatusTypes] = React.useState([]);
    const [occupancies, setOccupancies] = React.useState([]);
    const [loanTypes, setLoanTypes] = React.useState([]);

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const FetchRawData = async () => {
        setProgress(true);
        let rslt = await GetLoanRawData("6339996eba2ec8d57105cb71");
        setProgress(false);
        if (rslt.status === 100) {
            let tmp = rslt.data;
            setIsCoBorrower(Helper.ToBool(tmp.stageOne.coBorrowerIndicator));
            setRaw(tmp);
        }

        rslt = await GetList(15);
        if (rslt.status === 100) {
            setHowdoyouhear(rslt.data);
        }
        setLoanOfficers([]);

        rslt = await GetList(1);
        if (rslt.status === 100) setMortgageTypes(rslt.data);

        rslt = await GetList(2);
        if (rslt.status === 100) setAmortizationTypes(rslt.data);

        rslt = await GetList(3);
        if (rslt.status === 100) setLoanPurposeTypes(rslt.data);

        rslt = await GetList(4);
        if (rslt.status === 100) setOccupancyTypes(rslt.data);

        rslt = await GetList(5);
        if (rslt.status === 100) setUnitTypes(rslt.data);

        rslt = await GetList(6);
        if (rslt.status === 100) setGiftsOrGrantTypes(rslt.data);

        rslt = await GetList(16);
        if (rslt.status === 100) setSourceTypes(rslt.data);

        rslt = await GetListById(18);
        if (rslt.status === 100) setStatesList(rslt.data);

        rslt = await GetList(20);
        if (rslt.status === 100) setMaritalStatusTypes(rslt.data);

        rslt = await GetList(7);
        if (rslt.status === 100) setCitizenshipTypes(rslt.data);

        rslt = await GetList(8);
        if (rslt.status === 100) setHousingtypes(rslt.data);

        rslt = await GetList(1); if (rslt.status === 100) setLoanTypes(rslt.data);
        rslt = await GetList(4); if (rslt.status === 100) setOccupancies(rslt.data);
        rslt = await GetList(10); if (rslt.status === 100) setAssetsTypes(rslt.data);
        rslt = await GetList(11); if (rslt.status === 100) setOtherAssetsTypes(rslt.data);
        rslt = await GetList(12); if (rslt.status === 100) setPropertyStatusTypes(rslt.data);
        rslt = await GetList(13); if (rslt.status === 100) setLibalitiyTypes(rslt.data);
        rslt = await GetList(14); if (rslt.status === 100) setOtherLibalitiyTypes(rslt.data);
        rslt = await GetList(19); if (rslt.status === 100) setOtherCreditsTypes(rslt.data);
    }

    if (initlized) {
        setInitlized(false);
        setProgress(false);
        setRaw({});
        FetchRawData();
    }

    return (
        <>
            <Loader show={progress} />
            <div id="container">
                <div id="content">
                    <div className="widthfix">
                        <div className="appFormPgBlk">
                            <RendorStageOne row={raw.stageOne} howdoyouhear={howdoyouhear} loanOfficers={loanOfficers} />
                            <RendorStageTwo
                                row={raw.stageTwo}
                                mortgageTypes={mortgageTypes}
                                amortizationTypes={amortizationTypes}
                                loanPurposeTypes={loanPurposeTypes}
                                occupancyTypes={occupancyTypes}
                                unitTypes={unitTypes}
                                statesList={statesList}
                                giftsOrGrantTypes={giftsOrGrantTypes}
                                sourceTypes={sourceTypes}
                            />
                            <RendorStageThree
                                row={raw.stageThree}
                                maritalStatusTypes={maritalStatusTypes}
                                citizenshipTypes={citizenshipTypes}
                                statesList={statesList}
                                unitTypes={unitTypes}
                                housingTypes={housingTypes}
                                sourceTypes={sourceTypes}
                            />
                            <RendorStageFour row={raw.stageFour}
                                maritalStatusTypes={maritalStatusTypes}
                                citizenshipTypes={citizenshipTypes}
                                statesList={statesList}
                                housingTypes={housingTypes}
                                sourceTypes={sourceTypes}
                            />
                            <RendorStageFive row={raw.stageFive}
                                unitTypes={unitTypes}
                                assetsTypes={assetsTypes}
                                otherAssetsTypes={otherAssetsTypes}
                                otherCreditsTypes={otherCreditsTypes}
                                libalitiyTypes={libalitiyTypes}
                                otherLibalitiyTypes={otherLibalitiyTypes}
                                occupancies={occupancies}
                                statesList={statesList}
                                propertyStatusTypes={propertyStatusTypes}
                                loanTypes={loanTypes}
                            />
                            <RendorStageSix row={raw.stageSix} isCoBorrower={isCoBorrower} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Component;
