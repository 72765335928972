import { React } from "../../../../common";
import Container from "../../../container";
import { AmtCalc } from "../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_buy_first">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Buy a Home</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Investment on Residential Property</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Monthly Payment</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">If you're planning to buy a residential investment property, you want to make sure your purchase makes financial sense. There's a lot to consider in addition to the purchase price and mortgage - especially if you will be depending on income from that property. You can get advice from us on mortgage options to help you make decisions that are right for you.</div>
                                    <div className="pgTxtNormal">Maximize your investment property potential with some basic preparation:</div>
                                    <ul className="bulletPoints_un">
                                        <li className="bp_circleOutline">What kinds of loans are available for investment properties?</li>
                                        <li className="bp_circleOutline">What if I'm purchasing out-of-state?</li>
                                        <li className="bp_circleOutline">How do I prepare for my mortgage application?</li>
                                        <li className="bp_circleOutline">What are the steps from mortgage prequalification to closing?</li>
                                    </ul>
                                </li>
                                <li className="IIcol_100by400">
                                    <AmtCalc title={"Monthly Payment"} />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;