import { React, Link, useNavigate, Helper } from "../../../common";
import { GetRequests } from "../../../services/dashboardApi";
import { GetPreDocRequests } from "../../../services/preapprovalApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [requestsList, setRequestsList] = React.useState([]);
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    global.Busy(true);
    setRequestsList([]);

    let _request1 = [], _request2 = [];
    let rslt = await GetRequests();
    if (rslt.status === 100) _request1 = rslt.data || [];

    rslt = await GetPreDocRequests();
    if (rslt.status === 100) _request2 = rslt.data || [];
    _request1 = _request1.concat(_request2);
    setRequestsList(_request1);

    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnDocUploadClicked = (e, id, type) => {
    e.preventDefault();
    if (type && type === 'pre') {
      NavigateTo(`/predocs`);
    } else {
      NavigateTo(`/docupload/${id}`);
    }
  };

  const RenderRequestsList = ({ item }) => {
    let _particular = "Upload income related documents for loan";
    let _preparticular = "Upload documents for pre-approval loan";

    const IsTypeIsPre = () => {
      if (item.type && item.type === 'Pre') return true;
      return false;
    }

    return (
      <tr>
        <td>
          <div className="dash_unread"></div>
        </td>
        <td>{Helper.ToDate(item.createdt || item.createdAt, "YYYY-MMM-DD HH:mm", true)}</td>

        {!IsTypeIsPre() && (
          <>
            <td>{_particular}</td>
            <td>{parseInt(item.count) === 0 ? "Yet to upload" : "Uploaded"}</td>
          </>
        )}

        {IsTypeIsPre() && (
          <>
            <td>{_preparticular}</td>
            <td>{parseInt(item.count) === parseInt(item.total) ? "Uploaded" : "Yet to upload"}</td>
          </>
        )}

        {!IsTypeIsPre() && (
          <td>
            {parseInt(item.count) === 0 ? (
              <Link to="#" onClick={(e) => OnDocUploadClicked(e, item.id)}>
                <div className="dash_uploadIcon"></div>
              </Link>
            ) : (
              <div className="dash_uploadIcon_dis"></div>
            )}
          </td>
        )}

        {IsTypeIsPre() && (
          <td>
            {parseInt(item.count) !== parseInt(item.total) ? (
              <Link to="#" onClick={(e) => OnDocUploadClicked(e, item._id, "pre")}>
                <div className="dash_uploadIcon"></div>
              </Link>
            ) : (
              <div className="dash_uploadIcon_dis"></div>
            )}
          </td>
        )}
      </tr>
    );
  };

  return (
    <div className="app_box_shadow_noPad">
      <div className="app_headBlk_pad20">
        <div className="app_headBlk_blkName">Requests</div>
        <div className="blockRefresh_dash"></div>
        <div className="selectRight">
          <select name="filterType" required>
            <option value="#">Only Latest</option>
            <option value="#">All</option>
          </select>
        </div>
      </div>

      <div className="dash_request_blk">
        <div className="dash_request_head">
          <table>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>Date</td>
                <td>Particulars</td>
                <td>Status</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="dash_request">
          {requestsList && requestsList.length > 0 ? (
            <table>
              <tbody>
                {requestsList.slice(0, 3).map((x, index) => {
                  return <RenderRequestsList key={index} item={x} />;
                })}
              </tbody>
            </table>
          ) : (
            <div className="noContent">No request details available</div>
          )}
        </div>
      </div>
      <div className="appBox_bottom">
        <Link to="#">View All Requests</Link>
      </div>
    </div>
  );
};

export default Component;
