const Component = () => {
    return (
        <div id="info_coborrower" className="pop_disable_bg">
            <div className="coBorrower_pop">
                <div className="pop_head"><label id="infoCoBorrowerTitle">Co-Borrower</label></div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Select this option if you like to add your spouse as a co-borrower</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_coborrower')} />
                </div>
            </div>
        </div>
    );
};

export default Component;