import API from "./api";
import session from "../session";

const GetLoans = async (typeId) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`/dashboard/loans/${typeId}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetRequests = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`/loan/requests`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetAlerts = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`/dashboard/alerts`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreApproval = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`/dashboard/preapproval`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

export { GetLoans, GetRequests, GetAlerts, GetPreApproval };
