import { React, Helper, TextInput, DropDown } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../components";
import { HowDoYouBrief } from "../../../../helper/support";
import { BecomeBranch } from "../../../../services/commonApi";
import { GetListById } from "../../../../services/enumsApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState();
  const [success, setSuccess] = React.useState(null);
  const [state, setState] = React.useState(false);
  const [statesList, setStatesList] = React.useState([]);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchLookUps = async () => {
    return new Promise(async (resolve) => {
      global.Busy(true);
      let rslt = await GetListById(18);
      global.Busy(false);
      if (rslt.status === 100) {
        let tmp = rslt.data;
        Helper.AddSelectOption(tmp);
        setStatesList(tmp);
      }
    });
  };

  if (initlized) {
    setInitlized(false);
    setError(null);
    setSuccess(null);
    FetchLookUps();
  }

  const OnInputChanged = async (parent, path, name, value) => {
    setError(null);
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const OnBecomBranchClicked = async (e) => {
    e.preventDefault();

    if (Helper.IsJSONEmpty(row)) {
      setError("You have some errors. Please fix and proceed.");
      return;
    }

    let isInvalid = false;
    let _fields = [
      "branchName",
      "branchPhone",
      "branchEmail",
      "branchNMLS",
      "branchState",
      "branchBrief",
      "branchMessage",
    ];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        if (!isInvalid) isInvalid = true;
      }
    });

    if (!isInvalid && !Helper.IsEmailValid(row.branchEmail)) {
      if (!isInvalid) isInvalid = true;
    }

    if (isInvalid) {
      setError("You have some errors. Please fix and proceed.");
      return;
    }

    global.Busy(true);
    let rslt = await BecomeBranch(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    setSuccess(rslt.statusText);
  };

  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_about">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcHeadTxt_generic">Become a Branch</div>
                  </li>
                  <li className="bc_col">
                    <div className="bcRightBlk">Contact Information</div>
                  </li>
                </div>
              </ul>
            </div>

            <div className="widthfix_10px">
              <ul className="IIcol_100by400">
                <li className="IIcol_100by400">
                  <div className="pgTxtBoldNoTopSpace">
                    No more searching online and filling out applications for
                    hours. Just fill this online form and join our ever growing
                    list of Loan Officers.
                  </div>
                  <div className="contactFormNoShadow">
                    <ul className="contactForm_IIcol">
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">Name</div>
                        <div className="contactFormUserIcon"></div>
                        <TextInput
                          tag={"branchName"}
                          path={"branchName"}
                          name={"branchName"}
                          placeholder={"Name"}
                          value={Helper.ToString(row?.branchName)}
                          onInputChildChanged={OnInputChanged}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">Email</div>
                        <div className="contactFormMailIcon"></div>
                        <TextInput
                          tag={"branchEmail"}
                          path={"branchEmail"}
                          name={"branchEmail"}
                          placeholder={"Email"}
                          value={Helper.ToString(row?.branchEmail)}
                          type={"EMAIL"}
                          onInputChildChanged={OnInputChanged}
                          errors={errors}
                          onBlurError={OnBlurError}
                          onInputClicked={OnInputClicked}
                          required={true}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">Phone Number</div>
                        <div className="contactFormPhIcon"></div>
                        <TextInput
                          tag={"branchPhone"}
                          path={"branchPhone"}
                          name={"branchPhone"}
                          placeholder={"Phone Number"}
                          type={"PHONE"}
                          value={Helper.ToString(row?.branchPhone)}
                          onInputChildChanged={OnInputChanged}
                          errors={errors}
                          onBlurError={OnBlurError}
                          onInputClicked={OnInputClicked}
                          required={true}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">NMLS Number</div>
                        <div className="contactFormHastagIcon"></div>
                        <TextInput
                          tag={"branchNMLS"}
                          path={"branchNMLS"}
                          name={"branchNMLS"}
                          placeholder={"NMLS Number"}
                          value={Helper.ToString(row?.branchNMLS)}
                          onInputChildChanged={OnInputChanged}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">State Licensing</div>
                        <div className="contactFormTypeIcon"></div>
                        <DropDown
                          tag={"branchState"}
                          path={"branchState"}
                          name={"branchState"}
                          value={row?.branchState}
                          items={statesList}
                          displayName={"text"}
                          displayValue={"value"}
                          onInputChildChanged={OnInputChanged}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">
                          How you best describe yourself
                        </div>
                        <div className="contactFormTypeIcon"></div>
                        <DropDown
                          tag={"branchBrief"}
                          path={"branchBrief"}
                          name={"branchBrief"}
                          value={row?.branchBrief}
                          items={HowDoYouBrief()}
                          displayName={"text"}
                          displayValue={"text"}
                          onInputChildChanged={OnInputChanged}
                        />
                      </li>
                    </ul>
                    <div className="contactForm_Icol">
                      <div className="contactFormHead">Message</div>
                      <div className="contactFormNoteIcon"></div>
                      <TextInput
                        tag={"branchMessage"}
                        path={"branchMessage"}
                        name={"branchMessage"}
                        placeholder={"Message"}
                        value={Helper.ToString(row?.branchMessage)}
                        onInputChildChanged={OnInputChanged}
                      />
                    </div>
                    <div className="contactForm_btn_label">
                      <input
                        type="button"
                        value="Apply Now"
                        className="contactFormBtn"
                        onClick={(e) => OnBecomBranchClicked(e)}
                      />
                    </div>

                    {error && (
                      <div className="errormessage">
                        {error}
                      </div>
                    )}
                    {success && (
                      <div className="successmessage">
                        Thank you for contacting us. We will get back to you
                        ASAP.
                      </div>
                    )}
                  </div>
                </li>
                <li className="IIcol_100by400">
                  <ContactInfo />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
