import { React, Helper } from "../../../../common";

const Component = (props) => {
    const { item, onInputChanged } = props;
    const rootpath = `declarations`;
    let headStyle = item?.coBorrowerIndicator ? "form1003_declarations_borCobor_head" : "form1003_declarations_bor_head";
    let cssStyle = item?.coBorrowerIndicator ? "form1003_declarations_borCobor" : "form1003_declarations_bor";

    const OnInputLocalChanged = (name, value) => {
        let path = `${rootpath}.${name}`.replace(/\./g, '_');
        if (onInputChanged) onInputChanged(path, value);
    }

    const OnDummyChanged = () => { };

    return (
        <>
            <div className="form1003_declarations">
                <ul className={headStyle}>
                    <li className={headStyle}>About this Property and Your Money for this Loan</li>
                    <li className={headStyle}>Borrower</li>
                    {item?.coBorrowerIndicator && <li className={headStyle}>Co-Borrower</li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Will you occupy the property as your primary residence?</li>
                    <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.borrower?.intentToOccupyIndicator)}
                            onChange={(e) => OnInputLocalChanged('borrower.intentToOccupyIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.intentToOccupyIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.intentToOccupyIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Have you had an ownership interest in another property in the last three years?</li>
                    <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.borrower?.homeownerPastThreeYearsIndicator)}
                            onChange={(e) => OnInputLocalChanged('borrower.homeownerPastThreeYearsIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.homeownerPastThreeYearsIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.homeownerPastThreeYearsIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>What type of property did you own?</li>
                    <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.borrower?.priorPropertyUsageType)}
                            onChange={(e) => OnInputLocalChanged('borrower.priorPropertyUsageType', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="PrimaryResidence">Primary Residence</option>
                            <option value="SecondaryResidence">Second Home</option>
                            <option value="Investment">Investment Property</option>
                            <option value="FHASecondaryResidence">FHA Secondary Residence</option>
                        </select>
                    </li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.priorPropertyUsageType)}
                            onChange={(e) => OnInputLocalChanged('coborrower.priorPropertyUsageType', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="PrimaryResidence">Primary Residence</option>
                            <option value="SecondaryResidence">Second Home</option>
                            <option value="Investment">Investment Property</option>
                            <option value="FHASecondaryResidence">FHA Secondary Residence</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>How did you hold title to the property?</li>
                    <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.borrower?.priorPropertyTitleType)}
                            onChange={(e) => OnInputLocalChanged('borrower.priorPropertyTitleType', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="Sole">Sole Ownership</option>
                            <option value="JointWithSpouse">Joint With Spouse</option>
                            <option value="JointWithOtherThanSpouse">Joint With Other Than Spouse</option>
                        </select>
                    </li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.priorPropertyTitleType)}
                            onChange={(e) => OnInputLocalChanged('coborrower.priorPropertyTitleType', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="Sole">Sole Ownership</option>
                            <option value="JointWithSpouse">Joint With Spouse</option>
                            <option value="JointWithOtherThanSpouse">Joint With Other Than Spouse</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.specialBorrowerSellerRelationshipIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.specialBorrowerSellerRelationshipIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.specialBorrowerSellerRelationshipIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.specialBorrowerSellerRelationshipIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?</li>
                    <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.borrower?.undisclosedBorrowedFundsIndicator)}
                            onChange={(e) => OnInputLocalChanged('borrower.undisclosedBorrowedFundsIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.undisclosedBorrowedFundsIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.undisclosedBorrowedFundsIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                {Helper.ToBool(item?.borrower?.undisclosedBorrowedFundsIndicator) && (
                    <ul className={cssStyle}>
                        <li className={cssStyle}>What is the amount?</li>
                        <li className={cssStyle}>
                            <input type="text"
                                value={Helper.ToString(item?.borrower?.undisclosedBorrowedFundsAmount)}
                                onChange={(e) => OnInputLocalChanged('borrower.undisclosedBorrowedFundsAmount', e.target.value)}
                                onKeyPress={(e) => {
                                    return Helper.AcceptedChars(e, 'FINANCE');
                                }}
                            />
                        </li>
                        {item?.coBorrowerIndicator && Helper.ToBool(item?.coborrower?.undisclosedBorrowedFundsIndicator) && <li className={cssStyle}>
                            <input type="text"
                                value={Helper.ToString(item?.coborrower?.undisclosedBorrowedFundsAmount)}
                                onChange={(e) => OnInputLocalChanged('coborrower.undisclosedBorrowedFundsAmount', e.target.value)}
                                onKeyPress={(e) => {
                                    return Helper.AcceptedChars(e, 'FINANCE');
                                }}
                            />
                        </li>}
                    </ul>
                )}
                <ul className={cssStyle}>
                    <li className={cssStyle}>Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.undisclosedMortgageApplicationIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.undisclosedMortgageApplicationIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.undisclosedMortgageApplicationIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.undisclosedMortgageApplicationIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan thatis not disclosed on this application?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.undisclosedCreditApplicationIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.undisclosedCreditApplicationIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.undisclosedCreditApplicationIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.undisclosedCreditApplicationIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.propertyProposedCleanEnergyLienIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.propertyProposedCleanEnergyLienIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.propertyProposedCleanEnergyLienIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.propertyProposedCleanEnergyLienIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
            </div>
            <div className="form1003_declarations">
                <ul className={headStyle}>
                    <li className={headStyle}>About Your Finance</li>
                    <li className={headStyle}>Borrower</li>
                    {item?.coBorrowerIndicator && <li className={headStyle}>Co-Borrower</li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.undisclosedComakerOfNoteIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.undisclosedComakerOfNoteIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.undisclosedComakerOfNoteIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.undisclosedComakerOfNoteIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Are there any outstanding judgments against you?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.outstandingJudgementsIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.outstandingJudgementsIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.outstandingJudgementsIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.outstandingJudgementsIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Are you currently delinquent or in default on a Federal debt?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.presentlyDelinquentIndicatorURLA)}
                        onChange={(e) => OnInputLocalChanged('borrower.presentlyDelinquentIndicatorURLA', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.presentlyDelinquentIndicatorURLA)}
                            onChange={(e) => OnInputLocalChanged('coborrower.presentlyDelinquentIndicatorURLA', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Are you a party to a lawsuit in which you potentially have any personal financial liability?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.partyToLawsuitIndicatorURLA)}
                        onChange={(e) => OnInputLocalChanged('borrower.partyToLawsuitIndicatorURLA', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.partyToLawsuitIndicatorURLA)}
                            onChange={(e) => OnInputLocalChanged('coborrower.partyToLawsuitIndicatorURLA', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Have you conveyed title to any property in lieu of foreclosure in the past 7 years?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.priorPropertyDeedInLieuConveyedIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.priorPropertyDeedInLieuConveyedIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.priorPropertyDeedInLieuConveyedIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.priorPropertyDeedInLieuConveyedIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?</li>
                    <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.borrower?.priorPropertyShortSaleCompletedIndicator)}
                            onChange={(e) => OnInputLocalChanged('borrower.priorPropertyShortSaleCompletedIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.priorPropertyShortSaleCompletedIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.priorPropertyShortSaleCompletedIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Have you had property foreclosed upon in the last 7 years?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.priorPropertyForeclosureCompletedIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.priorPropertyForeclosureCompletedIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.priorPropertyForeclosureCompletedIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.priorPropertyForeclosureCompletedIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>
                <ul className={cssStyle}>
                    <li className={cssStyle}>Have you declared bankruptcy within the past 7 years?</li>
                    <li className={cssStyle}><select
                        value={Helper.ToString(item?.borrower?.bankruptcyIndicator)}
                        onChange={(e) => OnInputLocalChanged('borrower.bankruptcyIndicator', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option></select></li>
                    {item?.coBorrowerIndicator && <li className={cssStyle}>
                        <select
                            value={Helper.ToString(item?.coborrower?.bankruptcyIndicator)}
                            onChange={(e) => OnInputLocalChanged('coborrower.bankruptcyIndicator', e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </li>}
                </ul>

                {Helper.ToBool(item?.borrower?.bankruptcyIndicator) && (
                    <ul className={cssStyle}>
                        <li className={cssStyle}>Identify the type(s) of bankruptcy</li>
                        <li className={cssStyle}>
                            <ul>
                                <li><input type="checkbox"
                                    id="borrower.bankruptcyIndicatorChapterSeven"
                                    checked={Helper.ToBool(item?.borrower?.bankruptcyIndicatorChapterSeven)}
                                    onChange={() => OnDummyChanged()}
                                    onClick={(e) => OnInputLocalChanged('borrower.bankruptcyIndicatorChapterSeven', e.target.checked)} />
                                    <label htmlFor="borrower.bankruptcyIndicatorChapterSeven">Chapter 7</label></li>
                                <li><input type="checkbox"
                                    id="borrower.bankruptcyIndicatorChapterEleven"
                                    checked={Helper.ToBool(item?.borrower?.bankruptcyIndicatorChapterEleven)}
                                    onChange={() => OnDummyChanged()}
                                    onClick={(e) => OnInputLocalChanged('borrower.bankruptcyIndicatorChapterEleven', e.target.checked)} />
                                    <label htmlFor="borrower.bankruptcyIndicatorChapterEleven">Chapter 11</label></li>
                                <li><input type="checkbox"
                                    id="borrower.bankruptcyIndicatorChapterTwelve"
                                    checked={Helper.ToBool(item?.borrower?.bankruptcyIndicatorChapterTwelve)}
                                    onChange={() => OnDummyChanged()}
                                    onClick={(e) => OnInputLocalChanged('borrower.bankruptcyIndicatorChapterTwelve', e.target.checked)} />
                                    <label htmlFor="borrower.bankruptcyIndicatorChapterTwelve">Chapter 12</label></li>
                                <li><input type="checkbox"
                                    id="borrower.bankruptcyIndicatorChapterThirteen"
                                    checked={Helper.ToBool(item?.borrower?.bankruptcyIndicatorChapterThirteen)}
                                    onChange={() => OnDummyChanged()}
                                    onClick={(e) => OnInputLocalChanged('borrower.bankruptcyIndicatorChapterThirteen', e.target.checked)} />
                                    <label htmlFor="borrower.bankruptcyIndicatorChapterThirteen">Chapter 13</label></li>
                            </ul>
                        </li>
                        {item?.coBorrowerIndicator && Helper.ToBool(item?.coborrower?.bankruptcyIndicator) && <li className={cssStyle}>
                            <ul>
                                <li><input type="checkbox"
                                    id="coborrower.bankruptcyIndicatorChapterSeven"
                                    checked={Helper.ToBool(item?.coborrower?.bankruptcyIndicatorChapterSeven)}
                                    onChange={() => OnDummyChanged()}
                                    onClick={(e) => OnInputLocalChanged('coborrower.bankruptcyIndicatorChapterSeven', e.target.checked)} />
                                    <label htmlFor="coborrower.bankruptcyIndicatorChapterSeven">Chapter 7</label></li>
                                <li><input type="checkbox"
                                    id="coborrower.bankruptcyIndicatorChapterEleven"
                                    checked={Helper.ToBool(item?.coborrower?.bankruptcyIndicatorChapterEleven)}
                                    onChange={() => OnDummyChanged()}
                                    onClick={(e) => OnInputLocalChanged('coborrower.bankruptcyIndicatorChapterEleven', e.target.checked)} />
                                    <label htmlFor="coborrower.bankruptcyIndicatorChapterEleven">Chapter 11</label></li>
                                <li><input type="checkbox"
                                    id="coborrower.bankruptcyIndicatorChapterTwelve"
                                    checked={Helper.ToBool(item?.coborrower?.bankruptcyIndicatorChapterTwelve)}
                                    onChange={() => OnDummyChanged()}
                                    onClick={(e) => OnInputLocalChanged('coborrower.bankruptcyIndicatorChapterTwelve', e.target.checked)} />
                                    <label htmlFor="coborrower.bankruptcyIndicatorChapterTwelve">Chapter 12</label></li>
                                <li><input type="checkbox"
                                    id="coborrower.bankruptcyIndicatorChapterThirteen"
                                    checked={Helper.ToBool(item?.coborrower?.bankruptcyIndicatorChapterThirteen)}
                                    onChange={() => OnDummyChanged()}
                                    onClick={(e) => OnInputLocalChanged('coborrower.bankruptcyIndicatorChapterThirteen', e.target.checked)} />
                                    <label htmlFor="coborrower.bankruptcyIndicatorChapterThirteen">Chapter 13</label></li>
                            </ul>
                        </li>}
                    </ul>
                )}
            </div>
        </>
    )

};

export default Component;