const Component = () => {
    return (
        <div id="info_loanPurpose" className="pop_disable_bg">
            <div className="loanPurpose_pop">
                <div className="pop_head">Loan Purpose</div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Purchase - </div>
                    <div className="pop_content">Select if obtaining a loan for a new property.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">Refinance (Cash Out) - </div>
                    <div className="pop_content">If refinancing your mortgage at a higher amount than the current loan balance so that you can pull out the additional money for personal use.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">Refinance (No Cash Out) - </div>
                    <div className="pop_content">If you are obtaining a new loan that will payoff the an existing loan and use the property as security. With out obtaining cash out.</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_loanPurpose')} />
                </div>
            </div>
        </div>
    );
};

export default Component;