const Component = () => {
    return (
        <div id="info_amortizationType" className="pop_disable_bg">
            <div className="amortization_pop">
                <div className="pop_head">Amortization Type</div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Fixed Rate - </div>
                    <div className="pop_content">A mortgage whose interest rate remains constant throughout the life of the mortgage.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">ARM (Adjustable Rate) - </div>
                    <div className="pop_content">A variable or flexible rate mortgage with an interest rate that varies according to the financial index it is based upon. To limit the borrower's risk, the ARM may have a payment or rate cap.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">GPM (Graduated Payment) - </div>
                    <div className="pop_content">A fixed-interest loan with lower payments in the early years than the later years. The amount of the payment gradually increases over a period of time and then levels off at a payment sufficient to pay off the loan over the remaining amortization period.</div>
                    <div className="spacer_10margin_bottom"></div>
                    <div className="pop_contentHead">Other Loan Payment Type - </div>
                    <div className="pop_content">If your loan doesn't fall into the above types choose other.</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_amortizationType')} />
                </div>
            </div>
        </div>
    );
};

export default Component;