/* import {
    UpdateFileInfo,
    GetSignedUrl,
    UploadToBucket,
    MoveFile,
    UploadToISP,
} from "../services/uploadApi";
 */
import axios from "axios";
import { Helper } from "../common";
import { AddDocuments, GetSignedUrls, MoveDocument } from "../services/documentApi";

var fn = {};

fn.Upload = async (file, obj) => {
  return new Promise(async (resolve) => {
    global.Busy(true);
    let rslt = await GetSignedUrls(obj.req);
    if (rslt.status !== 100) {
      global.Busy(false);
      return resolve(rslt);
    }
    let urlInfo = rslt.data;

    if (!Helper.IsNullValue(urlInfo?.ISPUrl)) {
      rslt = await UploadToISP(urlInfo.ISPUrl, file, obj.ofileName);
      if (rslt.status !== 100) {
        global.Busy(false);
        return resolve(rslt);
      }
    }

    if (!Helper.IsNullValue(urlInfo?.AWSUrl)) {
      rslt = await UploadToBucket(urlInfo.AWSUrl, file, urlInfo.ContentType);
      if (rslt.status !== 100) {
        global.Busy(false);
        return resolve(rslt);
      }
    }

    let pathArray = rslt.data.split("?");
    let urlFile = pathArray[0];

    let req = {};
    if (obj.req.isPersona) {
      req = {
        source: urlFile,
        target: `${obj.req.folder}/${urlInfo.blobName}`,
      };
    } else {
      req = {
        source: urlFile,
        target: `uploads/${obj.loannumber}/${urlInfo.blobName}`,
      };
    }

    rslt = await MoveDocument(req);
    if (rslt.status !== 100) {
      global.Busy(false);
      return resolve(rslt);
    }

    obj.filename = req.target;
    obj.blobname = urlInfo.blobName;

    if (obj.type === "DOC") {
      rslt = await AddDocuments(obj);
      if (rslt.status !== 100) {
        global.Busy(false);
        return resolve(rslt);
      }
    } else {
      rslt = {
        data: {
          filename: req.target,
          blobname: urlInfo.blobName,
        },
      };
    }

    global.Busy(false);
    return resolve({ status: 100, data: rslt.data });
  });
};

const UploadToBucket = async (url, data, type) => {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": type,
    },
  };
  try {
    const res = await axios.put(url, data, headers);
    if (res.status === 200) {
      return { status: 100, data: res.request.responseURL };
    }
    return { statusText: res.statusText, status: 900 };
  } catch (err) {
    console.log(err);
    return { statusText: err.message, status: 900 };
  }
};

const UploadToISP = async (url, file, filename) => {
  return new Promise(async (resolve) => {
    var data = new FormData();
    data.append(filename, file);
    var config = {
      method: "put",
      url: url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-ms-blob-type": "BlockBlob",
      },
      data: data,
    };

    try {
      axios(config)
        .then(function (res) {
          if (res.status === 201)
            return resolve({ statusText: "Created", status: 100 });
          return resolve({ statusText: res.data, status: 900 });
        })
        .catch(function (err) {
          console.log(err);
          return resolve({ statusText: err.message, status: 900 });
        });
    } catch (err) {
      console.log(err);
      return resolve({ statusText: err.message, status: 900 });
    }
  });
};

export default fn;
