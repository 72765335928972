import { React, Helper, TextInput, DropDown } from "../../../../common";
import { ContactInfo } from "../../components";
import Container from "../../../container";
import { LoanTypes } from "../../../../helper/support";
import { ContactUs } from "../../../../services/commonApi";

const Component = () => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState();
  const [success, setSuccess] = React.useState(null);
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setError(null);
    setSuccess(null);
  }, []);

  const OnInputChanged = async (parent, path, name, value) => {
    setError(null);
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const OnContactUsClicked = async (e) => {
    e.preventDefault();

    if (Helper.IsJSONEmpty(row)) {
      setError("You have some errors. Please fix and proceed.");
      return;
    }

    let isInvalid = false;
    let _fields = [
      "contName",
      "contPhone",
      "contEmail",
      "contZip",
      "contCity",
      "contLoanType",
      "contMessage",
    ];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        if (!isInvalid) isInvalid = true;
      }
    });

    if (!isInvalid && !Helper.IsEmailValid(row.contEmail)) {
      if (!isInvalid) isInvalid = true;
    }

    if (isInvalid) {
      setError("You have some errors. Please fix and proceed.");
      return;
    }

    global.Busy(true);
    let rslt = await ContactUs(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    setSuccess(rslt.statusText);
  };

  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_contact">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcBlkDesc">
                      <div className="blkDescTxt">Get In Touch</div>
                      <div className="rightCurve"></div>
                    </div>
                    <div className="bcHeadTxt">Contact Details</div>
                  </li>
                  <li className="bc_col">
                    <div className="bcRightBlk">Contact Information</div>
                  </li>
                </div>
              </ul>
            </div>

            <div className="widthfix_10px">
              <ul className="IIcol_100by400">
                <li className="IIcol_100by400">
                  <div className="pgTxtBold_L">
                    Please drop us a line, we would love to hear from you
                  </div>
                  <div className="pgTxtNormal">
                    One of our loan officers will contact you for a brief
                    consultation to find the best home loan solution for you.
                  </div>
                  <div className="contactFormNoShadow">
                    <ul className="contactForm_IIcol">
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">Name</div>
                        <div className="contactFormUserIcon"></div>
                        <TextInput
                          tag={"contName"}
                          path={"contName"}
                          name={"contName"}
                          placeholder={"Name"}
                          value={Helper.ToString(row?.contName)}
                          onInputChildChanged={OnInputChanged}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">Phone Number</div>
                        <div className="contactFormPhIcon"></div>
                        <TextInput
                          maxLength={14}
                          tag={"contPhone"}
                          path={"contPhone"}
                          name={"contPhone"}
                          placeholder={"Phone Number"}
                          value={Helper.ToString(row?.contPhone)}
                          type={"PHONE"}
                          onInputChildChanged={OnInputChanged}
                          errors={errors}
                          onBlurError={OnBlurError}
                          onInputClicked={OnInputClicked}
                          required={true}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">Email</div>
                        <div className="contactFormMailIcon"></div>
                        <TextInput
                          tag={"contEmail"}
                          path={"contEmail"}
                          name={"contEmail"}
                          placeholder={"Email"}
                          value={Helper.ToString(row?.contEmail)}
                          type={"EMAIL"}
                          onInputChildChanged={OnInputChanged}
                          errors={errors}
                          onBlurError={OnBlurError}
                          onInputClicked={OnInputClicked}
                          required={true}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">Zip Code</div>
                        <div className="contactFormHomeIcon"></div>
                        <TextInput
                          maxLength={10}
                          tag={"contZip"}
                          path={"contZip"}
                          name={"contZip"}
                          placeholder={"Zip Code"}
                          value={Helper.ToString(row?.contZip)}
                          type={"ZIP"}
                          errors={errors}
                          onBlurError={OnBlurError}
                          onInputChildChanged={OnInputChanged}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">City</div>
                        <div className="contactFormLocationIcon"></div>
                        <TextInput
                          tag={"contCity"}
                          path={"contCity"}
                          name={"contCity"}
                          placeholder={"City"}
                          value={Helper.ToString(row?.contCity)}
                          onInputChildChanged={OnInputChanged}
                        />
                      </li>
                      <li className="contactForm_IIcol">
                        <div className="contactFormHead">Loan Type</div>
                        <div className="contactFormTypeIcon"></div>
                        <DropDown
                          tag={"contLoanType"}
                          path={"contLoanType"}
                          name={"contLoanType"}
                          value={row?.contLoanType}
                          items={LoanTypes("Loan Type")}
                          displayName={"text"}
                          displayValue={"text"}
                          onInputChildChanged={OnInputChanged}
                        />
                      </li>
                    </ul>
                    <div className="contactForm_Icol">
                      <div className="contactFormHead">Message</div>
                      <div className="contactFormNoteIcon"></div>
                      <TextInput
                        tag={"contMessage"}
                        path={"contMessage"}
                        name={"contMessage"}
                        placeholder={"Message"}
                        value={Helper.ToString(row?.contMessage)}
                        onInputChildChanged={OnInputChanged}
                      />
                    </div>
                    <div className="contactForm_btn_label">
                      <input
                        type="button"
                        value="Reach Us"
                        className="contactFormBtn"
                        onClick={(e) => OnContactUsClicked(e)}
                      />
                    </div>
                    {error && (
                      <div className="errormessage">
                        {error}
                      </div>
                    )}
                    {success && (
                      <div
                        className="successmessage"
                      >
                        Thank you for contacting us. We will get back to you
                        ASAP.
                      </div>
                    )}
                  </div>
                </li>
                <li className="IIcol_100by400">
                  <ContactInfo />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
