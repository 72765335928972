import { React, Link, Helper } from "../../../../common";
import {
  DemograpicCheckInput,
  DemograpicTextInput,
  DemograpicOptionInput,
  DemograpicRadioInput,
} from "./components";

const RendorDemoGraph = (props) => {
  const { item, tag, title, path, onHelpClicked, onInputChanged, errors } = props;

  const routePath = `${path}.${tag}`;

  const OnHelpClicked = (e) => {
    e.preventDefault();
    if (onHelpClicked) onHelpClicked(1, title);
  };

  const GetValue = (e) => {
    let _item = item || {};
    if (!Helper.IsNullValue(_item[e])) return _item[e];
    return undefined;
  }

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">Demographic Information of {title}</div>
        <div className="formTipHolder">
          <Link onClick={(e) => OnHelpClicked(e)} to="#" title="Info">
            <div className="formTip"></div>
          </Link>
        </div>
      </div>
      <div className="form1003ContentBlk">
        <div className="spacer_20margin_bottom">
          <div className="form1003ContentHead">Ethnicity</div>
          <div className="formMandatory">*</div>
          <ul className="form_A">
            <DemograpicOptionInput
              errors={errors}
              errorId={`${tag}EthnicityIndicator`}
              tag={tag}
              path={path}
              item={item}
              options={Helper.EthnicityOpts()}
              onInputOptionChanged={onInputChanged}
            />
          </ul>
        </div>
        <ul className="formContentIIcol_20by80">
          {Helper.EthnicitiesList()
            .filter((z) => z.type !== 1)
            .map((x) => {
              return (
                <li className="formContentIIcol_20by80" key={x.id}>
                  <div className="formCheckboxLabel">
                    <DemograpicCheckInput
                      path={`${routePath}.${x.name}`}
                      content={x.label}
                      value={GetValue(x.name)}
                      onInputOptionChanged={onInputChanged}
                    />
                  </div>
                </li>
              );
            })}
        </ul>
        <ul className="formContentIIcol">
          {Helper.EthnicitiesList()
            .filter((z) => z.type === 1)
            .map((x) => {
              return (
                <span key={x.id}>
                  <li className="formContentIIcol">
                    <div className="formCheckboxLabel">
                      <DemograpicTextInput
                        path={`${routePath}.${x.name}`}
                        value={GetValue(x.name)}
                        onInputTextChanged={onInputChanged}
                      />
                    </div>
                  </li>
                  <li className="formContentIIcol">
                    <div className="form1003NoteText">
                      Eg: Argentinean, Colombian, Dominican, Nicaraguan,
                      Salvadoran, Spaniard, and so on.
                    </div>
                  </li>
                </span>
              );
            })}
        </ul>

        <div className="blkInsidePartition"></div>
        <div className="spacer_20margin_bottom">
          <div className="form1003ContentHead">Race</div>
          <div className="formMandatory">*</div>
          <div className="spacer_20margin_bottom">
            <div className="formCheckboxLabel">
              <DemograpicCheckInput
                path={`${routePath}.hmdaAmericanIndianIndicator`}
                content={"American Indian or Alaska Native"}
                value={GetValue("hmdaAmericanIndianIndicator")}
                onInputOptionChanged={onInputChanged}
              />
            </div>
          </div>
          <ul className="formContentIIcol_20by80">
            <li className="formContentIIcol_20by80">&nbsp;</li>
            <li className="formContentIIcol_20by80">
              <div className="spacer_5margin_bottom">
                <div className="textPad10">
                  <DemograpicTextInput
                    path={`${routePath}.hmdaAmericanIndianTribe`}
                    value={GetValue('hmdaAmericanIndianTribe')}
                    onInputTextChanged={onInputChanged}
                  />
                </div>
              </div>
              <div className="spacer_5margin_bottom">
                <div className="form1003NoteText">
                  Print name of enrolled or principal tribe
                </div>
              </div>
            </li>
          </ul>

          <div className="spacer_20margin_bottom">
            <div className="formCheckboxLabel">
              <DemograpicCheckInput
                path={`${routePath}.hmdaAsianIndicator`}
                content={"Asian"}
                value={GetValue("hmdaAsianIndicator")}
                onInputOptionChanged={onInputChanged}
              />
            </div>
          </div>

          <ul className="formContentIIcol_20by80">
            <li className="formContentIIcol_20by80">&nbsp;</li>
            <li className="formContentIIcol_20by80">
              <ul className="formContentIIcol">
                {Helper.AsiansList()
                  .filter((z) => z.type !== 1)
                  .map((x) => {
                    return (
                      <li className="formContentIIcol" key={x.id}>
                        <div className="formCheckboxLabel">
                          <DemograpicCheckInput
                            path={`${routePath}.${x.name}`}
                            content={x.label}
                            value={GetValue(x.name)}
                            onInputOptionChanged={onInputChanged}
                          />
                        </div>
                      </li>
                    );
                  })}
              </ul>
              {Helper.AsiansList()
                .filter((z) => z.type === 1)
                .map((x) => {
                  return (
                    <span key={x.id}>
                      <div className="spacer_5margin_bottom">
                        <div className="textPad10">
                          <DemograpicTextInput
                            path={`${routePath}.${x.name}`}
                            value={GetValue(x.name)}
                            onInputTextChanged={onInputChanged}
                          />
                        </div>
                      </div>
                      <div className="spacer_5margin_bottom">
                        <div className="form1003NoteText">
                          Eg: Hmong, Laotian, Thai, Pakistani, Cambodian, and so
                          on.
                        </div>
                      </div>
                    </span>
                  );
                })}
            </li>
          </ul>

          <div className="spacer_20margin_bottom">
            <div className="formCheckboxLabel">
              <DemograpicCheckInput
                path={`${routePath}.hmdaAfricanAmericanIndicator`}
                content={"Black or African American"}
                value={GetValue("hmdaAfricanAmericanIndicator")}
                onInputOptionChanged={onInputChanged}
              />
            </div>
          </div>
          <div className="spacer_20margin_bottom">
            <div className="formCheckboxLabel">
              <DemograpicCheckInput
                path={`${routePath}.hmdaPacificIslanderIndicator`}
                content={"Native Hawaiian or Other Pacific Islander"}
                value={GetValue("hmdaPacificIslanderIndicator")}
                onInputOptionChanged={onInputChanged}
              />
            </div>
          </div>

          <ul className="formContentIIcol_20by80">
            <li className="formContentIIcol_20by80">&nbsp;</li>
            <li className="formContentIIcol_20by80">
              <ul className="formContentIIcol">
                {Helper.PacificIslanders()
                  .filter((z) => z.type !== 1)
                  .map((x) => {
                    return (
                      <li className="formContentIIcol" key={x.id}>
                        <div className="formCheckboxLabel">
                          <DemograpicCheckInput
                            path={`${routePath}.${x.name}`}
                            content={x.label}
                            value={GetValue(x.name)}
                            onInputOptionChanged={onInputChanged}
                          />
                        </div>
                      </li>
                    );
                  })}
              </ul>
              {Helper.PacificIslanders()
                .filter((z) => z.type === 1)
                .map((x) => {
                  return (
                    <span key={x.id}>
                      <div className="spacer_5margin_bottom">
                        <div className="textPad10">
                          <DemograpicTextInput
                            path={`${routePath}.${x.name}`}
                            value={GetValue(x.name)}
                            onInputTextChanged={onInputChanged}
                          />
                        </div>
                      </div>
                      <div className="spacer_5margin_bottom">
                        <div className="form1003NoteText">
                          Eg: Fijian, Tongan, and so on.
                        </div>
                      </div>
                    </span>
                  );
                })}
            </li>
          </ul>

          <div className="spacer_20margin_bottom">
            <div className="formCheckboxLabel">
              <DemograpicCheckInput
                path={`${routePath}.hmdaWhiteIndicator`}
                content={"White"}
                value={GetValue("hmdaWhiteIndicator")}
                onInputOptionChanged={onInputChanged}
              />
            </div>
          </div>

          <div className="spacer_20margin_bottom">
            <div className="formCheckboxLabel">
              <DemograpicCheckInput
                path={`${routePath}.hmdaRaceDoNotWishProvideIndicator`}
                content={"I do not wish to provide this information"}
                value={GetValue("hmdaRaceDoNotWishProvideIndicator")}
                onInputOptionChanged={onInputChanged}
              />
            </div>
          </div>
        </div>
        <div className="blkInsidePartition"></div>
        <ul className="formContentIIcol_20by80">
          <li className="formContentIIcol_20by80">
            <div className="form1003ContentHead">Sex</div>
            <div className="formMandatory">*</div>
          </li>
          <li className="formContentIIcol_20by80">
            <DemograpicRadioInput
              tag={tag}
              path={path}
              item={item}
              options={Helper.GenderTypesList()}
              onInputOptionChanged={onInputChanged}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

const Component = (props) => {
  const { item, onInputChanged, onHelpClicked, onBlurError, onInputClicked, errors } = props;

  const rootpath = `demographicInfo`;

  let clsStyle = item?.coBorrowerIndicator ? "form1003IIcol" : "form1003Icol";
  return (
    <ul className={clsStyle}>
      <li className={clsStyle}>
        <RendorDemoGraph
          item={item?.borrower}
          title={"Borrower"}
          tag={"borrower"}
          path={rootpath}
          onHelpClicked={onHelpClicked}
          onInputChanged={onInputChanged}
          onBlurError={onBlurError}
          onInputClicked={onInputClicked}
          errors={errors}
        />
      </li>
      {item?.coBorrowerIndicator && (
        <li className={clsStyle}>
          <RendorDemoGraph
            item={item?.coborrower}
            title={"Co-Borrower"}
            tag={"coborrower"}
            path={rootpath}
            onHelpClicked={onHelpClicked}
            onInputChanged={onInputChanged}
            onBlurError={onBlurError}
            onInputClicked={onInputClicked}
            errors={errors}
          />
        </li>)}
    </ul>
  );

};

export default Component;
