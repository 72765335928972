import { React } from "../../common";
import Container from "../container";
import Authorization from "../popups/authorize";

import {
  AppStatus,
  Form1003,
  Notifications,
  PreApproval,
  Requests,
} from "./childs";

const Component = () => {
  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <div className="dashboard_pgBlk">
                {/*Application Status*/}
                <AppStatus />

                <ul className="appPgStructure_60by40">
                  <li className="appPgStructure_60by40">
                    {/*Request*/}
                    <Requests />
                  </li>

                  <li className="appPgStructure_60by40">
                    {/*Notifications*/}
                    <Notifications />
                  </li>
                </ul>

                <ul className="appPgStructure_IIcol">
                  <li className="appPgStructure_IIcol">
                    {/*PreApproval*/}
                    <PreApproval />
                  </li>
                  <li className="appPgStructure_IIcol">
                    {/*Form1003*/}
                    <Form1003 />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Authorization />
    </>
  );
};
export default Component;
