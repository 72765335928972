const Component = ({ helpType }) => {
    return (
        <>
            <div id="info_MilitaryServiceInfo" className="pop_disable_bg">
                <div className="coApplicant_pop">
                    <div className="pop_head">Military Service Info</div>
                    <div className="pop_contentArea">
                        <div className="pop_contentHead">
                            Please fill a new military service info for this {helpType}.
                        </div>
                    </div>
                    <div className="pop_buttonLabel">
                        <input
                            type="button"
                            value="Close"
                            className="pop_button"
                            onClick={() => window.hide("info_MilitaryServiceInfo")}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Component;