import { React, Helper } from "../../../common";
import { GetLoans } from "../../../services/dashboardApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [selectedLoanId, setSelectedLoanId] = React.useState(0);
  const [loanData, setLoanData] = React.useState({});
  const [loansList, setLoansList] = React.useState([]);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  React.useEffect(() => {
    let _item = loansList.filter((x) => x._id === selectedLoanId);
    if (_item && _item.length > 0) _item = _item[0];
    setLoanData(_item);
  }, [loansList, selectedLoanId]);

  const FetchResults = async () => {
    global.Busy(true);
    setLoansList([]);
    let rslt = await GetLoans(1);
    if (rslt.status === 100) {
      let tmp = rslt.data || [];
      if (tmp.length > 0) setSelectedLoanId(tmp[0]._id);
      setLoansList(tmp);
    }
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnLoanSelected = (loanid) => {
    setSelectedLoanId(loanid);
  };

  if (loansList && loansList.length > 0) {
    return (
      <div className="app_box_shadow">
        <div className="app_headBlk">
          <div className="app_headBlk_blkName_status">Application Status -</div>
          <div className="selectAppNo">
            <select
              name="filterType"
              value={Helper.ToString(selectedLoanId)}
              onChange={(e) => OnLoanSelected(e.target.value)}
            >
              {loansList &&
                loansList.length > 0 &&
                loansList.map((x) => {
                  return (
                    <option key={x._id} value={x._id}>
                      {x.loanNumber}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        {!Helper.IsJSONEmpty(loanData) && (
          <>
            <ul className="statusSummary">
              <li className="statusSummary">Borrower Name:</li>
              <li className="statusSummary" title={loanData?.borrower}>{loanData?.borrower}</li>
              <li className="statusSummary">Address:</li>
              <li
                className="statusSummary"
                title={Helper.ToString(loanData.address)}
              >
                {Helper.ToString(loanData.address)}
              </li>
              <li className="statusSummary">Submitted On:</li>
              <li className="statusSummary">
                {Helper.ToDateFormat(loanData?.dateOn, "yyyy-MM-DD HH:mm")}
              </li>
            </ul>
            <ul className="loanStatus">
              <li className="loanStatus success">
                <div className="statusnumber successNo">&#x2713;</div>
                <div className="statusTxt">Started</div>
              </li>
              <li className="loanStatus success">
                <div className="statusnumber successNo">&#x2713;</div>
                <div className="statusTxt">Registration</div>
              </li>
              <li className="loanStatus current">
                <div className="statusnumber active">3</div>
                <div className="statusTxt">Rate Lock</div>
              </li>
              <li className="loanStatus">
                <div className="statusnumber">4</div>
                <div className="statusTxt">Disclosure</div>
              </li>
              <li className="loanStatus">
                <div className="statusnumber">5</div>
                <div className="statusTxt">Submission</div>
              </li>
              <li className="loanStatus">
                <div className="statusnumber">6</div>
                <div className="statusTxt">Approval</div>
              </li>
              <li className="loanStatus">
                <div className="statusnumber">7</div>
                <div className="statusTxt">Processing</div>
              </li>
              <li className="loanStatus">
                <div className="statusnumber">8</div>
                <div className="statusTxt">Closing</div>
              </li>
            </ul>
          </>
        )}
      </div>
    )
  }
  return null;
};

export default Component;
