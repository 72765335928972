import React from "react";

const Component = (props) => {
    const { tag, path, item, options, onInputOptionChanged } = props;

    const OnCheckClicked = (e) => {
        let values = options.map((x) => {
            let _path = `${path}.${tag}.${x.name}`.replace(/\./g, '_');
            let checked = x.name === e.name ? true : false;
            return { name: _path, value: checked };
        });
        values.forEach(elm => {
            if (onInputOptionChanged) onInputOptionChanged(elm.name, elm.value);
        });
    }


    const OnDummyCheckedChanged = () => { };

    const GetValue = (e) => {
        return (item && item[e]) || undefined;
    }

    const GetKeyName = (e) => {
        return `${path}.${tag}.${e}`.replace(/\./g, '_');
    }

    return (
        <>
            {options && options.map((x, i) => {
                return (
                    <div key={i} className="spacer_10margin_bottom">
                        <div className="formCheckboxLabel">

                            <input
                                type="radio"
                                name={`${GetKeyName(x.name)}`}
                                id={`${GetKeyName(x.name)}`}
                                checked={GetValue(x.name) ? true : false}
                                onClick={(e) => OnCheckClicked(x)}
                                onChange={(e) => OnDummyCheckedChanged(e)}
                            />
                            <label htmlFor={`${GetKeyName(x.name)}`}>{x.label}</label>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default Component;
