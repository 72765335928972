import { React, Helper, TextInput2 as TextInput, RadioInput2 as RadioInput, DropDown2 as DropDown } from "../../../../common";

const RenderDependencyList = (props) => {
  // dependentsAgesDescription
  const { item, path, errors, onInputChanged, onBlurError, onInputClicked } = props;

  const [ages, setAges] = React.useState([]);

  React.useEffect(() => {
    let _ages = item.dependentsAgesDescription || "";
    let tmp = _ages.split(",");
    setAges(tmp);

  }, [item.dependentsAgesDescription])

  const AddNewAgeItem = (e) => {
    e.preventDefault();
    let _ages = ages;
    _ages.push("");
    if (onInputChanged) onInputChanged(`${path}.dependentsAgesDescription`, _ages.join(","));
  };

  const RemoveNewAgeItem = (e) => {
    let _ages = ages;
    if (_ages.length > e) _ages.splice(e, 1);
    if (onInputChanged) onInputChanged(`${path}.dependentsAgesDescription`, _ages.join(","));
  };

  const OnInputAgeChanged = (path, value, index) => {
    let _ages = ages;
    _ages[index] = value;
    if (onInputChanged) onInputChanged(path, _ages.join(","));
  };

  return (
    <>
      {ages.map((item, index) => {
        return (
          <li className="formContentIIcol" key={index} >
            <div className="form1003ContentHead">Dependent Age</div>
            {index === ages.length - 1 &&
              <div className="form1003ContentHead_right" keyid={index}>
                <input
                  type="button"
                  defaultValue="&#65291; Add"
                  className="contentHeadRight_button"
                  onClick={(e) => AddNewAgeItem(e)}
                />
              </div>
            }

            {ages.length > 1 && (
              <div className="form1003ContentHead_right">
                <input
                  type="button"
                  defaultValue="&#8722; Remove"
                  className="contentHeadRight_button"
                  onClick={(e) => RemoveNewAgeItem(index)}
                />
              </div>
            )}
            <div className="timeicon"></div>
            <TextInput
              errors={errors}
              path={`${path}.dependentsAgesDescription`}
              index={index}
              value={Helper.ToString(item)}
              valuetype={'NFINANCE'}
              onInputChildChanged={OnInputAgeChanged}
              placeholder="Dependents Age"
              requiredMessage={'Dependents Age is required'}
              dataRequired={true}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
            />
          </li>
        )
      })}
    </>
  );
}

const Component = (props) => {
  const { item, maritalStatusTypes, citizenshiptypes,
    onInputChanged, errors, noheaders, onBlurError, onInputClicked } = props;

  const rootpath = `applicant`;

  const IsDependentIndicator = () => {
    return Helper.ToUndefinedCheck(Helper.GetJsonValue(item, `dependentIndicator`));
  }

  const GetHeader = () => {
    return item?.type === 0 ? "Borrower" : "Co-Borrower";
  }

  return (
    <>
      {!noheaders && (
        <div className="form1003BlkHead">
          <div className="formHeadContent">{GetHeader()} Information</div>
          <div className="form1003Blk_right">&nbsp;</div>
        </div>
      )}
      <div className={noheaders ? undefined : "form1003ContentBlk"}>
        <ul className="formContentIIcol">
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Social Security Number</div>
            <div className="lockicon"></div>
            <TextInput
              errors={errors}
              path={`${rootpath}.taxIdentificationIdentifier`}
              value={Helper.GetJsonValue(item, `taxIdentificationIdentifier`)}
              valuetype={'SSN'}
              maxLength={11}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
              validationMessage={'Enter valid SSN Number'}
              placeholder={'Social Security Number'}
              validate={true} />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Date of Birth</div>
            <div className="form_dateicon"></div>
            <TextInput
              path={`${rootpath}.birthDate`}
              value={Helper.ToDate(Helper.GetJsonValue(item, `birthDate`), 'MM/DD/YYYY')}
              maxLength={10}
              placeholder="MM/DD/YYYY"
              valuetype={'DOB'}
              validate={true}
              validationMessage={'Enter valid Date of Birth'}
              errors={errors}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Marital Status</div>
            <div className="formMandatory">*</div>
            <div className="matitalicon"></div>
            <DropDown
              path={`${rootpath}.maritalStatusType`}
              value={Helper.GetJsonValue(item, `maritalStatusType`)}
              items={maritalStatusTypes}
              displayName={"text"}
              displayValue={"value"}
              errors={errors}
              onInputChildChanged={onInputChanged}
              onInputClicked={onInputClicked}
              dataRequired={true}
              requiredMessage={'Marital Status is required'}
              indexValue={1}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Do you have dependents?</div>
            <div className="formMandatory">*</div>
            <ul className="form_A" data-opts={true} data-req-msg={"Do you have dependents?"} data-id={`${rootpath}.dependentIndicator`}>
              <RadioInput
                path={`${rootpath}.dependentIndicator`}
                value={Helper.ToUndefinedCheck(Helper.GetJsonValue(item, `dependentIndicator`))}
                options={Helper.Options()}
                errors={errors}
                uselabel={false}
                onInputChildChanged={onInputChanged} />
            </ul>
          </li>
          {IsDependentIndicator() && (
            <RenderDependencyList
              item={item}
              path={rootpath}
              errors={errors}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
              onInputChanged={onInputChanged} />
          )}
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Citizenship</div>
            <div className="usericon"></div>
            <DropDown
              errors={errors}
              path={`${rootpath}.urla2020CitizenshipResidencyType`}
              value={Helper.GetJsonValue(item, `urla2020CitizenshipResidencyType`)}
              items={citizenshiptypes}
              displayName={"text"}
              displayValue={"value"}
              onInputChildChanged={onInputChanged}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Component;
