import { Link } from "react-router-dom";

const Component = () => {
  return (
    <div className="paymentBlk">
      <div className="widthfix_10px">
        <ul className="paymentIIcol">
          <li className="paymentIIcol">
            <div className="payment_blkHeadTxt">Calculate Payments</div>
            <div className="payment_blkDescTxt">
              See how different loan amounts and interest rates affect your
              monthly payments
            </div>
          </li>
          <li className="paymentIIcol">
            <ul className="paymentPoints">
              <li className="paymentPoints"><Link to="/mortgagecalculator" className="paymentLink">Mortgage Calculator</Link></li>
              <li className="paymentPoints"><Link to="/incometoqualify" className="paymentLink">How much income to qualify?</Link></li>
              <li className="paymentPoints"><Link to="/refinancecalculator" className="paymentLink">Refinance Calculator</Link></li>
              <li className="paymentPoints"><Link to="/affordability" className="paymentLink">How much can I afford?</Link></li>
              <li className="paymentPoints"><Link to="/principalcalculator" className="paymentLink">Principal Calculator</Link></li>
              <li className="paymentPoints"><Link to="/taxbenefit" className="paymentLink">Tax benefits of buying</Link></li>
              <li className="paymentPoints"><Link to="/extrapayment" className="paymentLink">Extra Payment Calculator</Link></li>
              <li className="paymentPoints"><Link to="/apr" className="paymentLink">What's my APR?</Link></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Component;
