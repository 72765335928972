import { React, Storage, Helper, RadioInput } from "../../../common";
import { Processes, PropertyPlan, HelpItems } from "../../../helper/support";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";

const Component = ({ onClick }) => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [stage, setStage] = React.useState(0);
  const [state, setState] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  if (initialized) {
    setInitialized(false);
    setErrors([]);
    let data = Storage.RetrieveJSON("stages");
    setRow(data.stage1 || {});
    setStage(1);
  }

  const OnInputLabelChanged = async (parent, path, name, value) => {
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnInputChanged = async (parent, path, name, value) => {
    OnInputLabelChanged(parent, path, name, value);
    GetIndicatorText(parent, path, name, value);
  };

  const GetIndicatorText = async (parent, path, name, value) => {
    let titlename = undefined;
    let title = undefined;
    switch (name) {
      case "processIndicator":
        titlename = "processIndicatorText";
        title = Processes().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      case "propertyPlanIndicator":
        titlename = "propertyPlanIndicatorText";
        title = PropertyPlan().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      case "helpItemIndicator":
        titlename = "helpItemIndicatorText";
        title = HelpItems().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      default:
        titlename = undefined;
        title = undefined;
        break;
    }
    OnInputLabelChanged(parent, titlename, titlename, title);
  };

  const OnNavClicked = async (e) => {
    setError(null);
    setErrors([]);

    let _fields = [
      "helpItemIndicator",
      "propertyPlanIndicator",
      "processIndicator",
    ];

    let _errs = [];
    _fields.forEach((elm) => {
      _errs.push({ field: elm });
    });

    if (Helper.IsJSONEmpty(row)) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    _errs = [];
    let isInvalid = false;

    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        isInvalid = true;
        _errs.push({ field: elm });
      }
    });

    if (isInvalid) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let data = Storage.RetrieveJSON("stages");
    data["stage1"] = row;
    data["stage"] = e;

    Storage.StoreJSON("stages", data);
    if (onClick) onClick(e);
  };

  return (
    <>
      <RenderBreadCrumb stage={1} row={row} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead">
            Complete five easy steps to get pre-approved
          </li>
          <li className="countBlk">
            <div className="countNo">1/5</div>
          </li>
        </ul>
        <div className="purchase">
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">Where in the process are you?</div>
            {/* use className="pur_Q_error" for error submission */}
            <ul className="pur_A">
              <RadioInput
                options={Processes()}
                path={`processIndicator`}
                tag={`processIndicator`}
                name={"processIndicator"}
                uselabel={false}
                value={Helper.ToInteger(row?.processIndicator)}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">
              When do you plan on purchasing your property?
            </div>
            <ul className="pur_A">
              <RadioInput
                options={PropertyPlan()}
                path={`propertyPlanIndicator`}
                tag={`propertyPlanIndicator`}
                name={"propertyPlanIndicator"}
                uselabel={false}
                value={Helper.ToInteger(row?.propertyPlanIndicator)}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">How can we help?</div>
            <ul className="pur_A">
              <RadioInput
                options={HelpItems()}
                path={`helpItemIndicator`}
                tag={`helpItemIndicator`}
                name={"helpItemIndicator"}
                uselabel={false}
                value={Helper.ToInteger(row?.helpItemIndicator)}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              disabled
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked(stage + 1)}
            />
          </div>
          {error && (
            <div className="errormessage">All fields are mandatory</div>
          )}
        </div>
      </li>
    </>
  );
};

export default Component;
