import { React, Link, Helper, ViewPdf, BreadCrumb } from "../../../common";
import LoanSummary from "../loansummary";
import Container from "../../container";
import {
  GetDocuments,
  SetStatus,
  GetDocumentUrl,
} from "../../../services/documentApi";

const Component = () => {
  const [search, setSearch] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [noResults, setNoResults] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [pdfStream, setPdfStream] = React.useState(null);
  const [viewPdf, setViewPdf] = React.useState(false);
  const [viewType, setViewType] = React.useState(null);

  const OnLoanSelected = (e) => {
    setSearch(e);
    FetchLoans(e);
  };

  const FetchLoans = async (e) => {
    setError(null);
    setRows([]);
    setNoResults(null);
    if (Helper.ToInteger(e?.id) < 1) {
      setNoResults("Select loan to view the documents");
    } else {
      global.Busy(true);
      let rslt = await GetDocuments(e.id, -100);
      global.Busy(false);
      if (rslt.status === 100) {
        setRows(rslt.data);
        if (rslt.data.length === 0) {
          setRows([]);
          setNoResults(`No matcing results found`);
        }
      } else {
        setNoResults(rslt.statusText);
      }
    }
  };

  const SetDocActiveStatus = async (item, statusid) => {
    setError(null);
    item.is_active = statusid === 1 ? true : false;
    let data = {
      loan_id: item.loan_id,
      stage_name: item.doctype,
      documentName: item.docname,
      version: item.version,
      is_active: item.is_active,
      lender_id: item.lender_id,
      borrower_id: item.borrower_id,
    };

    global.Busy(true);
    let rslt = await SetStatus(data);
    global.Busy(false);
    if (rslt.status !== 100) {
      item.is_active = statusid === 1 ? false : true;
      setError(rslt.statusText);
      return;
    }

    await FetchLoans(search);
  };

  const OnLinkOpen = async (e, type) => {
    let data = {
      doc: e,
      type: type,
    };
    setViewType(type);
    global.Busy(true);
    let rslt = await GetDocumentUrl(data);
    global.Busy(false);
    if (rslt.status !== 100) {
      alert(rslt.statusText);
      return;
    }
    setPdfStream(rslt.data);
    setViewPdf(true);
  };

  const RenderDocActiveStatus = ({ item }) => {
    let isActive = item.is_active;
    if (isActive) {
      return (
        <Link to="#" title="Enable" onClick={() => SetDocActiveStatus(item, 0)}>
          <div className="enableicon"></div>
        </Link>
      );
    } else {
      return (
        <Link
          to="#"
          title="Disable"
          onClick={() => SetDocActiveStatus(item, 1)}
        >
          <div className="disableicon"></div>
        </Link>
      );
    }
  };

  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <BreadCrumb home="Documents" title="View Documents" />
              <div className="document_pgBlk">
                <div className="appPgStructure_Icol">
                  <LoanSummary
                    view={true}
                    onLoanSelected={OnLoanSelected}
                    setSearch={setSearch}
                  />
                </div>
                <div className="appPgStructure_Icol">
                  <div className="app_box_shadow_noPad">
                    <div className="app_headBlk_pad20">
                      <div className="app_headBlk_blkName">
                        View Documents for Loan No: {search?.loannumber}
                      </div>
                    </div>
                    <div className="appContentarea">
                      {noResults && (
                        <div className="noContent">{noResults}</div>
                      )}
                      {rows && rows.length > 0 && (
                        <>
                          <div className="viewISPTable_head">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Sl.</td>
                                  <td>Document Type</td>
                                  <td>Document Name</td>
                                  <td>Document Info</td>
                                  <td>Ver</td>
                                  <td>Uploaded By</td>
                                  <td>Date &amp; Time</td>
                                  <td>ISP</td>
                                  <td>Aura</td>
                                  {/* <td>Action</td> */}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="viewISPTable">
                            <table>
                              <tbody>
                                {rows &&
                                  rows.map((x, index) => {
                                    return (
                                      <tr key={x.keyIndex}>
                                        <td>{x.keyIndex}</td>
                                        <td>{x.doctype}</td>
                                        <td>{x.docname}</td>
                                        <td>{x.docinfo}</td>
                                        <td>{x.version}</td>
                                        <td>
                                          {Helper.GetStringJoin(x, [
                                            "first_name",
                                            "last_name",
                                          ])}
                                        </td>
                                        <td>
                                          {Helper.ToDate(
                                            x.createdt,
                                            "YYYY-MM-DD HH:mm"
                                          )}
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            onClick={(e) =>
                                              OnLinkOpen(x.blobname, "ISP")
                                            }
                                            title="PDF"
                                          >
                                            <div className="pdficon"></div>
                                          </Link>
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            onClick={(e) =>
                                              OnLinkOpen(x.blobname, "AWS")
                                            }
                                            title="PDF"
                                          >
                                            <div className="pdficon"></div>
                                          </Link>
                                        </td>
                                        {/* <td>
                                          <RenderDocActiveStatus item={x} />
                                        </td> */}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {error && <div className="errormessage">{error}</div>}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ViewPdf
        stream={pdfStream}
        setViewPdf={setViewPdf}
        viewPdf={viewPdf}
        viewType={viewType}
      />
    </>
  );
};

export default Component;
