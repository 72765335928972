import { React, Storage } from "../../../common";
import ficoscore from "../../../content/img/heroImg_statistics.jpg";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";

const Component = ({ onClick }) => {
  const [stage, setStage] = React.useState(0);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  if (initialized) {
    setInitialized(false);
    setStage(8);
  }

  const OnNavClicked = async (type, e) => {
    if (type === "prev") {
      if (onClick) onClick(e);
      return;
    }

    let data = Storage.RetrieveJSON("stages");
    data["stage"] = e;
    Storage.StoreJSON("stages", data);
    if (onClick) onClick(e);
  };
  return (
    <>
      <RenderBreadCrumb stage={8} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead_100pc">
            Just a few more steps until you are pre-approved
          </li>
        </ul>
        <div className="purchase">
          <div className="fico_head">Your FICO&trade; Score is 710</div>
          <div className="spacer_25margin_bottom">
            <img src={ficoscore} alt="FICO Score" />
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              onClick={(e) => OnNavClicked('prev', stage - 1)}
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked('next', stage + 1)}
            />
          </div>
        </div>
      </li>
    </>
  );
};

export default Component;
