import { React } from "../../../../../common";
import Container from "../../../../container";
import TabNavigations from "../tablist";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Mortgage Tools</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt_topTxt_noRight">Refinance Calculator</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="calcPgStructure">
                                <li className="calcPgStructure">
                                    <TabNavigations type="refinancecalculator" />
                                </li>
                                <li className="calcPgStructure">
                                    <div className="comingSoonText">Coming soon</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;