import { React, Link, Helper } from "../../../common";
import { GetLoans } from "../../../services/dashboardApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [loansList, setLoansList] = React.useState([]);
  const [filterType, setFilterType] = React.useState(0);
  const [loanFilterList, setLoanFilterList] = React.useState([]);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    global.Busy(true);
    setLoansList([]);
    let rslt = await GetLoans(0);
    if (rslt.status === 100) {
      let tmp = rslt.data || [];
      setLoansList(tmp);
      setLoanFilterList(tmp);
    }
    global.Busy(false);
  };

  React.useEffect(() => {
    let _item = loansList;
    if (parseInt(filterType) === -1) {
      _item = loansList.filter((x) => x.statusIndicator !== 3);
    } else if (parseInt(filterType) === 3) {
      _item = loansList.filter((x) => x.statusIndicator === 3);
    }
    setLoanFilterList(_item);
  }, [loansList, filterType]);

  if (initlized) {
    setFilterType(0);
    setInitlized(false);
    FetchResults();
  }

  return (
    <div className="app_box_shadow_noPad">
      <div className="app_headBlk_pad20">
        <div className="app_headBlk_blkName">1003 Application</div>
        <div className="blockRefresh_dash"></div>
        <div className="selectRight">
          <select
            name="filter1003Type"
            value={Helper.ToString(filterType)}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="0">Status</option>
            <option value="-1">Saved</option>
            <option value="3">Completed</option>
          </select>
        </div>
      </div>
      <div className="appContentarea">
        <div className="dash_form1003">
          <div className="dash_form1003_head">
            <table>
              <tbody>
                <tr>
                  <td>Application No</td>
                  <td>Borrower Name</td>
                  <td>Property Address</td>
                  <td>Status</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>

          {loanFilterList && loanFilterList.length > 0 ? (
            <div className="dash_form1003">
              <table>
                <tbody>
                  {loanFilterList.map((x) => {
                    return (
                      <tr key={x._id}>
                        <td>{x.loanNumber}</td>
                        <td>{x.borrower}</td>
                        <td>{x.address}</td>
                        <td>
                          {x.statusIndicator === 3 ? "Completed" : "Saved"}
                        </td>
                        <td>
                          {x.statusIndicator === 3 ? (
                            <Link to="#" title="Download Fannie Mae File">
                              <div className="dash_downloadForm"></div>
                            </Link>
                          ) : (
                            <Link to="#" title="Resume Application">
                              <div className="dash_resumeForm"></div>
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="noContent">No Form1003 loans</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Component;
