import { React, TextInput2 as TextInput, DropDown2 as DropDown } from "../../../../common";

const Component = (props) => {
  const { item, unitTypes, statesList, onInputChanged, errors, onBlurError } = props;

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">Property Details</div>
        <div className="form1003Blk_right">&nbsp;</div>
      </div>
      <div className="form1003ContentBlk">
        <ul className="formContentIIcol">
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Street</div>
            <div className="locationicon"></div>
            <TextInput
              path={"property.addressLineText"}
              errors={errors}
              value={item?.property?.addressLineText}
              onInputChildChanged={onInputChanged} />

          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Unit No</div>
            <div className="hastagicon"></div>
            <TextInput
              path={"property.addressUnitIdentifier"}
              errors={errors}
              valuetype={'NFINANCE'}
              value={item?.property?.addressUnitIdentifier}
              onInputChildChanged={onInputChanged} />

          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Unit Type</div>
            <div className="locationicon"></div>
            <DropDown
              path={"property.addressUnitDesignatorType"}
              errors={errors}
              value={item?.property?.addressUnitDesignatorType}
              items={unitTypes}
              displayName={"text"}
              displayValue={"value"}
              onInputChildChanged={onInputChanged}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">City</div>
            <div className="locationicon"></div>
            <TextInput
              path={"property.city"}
              errors={errors}
              value={item?.property?.city}
              onInputChildChanged={onInputChanged} />

          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">State</div>
            <div className="locationicon"></div>
            <DropDown
              path={"property.state"}
              errors={errors}
              value={item?.property?.state}
              items={statesList}
              displayName={"text"}
              displayValue={"value"}
              onInputChildChanged={onInputChanged}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Zip Code</div>
            <div className="form_homeicon"></div>
            <TextInput
              path={"property.postalCode"}
              errors={errors}
              value={item?.property?.postalCode}
              valuetype={'ZIP'}
              validate={true}
              validationMessage={'Enter Valid Zip Code'}
              onBlurError={onBlurError}
              onInputChildChanged={onInputChanged} />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Country</div>
            <div className="countryicon"></div>
            <TextInput
              path={"property.country"}
              errors={errors}
              value={item?.property?.country}
              onInputChildChanged={onInputChanged} />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Number of Units</div>
            <div className="hastagicon"></div>
            <TextInput
              path={"property.financedNumberOfUnits"}
              errors={errors}
              valuetype={'NFINANCE'}
              value={item?.property?.financedNumberOfUnits}
              onInputChildChanged={onInputChanged} />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Estimated Value</div>
            <div className="form_dollaricon"></div>
            <TextInput
              path={"property.propertyEstimatedValueAmount"}
              errors={errors}
              valuetype={'FINANCE'}
              value={item?.property?.propertyEstimatedValueAmount}
              onInputChildChanged={onInputChanged} />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Appraised Value</div>
            <div className="form_dollaricon"></div>
            <TextInput
              path={"property.propertyAppraisedValueAmount"}
              errors={errors}
              valuetype={'FINANCE'}
              value={item?.property?.propertyAppraisedValueAmount}
              onInputChildChanged={onInputChanged} />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Component;
