import { React, Link, TextInput2 as TextInput, DropDown2 as DropDown } from "../../../../common";

const RenderChildLiabilities = (props) => {
  const { path, item, count, libalitiyTypes, onInputChanged } = props;

  const OnDeleteClicked = (e) => {
    e.preventDefault();
    if (onInputChanged) onInputChanged(`${path}.delete`.replace(/\./g, '_'), true);
  }

  return (
    <>
      {count > 1 && (
        <div className="formPartition">
          <div className="closePartition">
            <Link to="#" onClick={(e) => OnDeleteClicked(e)}>&#x2715; &nbsp;Delete</Link>
          </div>
        </div>
      )}
      <ul className="formContentIIcol">
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Account Type</div>
          <div className="typeicon"></div>
          <DropDown
            path={`${path}.liabilityType`}
            value={item?.liabilityType}
            items={libalitiyTypes}
            displayName={"text"}
            displayValue={"value"}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Company Name</div>
          <div className="officeicon"></div>
          <TextInput
            path={`${path}.holderName`}
            value={item?.holderName}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Monthly Payment</div>
          <div className="form_dollaricon"></div>
          <TextInput
            path={`${path}.monthlyPaymentAmount`}
            value={item?.monthlyPaymentAmount}
            onInputChildChanged={onInputChanged}
            valuetype={"FINANCE"}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Unpaid Balance</div>
          <div className="form_dollaricon"></div>
          <TextInput
            path={`${path}.unpaidBalanceAmount`}
            value={item?.unpaidBalanceAmount}
            onInputChildChanged={onInputChanged}
            valuetype={"FINANCE"}
          />
        </li>
      </ul>
    </>
  );
};

const Component = (props) => {
  const { items, libalitiyTypes, onAddArrayList, onInputChanged } = props;
  const item = items && items.childs && items.childs.length > 0 ? items.childs[0] : null;

  const OnAddNewItem = (e) => {
    e.preventDefault();
    let obj = {
      title: item?.title,
      owner: item?.owner,
      delete: false
    };
    if (onAddArrayList) onAddArrayList('liabilities', obj);
  };

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">LIABILITIES -</div>
      </div>
      <div className="form1003ContentBlk">
        <div className="form1003BlkInsideHead_topMinus40">
          <div className="formHeadContent_70pc">
            Credits Cards, Other Debts and Lease that You Owe
          </div>
          <div className="form1003Blk_right">
            <input
              type="button"
              defaultValue="&#65291; Another"
              className="headRight_button"
              onClick={(e) => OnAddNewItem(e)}
            />
          </div>
        </div>

        {items && items.childs && items.childs.map((x) => {
          if (!x.delete) {
            return (
              <RenderChildLiabilities
                count={items.childs.filter((x) => !x.delete).length}
                key={x.index}
                path={`liabilities.${x.index}`}
                item={x}
                libalitiyTypes={libalitiyTypes}
                onInputChanged={onInputChanged}
              />
            )
          } else { return null }
        })}
      </div>
    </>
  );
};

export default Component;
