import { React, Header, Footer } from "../../common";

import { Quotation } from "../popups";
import {
  HomeVideo, AboutUs, Whyi3Lending, LoanOptions, Process, Service,
  ReachYou, Blog, Payment, Testimonial, HomeViewBottom
} from "./childs";

const Component = () => {
  return (
    <>
      <Header />

      {/*Home Video*/}
      <HomeVideo />

      {/*Homepage Bottom*/}
      <HomeViewBottom />

      {/*About Us*/}
      <AboutUs />

      {/*Why i3Lending*/}
      <Whyi3Lending />

      {/*Loan Options*/}
      <LoanOptions />

      {/*Our Process*/}
      <Process />

      {/*Our Services*/}
      <Service />

      {/*Reach You*/}
      <ReachYou />

      {/*Blogs*/}
      <Blog />

      {/*Calculate Payment*/}
      <Payment />

      {/*Testimonials*/}
      <Testimonial />

      {/*Footer*/}
      <Footer type={"Full"} />

      <Quotation />
    </>
  );
};

export default Component;
