import { React, Session, useNavigate } from "../../common";

const Component = () => {
  const NavigateTo = useNavigate();
  const [agreed, setAgreed] = React.useState(false);

  const OnContinue = async (e) => {
    e.preventDefault();
    Session.Remove("coBorrowerIndicator");
    Session.Remove("loanid");
    window.hide("popAuthorize");
    NavigateTo("/stage1");
  };

  return (
    <>
      <div id="popAuthorize" className="pop_disable_bg">
        <div className="authorize_pop">
          <div className="pop_head">Authorization Agreements</div>
          <div className="pop_contentArea">
            <div className="pop_contentHead">
              CREDIT REPORT AUTHORIZATION FORM
            </div>
            <div className="spacer_10margin_bottom"></div>
            <div className="pop_content">
              Authorization is hereby granted to i3 Lending Inc, to obtain a
              consumer credit report through a credit reporting agency chosen by
              i3 Lending Inc. I understand and agree that i3 Lending Inc intends
              to use the consumer credit report for the purposes of evaluating
              my financial readiness to obtain a loan.
            </div>
            <div className="spacer_10margin_bottom"></div>
            <div className="pop_content">
              I understand that this credit report will be retained on file at
              the i3 Lending Inc office for use only by i3 Lending Inc staff.
              This information will not be disclosed to anyone by i3 Lending Inc
              without my written consent.
            </div>
            <div className="spacer_10margin_bottom"></div>
            <div className="pop_content">
              Furthermore, I understand that, should I choose to apply for
              financing through i3 Lending Inc, a revised credit report costing
              an additional fee may be required.
            </div>
            <div className="spacer_10margin_bottom"></div>
            <div className="pop_content">
              Provides loans to farmers and other qualified applicants who
              cannot obtain financing elsewhere.
            </div>
          </div>
          <div className="popConsent">
            CHECKING THE BOX BELOW GRANTS PERMISSION FOR THE RELEASE OF
            FINANCIAL INFORMATION TO THE CREDIT REPORTING AGENCY AND GRANTS
            PERMISSION FOR i3 LENDING INC TO OBTAIN A COPY OF YOUR CREDIT
            REPORT.
            <div className="popAgree">
              <input
                type="checkbox"
                id="cb_agree"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
              />
              <label htmlFor="cb_agree">I AGREE</label>
            </div>
          </div>
          {/* <div style="color:red">If I agree is checked, enable Continue button</div> */}
          <div className="pop_buttonLabel">
            <input
              type="button"
              value="Close"
              className="pop_button"
              style={{ marginRight: 5 }}
              onClick={(e) => window.hide("popAuthorize")}
            />
            {agreed && (
              <input
                type="button"
                value="Continue"
                className="pop_button"
                onClick={(e) => OnContinue(e)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Component;
