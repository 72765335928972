const Component = () => {
    return (
        <div className="ourProcessBlk">
            <div className="widthfix_10px">
                <div className="ourProcess_blockDesc">Our Process<span className="curveShape"></span></div>
                <div className="ourProcess_blkHeadTxt">How does it Work?</div>
                <ul className="ourProcessIIblk">
                    <li className="ourProcessIIblk">
                        <ul className="ourProcessPointContentBlk">
                            <li className="ourProcessPointContentBlk">
                                <div className="ourProcessCircle"></div>
                            </li>
                            <li className="ourProcessPointContentBlk">
                                <div className="ourProcessColTxtHead">Choose Loan Amount</div>
                                <div className="ourProcessColTxt">It is recommended to look for homes that cost no more that three to five times their annual household income.</div>
                            </li>
                            <li className="ourProcessPointContentBlk">
                                <div className="ourProcessCircle"></div>
                            </li>
                            <li className="ourProcessPointContentBlk">
                                <div className="ourProcessColTxtHead">Get Pre-approved</div>
                                <div className="ourProcessColTxt">It is recommended to look for homes that cost no more that three to five times their annual household income.</div>
                            </li>
                            <li className="ourProcessPointContentBlk">
                                <div className="ourProcessCircle"></div>
                            </li>
                            <li className="ourProcessPointContentBlk">
                                <div className="ourProcessColTxtHead">Close your Loan</div>
                                <div className="ourProcessColTxt">It is recommended to look for homes that cost no more that three to five times their annual household income.</div>
                            </li>
                        </ul>
                    </li>
                    <li className="ourProcessIIblk">&nbsp;</li>
                </ul>
            </div>
        </div>
    )
}

export default Component;