const Component = () => {
    return (
        <div id="profileComplete_pop" className="pop_disable_bg">
            <div className="profileComplete_pop">
                <div className="pop_head">Complete Profile Details</div>
                <div className="pop_contentArea">
                    <div className="pop_content">Please update your information to access other pages</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('profileComplete_pop')} />
                </div>
            </div>
        </div>
    );
};

export default Component;