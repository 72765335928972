import { React, Helper, TextInput2 as TextInput, DropDown2 as DropDown } from "../../common";
import { LoanAmountTypes, PropertyValueTypes, LoanValueTypes, CreditValueTypes } from "../../helper/support";
import { RequestForQuote } from "../../services/commonApi";

const Component = () => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [success, setSuccess] = React.useState(null);
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setErrors([]);
    setError(null);
    setSuccess(null);
  }, []);

  const OnInputChanged = async (path, value, index) => {
    setError(null);
    setSuccess(null);
    let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
    setRow(newRow);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };


  const OnSubmitClicked = async (e) => {
    e.preventDefault();
    setError(null);
    setErrors([]);

    let _fields = [
      "quoteFName",
      "quoteLName",
      "quoteEmail",
      "quotePhone",
      "quoteAmount",
      "quoteValue",
      "quoteLoanType",
      "quoteCredit"
    ];


    let _errs = [];
    _fields.forEach((elm) => {
      _errs.push({ field: elm });
    });

    if (Helper.IsJSONEmpty(row)) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let isInvalid = false;

    _errs = [];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        isInvalid = true;
        _errs.push({ field: elm });
      }
    });

    if (!isInvalid) {
      if (!Helper.IsEmailValid(row["quoteEmail"])) {
        isInvalid = true;
        _errs.push({ field: "quoteEmail" });
      }
      if (!Helper.IsValidPhone(row["quotePhone"])) {
        isInvalid = true;
        _errs.push({ field: "quotePhone" });
      }
    }

    if (isInvalid) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    global.Busy(true);
    let rslt = await RequestForQuote(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(Helper.ErrorMessage);
      return;
    }
    setSuccess(rslt.statusText);
  };


  return (
    <div id="rateQuote_pop" className="pop_disable_bg">
      <div className="rateQuote_pop">
        <div className="rateQuote_ContentArea">
          <div className="rateQuote_Head">Get a Rate Quote</div>
          <ul className="rateQuoteIIcol">
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">First Name</div>
              <TextInput
                path={`quoteFName`}
                errors={errors}
                value={Helper.ToString(row?.quoteFName)}
                onInputChildChanged={OnInputChanged}
                required={true}
                dataRequired={true}
              />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Last Name</div>
              <TextInput
                path={"quoteLName"}
                errors={errors}
                value={Helper.ToString(row?.quoteLName)}
                onInputChildChanged={OnInputChanged}
                required={true}
                dataRequired={true}
              />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Email</div>
              <TextInput
                path={"quoteEmail"}
                value={Helper.ToString(row?.quoteEmail)}
                onInputChildChanged={OnInputChanged}
                valuetype={"EMAIL"}
                errors={errors}
                required={true}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                requiredMessage={`Enter Valid Email`}
              />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Phone</div>
              <TextInput
                maxLength={14}
                path={"quotePhone"}
                value={Helper.ToString(row?.quotePhone)}
                onInputChildChanged={OnInputChanged}
                valuetype={"PHONE"}
                errors={errors}
                required={true}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                requiredMessage={`Enter Valid Phone Number`}
              />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Loan Amount</div>
              <DropDown
                path={"quoteAmount"}
                value={row?.quoteAmount}
                items={LoanAmountTypes()}
                displayName={"text"}
                displayValue={"value"}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Property Value</div>
              <DropDown
                path={"quoteValue"}
                value={row?.quoteValue}
                items={PropertyValueTypes()}
                displayName={"text"}
                displayValue={"value"}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Loan Type</div>
              <DropDown
                path={"quoteLoanType"}
                value={row?.quoteLoanType}
                items={LoanValueTypes()}
                displayName={"text"}
                displayValue={"value"}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Credit History</div>
              <DropDown
                path={"quoteCredit"}
                value={row?.quoteCredit}
                items={CreditValueTypes()}
                displayName={"text"}
                displayValue={"value"}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </li>
          </ul>
          <div className="rq_button_label">
            <input
              type="button"
              className="rateQuoteBut"
              value="Submit"
              onClick={(e) => OnSubmitClicked(e)}
            />
            <input
              type="button"
              className="rateQuoteBut"
              value="Close"
              onClick={() => window.hide("rateQuote_pop")}
            />
          </div>
        </div>
        {error && (
          <div className="errormessage_center">
            {error}
          </div>
        )}
        {success && (
          <div className="successmessage">
            {success}
          </div>
        )}
      </div>
    </div>
  );
};
export default Component;