import { React, Link } from "../../../../../common";
import Container from "../../../../container";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_blog">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col_100pc">
                                        <Link to="/blogs"><div className="bcHeadTxt_generic_inline">Blogs</div></Link>
                                        <div className="bcHeadTxt_generic_inline_support">- Blockchain in Mortgage Industry</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="Icol">
                                <div className="pgTxtBold_XL">Blockchain in Mortgage Industry</div>
                                <div className="pgTxtNormal">The mortgage industry in the USA has been one of the most traditional and bureaucratic industries. It has been slow to adapt to technological advancements and has often relied on paper-based systems that have proven to be inefficient and error-prone. However, with the advent of blockchain technology, the mortgage industry is set to undergo a transformation that will benefit both borrowers and lenders alike.</div>
                                <div className="pgTxtNormal">By 2023, the use of blockchain technology in the mortgage industry will be a standard practice. Blockchain is a distributed ledger technology that offers an immutable record of transactions. This technology can provide significant benefits to the mortgage industry, including improved efficiency, transparency, and security.</div>
                                <div className="pgTxtNormal">The use of blockchain technology in the mortgage industry will enable lenders to create smart contracts that automate the lending process. Smart contracts are self-executing contracts that are programmed to automatically execute when certain conditions are met. This means that lenders will be able to automate tasks such as credit checks, appraisals, and verifications of income and employment.</div>
                                <div className="pgTxtNormal">This automation will significantly reduce the time it takes to process a mortgage application, reducing the average time from application to closing from 45 days to as little as 7 days. This will not only benefit the borrower but also the lender, who will be able to process more loans in a shorter amount of time.</div>
                                <div className="pgTxtNormal">Blockchain technology will also improve transparency in the mortgage industry. The distributed nature of the blockchain means that all parties involved in the lending process will have access to the same information. This will enable borrowers to see the status of their application in real-time and provide lenders with a more accurate picture of the borrower's financial situation.</div>
                                <div className="pgTxtNormal">Furthermore, blockchain technology will significantly enhance security in the mortgage industry. The immutable record of transactions will make it nearly impossible to alter or manipulate data, making fraud and other illegal activities less likely to occur.</div>
                                <div className="pgTxtNormal">In conclusion, the use of blockchain technology in the mortgage industry in the USA in 2023 will be a game-changer. It will significantly improve efficiency, transparency, and security, benefiting both borrowers and lenders alike. As the mortgage industry continues to adopt blockchain technology, it will create a more streamlined and efficient lending process, making it easier for people to purchase their dream homes.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
