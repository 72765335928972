import { React, Helper, TextInput } from "../../../common";
import { CallBack } from "../../../services/commonApi";

const Component = () => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setError(null);
    setSuccess(null);
  }, []);

  const OnInputChanged = async (parent, path, name, value) => {
    setError(null);
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnCallBackClicked = async (e) => {
    e.preventDefault();
    if (Helper.IsJSONEmpty(row)) {
      setError("OK");
      return;
    }

    let isInvalid = false;
    let _fields = ["callName", "callEmail", "callPhone"];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        if (!isInvalid) isInvalid = true;
      }
    });

    if (!isInvalid && !Helper.IsEmailValid(row.callEmail)) {
      if (!isInvalid) isInvalid = true;
    }

    if (isInvalid) {
      setError("OK");
      return;
    }

    global.Busy(true);
    let rslt = await CallBack(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    setSuccess(rslt.statusText);
  };

  return (
    <div>
      <div className="inPgRightHeadResponsive">Leave Your Phone Number</div>
      <div className="requestCallBackBlk">
        <div className="requestCallBack">
          <TextInput
            tag={"callName"}
            path={"callName"}
            name={"callName"}
            placeholder={"Name"}
            value={Helper.ToString(row?.callName)}
            onInputChildChanged={OnInputChanged}
          />

          <TextInput
            tag={"callEmail"}
            path={"callEmail"}
            name={"callEmail"}
            placeholder={"Email"}
            value={Helper.ToString(row?.callEmail)}
            onInputChildChanged={OnInputChanged}
          />

          <TextInput
            maxLength={14}
            tag={"callPhone"}
            path={"callPhone"}
            name={"callPhone"}
            placeholder={"Phone Number"}
            value={Helper.ToString(row?.callPhone)}
            type={"PHONE"}
            onInputChildChanged={OnInputChanged}
          />
        </div>
        <input
          type="button"
          className="callBack_button"
          value="Request Call Back"
          onClick={(e) => OnCallBackClicked(e)}
        />
        {error && (
          <div className="errormessage" style={{ marginTop: 8 }}>
            All details are mandatory
          </div>
        )}
        {success && (
          <div className="successmessage" style={{ marginTop: 8 }}>
            {success}
          </div>
        )}
      </div>
    </div>
  );
};

export default Component;
