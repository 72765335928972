import { React, Helper, TextInput } from "../../common";
import Container from "../container";
import { ContactInfo } from "../home/components";
import { ContactUs } from "../../services/commonApi";

const Component = () => {

    const [row, setRow] = React.useState({});
    const [error, setError] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [success, setSuccess] = React.useState(null);
    const [state, setState] = React.useState(false);

    React.useEffect(() => {
        setError(null);
        setSuccess(null);
        setErrors([]);
    }, []);

    const OnInputChanged = async (parent, path, name, value) => {
        setError(null);
        setErrors([]);
        let options = { IsArray: false };
        let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
        setRow(newRow);
        setState(!state);
    };

    const OnContactUsClicked = async (e) => {
        e.preventDefault();
        setErrors([]);
        setError(null);

        let _errs = [];
        let _fields = [
            "contName",
            "contPhone",
            "contEmail",
            "contZip",
            "contMessage",
        ];

        _fields.forEach((elm) => {
            _errs.push({ field: elm });
        });

        if (Helper.IsJSONEmpty(row)) {
            setError("OK");
            setErrors(_errs);
            return;
        }

        _errs = [];
        let isInvalid = false;

        _fields.forEach((elm) => {
            if (Helper.IsNullValue(row[elm])) {
                if (!isInvalid) isInvalid = true;
                _errs.push({ field: elm });
            }
        });

        if (!isInvalid && !Helper.IsEmailValid(row.contEmail)) {
            if (!isInvalid) isInvalid = true;
            _errs.push({ field: "contEmail" });
        }

        if (isInvalid) {
            setErrors(_errs);
            setError("OK");
            return;
        }

        row["contCity"] = "NA";
        row["contLoanType"] = "NA";

        global.Busy(true);
        let rslt = await ContactUs(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        setSuccess(rslt.statusText);
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_contact">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Get In Touch</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Help &amp; Support</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBold_L">Hello, how can we help you?</div>
                                    <div className="pgTxtNormal">Please write your query and one of our executive will get in touch with you shortly</div>
                                    <div className="contactFormNoShadow">
                                        <ul className="contactForm_IIcol">
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Name</div>
                                                <div className="contactFormUserIcon"></div>
                                                <TextInput
                                                    tag={"contName"}
                                                    path={"contName"}
                                                    name={"contName"}
                                                    placeholder={"Name"}
                                                    value={Helper.ToString(row?.contName)}
                                                    onInputChildChanged={OnInputChanged}
                                                    errors={errors}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Phone Number</div>
                                                <div className="contactFormPhIcon"></div>
                                                <TextInput
                                                    maxLength={14}
                                                    tag={"contPhone"}
                                                    path={"contPhone"}
                                                    name={"contPhone"}
                                                    placeholder={"Phone Number"}
                                                    value={Helper.ToString(row?.contPhone)}
                                                    type={"PHONE"}
                                                    onInputChildChanged={OnInputChanged}
                                                    errors={errors}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Email</div>
                                                <div className="contactFormMailIcon"></div>
                                                <TextInput
                                                    tag={"contEmail"}
                                                    path={"contEmail"}
                                                    name={"contEmail"}
                                                    placeholder={"Email"}
                                                    value={Helper.ToString(row?.contEmail)}
                                                    onInputChildChanged={OnInputChanged}
                                                    errors={errors}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Zip Code</div>
                                                <div className="contactFormHomeIcon"></div>
                                                <TextInput
                                                    maxLength={10}
                                                    tag={"contZip"}
                                                    path={"contZip"}
                                                    name={"contZip"}
                                                    placeholder={"Zip Code"}
                                                    value={Helper.ToString(row?.contZip)}
                                                    type={"NFINANCE"}
                                                    onInputChildChanged={OnInputChanged}
                                                    errors={errors}
                                                />
                                            </li>
                                        </ul>
                                        <div className="contactForm_Icol">
                                            <div className="contactFormHead">Message</div>
                                            <div className="contactFormNoteIcon"></div>
                                            <TextInput
                                                tag={"contMessage"}
                                                path={"contMessage"}
                                                name={"contMessage"}
                                                placeholder={"Message"}
                                                value={Helper.ToString(row?.contMessage)}
                                                onInputChildChanged={OnInputChanged}
                                                errors={errors}
                                            />
                                        </div>
                                        <div className="contactForm_btn_label">
                                            <input type="button" value="Reach Us" className="contactFormBtn"
                                                onClick={(e) => OnContactUsClicked(e)} />
                                        </div>
                                        {error && (
                                            <div className="errormessage">
                                                You have some errors. Please fix and proceed.
                                            </div>
                                        )}
                                        {success && (
                                            <div
                                                className="successmessage"
                                            >
                                                Thank you for contacting us. We will get back to you
                                                ASAP.
                                            </div>
                                        )}
                                    </div>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul >
                        </div >

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
