import { React, Link, TextInput2 as TextInput, DropDown2 as DropDown } from "../../../../common";

const RenderChildOtherLiabilities = (props) => {
  const { path, item, count, otherLibalitiyTypes, onInputChanged } = props;

  const OnDeleteClicked = (e) => {
    e.preventDefault();
    if (onInputChanged) onInputChanged(`${path}.delete`.replace(/\./g, '_'), true);
  }

  return (
    <>
      {count > 1 && (
        <div className="formPartition">
          <div className="closePartition">
            <Link to="#" onClick={(e) => OnDeleteClicked(e)}>&#x2715; &nbsp;Delete</Link>
          </div>
        </div>
      )}
      <ul className="formContentIIcol">
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Liability or Expense Type</div>
          <div className="typeicon"></div>
          <DropDown
            path={`${path}.liabilityOrExpenseType`}
            value={item?.liabilityOrExpenseType}
            items={otherLibalitiyTypes}
            displayName={"text"}
            displayValue={"value"}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Monthly Payment</div>
          <div className="form_dollaricon"></div>
          <TextInput
            path={`${path}.monthlyPayment`}
            value={item?.monthlyPayment}
            onInputChildChanged={onInputChanged}
          />
        </li>
      </ul>
    </>
  );
};

const Component = (props) => {

  const { items, otherLibalitiyTypes, onAddArrayList, onInputChanged } = props;
  const item = items && items.childs && items.childs.length > 0 ? items.childs[0] : null;

  const OnAddNewItem = (e) => {
    e.preventDefault();
    let obj = {
      title: item?.title,
      borrowerType: item?.borrowerType,
      delete: false
    };
    if (onAddArrayList) onAddArrayList('otherLiabilities', obj);
  };

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent"> Other Liabilities and Expenses</div>
        <div className="form1003Blk_right">
          <input
            type="button"
            defaultValue="&#65291; Another"
            className="headRight_button"
            onClick={(e) => OnAddNewItem(e)}
          />
        </div>
      </div>
      <div className="form1003ContentBlk">
        {items && items.childs && items.childs.map((x) => {
          if (!x.delete) {
            return (
              <RenderChildOtherLiabilities
                count={items.childs.filter((x) => !x.delete).length}
                key={x.index}
                path={`otherLiabilities.${x.index}`}
                item={x}
                otherLibalitiyTypes={otherLibalitiyTypes}
                onInputChanged={onInputChanged}
              />
            )
          } else { return null }
        })}
      </div>
    </>
  );
};

export default Component;
