import { React, Helper, TextInput } from "../../../common";
import { CallBack } from "../../../services/commonApi";

const Component = () => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState();
  const [success, setSuccess] = React.useState(null);
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setError(null);
    setSuccess(null);
  }, []);

  const OnInputChanged = async (parent, path, name, value) => {
    setError(null);
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const OnCallBackClicked = async (e) => {
    e.preventDefault();
    if (Helper.IsJSONEmpty(row)) {
      setError("All details are mandatory");
      return;
    }

    let isInvalid = false;
    let _fields = ["callName", "callEmail", "callPhone"];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        if (!isInvalid) isInvalid = true;
      }
    });

    if (!isInvalid && !Helper.IsEmailValid(row.callEmail)) {
      if (!isInvalid) isInvalid = true;
    }

    if (isInvalid) {
      setError("All details are mandatory");
      return;
    }

    global.Busy(true);
    let rslt = await CallBack(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    setSuccess(rslt.statusText);
  };

  return (
    <div className="reachYouBlk">
      <div className="widthfix_10px">
        <div className="reachYou_blkHeadTxt">
          Just let us know how to reach you and one of our loan officers will be
          in touch shortly
        </div>
        <ul className="reachYouForm">
          <li className="reachYouForm">
            <TextInput
              tag={"callName"}
              path={"callName"}
              name={"callName"}
              placeholder={"Name"}
              value={Helper.ToString(row?.callName)}
              onInputChildChanged={OnInputChanged}
            />
          </li>
          <li className="reachYouForm">
            <TextInput
              maxLength={14}
              tag={"callPhone"}
              path={"callPhone"}
              name={"callPhone"}
              placeholder={"Phone Number"}
              value={Helper.ToString(row?.callPhone)}
              type={"PHONE"}
              onInputChildChanged={OnInputChanged}
              errors={errors}
              onBlurError={OnBlurError}
              onInputClicked={OnInputClicked}
              required={true}
            />
          </li>
          <li className="reachYouForm">
            <TextInput
              tag={"callEmail"}
              path={"callEmail"}
              name={"callEmail"}
              placeholder={"Email"}
              value={Helper.ToString(row?.callEmail)}
              type={"EMAIL"}
              onInputChildChanged={OnInputChanged}
              errors={errors}
              onBlurError={OnBlurError}
              onInputClicked={OnInputClicked}
              required={true}
            />
          </li>
          <li className="reachYouForm">
            <input
              type="button"
              className="reachYouButton"
              value="Submit"
              onClick={(e) => OnCallBackClicked(e)}
            />
          </li>
        </ul>
        {error && (
          <div className="errormessage" style={{ marginTop: 8 }}>
            {error}
          </div>
        )}
        {success && (
          <div className="successmessage" style={{ marginTop: 8 }}>
            {success}
          </div>
        )}
      </div>
    </div>
  );
};
export default Component;
