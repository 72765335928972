import { React, Link, TextInput2 as TextInput, DropDown2 as DropDown } from "../../../../common";

const Component = (props) => {
  const { item, mortgageTypes, amortizationTypes, propertyTypes,
    loanPurposeTypes, occupancyTypes, onInputChanged, onBlurError, onInputClicked, errors } = props;

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">Loan Details</div>
        <div className="form1003Blk_right">&nbsp;</div>
      </div>
      <div className="form1003ContentBlk">
        <ul className="formContentIIcol">
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Mortgage Applied For</div>
            <Link
              to="#"
              onClick={(e) => window.pop("info_mortgageApplied")}
              title="Info"
            >
              <div className="formTip"></div>
            </Link>
            <div className="typeicon"></div>
            <DropDown
              errors={errors}
              path={`loanDetails.mortgageType`}
              value={item?.loanDetails?.mortgageType}
              items={mortgageTypes}
              displayName={"text"}
              displayValue={"value"}
              onInputChildChanged={onInputChanged}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Loan Amount</div>
            <div className="formMandatory">*</div>
            <Link
              to="#"
              onClick={(e) => window.pop("info_loanAmount")}
              title="Info"
            >
              <div className="formTip"></div>
            </Link>
            <div className="form_dollaricon"></div>
            <TextInput
              errors={errors}
              path={`loanDetails.borrowerRequestedLoanAmount`}
              value={item?.loanDetails?.borrowerRequestedLoanAmount}
              valuetype={'FINANCE'}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
              requiredMessage={'Loan Amount is required'}
              dataRequired={true}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Interest Rate</div>
            <Link
              to="#"
              onClick={(e) => window.pop("info_interestRate")}
              title="Info"
            >
              <div className="formTip"></div>
            </Link>
            <div className="form_percenticon"></div>
            <TextInput
              errors={errors}
              path={`loanDetails.requestedLoanRate`}
              value={item?.loanDetails?.requestedLoanRate}
              valuetype={'FINANCE'}
              onInputChildChanged={onInputChanged} />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Loan Term in Months</div>
            <Link
              to="#"
              onClick={(e) => window.pop("info_loanTerm")}
              title="Info"
            >
              <div className="formTip"></div>
            </Link>
            <div className="form_dateicon"></div>
            <TextInput
              path={`loanDetails.loanTermInMonths`}
              errors={errors}
              value={item?.loanDetails?.loanTermInMonths}
              valuetype={'NFINANCE'}
              onInputChildChanged={onInputChanged} />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Amortization Type</div>
            <Link
              to="#"
              onClick={(e) => window.pop("info_amortizationType")}
              title="Info"
            >
              <div className="formTip"></div>
            </Link>
            <div className="typeicon"></div>
            <DropDown
              path={`loanDetails.amortizationType`}
              errors={errors}
              value={item?.loanDetails?.amortizationType}
              items={amortizationTypes}
              displayName={"text"}
              displayValue={"value"}
              onInputChildChanged={onInputChanged}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Loan Purpose</div>
            <Link
              to="#"
              onClick={(e) => window.pop("info_loanPurpose")}
              title="Info"
            >
              <div className="formTip"></div>
            </Link>
            <div className="typeicon"></div>
            <DropDown
              path={`loanDetails.loanPurposeType`}
              errors={errors}
              value={item?.loanDetails?.loanPurposeType}
              items={loanPurposeTypes}
              displayName={"text"}
              displayValue={"value"}
              onInputChildChanged={onInputChanged}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Occupancy</div>
            <Link
              to="#"
              onClick={(e) => window.pop("info_occupancy")}
              title="Info"
            >
              <div className="formTip"></div>
            </Link>
            <div className="form_homeicon"></div>
            <DropDown
              errors={errors}
              path={`loanDetails.propertyUsageType`}
              value={item?.loanDetails?.propertyUsageType}
              items={occupancyTypes}
              displayName={"text"}
              displayValue={"value"}
              onInputChildChanged={onInputChanged}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Property Type</div>
            <div className="typeicon"></div>
            <DropDown
              path={`loanDetails.propertyType`}
              errors={errors}
              value={item?.loanDetails?.propertyType}
              items={propertyTypes}
              displayName={"text"}
              displayValue={"value"}
              onInputChildChanged={onInputChanged}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Component;
