import { React, Link, Storage, useNavigate, Helper } from "../../../../common";
import Container from "../../../container";
import { InfoFixedThirty } from "./popup";
import { InfoFixedThirtyFHA } from "./popup";
import { InfoFixedTwenty } from "./popup";
import { InfoFixedFifteen } from "./popup";
import { ConfirmDialog } from "../../../popups";

import { GetMortgageRates } from "../../../../services/loanApi";

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [confirmShow, setConfirmShow] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const NavigateTo = useNavigate();

    const OnGettingStarted = async (e) => {
        //let data = await Storage.RetrieveJSONAsync("stages");
        let nav = undefined; //data["stage"];
        if (!nav) {
            await Storage.RemoveAsync("preapprove");
            await Storage.RemoveAsync("stages");
            document.getElementById("ziplook_pop").style.display = "block";
        } else {
            setConfirmShow(true);
        }
    };

    const OnCancelClicked = (e) => {
        setConfirmShow(false);
    };

    const OnConfirmClicked = async (e) => {
        if (!e) {
            await Storage.RemoveAsync("stages");
            await Storage.RemoveAsync("preapprove");
        }
        setConfirmShow(false);
        if (!e) {
            OnGettingStarted();
        } else {
            NavigateTo("/preapprove");
        }
    };

    const OnPopupInfoClicked = (e, name) => {
        e.preventDefault();
        document.getElementById(name).style.display = "block";
    }

    const FetchResults = async () => {
        global.Busy(true);
        setRows([]);
        let rslt = await GetMortgageRates();
        if (rslt.status === 100) {
            let tmp = rslt.data || [];
            setRows(tmp);
        }
        global.Busy(false);
    };


    if (initlized) {
        setInitlized(false);
        setRows([]);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const GetPriceValue = (type, orderid, name) => {
        let _tmp = rows.filter((x) => x.type.toUpperCase() === type.toUpperCase() && parseInt(x.orderid) === orderid);
        if (_tmp && _tmp.length > 0) {
            return _tmp[0][name].toFixed(3);
        }
        return 0.000;
    }

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Mortgage Rates</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol">
                                <li className="IIcol">
                                    <div className="mortgageRateBlk">
                                        <div className="mr_heading">Purchase</div>
                                        <div className="mr_noteTxt">The below rates are estimated current rates as of: <b>{Helper.ToDate(new Date(), "YYYY-MMM-DD")}</b></div>
                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">{GetPriceValue("purchase", 1, 'rate')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">{GetPriceValue("purchase", 1, 'apr')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed FHA
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirtyFHA')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">{GetPriceValue("purchase", 2, 'rate')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">{GetPriceValue("purchase", 2, 'apr')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">20-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedtwenty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">{GetPriceValue("purchase", 3, 'rate')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">{GetPriceValue("purchase", 3, 'apr')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedfifteen')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">{GetPriceValue("purchase", 4, 'rate')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">{GetPriceValue("purchase", 4, 'apr')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="IIcol">
                                    <div className="mortgageRateBlk">
                                        <div className="mr_heading">Refinance</div>
                                        <div className="mr_noteTxt">The below rates are estimated current rates as of: <b>{Helper.ToDate(new Date(), "YYYY-MMM-DD")}</b></div>
                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">{GetPriceValue("refinance", 1, 'rate')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">{GetPriceValue("refinance", 1, 'apr')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed FHA
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirtyFHA')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">{GetPriceValue("refinance", 2, 'rate')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">{GetPriceValue("refinance", 2, 'apr')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">20-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedtwenty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">{GetPriceValue("refinance", 3, 'rate')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">{GetPriceValue("refinance", 3, 'apr')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedfifteen')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">{GetPriceValue("refinance", 4, 'rate')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">{GetPriceValue("refinance", 4, 'apr')}<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
            <ConfirmDialog
                title="Do you want to continue with your Pre-approval?"
                message="You'll be able to pick from where you left"
                display={confirmShow}
                onConfirmClicked={OnConfirmClicked}
                onCancelClicked={OnCancelClicked}
            />
            <InfoFixedThirty />
            <InfoFixedThirtyFHA />
            <InfoFixedTwenty />
            <InfoFixedFifteen />
        </>
    )
}

export default Component;
