import { React, Storage, Helper, Session, RadioInput, DropDown, TextInput } from "../../../common";
import { OptionTypes, PhoneTypes } from "../../../helper/support";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";

const Component = ({ onClick }) => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [stage, setStage] = React.useState(0);
  const [state, setState] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  if (initialized) {
    setInitialized(false);
    setErrors([]);
    let data = Storage.RetrieveJSON("stages");
    setStage(5);
    let _row = data.stage5 || { coborrower: { phoneType: "Mobile" } };
    setRow(_row);
  }

  const OnInputChanged = async (parent, path, name, value) => {
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const OnNavClicked = async (type, e) => {
    setError(null);
    setErrors([]);

    if (type === "prev") {
      if (onClick) onClick(e);
      return;
    }

    let _fields = [
      "firstName",
      "lastName",
      "birthDate",
      "mobilePhone",
      "phoneType",
      "totalAnnualGrossIncome",
      "totalMonthlyDebtPayments",
      "militaryServiceIndicator",
    ];

    let _errs = [];
    _fields.forEach((elm) => {
      _errs.push({ field: elm });
    });

    if (Helper.IsJSONEmpty(row)) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let isInvalid = false;
    _errs = [];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row?.coborrower[elm])) {
        isInvalid = true;
        _errs.push({ field: elm });
      }
    });

    if (!isInvalid) {
      if (!Helper.IsValidateDate(row?.coborrower["birthDate"], "DOB")) {
        isInvalid = true;
        _errs.push({ field: "birthDate" });
      }
      if (!Helper.IsValidPhone(row?.coborrower["mobilePhone"])) {
        isInvalid = true;
        _errs.push({ field: "mobilePhone" });
      }
    }

    if (isInvalid) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let _stage = e;
    let data = Storage.RetrieveJSON("stages");
    data["stage5"] = row;
    if (Session.IsLoggedIn()) _stage++;
    data["stage"] = _stage;

    Storage.StoreJSON("stages", data);
    if (onClick) onClick(_stage);
  };

  return (
    <>
      <RenderBreadCrumb stage={5} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead">
            Now, tell us a bit more about your co-borrower
          </li>
          <li className="countBlk">
            <div className="countNo">5/6</div>
          </li>
        </ul>
        <div className="purchase">
          <div className="spacer_25margin_bottom">
            <div className="pur_Q">Full Legal Name</div>
            <ul className="III_col">
              <li className="III_col">
                <TextInput
                  placeholder="First Name"
                  tag={"coborrower.firstName"}
                  path={"coborrower.firstName"}
                  name={"firstName"}
                  value={Helper.ToString(row?.coborrower?.firstName)}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="III_col">
                <TextInput
                  placeholder="Last Name"
                  tag={"coborrower.lastName"}
                  path={"coborrower.lastName"}
                  name={"lastName"}
                  value={Helper.ToString(row?.coborrower?.lastName)}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
            </ul>
          </div>
          <div className="spacer_25margin_bottom">
            <ul className="III_col">
              <li className="III_col">
                <div className="pur_Q">Date of Birth</div>
                <TextInput
                  placeholder="MM/DD/YYYY"
                  tag={"coborrower.birthDate"}
                  path={"coborrower.birthDate"}
                  name={"birthDate"}
                  type={"DATE"}
                  maxLength={10}
                  value={Helper.ToString(row?.coborrower?.birthDate)}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Phone Number</div>
                <TextInput
                  maxLength={14}
                  tag={"coborrower.mobilePhone"}
                  path={"coborrower.mobilePhone"}
                  name={"mobilePhone"}
                  placeholder={"Phone Number"}
                  value={Helper.ToString(row?.coborrower?.mobilePhone)}
                  type={"PHONE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
              </li>
              <li className="III_col">
                <div className="pur_Q">Phone Type</div>
                <DropDown
                  tag={"coborrower.phoneType"}
                  path={"coborrower.phoneType"}
                  name={"phoneType"}
                  value={row?.coborrower?.phoneType}
                  items={PhoneTypes()}
                  displayName={"text"}
                  displayValue={"value"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
              </li>
            </ul>
          </div>
          <div className="spacer_10margin_bottom">
            <ul className="II_col">
              <li className="II_col">
                <div className="pur_Q">Total Annual Gross Income</div>
                <TextInput
                  currency="$"
                  maxLength={14}
                  placeholder="Total Annual Gross Income"
                  tag={"coborrower.totalAnnualGrossIncome"}
                  path={"coborrower.totalAnnualGrossIncome"}
                  name={"totalAnnualGrossIncome"}
                  value={Helper.ToString(
                    row?.coborrower?.totalAnnualGrossIncome
                  )}
                  type={"NFINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="II_col">
                <div className="pur_Q">Total Monthly Debt Payments</div>
                <TextInput
                  currency="$"
                  maxLength={14}
                  placeholder="Total Monthly Debt Payments"
                  tag={"coborrower.totalMonthlyDebtPayments"}
                  path={"coborrower.totalMonthlyDebtPayments"}
                  name={"totalMonthlyDebtPayments"}
                  value={Helper.ToString(
                    row?.coborrower?.totalMonthlyDebtPayments
                  )}
                  type={"NFINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
            </ul>
          </div>
          <div className="spacer_10margin_bottom">
            <div className="pur_Q">Military Service</div>
            <ul className="pur_A">
              <RadioInput
                options={OptionTypes()}
                path={`coborrower.militaryServiceIndicator`}
                tag={`coborrower.militaryServiceIndicator`}
                name={"militaryServiceIndicator"}
                uselabel={false}
                value={row?.coborrower?.militaryServiceIndicator}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              onClick={(e) => OnNavClicked("prev", stage - 1)}
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked("next", stage + 1)}
            />
          </div>
          {error && (
            <div className="errormessage">{error}</div>
          )}
        </div>
      </li>
    </>
  );
};

export default Component;
