import { React, Link } from "../../../../../common";
import Container from "../../../../container";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_blog">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col_100pc">
                                        <Link to="/blogs"><div className="bcHeadTxt_generic_inline">Blogs</div></Link>
                                        <div className="bcHeadTxt_generic_inline_support">- Mortgage Loans, it's types and details</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="Icol">
                                <div className="pgTxtBold_XL">Mortgage loans, it's types and details</div>
                                <div className="pgTxtNormal">Mortgage loans are a type of secured loan that is commonly used to finance the purchase of real estate property in the United States. With the high cost of homes, many Americans turn to mortgage loans to make their dream of homeownership a reality. In this blog, we will discuss what mortgage loans are, how they work, and the different types of mortgage loans available in the USA.</div>
                                <div className="pgTxtNormal">What is a Mortgage Loan? A mortgage loan is a type of loan that is secured by real estate property. In other words, if you fail to repay the loan, the lender has the right to take possession of the property. Mortgage loans are typically used to finance the purchase of a home or other real estate property. The loan is paid back over a set period of time, typically 15 to 30 years, with monthly payments.</div>
                                <div className="pgTxtNormal">How do Mortgage Loans work? When you apply for a mortgage loan, the lender will evaluate your creditworthiness and financial situation to determine whether you qualify for a loan. This evaluation includes checking your credit score, income, debt-to-income ratio, and employment history. If you are approved for a loan, the lender will then provide you with the funds to purchase the property.</div>
                                <div className="pgTxtNormal">The loan will be secured by a lien on the property, which means that if you fail to make your monthly payments, the lender has the right to foreclose on the property and sell it to recover their funds. It is important to note that mortgage loans typically have a lower interest rate than other types of loans because they are secured by the property.</div>
                                <div className="pgTxtBold">Types of Mortgage Loans</div>
                                <div className="pgTxtNormal">There are several types of mortgage loans available in the USA, including:</div>
                                <ul className="bulletPoints_un">
                                    <li className="bp_circleOutline"><b>Fixed-Rate Mortgage:</b> A fixed-rate mortgage is a type of mortgage loan where the interest rate remains the same throughout the life of the loan. This means that your monthly payment will remain the same, making it easier to budget.</li>
                                    <li className="bp_circleOutline"><b>Adjustable-Rate Mortgage:</b> An adjustable-rate mortgage is a type of mortgage loan where the interest rate can change over time. This means that your monthly payment may go up or down depending on the interest rate.</li>
                                    <li className="bp_circleOutline"><b>FHA Loan:</b> An FHA loan is a government-backed loan that is designed to help first-time homebuyers or those with lower credit scores. These loans have lower down payment requirements and lower credit score requirements than traditional mortgage loans.</li>
                                    <li className="bp_circleOutline"><b>VA Loan:</b> A VA loan is a type of mortgage loan that is available to veterans and their spouses. These loans are backed by the Department of Veterans Affairs and offer competitive interest rates and low down payment requirements.</li>
                                    <li className="bp_circleOutline"><b>Jumbo Loan:</b> A jumbo loan is a type of mortgage loan that is used to finance high-end homes. These loans have higher interest rates and stricter qualification requirements than traditional mortgage loans.</li>
                                </ul>
                                <div className="pgTxtNormal">In Conclusion, Mortgage loans are a popular way for Americans to finance the purchase of real estate property. There are several types of mortgage loans available, each with its own advantages and disadvantages. It is important to do your research and carefully consider your financial situation before applying for a mortgage loan. With the right mortgage loan, you can achieve your dream of homeownership and enjoy the benefits of owning a home.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
