import { React, Helper, TextInput2 as TextInput, CheckInput2 as CheckInput, DropDown2 as DropDown } from "../../../../common";

const Component = (props) => {
  const { item, errors, unittypes, statesList, housingtypes,
    onInputChanged, onBlurError, onInputClicked } = props;
  const rootpath = `residences.${item.index}`;

  const [mandatory, setMandatory] = React.useState(true);

  React.useEffect(() => {
    setMandatory(true);
    if (item?.currentyears > 1 && item?.previousAddressIndicator) {
      setMandatory(false);
    }
  }, [item]);

  const IsRentSelected = () => {
    return item?.residencyBasisType === 'Rent';
  }

  if (!mandatory) return null;

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">{item?.title}</div>
        <div className="form1003Blk_right">
          {item?.usePrevAddr && item?.index > 0 ? <CheckInput
            path={`${rootpath}.sameAsCurrentAddressIndicator`}
            value={item?.sameAsCurrentAddressIndicator}
            content={`Same as Current Address`}
            onInputChildChanged={onInputChanged} /> : ""
          }
        </div>
      </div>
      <div className="form1003ContentBlk">
        <ul className="formContentIIcol">
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Street</div>
            <div className="formMandatory" style={{ display: mandatory ? "inline-block" : "none" }}>*</div>
            <div className="locationicon"></div>
            <TextInput
              errors={errors}
              path={`${rootpath}.uRLA2020StreetAddress`}
              value={item?.uRLA2020StreetAddress}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
              requiredMessage={'Street is required'}
              dataRequired={mandatory}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Unit No</div>
            <div className="hastagicon"></div>
            <TextInput
              path={`${rootpath}.addressUnitIdentifier`}
              value={item?.addressUnitIdentifier}
              onInputChildChanged={onInputChanged}
              valuetype={'NFINANCE'} />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Unit Type</div>
            <div className="locationicon"></div>
            <DropDown
              path={`${rootpath}.addressUnitDesignatorType`}
              value={item?.addressUnitDesignatorType}
              onInputChildChanged={onInputChanged}
              items={unittypes}
              displayName={"text"}
              displayValue={"value"}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">City</div>
            <div className="formMandatory" style={{ display: mandatory ? "inline-block" : "none" }}>*</div>
            <div className="locationicon"></div>
            <TextInput
              path={`${rootpath}.addressCity`}
              value={item?.addressCity}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
              requiredMessage={'City is required'}
              dataRequired={mandatory}
              errors={errors}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">State</div>
            <div className="formMandatory" style={{ display: mandatory ? "inline-block" : "none" }}>*</div>
            <div className="locationicon"></div>
            <DropDown
              path={`${rootpath}.addressState`}
              value={item?.addressState}
              items={statesList}
              displayName={"text"}
              displayValue={"value"}
              errors={errors}
              onInputChildChanged={onInputChanged}
              onInputClicked={onInputClicked}
              onBlurError={onBlurError}
              requiredMessage={'State is required'}
              indexValue={1}
              dataRequired={mandatory}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Zip Code</div>
            <div className="formMandatory" style={{ display: mandatory ? "inline-block" : "none" }}>*</div>
            <div className="form_homeicon"></div>
            <TextInput
              path={`${rootpath}.addressPostalCode`}
              value={item?.addressPostalCode}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
              requiredMessage={'Zip Code is required'}
              dataRequired={mandatory}
              errors={errors}
              validationMessage={'Enter Valid Zip Code'}
              validate={true}
              valuetype={"ZIP"}
              required={true}
            />
          </li>
          <li className="formContentIIcol">
            <div className="form1003ContentHead">Country</div>
            <div className="formMandatory" style={{ display: mandatory ? "inline-block" : "none" }}>*</div>
            <div className="countryicon"></div>
            <TextInput
              path={`${rootpath}.country`}
              value={item?.country}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
              requiredMessage={'Country is required'}
              dataRequired={mandatory}
              errors={errors}
            />
          </li>

          {item?.index === 0 && (
            <li className="formContentIIcol">
              <div className="form1003ContentHead">Housing</div>
              <div className="formMandatory">*</div>
              <div className="form_homeicon"></div>
              <DropDown
                path={`${rootpath}.residencyBasisType`}
                value={item?.residencyBasisType}
                onInputChildChanged={onInputChanged}
                items={housingtypes}
                displayName={"text"}
                displayValue={"value"}
                errors={errors}
                onInputClicked={onInputClicked}
                dataRequired={true}
                requiredMessage={'Housing is required'}
                indexValue={1}
              />
            </li>
          )}

          {IsRentSelected() && (
            <li className="formContentIIcol">
              <div className="form1003ContentHead">Monthly Rent Amount</div>
              <div className="form_dollaricon"></div>
              <TextInput
                path={`${rootpath}.rent`}
                value={item?.rent}
                onInputChildChanged={onInputChanged}
                onBlurError={onBlurError}
                onInputClicked={onInputClicked}
                requiredMessage={'Monthly Rent Amount is required'}
                dataRequired={true}
                errors={errors}
                type={"FINANCE"} />
            </li>
          )}
          {item?.index === 0 && (
            <>
              <li className="formContentIIcol">
                <div className="form1003ContentHead">How Long at Address</div>
                <div className="formMandatory">*</div>
                <ul className="formContentIIcol_noBot">
                  <li className="formContentIIcol_noBot">
                    <div className="form_dateicon"></div>
                    <DropDown
                      path={`${rootpath}.durationTermYears`}
                      value={item?.durationTermYears}
                      onInputChildChanged={onInputChanged}
                      items={Helper.GetYears()}
                      displayName={"text"}
                      displayValue={"value"}
                      errors={errors}
                      onInputClicked={onInputClicked}
                      dataRequired={true}
                      requiredMessage={'How Long at Address is required'}
                      indexValue={1}
                    />
                  </li>
                  <li className="formContentIIcol_noBot">
                    <div className="form_dateicon"></div>
                    <DropDown
                      path={`${rootpath}.durationTermMonths`}
                      value={item?.durationTermMonths}
                      onInputChildChanged={onInputChanged}
                      items={Helper.GetMonths()}
                      displayName={"text"}
                      displayValue={"value"}
                      errors={errors}
                      onInputClicked={onInputClicked}
                      dataRequired={true}
                      requiredMessage={'How Long at Address is required'}
                      indexValue={1}
                    />
                  </li>
                </ul>
              </li>
              <li className="formContentIIcol">
                <div className="form1003ContentHead">&nbsp;</div>
                <div className="form1003NoteText">
                  Please fill previous address, if stay in current address is less than 2 years
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default Component;
