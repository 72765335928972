import React from "react";
import helper from "../helper";

const Component = (props) => {
  const { tag, name, value, items, displayName, displayValue,
    onInputChildChanged, index, borderColor, disabled, errors, width, parent, path } = props;

  const tItems = items || [];
  const tReadOnly = disabled || false;
  const lWidth = width || null;
  let tIndex = index || 0;

  const OnSelectInputChanged = (e) => {
    if (onInputChildChanged) {
      if (name) {
        onInputChildChanged(parent, path, name, e, tIndex);
      } else {
        onInputChildChanged(parent, path, e, tIndex);
      }
    }
  }

  const GetKeyName = () => {
    let _name = tag || "cboinput";
    if (name) {
      return `${_name}_${name.replace(".", "_")}_${tIndex}`;
    } else {
      return `${_name}_${tIndex}`;
    }
  }

  return (
    <>
      <select
        id={GetKeyName()}
        name={GetKeyName()}
        style={{
          width: lWidth,
          borderColor: helper.GetErrorIndex(errors, name) > -1 ? "#b22929" : borderColor
        }}
        disabled={tReadOnly}
        value={helper.ToString(value)}
        onChange={(e) => OnSelectInputChanged(e.target.value)}
      >
        {!helper.IsArrayNull(tItems) &&
          tItems.map((x, index) => {
            return (
              <option key={index} value={x[displayValue]}>
                {x[displayName]}
              </option>
            );
          })}
      </select>
    </>
  );
}

export default Component;
