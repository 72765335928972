const Component = () => {
    return (
        <div className="whyi3Blk">
            <div className="widthfix_10px">
                <div className="whyi3_blkHeadTxt">Why Choose i3 Lending?</div>
                <div className="whyi3_descTxt">You can trust us to provide affordable, flexible home lending solutions that fit your unique, real-life needs. Our simple products, pricing and process make it easy for you to get home financing.</div>
                <ul className="whyi3PointBlk">
                    <li className="whyi3PointBlk">
                        <div className="whyi3_icon1"></div>
                        <div className="whyi3_blockHead">2013</div>
                        <div className="whyi3_blockDesc">Year of Foundation</div>
                    </li>
                    <li className="whyi3PointBlk">
                        <div className="whyi3_icon2"></div>
                        <div className="whyi3_blockHead">25,000+</div>
                        <div className="whyi3_blockDesc">Happy Customers</div>
                    </li>
                    <li className="whyi3PointBlk">
                        <div className="whyi3_icon3"></div>
                        <div className="whyi3_blockHead">8,184</div>
                        <div className="whyi3_blockDesc">Issued Loans</div>
                    </li>
                    <li className="whyi3PointBlk">
                        <div className="whyi3_icon4"></div>
                        <div className="whyi3_blockHead">110+</div>
                        <div className="whyi3_blockDesc">Loan Officers</div>
                    </li>
                    <li className="whyi3PointBlk">
                        <div className="whyi3_icon5"></div>
                        <div className="whyi3_blockHead">115+</div>
                        <div className="whyi3_blockDesc">Business Partners</div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;