import API from "./api";
import session from "../session";

const SearchLoans = async (input) => {
  /* // Format
    let input = {
      loannumber: "12345",
      borrower: "borrower",
      lender: "lender",
      upload: false,
      stakes: false,
      lookup: false,
      list: false,
      manage: false,
      manageonly: false
    } */

  let token = await session.GetHeader();
  try {
    const res = await API.post(`loan/search`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const SetApproval = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`loan/setapproval`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetApproval = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`loan/getapproval`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const AddShortLoan = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`eloan/short`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const AddLoan = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`eloan/form1003`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetLoans = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`eloan/form1003`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetLoan = async (stage, loanid) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`eloan/form1003/${loanid}/${stage}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetShortList = async (loanid, typeid) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`eloan/form1003/list/${loanid}/${typeid}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

// Need to remove this
const GetLoanRawData = async (id) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`eloanraw/${id}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetLoanOffiers = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`loan/officers`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetAllLoanOffiers = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`loan/allofficers`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetMortgageRates = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`eloan/mortgagerates`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

export {
  AddLoan,
  GetLoans,
  GetLoan,
  GetApproval,
  SetApproval,
  SearchLoans,
  AddShortLoan,
  GetLoanRawData,
  GetLoanOffiers,
  GetAllLoanOffiers,
  GetMortgageRates,
  GetShortList
};
