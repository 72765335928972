import { React, Link, Helper, RadioInput2 as RadioInput, TextInput2 as TextInput } from "../../../../common";

const Component = (props) => {
    const { index, row, errors, onInputChanged, type,
        readOnlyFields, onBlurError, onInputClicked, coborrower } = props;

    let popupTitle = "Co-Borrower";

    const tIndex = index === -1 ? "" : `.${index}`;
    const rootpath = `applicants${tIndex}`;
    const borrowerpath = `applicants${tIndex}.borrower`;
    const coborrowerpath = `applicants${tIndex}.coborrower`;

    const formStyle = coborrower ? "formContentIIcol" : "formContentIVcol";

    const IsCoBorrowerChecked = () => {
        return Helper.ToUndefinedCheck(Helper.GetJsonValue(row, `${rootpath}.coBorrowerIndicator`));
    }

    const GetBorrowerHeader = (childname) => {
        if (type === 'Applicant') {
            if (childname) return 'Co-Borrower Details';
            popupTitle = "Co-Borrower";
            return 'Personal Details';
        }
        let fName = Helper.GetJsonValue(row, `${borrowerpath}.firstName`);
        let lName = Helper.GetJsonValue(row, `${borrowerpath}.lastName`);
        let obj = { fName, lName };
        let _title = Helper.GetStringJoin(obj, ['fName', 'lName']);
        let _newTitle = Helper.IsNullValue(_title) ? `Co-Applicant ${tIndex}: ${childname} Details` : `Co-Applicant ${tIndex}: ${_title} ${childname} Details`;
        popupTitle = _newTitle;
        return _newTitle;
    }

    return (
        <>
            <div className="form1003BlkHead">
                <div className="formHeadContent">{GetBorrowerHeader("")}</div>
                <div className="form1003Blk_right">&nbsp;</div>
            </div>
            <div className="form1003ContentBlk">
                <ul className={formStyle}>
                    <li className={formStyle}>
                        <div className="form1003ContentHead">First Name</div>
                        <div className="formMandatory">*</div>
                        <div className="usericon"></div>
                        <TextInput
                            errors={errors}
                            path={`${borrowerpath}.firstName`}
                            value={Helper.GetJsonValue(row, `${borrowerpath}.firstName`)}
                            onInputChildChanged={onInputChanged}
                            required={true}
                            dataRequired={true}
                            readOnly={readOnlyFields?.indexOf('firstName') > -1}
                            requiredMessage={'First name is required'}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                        />
                    </li>
                    <li className={formStyle}>
                        <div className="form1003ContentHead">Middle Name</div>
                        <div className="usericon"></div>
                        <TextInput
                            path={`${borrowerpath}.middleName`}
                            value={Helper.GetJsonValue(row, `${borrowerpath}.middleName`)}
                            onInputChildChanged={onInputChanged}
                        />
                    </li>
                    <li className={formStyle}>
                        <div className="form1003ContentHead">Last Name</div>
                        <div className="formMandatory">*</div>
                        <div className="usericon"></div>
                        <TextInput
                            errors={errors}
                            path={`${borrowerpath}.lastName`}
                            value={Helper.GetJsonValue(row, `${borrowerpath}.lastName`)}
                            onInputChildChanged={onInputChanged}
                            readOnly={readOnlyFields?.indexOf('lastName') > -1}
                            required={true}
                            dataRequired={true}
                            requiredMessage={'Last name is required'}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                        />
                    </li>
                    <li className={formStyle}>
                        <div className="form1003ContentHead">Cell Phone</div>
                        <div className="formMandatory">*</div>
                        <div className="phicon"></div>
                        <TextInput
                            errors={errors}
                            maxLength={14}
                            path={`${borrowerpath}.mobilePhone`}
                            value={Helper.GetJsonValue(row, `${borrowerpath}.mobilePhone`)}
                            valuetype={"PHONE"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            required={true}
                            validate={true}
                            requiredMessage={'Cell phone is required'}
                            validationMessage={'Enter valid cell phone'}
                            dataRequired={true}
                        />
                    </li>
                    <li className={formStyle}>
                        <div className="form1003ContentHead">Work Phone</div>
                        <div className="phicon"></div>
                        <TextInput
                            errors={errors}
                            path={`${borrowerpath}.workPhone`}
                            value={Helper.GetJsonValue(row, `${borrowerpath}.workPhone`)}
                            valuetype={"PHONE"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            validate={true}
                            validationMessage={'Enter valid work phone'}
                        />
                    </li>
                    <li className={formStyle}>
                        <div className="form1003ContentHead">Email Address</div>
                        <div className="formMandatory">*</div>
                        <div className="mailicon"></div>
                        <TextInput
                            errors={errors}
                            path={`${borrowerpath}.emailAddressText`}
                            value={Helper.GetJsonValue(row, `${borrowerpath}.emailAddressText`)}
                            valuetype={"EMAIL"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            validate={true}
                            required={true}
                            requiredMessage={'Email-Id is required'}
                            validationMessage={'Enter valid email-id'}
                            dataRequired={true}
                        />
                    </li>
                </ul>

                {coborrower && (
                    <>
                        <ul className="formContentIIcol">
                            <li className="formContentIIcol">
                                <div className="form1003ContentHead">Is there a Co-Borrower?</div>
                                <Link to="#" onClick={(e) => Helper.OnPopupInfoClicked(e, 'info_coborrower', popupTitle)}><div className="formTip"></div></Link>
                                <ul className="form_A" data-opts={true} data-req-msg={"Is there a Co-Borrower?"} data-id={`${rootpath}.coBorrowerIndicator`}>
                                    <RadioInput
                                        path={`${rootpath}.coBorrowerIndicator`}
                                        errors={errors}
                                        value={Helper.ToUndefinedCheck(Helper.GetJsonValue(row, `${rootpath}.coBorrowerIndicator`))}
                                        options={Helper.Options()}
                                        uselabel={false}
                                        onInputChildChanged={onInputChanged}
                                    />
                                </ul>
                            </li>
                        </ul>
                        {IsCoBorrowerChecked() && (
                            <>
                                <div className="form1003BlkInsideHead">
                                    <div className="formHeadContent_85pc">{GetBorrowerHeader('Co-Borrower')}</div>
                                </div>
                                <ul className={formStyle}>
                                    <li className={formStyle}>
                                        <div className="form1003ContentHead">First Name</div>
                                        <div className="formMandatory">*</div>
                                        <div className="usericon"></div>
                                        <TextInput
                                            errors={errors}
                                            path={`${coborrowerpath}.firstName`}
                                            value={Helper.GetJsonValue(row, `${coborrowerpath}.firstName`)}
                                            onInputChildChanged={onInputChanged}
                                            onBlurError={onBlurError}
                                            onInputClicked={onInputClicked}
                                            required={true}
                                            requiredMessage={'First name is required'}
                                            dataRequired={true}
                                        />
                                    </li>
                                    <li className={formStyle}>
                                        <div className="form1003ContentHead">Middle Name</div>
                                        <div className="usericon"></div>
                                        <TextInput
                                            path={`${coborrowerpath}.middleName`}
                                            value={Helper.GetJsonValue(row, `${coborrowerpath}.middleName`)}
                                            onInputChildChanged={onInputChanged}
                                        />
                                    </li>
                                    <li className={formStyle}>
                                        <div className="form1003ContentHead">Last Name</div>
                                        <div className="formMandatory">*</div>
                                        <div className="usericon"></div>
                                        <TextInput
                                            errors={errors}
                                            path={`${coborrowerpath}.lastName`}
                                            value={Helper.GetJsonValue(row, `${coborrowerpath}.lastName`)}
                                            onInputChildChanged={onInputChanged}
                                            onBlurError={onBlurError}
                                            onInputClicked={onInputClicked}
                                            required={true}
                                            requiredMessage={'Last name is required'}
                                            dataRequired={true}
                                        />
                                    </li>
                                    <li className={formStyle}>
                                        <div className="form1003ContentHead">Cell Phone</div>
                                        <div className="formMandatory">*</div>
                                        <div className="phicon"></div>
                                        <TextInput
                                            errors={errors}
                                            maxLength={14}
                                            path={`${coborrowerpath}.mobilePhone`}
                                            value={Helper.GetJsonValue(row, `${coborrowerpath}.mobilePhone`)}
                                            valuetype={"PHONE"}
                                            onInputChildChanged={onInputChanged}
                                            onBlurError={onBlurError}
                                            onInputClicked={onInputClicked}
                                            required={true}
                                            validate={true}
                                            requiredMessage={'Cell phone is required'}
                                            validationMessage={'Enter valid cell phone'}
                                            dataRequired={true}
                                        />
                                    </li>
                                    <li className={formStyle}>
                                        <div className="form1003ContentHead">Work Phone</div>
                                        <div className="phicon"></div>
                                        <TextInput
                                            errors={errors}
                                            path={`${coborrowerpath}.workPhone`}
                                            value={Helper.GetJsonValue(row, `${coborrowerpath}.workPhone`)}
                                            valuetype={"PHONE"}
                                            onInputChildChanged={onInputChanged}
                                            onBlurError={onBlurError}
                                            onInputClicked={onInputClicked}
                                            validationMessage={'Enter valid work phone'}
                                            validate={true}
                                        />
                                    </li>
                                    <li className={formStyle}>
                                        <div className="form1003ContentHead">Email Address</div>
                                        <div className="formMandatory">*</div>
                                        <div className="mailicon"></div>
                                        <TextInput
                                            errors={errors}
                                            path={`${coborrowerpath}.emailAddressText`}
                                            value={Helper.GetJsonValue(row, `${coborrowerpath}.emailAddressText`)}
                                            valuetype={"EMAIL"}
                                            onInputChildChanged={onInputChanged}
                                            onBlurError={onBlurError}
                                            onInputClicked={onInputClicked}
                                            required={true}
                                            validate={true}
                                            requiredMessage={'Email-Id is required'}
                                            validationMessage={'Enter valid email-id'}
                                            dataRequired={true}
                                        />
                                    </li>
                                </ul>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default Component;