import { React, Session, Storage, Helper, RadioInput, TextInput, DropDown } from "../../../common";
import { OptionTypes } from "../../../helper/support";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";

let errorStyle = {
  borderColor: "#b22929",
  borderStyle: "solid",
  borderWidth: 1,
};

const Component = ({ onClick }) => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [stage, setStage] = React.useState(0);
  const [state, setState] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  if (initialized) {
    setInitialized(false);
    setErrors([]);
    let data = Storage.RetrieveJSON("stages");
    setStage(4);
    let _row = data.stage4 || {};
    setRow(_row);
  }

  const OnInputChanged = async (parent, path, name, value) => {
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnDummyChanged = () => { };

  const OnNavClicked = async (type, e) => {
    setError(null);
    setErrors([]);

    if (type === "prev") {
      if (onClick) onClick(e);
      return;
    }

    let _fields = [
      "acceptAggrement",
      "coBorrowerIndicator",
      "totalMonthlyDebtPayments",
      "totalAnnualGrossIncome",
    ];

    let _errs = [];
    _fields.forEach((elm) => {
      _errs.push({ field: elm });
    });

    if (Helper.IsJSONEmpty(row)) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    _errs = [];
    _fields = ["acceptAggrement", "coBorrowerIndicator"];
    let isInvalid = false;

    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        _errs.push({ field: elm });
        isInvalid = true;
      }
    });

    if (!isInvalid && Helper.IsJSONEmpty(row?.borrower)) {
      _errs.push({ field: "coBorrowerIndicator" });
      isInvalid = true;
    }

    if (!isInvalid) {
      _fields = ["totalMonthlyDebtPayments", "totalAnnualGrossIncome"];
      _fields.forEach((elm) => {
        if (Helper.IsNullValue(row.borrower[elm])) {
          _errs.push({ field: elm });
          isInvalid = true;
        }
      });
    }

    if (!isInvalid && row.coBorrowerIndicator) {
      if (!isInvalid && Helper.IsJSONEmpty(row?.coborrower)) {
        isInvalid = true;
        _errs.push({ field: "addressText" });
        _errs.push({ field: "emailAddressText" });
        _errs.push({ field: "relationType" });
      }
      if (!isInvalid) {
        _fields = ["addressText", "emailAddressText", "relationType"];
        _fields.forEach((elm) => {
          if (Helper.IsNullValue(row.coborrower[elm])) {
            _errs.push({ field: elm });
            isInvalid = true;
          }
        });

        if (!Helper.IsEmailValid(row.coborrower["emailAddressText"])) {
          _errs.push({ field: "emailAddressText" });
          isInvalid = true;
        }
      }
    }

    if (isInvalid) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let data = Storage.RetrieveJSON("stages");
    data["stage4"] = row;
    let tmp = parseInt(e);
    if (!row.coBorrowerIndicator) tmp++;
    if (!row.coBorrowerIndicator && Session.IsLoggedIn()) tmp++;
    data["stage"] = tmp;
    Storage.StoreJSON("stages", data);
    if (onClick) onClick(tmp);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  return (
    <>
      <RenderBreadCrumb stage={4} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead">Great! Let us know about your income</li>
          <li className="countBlk">
            <div className="countNo">4/5</div>
          </li>
        </ul>
        <div className="purchase">
          <div className="spacer_10margin_bottom">
            <ul className="II_col">
              <li className="II_col">
                <div className="pur_Q">Total Annual Gross Income</div>
                <TextInput
                  currency="$"
                  maxLength={14}
                  placeholder="Total Annual Gross Income"
                  tag={"borrower.totalAnnualGrossIncome"}
                  path={"borrower.totalAnnualGrossIncome"}
                  name={"totalAnnualGrossIncome"}
                  value={Helper.ToString(row?.borrower?.totalAnnualGrossIncome)}
                  type={"NFINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
              <li className="II_col">
                <div className="pur_Q">Total Monthly Debt Payments</div>
                <TextInput
                  currency="$"
                  maxLength={14}
                  placeholder="Total Monthly Debt Payments"
                  tag={"borrower.totalMonthlyDebtPayments"}
                  path={"borrower.totalMonthlyDebtPayments"}
                  name={"totalMonthlyDebtPayments"}
                  value={Helper.ToString(
                    row?.borrower?.totalMonthlyDebtPayments
                  )}
                  type={"NFINANCE"}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </li>
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">Do you want to add a Co-borrower?</div>
            <ul className="pur_A">
              <RadioInput
                options={OptionTypes()}
                path={`coBorrowerIndicator`}
                tag={`coBorrowerIndicator`}
                name={"coBorrowerIndicator"}
                uselabel={false}
                value={row?.coBorrowerIndicator}
                onInputChildChanged={OnInputChanged}
                errors={errors}
              />
            </ul>
          </div>
          {Helper.ToBool(row?.coBorrowerIndicator) && (
            <>
              <div className="spacer_25margin_bottom">
                <div className="pur_Q">Co-borrower Primary Address</div>
                <TextInput
                  placeholder="City or Zip"
                  tag={"coborrower.addressText"}
                  path={"coborrower.addressText"}
                  name={"addressText"}
                  value={Helper.ToString(row?.coborrower?.addressText)}
                  onInputChildChanged={OnInputChanged}
                  errors={errors}
                />
              </div>
              <div className="spacer_20margin_bottom">
                <ul className="II_col">
                  <li className="II_col">
                    <div className="pur_Q">
                      Relationship to Primary Borrower
                    </div>
                    <DropDown
                      tag={"coborrower_relationType"}
                      path={"coborrower.relationType"}
                      name={"relationType"}
                      value={Helper.ToString(row?.coborrower?.relationType)}
                      items={Helper.RelationTypes}
                      displayName={"text"}
                      displayValue={"value"}
                      onInputChildChanged={OnInputChanged}
                      errors={errors}
                    />
                  </li>
                  <li className="II_col">
                    <div className="pur_Q">Email of Co-borrower</div>
                    <TextInput
                      placeholder="Email Address"
                      tag={"coborrower.emailAddressText"}
                      path={"coborrower.emailAddressText"}
                      name={"emailAddressText"}
                      value={Helper.ToString(row?.coborrower?.emailAddressText)}
                      onInputChildChanged={OnInputChanged}
                      errors={errors}
                      type={"EMAIL"}
                      onBlurError={OnBlurError}
                      onInputClicked={OnInputClicked}
                      required={true}
                    />
                  </li>
                </ul>
              </div>
            </>
          )}

          <div
            className="spacer_25margin_bottom"
            style={
              Helper.GetErrorIndex(errors, "acceptAggrement") > -1
                ? errorStyle
                : { borderWidth: 1 }
            }
          >
            <input
              type="checkbox"
              id="acceptAggrement"
              checked={Helper.ToBool(row?.acceptAggrement)}
              onChange={() => OnDummyChanged()}
              onClick={(e) =>
                OnInputChanged(
                  null,
                  "acceptAggrement",
                  "acceptAggrement",
                  e.target.checked
                )
              }
            />
            <label htmlFor="acceptAggrement">
              We understand that by adding a co-borrower we are agreeing to
              apply for credit jointly. By continuing with the application, we
              will have access to application information and documents that are
              submitted by either borrower. This includes but is not limited to:
              income, credit score and factors impacting your credit score. If
              you do not wish to apply jointly, please remove the co-borrower.
            </label>
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              onClick={(e) => OnNavClicked("prev", stage - 1)}
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked("next", stage + 1)}
            />
          </div>
          {error && (
            <div className="errormessage">{error}</div>
          )}
        </div>
      </li>
    </>
  );
};

export default Component;
