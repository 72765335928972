import { Link } from "../../../common";

const Component = () => {
  const OnApplyNowClicked = (e) => {
    e.preventDefault();
    let _link = `${window.location.href}?q=/stage1`;
    window.location.href = _link;
  };

  return (
    <Link to="#" onClick={(e) => OnApplyNowClicked(e)}>
      <input type="button" value="Apply Now" className="loButton" />
    </Link>
  );
};

export default Component;
