import { React, Link, useNavigate } from "../../../common";
import TabNavigations from "../components/tablist";

const tabItemsList = [
  {
    id: "idthirty",
    header: "30 Year Fixed",
    items: [
      "Most popular mortgage plan",
      "No rising rates",
      "Steady, affordable monthly payments",
      "Qualify for higher loan amounts",
    ],
    link: "/thirty",
  },
  {
    id: "idfha",
    header: "FHA Loans",
    items: [
      "Down payment as low as 3.5%",
      "Include all costs in one loan",
      "Steady, affordable monthly payments",
      "Qualify for higher loan amounts",
    ],
    link: "/fha",
  },
  {
    id: "idva",
    header: "VA Loans",
    items: [
      "No money down up to $417,000",
      "No PMI (Private Mortgage Insurance)",
      "Loans can be closed in 7 to 10 days",
      "Qualify for higher loan amounts",
    ],
    link: "/va",
  },
  {
    id: "idreverse",
    header: "Reverse Mo",
    items: [
      "Receive money from the loan",
      "Include all costs in one loan",
      "Steady, affordable monthly payments",
      "Qualify for higher loan amounts",
    ],
    link: "/reverse",
  },
  {
    id: "idusda",
    header: "USDA Loans",
    items: [
      "No down payment required",
      "Competitive 30-year fixed interest rates",
      "Flexible credit guidelines",
      "No maximum purchase limit",
    ],
    link: "/usda",
  },
  {
    id: "idjumbo",
    header: "Jumbo Loans",
    items: [
      "No maximum purchase limit",
      "Flexible credit guidelines",
      "Steady, affordable monthly payments",
      "Qualify for higher loan amounts",
    ],
    link: "/jumbo",
  },
  {
    id: "idrenovation",
    header: "Renovation",
    items: [
      "Receive money from the loan",
      "Include all costs in one loan",
      "Steady, affordable monthly payments",
      "Qualify for higher loan amounts",
    ],
    link: "/renovation",
  },
  {
    id: "idnonqm",
    header: "Non-QM",
    items: [
      "Receive money from the loan",
      "Include all costs in one loan",
      "Steady, affordable monthly payments",
      "Qualify for higher loan amounts",
    ],
    link: "/nonqm",
  },
  {
    id: "idconventional",
    header: "FHA Loans",
    items: [
      "Receive money from the loan",
      "Include all costs in one loan",
      "Steady, affordable monthly payments",
      "Qualify for higher loan amounts",
    ],
    link: "/conventional",
  },
];

const Component = () => {
  const [tabInfo, setTabInfo] = React.useState(null);
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    setTabInfo(tabItemsList[0]);
  }, []);

  const OnTabClicked = (e) => {
    let iElm = tabItemsList.filter((x) => x.id === e);
    if (iElm && iElm.length > 0) {
      setTabInfo(iElm[0]);
    } else {
      setTabInfo(tabItemsList[0]);
    }
  };

  const OnLinkClicked = (e, link) => {
    e.preventDefault();
    NavigateTo(link);
  };

  const OnQuoteClicked = (e) => {
    e.preventDefault();
    document.getElementById("rateQuote_pop").style.display = "block";
  };

  return (
    <>
      <div className="LoanOptionBlk">
        <div className="widthfix_10px">
          <div className="lo_blkHead">Review Your Loan Options</div>
          <div className="lo_blkTxtDesc">
            No two loans are alike. Let us explain each options and you can
            decide what program is best for you.{" "}
          </div>
          <ul className="loPointsDescBlk">
            <li className="loPointsDescBlk">
              <TabNavigations
                type={"thirty"}
                home={true}
                onTabClicked={OnTabClicked}
              />
            </li>
            <li className="loPointsDescBlk">
              <ul className="loDescBlk">
                <li className="loDescBlk">
                  <div className="loContentBox">
                    <div className="loDescHead">{tabInfo?.header}</div>
                    <ul className="loDescPoints">
                      {tabInfo?.items.map((x, index) => (
                        <li className="loDescPoints" key={index}>
                          {x}
                        </li>
                      ))}
                    </ul>
                    <ul className="loButtonLabel">
                      <Link
                        to={"#"}
                        onClick={(e) => OnLinkClicked(e, tabInfo?.link)}
                      >
                        <li className="loButtonLabel">
                          <input
                            type="button"
                            value="Learn More"
                            className="loButton"
                          />
                        </li>
                      </Link>
                      <li className="loGetQuote">
                        <Link
                          to="#"
                          className="getQuotelink"
                          onClick={(e) => OnQuoteClicked(e)}
                        >
                          Get a Quote
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="loDescBlk"></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Component;
