import { React } from "../../../../common";
import Container from "../../../container";
import TabNavigations from "../../components/tablist";
import ApplyNow from "../applynow";

const Component = () => {
  return (
    <>
      <Container>
        <div id="container">
          <div id="content_fullHeader">
            <div id="heroImg_lo_30">
              <ul className="bc_bg">
                <div className="widthfix_10px">
                  <li className="bc_col">
                    <div className="bcBlkDesc">
                      <div className="blkDescTxt">Loan Options</div>
                      <div className="rightCurve"></div>
                    </div>
                    <div className="bcHeadTxt_topTxt_noRight">USDA Loans</div>
                  </li>
                  <li className="bc_col">&nbsp;</li>
                </div>
              </ul>
            </div>

            <div className="widthfix_10px">
              <ul className="loPgStructure">
                <li className="loPgStructure">
                  <TabNavigations type="usda" />
                </li>
                <li className="loPgStructure">
                  <ul className="loPointsBlk">
                    <li className="loPointsBlk">
                      <ul className="loDescPoints">
                        <li className="loDescPoints">
                          No down payment required
                        </li>
                        <li className="loDescPoints">
                          Competitive 30-year fixed interest rates
                        </li>
                        <li className="loDescPoints">
                          Flexible credit guidelines
                        </li>
                        <li className="loDescPoints">
                          No maximum purchase limit
                        </li>
                        <li className="loDescPoints">
                          Steady, affordable monthly payments
                        </li>
                        <li className="loDescPoints">
                          Qualify for higher loan amounts
                        </li>

                        <ApplyNow />
                      </ul>
                    </li>
                    <li className="loPointsBlk">
                      <div className="pgTxtNormal">
                        The United States Department of Agriculture, or USDA,
                        has developed a home loan guarantee program, aimed
                        towards fostering rural development and helping
                        qualified borrowers obtain mortgages in rural areas.
                        With its many benefits and easier qualifications, USDA
                        loans are a viable option for many people, including
                        low-income families who may not otherwise be able to
                        afford a home.
                      </div>
                      <div className="pgTxtNormal">
                        Below are some of the benefits:
                      </div>
                      <ul className="bulletPoints_un">
                        <li className="bp_circleOutline">
                          <b>No down payment required:</b> <br />
                          Whereas many conventional loan programs require a 20%
                          down payment, many USDA loans allow zero % down
                          payment.With the ability to receive financing up to
                          100% of the appraised home value, homebuyers don't
                          have to spend any money out of pocket.
                        </li>
                        <li className="bp_circleOutline">
                          <b>Competitive 30-year fixed interest rates:</b>
                          <br />
                          With the guarantee of the federal government, a lender
                          can offer the lowest interest rates to qualified
                          individuals and families.
                        </li>
                        <li className="bp_circleOutline">
                          <b>Flexible credit guidelines: </b> <br />
                          Although a credit report must be pulled, the USDA
                          Rural Development program has less stringent credit
                          guidelines, allowing potential homeowners with poor
                          credit the ability to qualify for a home loan.
                        </li>
                        <li className="bp_circleOutline">
                          <b>No maximum purchase limit:</b>
                          <br />
                          The USDA Rural Development program has no maximum
                          purchase price limit. However, a lender will still
                          determine the maximum amount of loan each applicant is
                          eligible for based on ability to repay.
                        </li>
                      </ul>
                      <div className="pgTxtNormal">
                        It is important to note that USDA loans were not
                        exclusively designed for single-family housing. The
                        loans may be applied to new homes; pre-existing homes;
                        condominiums; townhouses; land and the cost of building
                        a home; commercial property; and farms and equipment.
                      </div>
                      <div className="pgTxtNormal">
                        While there are many benefits of USDA loans, there are
                        also certain criteria you must meet in order to be
                        eligible, including location of the house, household
                        income, credit history, and number of dependents
                        claimed.
                      </div>
                      <div className="pgTxtNormal">
                        The team at i3 Lending is well-versed in USDA
                        guidelines, so speaking with one of our loan officers
                        will help you get on the right track. You may also visit
                        the USDA website to find houses located in eligible
                        rural areas defined by the USDA.
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
