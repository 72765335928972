const Component = () => {
    return (
        <div id="info_loanTerm" className="pop_disable_bg">
            <div className="loanTerm_pop">
                <div className="pop_head">Loan Term in Months</div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Loan Term in Months - </div>
                    <div className="pop_content">This is the length of your loan in months. For example if you are applying for a 30 year loan it would be 360 months. A 15 year loan would be 180 months and 20 year loan would be 240 months.</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_loanTerm')} />
                </div>
            </div>
        </div>
    );
};

export default Component;