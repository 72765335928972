import { React, useNavigate, Helper, Storage } from "../../common";

const Component = () => {
  const NavigateTo = useNavigate();
  const [area, setArea] = React.useState("");
  const [error, setError] = React.useState(null);

  const OnValidSubmit = async () => {
    setError(null);

    let data = {
      stage7: {
        borrower: {
          postalCode: area
        }
      },
      stage: 1,
    };

    await Storage.StoreAsync("preapprove", 1);
    await Storage.StoreAsync("stages", JSON.stringify(data));
    NavigateTo("/preapprove");
  };

  const OnCancelClicked = (e) => {
    setArea("");
    setError(null);
    document.getElementById("ziplook_pop").style.display = "none";
  };

  const OnSubmit = async (e) => {
    e.preventDefault();
    if (Helper.IsNullValue(area)) {
      setError("Enter City/Zip code");
      return;
    }
    global.Busy(true);
    await Helper.IsValidZipCode(area)
      .then(() => {
        global.Busy(false);
        OnValidSubmit();
      })
      .catch(() => {
        global.Busy(false);
        setError('Enter Valid ZipCode');
      });
  }

  React.useEffect(() => {
    setArea("");
    setError(null);
  }, []);

  return (
    <div id="ziplook_pop" className="loader display-none">
      <section className="popup-modal">
        <div className="ziplook_pop" style={{ top: "20%" }}>
          <div className="ziplook_ContentArea">
            <div className="ziplook_Head">Where are you looking?</div>
            <input
              type="text"
              placeholder="City or Zip Code"
              required
              value={Helper.ToString(area)}
              onChange={(e) => {
                setError(null);
                setArea(e.target.value);
              }}
            />
            {error && (
              <div className="errormessage" style={{ marginTop: 5 }}>
                {error}
              </div>
            )}
            <div className="ziplook_button_label">
              <input
                type="button"
                className="ziplookBut"
                value="Submit"
                onClick={(e) => OnSubmit(e)}
              />
              <input
                type="button"
                className="ziplookBut"
                value="Cancel"
                style={{ marginLeft: 5 }}
                onClick={(e) => OnCancelClicked(e)}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Component;
