import Container from "../container";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="dashboard_pgBlk">
                                <div>Completed List</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
