import { React } from "../../../../common";
import Container from "../../../container";
import { AmtCalc } from "../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_buy_first">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Buy a Home</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Buy your First Home</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Monthly Payment</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">Buying your first home is an exciting time, and you may find the process complicated and intimidating. Get on the right track early with information to help you choose a home and mortgage that best meet your needs.</div>
                                    <div className="pgTxtNormal">Building equity is a strong financial reason to buy a home - plus many people enjoy the personal satisfaction of homeownership. However, buying a home is not right for everyone. Renting can provide flexibility to quickly react to career and income changes but doesn't build equity or have the potential tax benefits of homeownership (consult your tax professional for more information).</div>
                                    <div className="pgTxtNormal">There's a lot to learn and do once you've decided to buy your first home and you may have a lot of questions, including:</div>
                                    <ul className="bulletPoints_un">
                                        <li className="bp_circleOutline">How much can I afford?</li>
                                        <li className="bp_circleOutline">How do I prepare for my mortgage application?</li>
                                        <li className="bp_circleOutline">How do I make an offer and negotiate the purchase price?</li>
                                        <li className="bp_circleOutline">How much cash do I need for my home purchase?</li>
                                        <li className="bp_circleOutline">What are the steps from mortgage application to purchase?</li>
                                    </ul>
                                </li>
                                <li className="IIcol_100by400">
                                    <AmtCalc title={"Monthly EMI Calculator"} />
                                </li>
                            </ul >
                        </div >

                    </div >
                </div >
            </Container>
        </>
    )
}

export default Component;