import { React, Link, Helper } from "../../common";
import { SubcribeForUpdates } from "../../services/commonApi";

const FooterFull = () => {
  const [email, setEmail] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [success, setSuccess] = React.useState(null);

  React.useEffect(() => {
    setErrors([]);
    setEmail(null);
    setSuccess(null);
  }, []);

  const OnSubscribeUpdates = async (e) => {
    e.preventDefault();
    setErrors([]);
    if (!Helper.IsEmailValid(email)) {
      let tmp = { field: "subemail" };
      setErrors([tmp]);
      return;
    }

    global.Busy(true);
    let rslt = await SubcribeForUpdates({ email: email });
    global.Busy(false);
    if (rslt.status !== 100) {
      let tmp = { field: "subemail" };
      setErrors([tmp]);
      return;
    }

    setSuccess(rslt.statusText);
  };

  const OnEmailUpdate = async (e) => {
    setSuccess(null);
    setEmail(e);
  };

  const OnNmlsLookupClicked = (e) => {
    e.preventDefault();
    const _link =
      "http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1020884";
    window.open(_link, "_blank");
  };

  const OnTexasRecoveryClicked = (e) => {
    e.preventDefault();
    const _link =
      "https://www.i3lending.com/wp-content/uploads/2019/02/edited_i3_noticeword.pdf";
    window.open(_link, "_blank");
  };

  return (
    <div id="footer_home">
      <div className="widthfix">
        <ul className="footerIIIcol">
          <li className="footerIIIcol">
            <ul className="footerLogoSocial">
              <li className="footerLogoSocial">
                <div className="i3LendingWhiteLogo footerLogoSetting"></div>
                <div className="footerNMLStxt">NMLS Id:</div>
                <div className="footerNMLStxt">1020884</div>
              </li>
              <li className="footerLogoSocial">
                <div className="footerHeadText">Social</div>
                <ul className="socialmedia">
                  <li className="socialmedia">
                    <div className="icon_fb"></div>
                  </li>
                  <li className="socialmedia">
                    <div className="icon_twitter"></div>
                  </li>
                  <li className="socialmedia">
                    <div className="icon_linkedin"></div>
                  </li>
                  <li className="socialmedia">
                    <div className="icon_youtube"></div>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="footerCopyright">
              i3 Lending Inc &copy; {new Date().getFullYear()} | All rights
              reserved.
            </div>
            <div className="footerTerms">
              <Link to="/terms" className="termsLink">
                Terms of Use
              </Link>
              &nbsp; | &nbsp;
              <Link to="/privacy" className="termsLink">
                Privacy Policy
              </Link>
            </div>
          </li>
          <li className="footerIIIcol">
            <div className="footerHeadText">Quick Links</div>
            <ul className="footerQuickLink">
              <li className="footerQuickLink">
                <Link to="/reviews" className="quickLink">
                  Reviews
                </Link>
              </li>
              <li className="footerQuickLink">
                <Link to="/branch" className="quickLink">
                  Branch Locations
                </Link>
              </li>
              <li className="footerQuickLink">
                <Link to="/disclosure" className="quickLink">
                  Disclosures and Licenses
                </Link>
              </li>
              <li className="footerQuickLink">
                <Link
                  to="#"
                  className="quickLink"
                  onClick={(e) => OnNmlsLookupClicked(e)}
                >
                  NMLS Consumer Access Lookup
                </Link>
              </li>
              <li className="footerQuickLink">
                <Link
                  to="#"
                  className="quickLink"
                  onClick={(e) => OnTexasRecoveryClicked(e)}
                >
                  Texas Recovery Fund Notice
                </Link>
              </li>
            </ul>
          </li>
          <li className="footerIIIcol">
            <div className="footerHeadText">Get Updates</div>
            <div className="footerGetUpdate">
              We'll keep you posted on rates, mortgage news, home tips and other
              great offers
            </div>
            <input
              type="email"
              placeholder="Your E-mail"
              value={Helper.ToString(email)}
              onChange={(e) => OnEmailUpdate(e.target.value)}
              style={{
                borderColor: Helper.GetErrorBackColor(errors, "subemail"),
              }}
            />
            <input
              type="button"
              defaultValue="Subscribe"
              className="footer_button"
              onClick={(e) => OnSubscribeUpdates(e)}
            />
            {success && <div className="successmessage"> {success}</div>}
          </li>
        </ul>
      </div>
    </div>
  );
};

const FooterSmall = () => {
  return (
    <div id="footer">
      <div className="footer_blk">
        <div className="widthfix">
          <ul className="footerLinks">
            <li className="footerLinks">
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            {/* <li className="footerLinks"><Link to="#">Cookies</Link></li> */}
            <li className="footerLinks">
              <Link to="/terms">Terms Of Use</Link>
            </li>
            {/* <li className="footerLinks"><Link to="#">Accessibility</Link></li> */}
            <li className="footerLinks">
              <Link to="/about">About Us</Link>
            </li>
            <li className="footerLinks">
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
          <div className="footer_copyright">
            i3 Lending Inc &copy; {new Date().getFullYear()} &nbsp;All Rights
            Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

const Component = ({ type }) => {
  return type ? <FooterFull /> : <FooterSmall />;
};

export default Component;
