import { React, Link } from "../../../common";
import { GetAlerts } from "../../../services/dashboardApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [alertList, setAlertList] = React.useState([]);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    global.Busy(true);
    setAlertList([]);
    let rslt = await GetAlerts();
    if (rslt.status === 100) {
      let tmp = rslt.data || [];
      setAlertList(tmp);
    }
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  return (
    <div className="app_box_shadow_noPad">
      <div className="app_headBlk_pad20">
        <div className="app_headBlk_blkName">Notifications</div>
        <div className="blockRefresh_dash"></div>
      </div>
      <div className="appContentarea">
        <div className="dash_notify_blk">
          <div className="dash_notify_head">
            <table>
              <tbody>
                <tr>
                  <td>Particulars</td>
                  <td>Date</td>
                  <td>Action</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="dash_notify">
            {alertList && alertList.length > 0 ? (
              <table>
                <tbody>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="noContent">No new notifictions</div>
            )}
          </div>
        </div>
      </div>
      <div className="appBox_bottom">
        <Link to="#">View All Notifications</Link>
      </div>
    </div>
  );
};

export default Component;
