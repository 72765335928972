import { Link } from "react-router-dom";
import helper from "./index";

const Processes = () => {
  return [
    { label: "I'm just researching", value: 1, checked: false },
    { label: "I'm going to open houses", value: 2, checked: false },
    { label: "I'm making offers", value: 3, checked: false },
    { label: "I have signed a purchase contract", value: 4, checked: false },
  ];
};

const PropertyPlan = () => {
  return [
    { value: 1, label: "0 - 3 months", checked: false },
    { value: 2, label: "3 - 6 months", checked: false },
    { value: 3, label: "6+ months", checked: false },
    { value: 4, label: "Not sure", checked: false },
  ];
};

const HelpItems = () => {
  return [
    { value: 1, label: "Get a pre-approval letter", checked: false },
    { value: 2, label: "Get an estimate", checked: false },
    /* { value: 3, label: "Find a real estate agent", checked: false }, */
  ];
};

const PropertyTypes = () => {
  return [
    { value: 1, label: "Single Family", checked: false },
    { value: 2, label: "Townhouse", checked: false },
    { value: 3, label: "Condo", checked: false },
    { value: 4, label: "2 to 4 Units", checked: false },
  ];
};

const PropertyUses = () => {
  return [
    { value: 1, label: "Primary Residence", checked: false },
    { value: 2, label: "Vacation Home", checked: false },
    { value: 3, label: "Investment", checked: false },
  ];
};

const PropertyPrices = () => {
  return [
    { value: 1, label: "Less than $100,000", checked: false },
    { value: 2, label: "$100,001 to $300,000", checked: false },
    { value: 3, label: "$300,001 to $500,000", checked: false },
    { value: 4, label: "$500,001 +", checked: false },
  ];
};

const PropertyDownPayments = () => {
  return [
    { value: 1, label: "0% VA/USDA", checked: false },
    { value: 2, label: "3% Conv.", checked: false },
    { value: 3, label: "3.5% FHA", checked: false },
    { value: 4, label: "5%", checked: false },
    { value: 5, label: "10%", checked: false },
    { value: 6, label: "20%", checked: false },
  ];
};

const MortgageTypes = (defLabel) => {
  if (defLabel) {
    return [
      { value: 0, text: defLabel },
      { value: 1, text: "Conventional" },
      { value: 2, text: "VA" },
      { value: 3, text: "FHA" },
      { value: 4, text: "USDA" },
    ];
  }
  return [
    { value: 0, text: "Select" },
    { value: 1, text: "Conventional" },
    { value: 2, text: "VA" },
    { value: 3, text: "FHA" },
    { value: 4, text: "USDA" },
  ];
};

const LoanTypes = (defLabel) => {
  if (defLabel) {
    return [
      { value: 0, text: defLabel },
      { value: 1, text: "Purchase" },
      { value: 2, text: "Refinance" },
      { value: 3, text: "Others" },
    ];
  }
  return [
    { value: 0, text: "Select" },
    { value: 1, text: "Purchase" },
    { value: 2, text: "Refinance" },
    { value: 3, text: "Others" },
  ];
};

const PhoneTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Mobile", value: "Mobile" },
    { id: 2, text: "Home", value: "Home" },
  ];
};

const CreditTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "720 + (Excellent)", value: "Excellent" },
    { id: 2, text: "660 - 719 (Good)", value: "Good" },
    { id: 3, text: "620 - 659 (Average)", value: "Average" },
    { id: 4, text: "580 - 619 (Below Average)", value: "Below Average" },
    { id: 5, text: "< 580 (Poor)", value: "Poor" },
  ];
};

const OptionTypes = () => {
  return [
    { label: "Yes", value: true, checked: false },
    { label: "No", value: false, checked: false },
  ];
};

const IncomeTypes = [
  { id: 0, name: "Select" },
  { id: 1, name: "Employed" },
  { id: 2, name: "Retired" },
  { id: 3, name: "Self Employed" },
  { id: 4, name: "Child Support/Alimony" },
  { id: 5, name: "Rental Income" },
  { id: 6, name: "Other Income" },
];

const HowDoYouBrief = () => {
  return [
    { value: 0, text: "Select" },
    { value: 1, text: "Sales Professional" },
    { value: 2, text: "Large Group Manager" },
  ];
};

const MonthlyDebits = () => {
  return [
    { value: 1, label: "$0 - $500", checked: false },
    { value: 2, label: "$501 - $1000", checked: false },
    { value: 3, label: "$1001 - $2000", checked: false },
    { value: 4, label: "$2001 - $5000", checked: false },
    { value: 5, label: "$5001 +", checked: false },
  ];
};

const GetTile = (arr, indx) => {
  return arr.filter((x) => x.id === indx)[0].title;
};

const BreadCrumbMaps = [
  {
    id: 1,
    name: "stage1",
    title: "Get Pre-approved",
    fields: [
      "processIndicatorText",
      "propertyPlanIndicatorText",
      "helpItemIndicatorText",
    ],
    link: "preq",
  },
  {
    id: 2,
    name: "stage2",
    title: "Property Details",
    fields: [
      "addressText",
      "propertyIndicatorText",
      "propertyUseIndicatorText",
      "propertyPriceIndicatorText",
      "propertyDownPaymentIndicatorText",
    ],
    link: "property",
  },
  {
    id: 3,
    name: "stage3",
    title: "About Self",
    fields: ["fullname", "fullphone", "militaryServiceIndicator"],
    link: "aboutself",
    child: "borrower",
  },
  {
    id: 4,
    name: "stage4",
    title: "Income Details",
    noexpand: true,
    link: "income",
  },
  {
    id: 5,
    name: "stage5",
    title: "Co-Borrower",
    noexpand: true,
    link: "stage5",
  },
  {
    id: 6,
    name: "confirm",
    title: "Confirm Account",
    noexpand: true,
    link: "confirm",
  },
];

const GetFullName = ({ row, names, tag, child }) => {
  if (helper.IsJSONEmpty(row)) return null;
  if (child) if (helper.IsJSONEmpty(row[child])) return null;

  let items = [];

  names.forEach((elm) => {
    if (child) {
      if (!helper.IsNull(row[child][elm])) items.push(row[child][elm]);
    } else {
      if (!helper.IsNull(row[elm])) items.push(row[elm]);
    }
  });

  let _name = items.join(" ").trim();
  if (!helper.IsNull(_name)) {
    if (tag) {
      return <li className="sub_breadcrumb">{_name}</li>;
    } else {
      return _name;
    }
  }
  return null;
};

const GetPhoneDisplay = ({ row, type, val, tag, child }) => {
  if (helper.IsJSONEmpty(row)) return null;
  if (child) if (helper.IsJSONEmpty(row[child])) return null;

  let phoneName = row[val] || undefined;
  let phoneType = row[type] || undefined;
  if (child) {
    phoneName = row[child][val] || undefined;
    phoneType = row[child][type] || undefined;
  }

  let _display = null;
  if (!helper.IsNull(phoneType) && !helper.IsNull(phoneName)) {
    _display = `${phoneType}: ${phoneName}`;
  } else if (!helper.IsNull(phoneType) && helper.IsNull(phoneName)) {
    _display = phoneType;
  } else if (helper.IsNull(phoneType) && !helper.IsNull(phoneName)) {
    _display = phoneName;
  }

  if (tag && !helper.IsNull(_display)) {
    return <li className="sub_breadcrumb">{_display}</li>;
  }

  return _display;
};

const GetMilitaryService = ({ row, name, tag, child }) => {
  if (helper.IsJSONEmpty(row)) return null;
  if (child) if (helper.IsJSONEmpty(row[child])) return null;
  let val = row[name];
  if (child) {
    val = row[child][name] || undefined;
  }
  if (!helper.IsNull(val)) {
    let _display = "Military Service:" + (val ? " Yes" : " No");
    if (tag) {
      return <li className="sub_breadcrumb">{_display}</li>;
    } else {
      return _display;
    }
  }
  return null;
};

const DrawBreadCrumbs = ({ stages, item, onNodeClicked }) => {
  let stage = stages[item.name];
  //console.log(item.title);
  if (stage === undefined || stage === null) return null;
  let expand = "+";
  return (
    <>
      <li className="breadcrumb">{item.id}</li>
      <li className="breadcrumb">
        <Link to="#" onClick={(e) => onNodeClicked(item.id)}>
          {item.title}
        </Link>
        {!item.noexpand && (
          <>
            <div className="expCol">
              <Link
                to="#"
                onClick={(e) => {
                  expand = expand === "+" ? "-" : "+";
                  let _display = expand === "+" ? "none" : "block";
                  e.target.parentElement.parentElement.children[2].style.display =
                    _display;
                  e.target.text = expand;
                }}
              >
                &#43;
              </Link>
            </div>
            <div className="childBlock" style={{ display: "none" }} id="Block1">
              <ul className="sub_breadcrumb">
                {item.fields &&
                  item.fields.map((col, index) => {
                    if (col === "fullname") {
                      // row, names, tag, index
                      return (
                        <GetFullName
                          row={stage}
                          names={["firstName", "lastName"]}
                          tag={true}
                          key={index}
                          child={item.child}
                        />
                      );
                    } else if (col === "fullphone") {
                      // row, type, val, tag
                      return (
                        <GetPhoneDisplay
                          row={stage}
                          type={"phoneType"}
                          val={"mobilePhone"}
                          tag={true}
                          key={index}
                          child={item.child}
                        />
                      );
                    } else if (col === "militaryServiceIndicator") {
                      // row, name, tag
                      return (
                        <GetMilitaryService
                          row={stage}
                          name={"militaryServiceIndicator"}
                          tag={true}
                          key={index}
                          child={item.child}
                        />
                      );
                    }
                    return (
                      stage[col] && (
                        <li className="sub_breadcrumb" key={index}>
                          {stage[col]}
                        </li>
                      )
                    );
                  })}
              </ul>
            </div>
          </>
        )}
      </li>
    </>
  );
};

const LoanAmountTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Less than $70,000", value: "Less than $70,000" },
      { id: 2, text: "$70,001 - $100,000", value: "$70,001 - $100,000" },
      { id: 3, text: "$100,001 - $150,000", value: "$100,001 - $150,000" },
      { id: 3, text: "$150,001 - $200,000", value: "$150,001 - $200,000" },
      { id: 3, text: "$200,001 + ", value: "$200,001 + " },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Less than $70,000", value: "Less than $70,000" },
    { id: 2, text: "$70,001 - $100,000", value: "$70,001 - $100,000" },
    { id: 3, text: "$100,001 - $150,000", value: "$100,001 - $150,000" },
    { id: 3, text: "$150,001 - $200,000", value: "$150,001 - $200,000" },
    { id: 3, text: "$200,001 + ", value: "$200,001 + " },
  ];
};

const PropertyValueTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Less than $70,000", value: "Less than $70,000" },
      { id: 2, text: "$70,001 - $100,000", value: "$70,001 - $100,000" },
      { id: 3, text: "$100,001 - $150,000", value: "$100,001 - $150,000" },
      { id: 3, text: "$150,001 - $200,000", value: "$150,001 - $200,000" },
      { id: 3, text: "$200,001 + ", value: "$200,001 + " },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Less than $70,000", value: "Less than $70,000" },
    { id: 2, text: "$70,001 - $100,000", value: "$70,001 - $100,000" },
    { id: 3, text: "$100,001 - $150,000", value: "$100,001 - $150,000" },
    { id: 3, text: "$150,001 - $200,000", value: "$150,001 - $200,000" },
    { id: 3, text: "$200,001 + ", value: "$200,001 + " },
  ];
};

const LoanValueTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Purchase Loan", value: "Purchase Loan" },
      { id: 2, text: "Refinance Loan", value: "Refinance Loan" },
      { id: 3, text: "Debt Consoldation", value: "Debt Consoldation" },
      { id: 3, text: "Home Equity", value: "Home Equity" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Purchase Loan", value: "Purchase Loan" },
    { id: 2, text: "Refinance Loan", value: "Refinance Loan" },
    { id: 3, text: "Debt Consoldation", value: "Debt Consoldation" },
    { id: 3, text: "Home Equity", value: "Home Equity" },
  ];
};

const CreditValueTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Excellent", value: "Excellent" },
      { id: 2, text: "Good", value: "Good" },
      { id: 3, text: "Fair", value: "Fair" },
      { id: 3, text: "Poor", value: "Poor" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Excellent", value: "Excellent" },
    { id: 2, text: "Good", value: "Good" },
    { id: 3, text: "Fair", value: "Fair" },
    { id: 3, text: "Poor", value: "Poor" },
  ];
};

export {
  GetTile,
  Processes,
  PropertyPlan,
  HelpItems,
  PropertyTypes,
  PropertyUses,
  PropertyPrices,
  PropertyDownPayments,
  IncomeTypes,
  MonthlyDebits,
  BreadCrumbMaps,
  DrawBreadCrumbs,
  GetFullName,
  GetPhoneDisplay,
  GetMilitaryService,
  OptionTypes,
  PhoneTypes,
  MortgageTypes,
  LoanTypes,
  HowDoYouBrief,
  CreditTypes,
  LoanAmountTypes,
  PropertyValueTypes,
  LoanValueTypes,
  CreditValueTypes
};
