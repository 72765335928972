import {
  React, Link, Helper,
  TextInput2 as TextInput, RadioInput2 as RadioInput,
  DropDown2 as DropDown, CheckInput2 as CheckInput
} from "../../../../common";

const RenderChildComponent = (props) => {
  const { path, item, count, unittypes, onInputChanged, statesList,
    errors, onBlurError, onInputClicked } = props;

  const GetTypeIndicator = (e) => {
    let tmp = item?.employmentTypeIndicator || "";
    return tmp === e;
  };

  const OnDeleteClicked = (e) => {
    e.preventDefault();
    if (onInputChanged) onInputChanged(`${path}.delete`.replace(/\./g, '_'), true);
  }

  return (
    <>
      {count && count > 1 && (
        <div className="formPartition">
          <div className="closePartition">
            <Link to="#" onClick={(e) => OnDeleteClicked(e)}>&#x2715; &nbsp;Delete</Link>
          </div>
        </div>
      )}
      <ul className="formContentIIcol">
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Employer or Business Name</div>
          <div className="officeicon"></div>
          <TextInput
            path={`${path}.employerName`}
            value={item?.employerName}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Phone</div>
          <div className="phicon"></div>
          <TextInput
            path={`${path}.phoneNumber`}
            value={item?.phoneNumber}
            valuetype={"PHONE"}
            onInputChildChanged={onInputChanged}
            onBlurError={onBlurError}
            onInputClicked={onInputClicked}
            validate={true}
            validationMessage={'Enter valid Phone'}
            errors={errors}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Street</div>
          <div className="locationicon"></div>
          <TextInput
            path={`${path}.uRLA2020StreetAddress`}
            value={item?.uRLA2020StreetAddress}
            onInputChildChanged={onInputChanged} />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Unit No</div>
          <div className="hastagicon"></div>
          <TextInput
            path={`${path}.unitNumber`}
            value={item?.unitNumber}
            valuetype={'NFINANCE'}
            onInputChildChanged={onInputChanged} />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Unit Type</div>
          <div className="locationicon"></div>
          <DropDown
            path={`${path}.unitType`}
            value={item?.unitType}
            items={unittypes}
            displayName={"text"}
            displayValue={"value"}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">City</div>
          <div className="locationicon"></div>
          <TextInput
            path={`${path}.addressCity`}
            value={item?.addressCity}
            onInputChildChanged={onInputChanged} />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">State</div>
          <div className="locationicon"></div>
          <DropDown
            path={`${path}.addressState`}
            value={item?.addressState}
            items={statesList}
            displayName={"text"}
            displayValue={"value"}
            onInputChildChanged={onInputChanged}
          />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Zip Code</div>
          <div className="form_homeicon"></div>
          <TextInput
            path={`${path}.addressPostalCode`}
            value={item?.addressPostalCode}
            onInputChildChanged={onInputChanged} />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Country</div>
          <div className="countryicon"></div>
          <TextInput
            path={`${path}.country`}
            value={item?.country}
            onInputChildChanged={onInputChanged} />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">Position or Title</div>
          <div className="noteicon"></div>
          <TextInput
            path={`${path}.positionDescription`}
            value={item?.positionDescription}
            onInputChildChanged={onInputChanged} />
        </li>
        <li className="formContentIIcol">
          <div className="form1003ContentHead">
            {GetTypeIndicator('Previous') ? "From date" : "Start date"}
          </div>
          <div className="form_dateicon"></div>
          <TextInput
            placeholder="MM/DD/YYYY"
            path={`${path}.employmentStartDate`}
            value={Helper.ToDate(item?.employmentStartDate, 'MM/DD/YYYY')}
            valuetype={"DATE"}
            onInputChildChanged={onInputChanged}
            onBlurError={onBlurError}
            onInputClicked={onInputClicked}
            validate={true}
            validationMessage={'Enter valid start date'}
            errors={errors}
          />
        </li>

        {GetTypeIndicator('Previous') && (
          <li className="formContentIIcol">
            <div className="form1003ContentHead">To date</div>
            <div className="form_dateicon"></div>
            <TextInput
              path={`${path}.endDate`}
              placeholder="MM/DD/YYYY"
              value={Helper.ToDate(item?.endDate, 'MM/DD/YYYY')}
              valuetype={"DATE"}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
              onInputClicked={onInputClicked}
              validate={true}
              validationMessage={'Enter valid end date'}
              errors={errors} />
          </li>
        )}

        {!GetTypeIndicator('Previous') && (
          <li className="formContentIIcol">
            <div className="form1003ContentHead">
              How long in this line of work?
            </div>
            <ul className="formContentIIcol_noBot">
              <li className="formContentIIcol_noBot">
                <div className="form_dateicon"></div>
                <DropDown
                  path={`${path}.timeInLineOfWorkYears`}
                  value={item?.timeInLineOfWorkYears}
                  items={Helper.GetYears()}
                  displayName={"text"}
                  displayValue={"value"}
                  onInputChildChanged={onInputChanged}
                />
              </li>
              <li className="formContentIIcol_noBot">
                <div className="form_dateicon"></div>
                <DropDown
                  path={`${path}.jobTermMonths`}
                  value={item?.jobTermMonths}
                  items={Helper.GetMonths()}
                  displayName={"text"}
                  displayValue={"value"}
                  onInputChildChanged={onInputChanged}
                />
              </li>
            </ul>
          </li>
        )}

        {!GetTypeIndicator('Previous') && (
          <div className="spacer_20margin_bottom">
            <div className="form1003ContentHead">
              Check if this statement applies
            </div>
            <div className="formCheckboxLabel">
              <CheckInput
                path={`${path}.specialEmployerRelationshipIndicator`}
                value={item?.specialEmployerRelationshipIndicator}
                content={`I am employed by a family member, property seller, real estate agent, or other party to the transaction`}
                onInputChildChanged={onInputChanged} />
            </div>
          </div>
        )}
        <div className="spacer_20margin_bottom">
          <div className="form1003ContentHead">
            Business Owner or Self-Employed
          </div>
          <div className="formCheckboxLabel">
            <CheckInput
              path={`${path}.selfEmployedIndicator`}
              value={item?.selfEmployedIndicator}
              content={`Check if you are the Business Owner or Self-Employed`}
              onInputChildChanged={onInputChanged} />
          </div>
        </div>
        {/* <div style={{color:"red"}}>Show Ownership Share and Monthly Income, if business owner or self employed is selected. Don't show Gross Monthly Income</div> */}
        {item?.selfEmployedIndicator && !GetTypeIndicator('Previous') && (
          <>
            <div className="spacer_20margin_bottom">
              <div className="form1003ContentHead">Ownership Share</div>
              <ul className="form_A">
                <RadioInput
                  path={`${path}.ownershipInterestType`}
                  value={item?.ownershipInterestType}
                  options={Helper.Ownerships()}
                  uselabel={false}
                  onInputChildChanged={onInputChanged} />
              </ul>
            </div>
          </>
        )}

        {item?.selfEmployedIndicator && (
          <>
            <li className="formContentIIcol">
              <div className="form1003ContentHead">
                {GetTypeIndicator('Previous') ? "Previous Monthly Income" : "Monthly Income (or Loss)"}
              </div>
              <div className="form_dollaricon"></div>
              <TextInput
                path={`${path}.employmentMonthlyIncomeAmount`}
                value={item?.employmentMonthlyIncomeAmount}
                valuetype={"FINANCE"}
                onInputChildChanged={onInputChanged} />
            </li>
          </>
        )}
      </ul>
      {item?.selfEmployedIndicator && !GetTypeIndicator('Previous') && (
        <>
          <div className="form1003BlkInsideHead">Gross Monthly Income</div>
          <ul className="formContentIIcol">
            <li className="formContentIIcol">
              <div className="form1003ContentHead">Base</div>
              <div className="form_dollaricon"></div>
              <TextInput
                path={`${path}.basePayAmount`}
                value={item?.basePayAmount}
                valuetype={"FINANCE"}
                onInputChildChanged={onInputChanged} />
            </li>
            <li className="formContentIIcol">
              <div className="form1003ContentHead">Overtime</div>
              <div className="form_dollaricon"></div>
              <TextInput
                path={`${path}.overtimeAmount`}
                value={item?.overtimeAmount}
                valuetype={"FINANCE"}
                onInputChildChanged={onInputChanged} />
            </li>
            <li className="formContentIIcol">
              <div className="form1003ContentHead">Bonus</div>
              <div className="form_dollaricon"></div>
              <TextInput
                path={`${path}.bonusAmount`}
                value={item?.bonusAmount} valuetype={"FINANCE"}
                onInputChildChanged={onInputChanged} />
            </li>
            <li className="formContentIIcol">
              <div className="form1003ContentHead">Commission</div>
              <div className="form_dollaricon"></div>
              <TextInput
                path={`${path}.commissionsAmount`}
                value={item?.commissionsAmount} valuetype={"FINANCE"}
                onInputChildChanged={onInputChanged} />
            </li>
            <li className="formContentIIcol">
              <div className="form1003ContentHead">Military Entitlements</div>
              <div className="form_dollaricon"></div>
              <TextInput
                path={`${path}.entitlementsAmount`}
                value={item?.entitlementsAmount} valuetype={"FINANCE"}
                onInputChildChanged={onInputChanged} />
            </li>
            <li className="formContentIIcol">
              <div className="form1003ContentHead">Other</div>
              <div className="form_dollaricon"></div>
              <TextInput
                path={`${path}.otherAmount`}
                value={item?.otherAmount} valuetype={"FINANCE"}
                onInputChildChanged={onInputChanged} />
            </li>
          </ul>
        </>
      )}
    </>
  );
};

const RenderEmployment = (props) => {
  const { item, unittypes, onInputChanged, statesList, errors,
    onBlurError, onInputClicked } = props;

  const rootpath = `employments.${item?.index}`;

  const GetTypeIndicator = (e) => {
    let tmp = item?.employmentTypeIndicator || "";
    return tmp === e;
  };

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">{item.title}</div>
        <div className="form1003Blk_right">&nbsp;</div>
      </div>
      <div className="form1003ContentBlk">
        {(GetTypeIndicator('Additional')) && (
          <>
            <div className="spacer_20margin_bottom">
              <div className="form1003ContentHead">
                Do you want to add {item.employmentTypeIndicator} Employment/Self-Employment and Income
              </div>
              <ul className="form_A" data-opts={true}
                data-req-msg={`Do you want to add ${item?.employmentTypeIndicator} Employment/Self-Employment and Income`}
                data-id={`${rootpath}.addEmployment`}>
                <RadioInput
                  path={`${rootpath}.addEmployment`}
                  value={Helper.ToUndefinedCheck(item?.addEmployment, true)}
                  options={Helper.Options()}
                  uselabel={false}
                  onInputChildChanged={onInputChanged}
                  errors={errors}
                />
              </ul>
            </div>
          </>
        )}

        {item?.addEmployment && (
          <RenderChildComponent
            errors={errors}
            path={rootpath}
            onInputChanged={onInputChanged}
            onBlurError={onBlurError}
            onInputClicked={onInputClicked}
            item={item} unittypes={unittypes}
            statesList={statesList} />
        )}
      </div>
    </>
  );
};

const RenderEmployments = (props) => {
  const { items, unittypes, onInputChanged, statesList, errors,
    onBlurError, onInputClicked, onAddArrayList } = props;

  const item = items && items.childs && items.childs.length > 0 ? items.childs[0] : null;

  const AddNewEmployment = (e) => {
    e.preventDefault();
    let obj = {
      title: item?.title, addEmployment: true,
      owner: item?.owner,
      currentEmploymentIndicator: item?.currentEmploymentIndicator,
      employmentTypeIndicator: item?.employmentTypeIndicator,
      delete: false
    };
    if (onAddArrayList) onAddArrayList('employments', obj);
  }

  return (
    <>
      <div className="form1003BlkHead">
        <div className="formHeadContent">{item?.title}</div>
        <div className="form1003Blk_right">&nbsp;</div>
      </div>
      <div className="form1003ContentBlk">
        <div className="spacer_20margin_bottom">
          <div className="form1003ContentHead">
            Do you want to add {item?.employmentTypeIndicator} Employment/Self-Employment and Income
          </div>
          <ul className="form_A" data-opts={true}
            data-req-msg={`Do you want to add ${item?.employmentTypeIndicator} Employment/Self-Employment and Income`}
            data-id={`employments.${item?.index}.addEmployment`}>
            <RadioInput
              path={`employments.${item?.index}.addEmployment`}
              value={Helper.ToUndefinedCheck(item?.addEmployment, true)}
              options={Helper.Options()}
              uselabel={false}
              onInputChildChanged={onInputChanged}
              errors={errors}
            />
          </ul>
        </div>

        {item?.addEmployment && (
          <div className="form1003BlkInsideHead">
            <div className="formHeadContent">Provide at least 2 years of current and previous emp</div>
            <div className="form1003Blk_right">
              <input type="button" value="+ New" className="headRight_button"
                onClick={(e) => AddNewEmployment(e)} />
            </div>
          </div>
        )}

        {item?.addEmployment && items && items.childs && items.childs.map((x) => {
          if (!x.delete) {
            return (
              <RenderChildComponent
                count={items.childs.filter((x) => !x.delete).length}
                key={x.index}
                path={`employments.${x.index}`}
                item={x}
                unittypes={unittypes}
                onInputChanged={onInputChanged}
                statesList={statesList}
                onBlurError={onBlurError}
                onInputClicked={onInputClicked}
                errors={errors}
              />
            )
          } else { return null }
        })}
      </div>
    </>
  );
};

export { RenderEmployment, RenderEmployments };
