import { React, Helper } from "../../../../../common";

const Component = (props) => {
    const { path, content, value, onInputOptionChanged } = props;

    const OnCheckClicked = (e) => {
        if (onInputOptionChanged) {
            onInputOptionChanged(path.replace(/\./g, '_'), e);
        }
    }

    const OnDummyChanged = () => { };

    return (
        <>
            <input type="checkbox"
                id={path}
                name={path}
                checked={Helper.ToCheck(value)}
                onChange={() => OnDummyChanged()}
                onClick={(e) => OnCheckClicked(e.target.checked)} />
            <label htmlFor={path}>{content}</label>
        </>
    );
};

export default Component;
