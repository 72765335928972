import { React, Link, Helper } from "../../../../../common";

const Component = (props) => {
    const { path, tag, errors, errorId, item, options, onInputOptionChanged } = props;

    const OnCheckClicked = (e) => {
        let values = options.map((x) => {
            let _path = `${path}.${tag}.${x.name}`.replace(/\./g, '_');
            let checked = x.name === e.name ? true : false;
            return { name: _path, value: checked };
        });
        values.forEach(elm => {
            if (onInputOptionChanged) onInputOptionChanged(elm.name, elm.value);
        });
    }

    const GetValue = (e) => {
        return (item && item[e]) || undefined;
    }

    let errorStyle = {
        borderColor: "#b22929",
        borderStyle: "solid",
        borderWidth: 1,
    };

    return (
        <>
            {options && options.map((x, i) => {
                return (
                    <li key={i}>
                        <Link to="#" id={x.name}
                            className={GetValue(x.name) ? "current" : undefined}
                            style={
                                Helper.GetErrorIndex(errors, errorId) > -1
                                    ? errorStyle
                                    : { borderWidth: 1 }
                            }
                            onClick={(e) => OnCheckClicked(x)} >
                            {x.label}
                        </Link>
                    </li>
                );
            })}
        </>
    );
};

export default Component;
