import { React } from "../../../../common";
import Container from "../../../container";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Consent to receive documents</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="Icol">
                                <div className="pgTxtNormal">I hereby consent to receive all loan documents, including but not limited to the loan application, loan agreement, disclosures, and other related documents, in electronic form from i3lending Inc. I understand that these documents will be provided to me electronically, and I acknowledge that I have the necessary hardware and software to access and retain these documents.</div>
                                <div className="pgTxtNormal">I understand that I may withdraw my consent to receive electronic documents at any time by providing written notice to i3lending Inc and that in the event I withdraw my consent, I may be required to provide a physical mailing address to receive these documents.</div>
                                <div className="pgTxtNormal">I acknowledge that I have read and understand the terms and conditions of receiving electronic loan documents from i3lending Inc. and that I consent to receiving these documents in electronic form.</div>
                            </div>

                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
