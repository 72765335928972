const Component = (props) => {
  const { title, message, display, onCancelClicked, onConfirmClicked } = props;

  const OnSubmit = async (e, status) => {
    e.preventDefault();
    if (onConfirmClicked) onConfirmClicked(status);
  };

  const OnCancelClicked = (e) => {
    e.preventDefault();
    if (onCancelClicked) onCancelClicked(e);
  };

  if (display) {
    return (
      <div id="confirm_pop" className="loader display-block">
        <section className="popup-modal">
          <div className="confirm_pop" style={{ top: "20%" }}>
            <div className="confirm_ContentArea">
              <div className="confirm_Head">{title}</div>
              <div className="confirm_content">{message}</div>
              <div className="confirm_button_label">
                <input
                  type="button"
                  className="confirmBut"
                  value="Yes"
                  onClick={(e) => OnSubmit(e, true)}
                />
                <input
                  type="button"
                  className="confirmBut"
                  value="No"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => OnSubmit(e, false)}
                />
                <input
                  type="button"
                  className="confirmBut"
                  value="Cancel"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => OnCancelClicked(e)}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return null;
  }
};

export default Component;
