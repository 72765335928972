import { React, Helper } from "../../../../../common";

const Component = (props) => {
    const { path, value, maxLength, onInputTextChanged, placeholder } = props;

    const OnTextInputChanged = (e) => {
        if (onInputTextChanged) onInputTextChanged(GetKeyName(), e);
    }

    const GetKeyName = () => {
        return path.replace(/\./g, '_');
    }

    return (
        <input
            id={GetKeyName()}
            name={GetKeyName()}
            type="text"
            value={Helper.ToString(value)}
            maxLength={maxLength && maxLength}
            placeholder={Helper.ToString(placeholder)}
            onChange={(e) => OnTextInputChanged(e.target.value)}
            style={{ paddingLeft: 10 }}
        />
    );
};

export default Component;
