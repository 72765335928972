import { React } from "../../../../common";
import Container from "../../../container";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_review">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Reviews &amp; Feedback</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="reviewBlk">
                                <ul className="IIcol_Review">
                                    <li className="IIcol_Review">
                                        <div className="reviewContentBlkLeft">
                                            <ul className="reviewPhotoContent">
                                                <li className="reviewPhotoContentLeft">ME
                                                    {/* <div className="reviewerPhoto"><img src="#" alt="" /></div> */}
                                                </li>
                                                <li className="reviewPhotoContentLeft">
                                                    <div className="reviewerName">MARY ELIZABETH</div>
                                                    <ul className="ratingDateBlk">
                                                        <li className="ratingDateBlk">
                                                            <div className="rating_sprite_red"><span className="rating_sprite_red_bg" style={{ width: "100%" }}></span></div>
                                                        </li>
                                                        <li className="ratingDateBlk">January 10, 2023</li>
                                                    </ul>
                                                    <div className="reviewDesc">Outstanding Experience!</div>
                                                    <div className="reviewContent">I would like to take this opportunity and thank Golf Phuphanich for an amazing experience. I had some credit issues to deal with and Golf was able to think outside of the box and help me find someone that was willing to invest in me!! The transaction went very smoothly..</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="reviewCurveArrowLeft" >
                                            <li className="reviewCurveArrowLeft">
                                                <div className="reviewLeftCurve"></div>
                                            </li>
                                            <li className="reviewCurveArrowLeft">&nbsp;</li>
                                        </ul>
                                    </li>

                                    <li className="IIcol_Review">
                                        <div className="reviewContentBlkRight">
                                            <ul className="reviewPhotoContent">
                                                <li className="reviewPhotoContentRight">
                                                    <div className="reviewerName">RAFEEQ PARKMAN</div>
                                                    <ul className="ratingDateBlk">
                                                        <li className="ratingDateBlk">
                                                            <div className="rating_sprite_red"><span className="rating_sprite_red_bg" style={{ width: "100%" }}></span></div>
                                                        </li>
                                                        <li className="ratingDateBlk">December 06, 2022</li>
                                                    </ul>
                                                    <div className="reviewDesc">Greta Experience</div>
                                                    <div className="reviewContent">As an first time home buyer I got lucky to get paired with Cedic Harris! I had a million questions and sometimes repeated the questions but Cedic has a calm and helping demeanor. No matter the issue Cedic helped and explained anything that came to my mind.</div>
                                                </li>
                                                <li className="reviewPhotoContentRight">RP
                                                    {/* <div className="reviewerPhoto"><img src="#" alt="" /></div> */}
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="reviewCurveArrowRight" >
                                            <li className="reviewCurveArrowRight">
                                                &nbsp;
                                            </li>
                                            <li className="reviewCurveArrowRight">
                                                <div className="reviewRightCurve"></div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="IIcol_Review">
                                        <div className="reviewContentBlkLeft">
                                            <ul className="reviewPhotoContent">
                                                <li className="reviewPhotoContentLeft">MC
                                                    {/* <div className="reviewerPhoto"><img src="#" alt="" /></div> */}
                                                </li>
                                                <li className="reviewPhotoContentLeft">
                                                    <div className="reviewerName">MICHAEL CRUZ</div>
                                                    <ul className="ratingDateBlk">
                                                        <li className="ratingDateBlk">
                                                            <div className="rating_sprite_red"><span className="rating_sprite_red_bg" style={{ width: "100%" }}></span></div>
                                                        </li>
                                                        <li className="ratingDateBlk">December 01, 2022</li>
                                                    </ul>
                                                    <div className="reviewDesc">Great customer service, great rates!</div>
                                                    <div className="reviewContent">I was originally told be my realtor to just use the builders lender since they gave me 5000 towards me cost. Luckily a friend called me and informed me about i3Lending and have them to do a comparison. i3Lending had a better rate by over a half a percentage.</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="reviewCurveArrowLeft" >
                                            <li className="reviewCurveArrowLeft">
                                                <div className="reviewLeftCurve"></div>
                                            </li>
                                            <li className="reviewCurveArrowLeft">&nbsp;</li>
                                        </ul>
                                    </li>

                                    <li className="IIcol_Review">
                                        <div className="reviewContentBlkRight">
                                            <ul className="reviewPhotoContent">
                                                <li className="reviewPhotoContentRight">
                                                    <div className="reviewerName">CLARK KENDRA</div>
                                                    <ul className="ratingDateBlk">
                                                        <li className="ratingDateBlk">
                                                            <div className="rating_sprite_red"><span className="rating_sprite_red_bg" style={{ width: "100%" }}></span></div>
                                                        </li>
                                                        <li className="ratingDateBlk">November 15, 2022</li>
                                                    </ul>
                                                    <div className="reviewDesc">Always responded quickly to any questions</div>
                                                    <div className="reviewContent">Peter Haskins is amazing! He always responded quickly to any questions I had and always followed up with me on needed information. Peter is also very knowledgeable and has a great personality! I would definitely recommend him to anyone and hope to work with him again in the future!</div>
                                                </li>
                                                <li className="reviewPhotoContentRight">CK
                                                    {/* <div className="reviewerPhoto"><img src="#" alt="" /></div> */}
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="reviewCurveArrowRight" >
                                            <li className="reviewCurveArrowRight">
                                                &nbsp;
                                            </li>
                                            <li className="reviewCurveArrowRight">
                                                <div className="reviewRightCurve"></div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="IIcol_Review">
                                        <div className="reviewContentBlkLeft">
                                            <ul className="reviewPhotoContent">
                                                <li className="reviewPhotoContentLeft">BM
                                                    {/* <div className="reviewerPhoto"><img src="#" alt="" /></div> */}
                                                </li>
                                                <li className="reviewPhotoContentLeft">
                                                    <div className="reviewerName">Brandon Mclean</div>
                                                    <ul className="ratingDateBlk">
                                                        <li className="ratingDateBlk">
                                                            <div className="rating_sprite_red"><span className="rating_sprite_red_bg" style={{ width: "100%" }}></span></div>
                                                        </li>
                                                        <li className="ratingDateBlk">November 06, 2022</li>
                                                    </ul>
                                                    <div className="reviewDesc">Great customer service, great rates!</div>
                                                    <div className="reviewContent">Let me start with WOW!! That’s really all I can say about the experience I have had with i3 Lending and Sanders! This is the second home I have purchased with Mr Sanders and i3 Lending. I have also refinanced a home with him. I would like to take this opportunity and thank Sander for an amazing experience.</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="reviewCurveArrowLeft" >
                                            <li className="reviewCurveArrowLeft">
                                                <div className="reviewLeftCurve"></div>
                                            </li>
                                            <li className="reviewCurveArrowLeft">&nbsp;</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
