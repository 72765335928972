import { React, Helper, ViewPdf, UploadHelper, Session } from "../../common";
import { GetProfile, SetProfile, DeleteProfileDoc } from "../../services/profileApi";
import { GetDocumentUrl } from "../../services/documentApi";
import Container from "../container";

const maxsize = 106000000;
const formats = ["PNG", "JPG", "PDF"];
const readOnlyFields = ["first_name", "last_name", "email", "cell_phone"];

const RenderInputType = (props) => {
  const { value, item, OnInputChanged, errors, readonly } = props;

  let type = item.type || "";

  const OnBlurPhoneChanged = (name, val) => {
    val = Helper.ToUSAPhone(val);
    if (val === null) return;
    OnInputChanged(name, val);
  };

  if (type.toUpperCase() === "PHONE") {
    return (
      <input
        type="text"
        value={Helper.ToString(value)}
        onChange={(e) => OnInputChanged(item.name, e.target.value)}
        readOnly={readonly}
        style={{
          borderColor:
            errors && errors.indexOf(item.name) > -1 ? "#ff0000" : undefined,
        }}
        onKeyPress={(e) => {
          return Helper.AcceptedChars(e, "PHONE");
        }}
        onBlur={(e) => OnBlurPhoneChanged(item.name, e.target.value)}
      />
    );
  } else if (item.type.toUpperCase() === "INT") {
    return (
      <input
        type="text"
        value={Helper.ToString(value)}
        onChange={(e) => OnInputChanged(item.name, e.target.value)}
        readOnly={readonly}
        style={{
          borderColor:
            errors && errors.indexOf(item.name) > -1 ? "#ff0000" : undefined,
        }}
        onKeyPress={(e) => {
          return Helper.AcceptedChars(e, "NFINANCE");
        }}
      />
    );
  } else if (item.type.toUpperCase() === "FLOAT") {
    return (
      <input
        type="text"
        value={Helper.ToString(value)}
        onChange={(e) => OnInputChanged(item.name, e.target.value)}
        readOnly={readonly}
        style={{
          borderColor:
            errors && errors.indexOf(item.name) > -1 ? "#ff0000" : undefined,
        }}
        onKeyPress={(e) => {
          return Helper.AcceptedChars(e, "FINANCE");
        }}
      />
    );
  } else if (item.type.toUpperCase() === "YESNO") {
    return (
      <div className="appGenericBox">
        <ul className="appformColumn">
          <li className="appformColumn_inline">
            <div className="checkRadio_Lable">
              <input
                type="radio"
                id={`${item.name}_1`}
                name={`${item.name}`}
                onChange={(e) => OnInputChanged(item.name, 1)}
                checked={Helper.ToString(value) === "1"}
              />
              <label htmlFor={`${item.name}_1`}>Yes</label>
            </div>
          </li>
          <li className="appformColumn_inline">
            <div className="checkRadio_Lable">
              <input
                type="radio"
                id={`${item.name}_2`}
                name={`${item.name}`}
                checked={Helper.ToString(value) === "0"}
                onChange={(e) => OnInputChanged(item.name, 0)}
              />
              <label htmlFor={`${item.name}_2`}>No</label>
            </div>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <input
        type="text"
        value={Helper.ToString(value)}
        onChange={(e) => OnInputChanged(item.name, e.target.value)}
        readOnly={readonly}
        style={{
          borderColor:
            errors && errors.indexOf(item.name) > -1 ? "#ff0000" : undefined,
        }}
      />
    );
  }
};

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [schema, setSchema] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [profile, setProfile] = React.useState({});
  const [errors, setErrors] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [updated, setUpdated] = React.useState(null);
  const [ferror, setFError] = React.useState(null);
  const [fsuccess, setFSuccess] = React.useState(null);
  const [fileurl, setFileUrl] = React.useState(null);

  const [pdfStream, setPdfStream] = React.useState(null);
  const [viewPdf, setViewPdf] = React.useState(false);
  const [viewType, setViewType] = React.useState(null);
  const [docReq, setDocReq] = React.useState(null);
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    global.Busy(true);
    let rslt = await GetProfile();
    if (rslt.status === 100) {
      let tmp = rslt.data || { req: {}, schema: [], values: {}, profile: {} };
      setProfile(tmp.profile);
      setDocReq(tmp.req);
      setSchema(tmp.schema);
      setRow(tmp.values);
    }
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    setFileUrl(null);
    setErrors([]);
    setError(null);
    setUpdated(null);
    setFError(null);
    setFSuccess(null);
    FetchResults();
  }

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnViewDocument = async (e, doc, type) => {
    e.preventDefault();
    let data = {
      doc: doc,
      type: type,
      entity: "PROFILE",
    };
    setViewType(type);
    global.Busy(true);
    let rslt = await GetDocumentUrl(data);
    global.Busy(false);
    if (rslt.status !== 100) {
      alert(rslt.statusText);
      return;
    }
    setPdfStream(rslt.data);
    setViewPdf(true);
  };

  const OnDeleteDocument = async (e) => {
    e.preventDefault();
    let _docName = schema.filter((z) => z.type === "doc")[0].name;

    let data = {
      field: _docName,
      folder: docReq.folder,
    };

    global.Busy(true);
    let rslt = await DeleteProfileDoc(data);
    if (rslt.status === 100) {
      let _row = row;
      _row[_docName] = null;
      _row["blobname"] = null;
      setRow(_row);
    }
    global.Busy(false);
  };

  const OnUploadDocument = async (e) => {
    e.preventDefault();

    let _docName = schema.filter((z) => z.type === "doc")[0].name;

    setFSuccess(null);
    setFError(null);
    setErrors([]);
    if (Helper.IsNullValue(fileurl)) {
      Helper.NativeInputClick("loandocs");
      setErrors([_docName]);
      setFError("All fields are mandatory");
    } else if (fileurl.size > maxsize) {
      Helper.NativeInputClick("loandocs");
      setErrors([_docName]);
      setFError("File size allowed: 100mb");
    } else {
      let _req = docReq;
      _req.fileName = fileurl.name;
      _req.isPersona = true;
      let rslt = await UploadHelper.Upload(fileurl, { req: _req });
      console.log(rslt);
      if (rslt.status !== 100) {
        Helper.NativeInputClick("loandocs");
        setFError(rslt.statusText);
        return;
      }

      setFSuccess(true);
      let _row = row;
      let data = rslt.data;

      _row["blobname"] = data.blobname;
      _row[_docName] = data.filename;
      setRow(_row);
      setState(!state);
    }
  };

  const OnFileInputChanged = (e) => {
    e.preventDefault();
    setFError(null);
    setFSuccess(null);
    let _file = e.target.files[0];
    if (!Helper.IsNullValue(_file)) {
      let _ext = _file.name.split(".").pop();
      let _index = formats.findIndex((x) => x === _ext.toUpperCase());
      if (_index === -1) {
        setFError(`Supported formats:${formats.join(",")}`);
        Helper.NativeInputClick("proiddoc");
      } else {
        if (_file.size > maxsize) {
          setFError("File size allowed: 100mb");
          Helper.NativeInputClick("proiddoc");
        } else {
          setFileUrl(e.target.files[0]);
        }
      }
    }
  };

  const OnUpdateProfile = async (e, reset) => {
    e.preventDefault();
    if (reset) {
      setInitlized(true);
    } else {
      setError(null);
      setErrors([]);
      global.Busy(true);
      let rslt = await SetProfile(row);
      global.Busy(false);
      if (rslt.status !== 100) {
        setErrors(rslt.errors);
        setError(rslt.statusText);
        return;
      }
      setInitlized(true);
      await Session.StoreJSON("status", 1);
      setUpdated(rslt.statusText);
    }
  };

  const IsReadOnly = (x) => {
    return readOnlyFields.indexOf(x) > -1;
  };

  const IsBlobNameOk = () => {
    let _blobName = row.blobname;
    if (Helper.IsNullValue(_blobName)) return false;
    return true;
  };

  const RenderFileActions = () => {
    let _blobName = row.blobname;
    return (
      <div className="postUploadBtnLabel">
        <input
          type="button"
          className="postUploadBut"
          value="View ISP File"
          onClick={(e) => OnViewDocument(e, _blobName, "ISP")}
        ></input>
        <input
          type="button"
          className="postUploadBut"
          value="View File"
          onClick={(e) => OnViewDocument(e, `borrower/${_blobName}`, "AWS")}
        ></input>
        <input
          type="button"
          className="postUploadBut"
          value="Delete File"
          onClick={(e) => OnDeleteDocument(e)}
        ></input>
      </div>
    );
  };

  const OnChangePasswordClicked = (e) => {
    e.preventDefault();
    document.getElementById("changepswd_pop").style.display = "block";
  }

  return (
    <Container>
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div className="profile_pgBlk">
              <ul className="appPgStructure_300by100pc">
                <li className="appPgStructure_300by100pc">
                  <div className="app_box_shadow_noPad">
                    <div className="app_headBlk_pad20">
                      <div className="app_headBlk_blkName">Account Details</div>
                    </div>
                    <div className="appContentarea_pad20">
                      <div className="accDetails">
                        <div className="acc_name">{profile?.fullname}</div>
                        <div className="memberBlock">
                          <div className="memberHead">Member Since:</div>
                          <div className="memberTxt">
                            {Helper.ToDateFormat(
                              profile?.createdt,
                              "MMM DD, YYYY"
                            )}
                          </div>
                        </div>
                        <div className="mailPhBlock">
                          <div className="horBlk">
                            <div className="mailIcon"></div>
                            <div className="mailPh_txt">{profile?.email}</div>
                          </div>
                          <div className="horBlk">
                            <div className="phIcon"></div>
                            <div className="mailPh_txt">{profile?.mobile}</div>
                          </div>
                        </div>
                        <div className="acc_btn_label">
                          <input
                            type="button"
                            className="acc_btn"
                            value="Change Password"
                            onClick={(e) => OnChangePasswordClicked(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="appPgStructure_300by100pc">
                  <div className="app_box_shadow_noPad">
                    <div className="app_headBlk_pad20">
                      <div className="app_headBlk_blkName">Profile Details</div>
                    </div>
                    <div className="appContentarea_pad20">
                      <ul className="appformColumn">
                        {schema &&
                          schema
                            .filter(
                              (z) => z.type !== "doc" && z.type !== "comment"
                            )
                            .map((x) => {
                              return (
                                <li key={x.name} className="appformColumn_III">
                                  <div className="appformContentHead">
                                    {x.title}
                                  </div>
                                  {Helper.IsRequired(x.required) && (
                                    <div className="appformMandatory">*</div>
                                  )}
                                  {!Helper.IsNullValue(x.icon) && (
                                    <div className={x.icon}></div>
                                  )}
                                  <RenderInputType
                                    value={row[x.name]}
                                    item={x}
                                    OnInputChanged={OnInputChanged}
                                    errors={errors}
                                    readonly={IsReadOnly(x.name)}
                                  />
                                </li>
                              );
                            })}

                        {schema &&
                          schema
                            .filter((z) => z.type === "doc")
                            .map((x) => {
                              return (
                                <li key={x.name} className="appformColumn_I">
                                  <div className="appformContentHead">
                                    Upload Documents for - {x.title}
                                  </div>
                                  {x.required && (
                                    <div className="appformMandatory">*</div>
                                  )}
                                  {fsuccess && (
                                    <div className="uploadSuccess">
                                      Upload Successful
                                    </div>
                                  )}
                                  <div className="fileUpload">
                                    <div
                                      className="uploadBox"
                                      style={{
                                        marginRight: 2,
                                        borderColor:
                                          errors && errors.indexOf(x.name) > -1
                                            ? "#ff0000"
                                            : undefined,
                                      }}
                                    >
                                      <input
                                        id="proiddoc"
                                        type="file"
                                        name="proiddoc"
                                        onClick={(e) => {
                                          e.target.value = null;
                                        }}
                                        onChange={(e) => OnFileInputChanged(e)}
                                      />
                                    </div>
                                    <div className="buttonUploadLayout">
                                      <input
                                        type="submit"
                                        className="buttonUpload"
                                        value="Upload"
                                        onClick={(e) => OnUploadDocument(e)}
                                      />
                                    </div>
                                    {ferror && (
                                      <div className="submitError">
                                        {ferror}
                                      </div>
                                    )}
                                    {IsBlobNameOk() && <RenderFileActions />}
                                  </div>
                                </li>
                              );
                            })}

                        {schema &&
                          schema
                            .filter((z) => z.type === "comment")
                            .map((x, index) => {
                              return (
                                <li key={index} className="appformColumn_I">
                                  <div className="appformContentHead">
                                    {x.title}
                                  </div>
                                  {x.required && (
                                    <div className="appformMandatory">*</div>
                                  )}
                                  <div className={x.icon}></div>
                                  <input
                                    type="text"
                                    value={Helper.ToString(row[x.name])}
                                    onChange={(e) =>
                                      OnInputChanged(x.name, e.target.value)
                                    }
                                    style={{
                                      borderColor:
                                        errors && errors.indexOf(x.name) > -1
                                          ? "#ff0000"
                                          : undefined,
                                    }}
                                    readOnly={IsReadOnly(x.name)}
                                  />
                                </li>
                              );
                            })}
                      </ul>
                      <div className="appform_btn_label">
                        <input
                          type="button"
                          className="appform_btn"
                          value={
                            Helper.ToInteger(row.id) < 1 ? "Submit" : "Update"
                          }
                          onClick={(e) => OnUpdateProfile(e)}
                        />
                        <input
                          type="button"
                          value="Reset"
                          className="appform_btn"
                          onClick={(e) => OnUpdateProfile(e, true)}
                        />
                      </div>
                      {error && <div className="errormessage">{error}</div>}
                      {updated && (
                        <div className="successmessage">{updated}</div>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ViewPdf
        stream={pdfStream}
        setViewPdf={setViewPdf}
        viewPdf={viewPdf}
        viewType={viewType}
      />
    </Container>
  );
};

export default Component;
